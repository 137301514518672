import { LeftOutlined } from "@ant-design/icons";
import { Button, Form, FormInstance, useModal } from "@pankod/refine-antd";
import { Option } from "@pankod/refine-core";
import { FormItemHiddenLabel } from "components/Form/FormItemHiddenLabel";
import AlertModal from "components/Modal/AlertModal";
import { useProductStepForm } from "context/ProductStepFormContext";
import React from "react";
import { isDuplicatedFieldWithPlatform } from "utils/form";
import { FormItem } from "./FormItem";

export const ManualSetup: React.FC<{
  setIsManual?: (val: boolean) => void;
  isCreate?: boolean;
  form?: FormInstance<{}>;
  firebasesCreateOptions?: Option[];
  onSkipStep?: () => void;
}> = ({ setIsManual, form, firebasesCreateOptions, onSkipStep, isCreate }) => {
  const { setValues, formLoading, form: _form } = useProductStepForm();
  const [isSkip, setIsSkip] = React.useState<boolean>(false);
  const { show, modalProps, close } = useModal();

  const onConfirm = () => {
    setIsSkip(!isSkip);
    onSkipStep?.();
    setValues?.((prev: any) => {
      return {
        ...prev,
        linking_apps: [],
      };
    });
    _form?.resetFields(["linking_apps"]);
  };

  return (
    <div className="relative">
      <AlertModal
        modalProps={modalProps}
        handleClose={close}
        message={`If you skip this step, all your filled application information won't be saved`}
        handleOk={onConfirm}
        isLoading={false}
      />
      {false && (
        <span className="mb-[5rem] w-[140px] block cursor-pointer" onClick={() => setIsManual?.(false)}>
          <LeftOutlined /> Back to Mass upload
        </span>
      )}
      {!isCreate && (
        <Button className="border-0 absolute right-0 top-[-50px]" disabled={formLoading} onClick={show}>
          Skip
        </Button>
      )}
      {!isSkip && (
        <Form.List
          name="linking_apps"
          initialValue={[{ project_id: "" }]}
          rules={[
            {
              validator(_rule, value) {
                if (isDuplicatedFieldWithPlatform("product_code", value)) {
                  return Promise.reject(new Error("App code can not duplicate. Please check it again."));
                }
                if (isDuplicatedFieldWithPlatform("bundle_id", value)) {
                  return Promise.reject(new Error("App bundle id can not duplicate. Please check it again."));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name }, index) => (
                <FormItem
                  key={key}
                  keyField={key}
                  name={name}
                  index={index}
                  remove={remove}
                  form={form}
                  firebasesCreateOptions={firebasesCreateOptions}
                  fields={fields}
                />
              ))}
              {errors && errors.length !== 0 && (
                <FormItemHiddenLabel>
                  <span className="text-danger">{errors}</span>
                </FormItemHiddenLabel>
              )}
              <FormItemHiddenLabel>
                <span className="cursor-pointer text-primary-2" onClick={() => add()}>
                  Have other app version ? Click to Add more
                </span>
              </FormItemHiddenLabel>
            </>
          )}
        </Form.List>
      )}
    </div>
  );
};
