import { METRIC } from "../chartConfig";
import { IConvertData, IOptionMetric } from "interfaces/chart";
import dayjs from "dayjs";

// helpers
import { formatterLabelChart } from "../chartHelper/commonHelper";

export const splineOption = (metric: string, data: IConvertData, optionMetrics: IOptionMetric[]) => {
  const tooltip = {
    crosshairs: true,
    shared: true,
    borderRadius: 5,
    useHTML: true,
    backgroundColor: "#fff",
    borderColor: "rgb(229 232 237 / 1",
    padding: 15,
    outside: true,
    formatter: function (this: any) {
      const dayFormatter = dayjs(data.days[this.x]).format("MMM D, YYYY");
      let contents = `
        <div style="min-width: 300px">
          <div style="display: flex; justify-content: space-between">
            <div style="color:{point.color}; font-weight: bold; margin-right: 10px; margin-bottom: 3px;">${dayFormatter}</div>
          </div>
          <hr style="margin: 5px 0 10px 0;" />
        </div>
      `;

      this.points.forEach((item: any) => {
        let number = formatterLabelChart(item.y, metric);
        const name = item.series.name;
        if (metric === METRIC.ConvRate.value) {
          number = item.y + "%";
        }
        contents += `
          <div style="display: flex; justify-content: space-between; align-item: center; margin-top: 15px; min-width: 300px">
            <div style="display: flex; align-items: center; margin-right: 10px">
              <span style="width: 8px; height: 8px; border-radius: 50%; margin-right: 7px; background-color: ${item.series.color}"></span>
              <span>${name}</span>
            </div>
            <div>
              <b style="min-width: 50px; display: inline-block; text-align: right"">${number}</b>
            </div>
          </div>
        `;
      });

      return contents;
    },
  };

  let text = optionMetrics.find((ele) => ele.value === metric)?.label;
  const percents = [METRIC.ConvRate.value];
  const usds = [METRIC.AvgEcpi.value, METRIC.Cost.value];

  if (percents.includes(metric)) {
    text = text + ` (%)`;
  }

  if (usds.includes(metric)) {
    text = `($) ` + text;
  }

  let yAxis: any = {
    min: 0,
    title: {
      text: text,
    },
  };

  if (metric === METRIC.Touchpoints.value) {
    yAxis = [
      {
        className: "highcharts-color-0",
        title: {
          text: "Impressions",
        },
      },
      {
        className: "highcharts-color-1",
        opposite: true,
        title: {
          text: "Clicks",
        },
      },
    ];
  }

  return {
    tooltip,
    yAxis,
  };
};
