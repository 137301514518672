import React, { useState, useCallback } from "react";
import { useCreate, useNotification } from "@pankod/refine-core";
import { RowSelectMethod } from "antd/lib/table/interface";
import { Popconfirm, Table, useModal } from "@pankod/refine-antd";
import { UsergroupAddOutlined, UsergroupDeleteOutlined, SearchOutlined, FilterFilled } from "@ant-design/icons";

// components
import DrawerUser from "./DrawerUser";
import { TagStatus } from "components/Tag";
import { ProductIconAndPlatform } from "components/ProductIcon";
import ColumnUserRole from "./Column/ColumnUserRole";
import ColumnUserAction from "./Column/ColumnUserAction";

// schema
import { Enum_Studiouserrole_Status } from "schema/schema";

// interface
import { IStudio, IStudioUser } from "interfaces";
import { useGetMyRole } from "hooks/useGetMyRole";
import { EnumRoleStudio } from "enums";
import { useMe } from "context/MeContext";
import { usePermission } from "hooks/usePermission";
import { sortBy } from "utils/sortBy";
import useTableProps from "hooks/useTableProps";
import RemoveUserModal from "components/Modal/RemoveUserModal";
import { useUserRole } from "hooks/useUserRole";
import { useUsers } from "hooks/useUsers/useUsers";
import { useGetHighestRole } from "hooks/useGetHigherRole";

interface IProps {
  studio?: IStudio;
  refetchStudio: () => void;
}

const UserInfo: React.FC<IProps> = ({ studio, refetchStudio }) => {
  // state
  const [isShowDrawerUser, setIsShowDrawerUser] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [forceUpdateDrawerUser, setForceUpdateDrawerUser] = useState(Date.now());
  const [errorData, setErrorData] = useState([]);
  const { modalProps, close, show } = useModal();
  const [isSelectedAll, setIsSelectedAll] = React.useState(false);

  // hooks
  const { open } = useNotification();
  const { mutateAsync: removeUsers } = useCreate();

  const { studioRolePermissions: optionsRole } = useUserRole();
  const { fetchMore: refetchUsers } = useUsers();
  const me = useMe();
  const { userRoleStudioPermission } = useGetMyRole();
  const { combineRoleWithSuperAdmin } = usePermission();
  const fullOptionsRole = optionsRole;

  const { aboveRoles } = useGetHighestRole(me.getMyRole(studio?.id).toLowerCase());
  const _optionsRole = React.useMemo(() => {
    return optionsRole?.filter((item) => item.value && !aboveRoles.includes(item.label as string));
  }, [aboveRoles, optionsRole]);
  const onSelectChange = (newSelectedRowKeys: React.Key[], _: any, info: { type: RowSelectMethod }) => {
    let _newSelectedRowKeys: React.Key[] = [];
    const studioId = studio?.studio_user_roles?.reduce((listIdStudio: any, _studio) => {
      if (
        isRowSelection({
          userId: _studio.users_permissions_user?.id,
          role: _studio.role?.name,
        })
      ) {
        listIdStudio[_studio.id.toString()] = _studio.id.toString();
      }
      return listIdStudio;
    }, {});
    if (info.type === "all") {
      if (!isSelectedAll) {
        _newSelectedRowKeys = newSelectedRowKeys.filter((item) => studioId[item]);
      }
      setIsSelectedAll(!isSelectedAll);
    } else {
      _newSelectedRowKeys = newSelectedRowKeys;
    }
    setSelectedRowKeys(_newSelectedRowKeys);
  };
  const isRowSelection = (record: any) => {
    if (
      record.userId?.toString() === me?.data?.id.toString() ||
      aboveRoles.includes(record?.role) ||
      !combineRoleWithSuperAdmin(userRoleStudioPermission !== EnumRoleStudio.Member) ||
      (!combineRoleWithSuperAdmin(userRoleStudioPermission !== EnumRoleStudio.Manager) && record.role === "ADMIN")
    ) {
      return false;
    }
    return true;
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    renderCell: (_value: boolean, record: any, _index: number, originNode: React.ReactNode) => {
      return isRowSelection(record) ? originNode : <></>;
    },
  };
  const dataSource = React.useMemo(
    () =>
      studio?.studio_user_roles
        ?.sort((a, b) => sortBy(b.updatedAt, a.updatedAt))
        .filter((user) => user.users_permissions_user?.id)
        .map((user: IStudioUser) => {
          return {
            id: user.id,
            avatar: user.users_permissions_user?.icon,
            studioUserRoleId: user.id,
            username: user.users_permissions_user?.username,
            email: user.users_permissions_user?.email,
            role: user.role?.name,
            userId: user.users_permissions_user?.id,
            status: user.status,
            invitationExpire: user.invitation_expiration,
          };
        }) || [],
    [studio],
  );
  const handleRemoveUser = async () => {
    if (selectedRowKeys.length === 0) return;

    const dataDeletes = selectedRowKeys.filter((selectedkey) => {
      const index = studio?.studio_user_roles?.findIndex((o) => o.id === selectedkey);

      return index !== undefined && index > -1;
    });
    try {
      await removeUsers({
        resource: "studios/remove-users",
        values: dataDeletes,
        successNotification: false,
        errorNotification: false,
      });
      open?.({
        type: "success",
        description: "Success",
        message: `User is successfully remove from studio`,
      });
    } catch (error: any) {
      console.log({ error });
      open?.({
        type: "error",
        description: "Error",
        message: error?.message,
      });
      if (error?.response?.data?.error?.details) {
        setErrorData(error?.response?.data?.error?.details);
        show();
      }
    } finally {
      refetchStudio();
    }
  };

  const { getColumnSearchProps } = useTableProps();

  const columns: any = React.useMemo(
    () => [
      {
        title: "Image",
        dataIndex: "avatar",
        width: "5%",
        className: "text-center text-[14px]",
        render: (avatar: string) => {
          return (
            <>
              <div className="flex items-center justify-center space-x-2">
                <ProductIconAndPlatform imageUrl={avatar} />
              </div>
            </>
          );
        },
      },
      {
        title: "User Email",
        dataIndex: "email",
        key: "email",
        with: "25%",
        className: "text-[14px]",
        ...getColumnSearchProps({
          dataIndex: "email",
        }),
        sorter: (a: { email: string }, b: { email: string }) => a?.email.localeCompare(b?.email),
        filters:
          dataSource?.map((user) => ({
            text: user.email,
            value: user.email,
          })) || [],
        filterSearch: true,
        filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
        onFilter: (value: string, record: { email: any[] }) => record.email.includes(value),
        render: (email: string) => <div className="font-normal">{email}</div>,
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
        with: "35%",
        className: "text-[14px]",
        sorter: (a: { role: string }, b: { role: string }) => a?.role.localeCompare(b?.role),
        filters:
          fullOptionsRole?.map((user) => ({
            text: user.label,
            value: user.label,
          })) || [],
        onFilter: (value: string, record: any) => record.role.includes(value),
        filterIcon: (filtered: boolean) => <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />,
        render: (role: string, record: any) => (
          <ColumnUserRole
            role={role}
            email={record.email}
            studio={studio}
            optionsRole={_optionsRole}
            refetchStudio={refetchStudio}
            status={record.status}
            aboveRoles={aboveRoles}
          />
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        with: "20%",
        className: "text-[14px]",
        sorter: (a: { status: string }, b: { status: string }) => a?.status.localeCompare(b?.status),
        filters: [
          {
            text: Enum_Studiouserrole_Status.Accepted,
            value: Enum_Studiouserrole_Status.Accepted,
          },
          {
            text: Enum_Studiouserrole_Status.Pending,
            value: Enum_Studiouserrole_Status.Pending,
          },
        ],
        onFilter: (value: string, record: any) => record.status.includes(value),
        filterIcon: (filtered: boolean) => <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />,
        render: (status: string) => <TagStatus status={status} />,
      },
      {
        title: "Action",
        dataIndex: "invitationExpire",
        key: "invitationExpire",
        with: "15%",
        className: "text-[14px]",
        render: (value: Date, record: { role: string; userId: string; email: string }) => (
          <ColumnUserAction invitationExpire={value} record={record} studio={studio} refetchStudio={refetchStudio} />
        ),
      },
    ],
    [getColumnSearchProps, dataSource, fullOptionsRole, studio, _optionsRole, refetchStudio, aboveRoles],
  );

  const onToggleShowDrawerUser = useCallback(() => {
    setForceUpdateDrawerUser(Date.now());
    setIsShowDrawerUser((prevState) => !prevState);
    if (!isShowDrawerUser) {
      refetchUsers({
        requestPolicy: "network-only",
      });
    }
  }, [isShowDrawerUser, refetchUsers]);

  return (
    <>
      <div className="w-full">
        {studio?.status && combineRoleWithSuperAdmin(userRoleStudioPermission !== EnumRoleStudio.Member) && (
          <div className="flex justify-end mb-[30px]">
            <div className="px-4 py-2 cursor-pointer">
              <span className="text-[#24A0ED] text-[14px] font-normal" onClick={onToggleShowDrawerUser}>
                <UsergroupAddOutlined /> Add
              </span>
            </div>
            <div className={`px-4 py-2 ${selectedRowKeys.length === 0 ? "cursor-not-allowed" : "cursor-pointer"}`}>
              <Popconfirm
                placement="topLeft"
                title="Are you sure to delete these users?"
                onConfirm={handleRemoveUser}
                okText="Yes"
                cancelText="No"
                disabled={selectedRowKeys.length === 0}
              >
                <span className="text-[#FF605C] text-[14px] font-normal">
                  <UsergroupDeleteOutlined /> Remove
                </span>
              </Popconfirm>
            </div>
          </div>
        )}
        <Table rowKey="id" rowSelection={rowSelection} columns={columns} dataSource={dataSource} />
      </div>
      <DrawerUser
        key={forceUpdateDrawerUser}
        isShowDrawerUser={isShowDrawerUser}
        onToggleShowDrawerUser={onToggleShowDrawerUser}
        studio={studio}
        refetchStudio={refetchStudio}
        optionsRole={_optionsRole}
      />
      <RemoveUserModal
        modalProps={modalProps}
        handleClose={close}
        isLoading={false}
        errorData={errorData}
        handleDelete={() => {
          handleRemoveUser();
        }}
        studioUsers={dataSource.map((o) => ({
          id: o.userId,
          username: o.username,
          email: o.email,
        }))}
      />
    </>
  );
};

export default React.memo(UserInfo);
