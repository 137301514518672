import { IResourceComponentsProps, useModal, useNavigation, useNotification } from "@pankod/refine-core";
import { Icons, List } from "@pankod/refine-antd";
import React from "react";
import useUpload from "hooks/useUpload";
import CopyAndMove from "components/Copy";
import config from "config";
import { UploadButton } from "components/Button/UploadButton";
import UploadModal from "components/Modal/UploadModal";
import { useQuery } from "hooks/useQuery";
import { ObjectDetail } from "./ObjectDetail";
import { useBreadcumbBucket } from "hooks/useBucket/useBreadcumbBucket";
import { useDownLoadObjects } from "hooks/useBucket/useDownloadObjects";
import { useDeleteObjects } from "hooks/useBucket/useDeleteObjects";
import { useBucketLocation } from "hooks/useBucket/useBucketLocation";
import { useProductInfo } from "hooks/useProduct/useProductInfo";
import { useListObjectsInBucket } from "hooks/useBucket/useListObjectsInBucket";
import { useBucketUltil } from "hooks/useBucket/useBucketUltil";
import { useExportCloudfront } from "hooks/useBucket/useExportCloudfront";
import { isFolder } from "utils/folderAndFile";
import { BreadcrumbAWS } from "components/Breadcrumb/BreadcrumbBucket";
import { useGetMyRole } from "hooks/useGetMyRole";
import { EnumRoleProduct } from "enums";
import { usePermission } from "hooks/usePermission";
const { AmazonOutlined } = Icons;

export const ObjectList: React.FC<IResourceComponentsProps & { isEditPage?: boolean }> = ({ isEditPage }) => {
  const query = useQuery();
  const { push } = useNavigation();
  const { productInfo, productId, isLoadingProduct } = useProductInfo();
  const { handleUpload, loading, data: dataUpload } = useUpload();
  const { visible, show, close } = useModal();
  const { open } = useNotification();
  const bucketName = productInfo.awsPayload?.bucket;
  const _prefix = productInfo._prefix;
  const isDefaultBucket = bucketName === config.BUCKET_NAME;
  const prefix = query.get("prefix") ? query.get("prefix") : isDefaultBucket ? productInfo.productCode + "/" : _prefix;
  const { isSuccessLocation, region, isLoadingLocation } = useBucketLocation({
    bucketName,
    productId,
  });

  const { selectedRowKeys, setSelectedRowKeys, setEditingKey, isEditing, rowSelection, components } = useBucketUltil({
    bucketName,
    region,
  });

  const { refetchObjects, dataSource, setDataSource, isFetchingObjects, isFile, BucketTable } = useListObjectsInBucket({
    bucketName,
    productInfo,
    prefix,
    region,
    isSuccessLocation,
  });

  const { onDelete, DeleteObjectButton, deleteLoading } = useDeleteObjects({
    bucketName,
    selectedRowKeys,
    productId,
    setSelectedRowKeys,
    refetch: refetchObjects,
  });

  const { onDownload, DownloadObjectButton } = useDownLoadObjects({
    bucketName,
    selectedRowKeys,
    productId,
    dataSource,
  });

  const { BreadcrumbBucket, breadcrumbItems } = useBreadcumbBucket({
    bucketName,
    prefix: prefix?.endsWith("/") ? prefix.slice(0, -1) : prefix,
    setSelectedRowKeys,
    _prefix: _prefix && _prefix.slice(0, -1),
  });

  const { ExportCloudfrontButton, triggerExport } = useExportCloudfront({
    data: dataSource,
    bucketName,
    prefix,
  });

  const handleSave = (row: any) => {
    const newData = dataSource ? [...dataSource] : [];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  React.useEffect(() => {
    if (dataUpload && !loading) {
      refetchObjects();
      open?.({
        type: "success",
        description: "Success",
        message: "Files are successfully uploaded",
      });
    }
  }, [loading, dataUpload, open, refetchObjects]);

  React.useEffect(() => {
    if (!isLoadingProduct && !bucketName) {
      push(`/products/show/${productInfo.id}`);
    }
  }, [productInfo.id, bucketName, isLoadingProduct, push]);

  const { userRoleProductPermission: userRolePermision } = useGetMyRole();
  const { combineRoleWithSuperAdmin } = usePermission();

  return (
    <List
      title={
        <span className="font-normal">
          <AmazonOutlined /> <strong>Bucket:</strong>{" "}
          {isDefaultBucket ? `${bucketName}/${productInfo.productCode}` : bucketName}
        </span>
      }
      pageHeaderProps={{
        onBack: () => push(`/products/show/${productId}`),
      }}
      breadcrumb={!isEditPage ? <BreadcrumbAWS /> : <></>}
    >
      <BreadcrumbBucket breadcrumbItems={breadcrumbItems} productId={productId} />
      {!isFile ? (
        <>
          <div className="flex justify-between mb-6">
            <div className=" flex justify-end space-x-2">
              <CopyAndMove
                cb={() => refetchObjects()}
                disabled={
                  selectedRowKeys.length !== 1 ||
                  productInfo.isDisabled ||
                  !combineRoleWithSuperAdmin(userRolePermision !== EnumRoleProduct.Viewer)
                }
                object={selectedRowKeys}
                resetSelectedRow={() => setSelectedRowKeys([])}
                productInfo={productInfo}
                currentBucket={bucketName}
              />
              <div className="flex space-x-2 items-center">
                <UploadButton
                  loading={loading}
                  onClick={show}
                  disable={
                    productInfo.isDisabled || !combineRoleWithSuperAdmin(userRolePermision !== EnumRoleProduct.Viewer)
                  }
                />
                <p className="mb-0 text-primary">{loading && "Uploading files to S3 ..."}</p>
              </div>
            </div>
            <div className="flex justify-start space-x-2">
              <DownloadObjectButton
                onDownload={onDownload}
                disabled={selectedRowKeys.length === 0 || selectedRowKeys.some((rowKey) => isFolder(rowKey.toString()))}
              />
              <DeleteObjectButton
                onDelete={onDelete}
                loading={deleteLoading}
                disabled={
                  selectedRowKeys.length === 0 ||
                  productInfo.isDisabled ||
                  !combineRoleWithSuperAdmin(userRolePermision !== EnumRoleProduct.Viewer)
                }
              />
              <ExportCloudfrontButton isLoading={isLoadingProduct} triggerExport={triggerExport} />
            </div>
          </div>
          <BucketTable
            dataSource={dataSource}
            loading={isLoadingProduct || isFetchingObjects || isLoadingLocation}
            rowSelection={rowSelection}
            components={components}
            setEditingKey={setEditingKey}
            bucketName={bucketName}
            prefix={prefix}
            isEditing={isEditing}
            handleSave={handleSave}
            productInfo={productInfo}
          />

          <UploadModal
            onClose={close}
            handleUpload={handleUpload}
            isOpen={visible}
            prefix={prefix}
            bucketName={bucketName}
          />
        </>
      ) : (
        <ObjectDetail
          productInfo={productInfo}
          loading={isLoadingProduct}
          region={region}
          bucketName={bucketName}
          prefix={prefix}
        />
      )}
    </List>
  );
};
