import ProductInfo from "factory/Product";
import { Enum_Integration_Service, IntegrationPayloadEntityFragment } from "schema/schema";
export const getAllIntegrationConnected = (productInfo: ProductInfo) => {
  return productInfo?.integrationPayloads?.data.map((item: IntegrationPayloadEntityFragment) => {
    return {
      id: item.attributes?.integration?.data?.id,
      name: item.attributes?.integration?.data?.attributes?.name,
      description: item.attributes?.integration?.data?.attributes?.description,
      icon: {
        alt: item.attributes?.integration?.data?.attributes?.icon?.data?.attributes?.alternativeText,
        url: item.attributes?.integration?.data?.attributes?.icon?.data?.attributes?.url,
      },
      service: item.attributes?.integration?.data?.attributes?.service,
      payload: item.attributes?.payload,
    };
  });
};
export const checkExitsIntegration = (productInfo: ProductInfo, service: Enum_Integration_Service) => {
  const listIntegrationConnect = getAllIntegrationConnected(productInfo);
  switch (service) {
    case Enum_Integration_Service.AmaPassport:
    case Enum_Integration_Service.EventGateway:
    case Enum_Integration_Service.RevenueCat:
      return (
        listIntegrationConnect?.find((item) => item.service === service)?.payload &&
        !listIntegrationConnect?.find((item) => item.service === service)?.payload["pmp:disabled"]
      );
    default:
      return listIntegrationConnect?.some((v: any) => v.service === service);
  }
};
