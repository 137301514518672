import { useGetIdentity, useNavigation } from "@pankod/refine-core";
import { LoadingFullPage } from "components/Loading/FullPage";
import { useParams } from "react-router-dom";
import React from "react";
import { useAuth } from "hooks/useAuth";

export const Invitations = () => {
  const [shouldLogout, setShouldLogout] = React.useState(false);
  const params = useParams();
  const userId = params.userId;
  const { logout } = useAuth();
  const { push } = useNavigation();
  const { data: me } = useGetIdentity();
  React.useEffect(() => {
    if (me) {
      if (me.id.toString() !== userId) {
        setShouldLogout(true);
      } else {
        push("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, me]);

  if (shouldLogout) {
    logout();
  }
  return <LoadingFullPage />;
};
