import { css } from "@emotion/react";
import { Button, Col, Modal, ModalProps, Radio, RadioChangeEvent, Row, Space } from "@pankod/refine-antd";
import { ImageElement } from "components/Image";
import { useProduct } from "context/ProductContext";

import React from "react";
export const ReConnectRevenueCatModal: React.FC<{
  projectInfo?: any;
  modalProps: ModalProps;
  handleClose: () => void;
  handleConnect: (e: any) => void;
}> = ({ modalProps, handleClose, handleConnect, projectInfo }) => {
  const [valueRadio, setValueRadio] = React.useState("existing");

  const onChangeRadio = (e: RadioChangeEvent) => {
    setValueRadio(e.target.value);
  };
  const { productInfo } = useProduct();
  const productIcon = productInfo?.icon?.url;
  const handleReconnect = () => {
    if (valueRadio === "new") {
      handleConnect({});
    } else {
      handleConnect({
        project_url: `https://app.revenuecat.com/projects/${projectInfo?.id}/apps`,
      });
    }
    handleClose();
  };
  return (
    <Modal
      {...modalProps}
      centered
      onCancel={() => {
        handleClose();
        setValueRadio("existing");
      }}
      className="rounded-[10px] shadow-md w-[572px] bg-white pb-0"
      maskClosable={false}
      title={<div className="mt-[8px] w-full text-white text-[16px]">Notification for existing project</div>}
      css={css`
        .ant-modal-header {
          background-color: #4f006a;
          padding: 8px 24px;
          height: 56px;
        }
        .ant-modal-close-x {
        }
        .ant-modal-close .anticon {
          color: #ff7875;
          font-size: 24px;
          display: block;
          margin-top: 15px;
        }
        .ant-modal-body {
          padding-bottom: 0;
        }
        .ant-modal-footer {
          border-color: #f0f0f0;
        }
      `}
      footer={[
        <div key="footer">
          <Button
            htmlType="button"
            className="bg-gray-400 text-white "
            onClick={() => {
              handleClose();
              setValueRadio("existing");
            }}
          >
            Cancel
          </Button>
          <Button htmlType="button" type="primary" onClick={() => handleReconnect()}>
            Confirm
          </Button>
        </div>,
      ]}
    >
      <>
        <p className=" text-[14px]">Your product used to connect with below Revenue Cat project</p>
        <div className="bg-day-break-blue">
          <Row className="py-[5px] px-[10px] items-center border-b ">
            <Col span={6} className="font-bold text-[14px]">
              Project
            </Col>
            <Col className="text-[14px] flex items-center" span={18}>
              <ImageElement
                className="h-[48px] w-[48px] object-contain"
                src={productIcon}
                alt={projectInfo?.attributes?.name}
              />
              <p className="my-0 ml-[10px]">{projectInfo?.attributes?.name}</p>
              <div className="w-[6px] h-[6px] rounded-full bg-[#52C41A] ml-1"></div>
            </Col>
          </Row>
          <Row className="py-[18px] px-[10px]">
            <Col span={6} className="font-bold text-[14px]  ">
              Project URL
            </Col>
            <Col className="text-[14px] " span={18}>
              <a target="blank" href={`https://app.revenuecat.com/projects/${projectInfo?.id}/apps`}>
                {`https://app.revenuecat.com/projects/${projectInfo?.id}/apps`}
              </a>
            </Col>
          </Row>
        </div>
        <p className="text-[14px] mt-[20px]">Please choose option for Revenue Cat Integration</p>
        <Radio.Group onChange={onChangeRadio} className="mb-2 " value={valueRadio}>
          <Space direction="vertical" className="ml-[15px]">
            <Radio value={"existing"} defaultChecked>
              <p className=" text-[14px]">Verified & use above project</p>{" "}
            </Radio>
            <Radio value={"new"}>
              <p className="text-[14px]">Create new project instead</p>{" "}
            </Radio>
          </Space>
        </Radio.Group>
      </>
    </Modal>
  );
};
