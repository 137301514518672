/* eslint-disable no-useless-escape */
import React from "react";
// refine
import { useCreate } from "@pankod/refine-core";
import { Space, Button, Drawer, Form } from "@pankod/refine-antd";

// hooks
import { useUsers } from "hooks/useUsers/useUsers";

// interface
import { IStudio, TRoleProduct, TRoleStudio } from "interfaces";
import { useMe } from "context/MeContext";
import FormListAddUser from "components/Form/FormListAddUser";
import { UserRoleOptions, useUserRole } from "hooks/useUserRole";
import { UsersAppsOptions } from "hooks/useUsers/useSelectUsers";

interface IProps {
  isShowDrawerUser: boolean;
  studio?: IStudio;
  optionsRole?: UserRoleOptions[];
  onToggleShowDrawerUser: Function;
  refetchStudio: Function;
}

const DrawerUser: React.FC<IProps> = ({
  isShowDrawerUser,
  studio,
  optionsRole,
  onToggleShowDrawerUser,
  refetchStudio,
}) => {
  // state
  // hooks
  const [form] = Form.useForm();
  const { mutateAsync: inviteUser, isLoading } = useCreate();
  const me = useMe();
  const studioUsers = studio?.studio_user_roles;
  const userIds = studio?.studio_user_roles?.map((item: any) => {
    return item.users_permissions_user?.id.toString();
  });
  const { usersData: pmpUsers } = useUsers({
    variables: {
      filters: {
        id: {
          notIn: userIds ? [...userIds, me.data?.id] : [me.data?.id],
        },
      },
      pagination: {
        page: 1,
        pageSize: 10000,
      },
    },
  });

  const { getRoleIdByName } = useUserRole();

  const cancelFc = () => {
    form.resetFields();
    onToggleShowDrawerUser();
  };

  const onFinish = async (event: any) => {
    const dataSubmit: any[] = event.addUserEmail || [];
    const body = dataSubmit
      .map((item: { emails: string[]; role: TRoleStudio | TRoleProduct }) => {
        return item.emails.map((email) => {
          return {
            email,
            role_id: getRoleIdByName(item.role),
          };
        });
      })
      .flat();
    console.log({ body });
    await inviteUser({
      resource: `studios/${studio?.id}/invitations`,
      values: body,
      successNotification: {
        type: "success",
        message: "Successfully invited user.",
      },
      errorNotification: {
        type: "error",
        message: "Failure invited user.",
      },
    });
    onToggleShowDrawerUser();
    refetchStudio();
  };
  const optionUsers: UsersAppsOptions[] | undefined = pmpUsers?.map((data) => ({
    value: data.attributes?.email,
    label: data.attributes?.email,
    email: data.attributes?.email,
  }));

  return (
    <>
      <Drawer
        width={500}
        title={
          <div className="flex items-center justify-between">
            <span className="text-[16px] text-black leading-[24px] font-medium">Add user to {studio?.name}</span>
          </div>
        }
        extra={
          <Space>
            <Button onClick={cancelFc}>Cancel</Button>
            <Button type="primary" loading={isLoading} onClick={form.submit}>
              OK
            </Button>
          </Space>
        }
        placement="right"
        onClose={() => onToggleShowDrawerUser()}
        visible={isShowDrawerUser}
      >
        <FormListAddUser
          type="studioUsers"
          onFinish={onFinish}
          form={form}
          optionRoles={optionsRole}
          optionUsers={optionUsers}
          studioUsers={studioUsers}
        />
      </Drawer>
    </>
  );
};

export default React.memo(DrawerUser);
