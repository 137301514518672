import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { LoadingFullPage } from "components/Loading/FullPage";
import config from "config";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const LoginRedirect = () => {
  const [text, setText] = useState("Loading...");
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    // Successfully logged with the provider
    // Now logging with strapi by using the access_token (given by the provider) in props.location.search
    fetch(`${backendUrl}/api/auth/auth0/callback${location.search}`)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
        }
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        // Successfully logged with Strapi
        // Now saving the jwt to use it for future authenticated requests to Strapi
        localStorage.setItem(config.TOKEN_KEY, res.jwt);
        window.location.href = "/select-studio";
      })
      .catch((err) => {
        setText("An error occurred, please see the developer console.");
      });

    return () => {};
  }, [location.search, params.providerName, text]);

  return <LoadingFullPage />;
};

export default LoginRedirect;
