import { Form, Icons, RcFile, Upload, UploadFile } from "@pankod/refine-antd";
import { getValueProps, useStrapiUpload } from "@pankod/refine-strapi-v4";
import config from "config";
import React from "react";
import type { UploadChangeParam } from "antd/es/upload";
import { css } from "@emotion/react";
import { DataUpload } from "hooks/useUploadForm";
import { getBase64 } from "utils/folderAndFile";
import { ImageElement } from "components/Image";
import { FormItemHiddenLabel } from "components/Form/FormItemHiddenLabel";
const { LoadingOutlined, InboxOutlined } = Icons;

export interface TUploadForm {
  name?: string;
  required?: boolean;
  data?: DataUpload;
  loading: boolean;
  setLoading: (val: boolean) => void;
  label?: string;
  accept?: string;
}
const UploadForm: React.FC<TUploadForm> = ({
  name = "icon",
  required = true,
  data,
  loading,
  setLoading,
  label = "Icon",
  accept = "image/png, image/jpeg",
}) => {
  const { ...uploadProps } = useStrapiUpload({
    maxCount: 1,
  });
  const [err, setErr] = React.useState("");
  const onValueProps = React.useCallback((data: any) => {
    if (!data?.fileList) {
      return { fileList: data ? [data] : [] };
    }
    const res = getValueProps(data, "");
    return res;
  }, []);

  const [imageUrl, setImageUrl] = React.useState<string | undefined>(data?.icon?.url);
  const _imageUrl = imageUrl || data?.icon?.url;
  return (
    <div>
      <Form.Item
        name={name}
        rules={[
          {
            required,
          },
        ]}
        className="w-full px-2 mb-0"
        valuePropName="fileList"
        getValueProps={onValueProps}
        label={label}
      >
        <Upload.Dragger
          accept={accept}
          name="files"
          multiple={false}
          action={`${config.BACKEND_URL}/api/upload`}
          showUploadList={false}
          onChange={(info: UploadChangeParam<UploadFile>) => {
            if (info.file.status === "uploading") {
              setLoading(true);
              return;
            }
            if (info.file.status === "done") {
              // Get this url from response in real world.
              getBase64(info.file.originFileObj as RcFile, (url) => {
                setLoading(false);
                setImageUrl(url);
              });
            }
          }}
          {...uploadProps}
          beforeUpload={(file: any, files) => {
            const isLt5M = file.size / 1024 / 1024 <= 5;
            if (!isLt5M) {
              file.status = "error";
              setErr("File size must not exceed 5MB");
              return false;
            }
            setErr("");

            return uploadProps.beforeUpload(file, files);
          }}
          css={css`
            .ant-upload {
              padding: 0 !important;
            }
          `}
        >
          <div
            className={`${
              !_imageUrl ? "py-4" : ""
            } h-[130px] flex items-center justify-between w-full rounded overflow-hidden`}
          >
            {_imageUrl && !loading ? (
              <div
                className="w-full h-full relative flex items-center justify-center"
                css={css`
                  &:hover {
                    .edit {
                      opacity: 1 !important;
                    }
                  }
                `}
              >
                <ImageElement src={_imageUrl} alt="avatar" className="shadow-lg object-cover w-[60px]" />
                <div className="edit absolute top-0 left-0 opacity-0 flex items-center justify-between w-full h-full bg-primary/[0.4] transition duration-300">
                  <span className="mx-auto px-4 py-2 rounded text-[20px] text-white font-bold">Edit</span>
                </div>
              </div>
            ) : (
              <>
                {!loading ? (
                  <div className="mx-auto">
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined className="text-primary" />
                    </p>
                    <p className="ant-upload-text">Click or drag image to this area to upload</p>
                  </div>
                ) : (
                  <p className="text-center w-full px-8 text-[12px]">
                    <LoadingOutlined className="text-primary text-[20px]" />
                  </p>
                )}
              </>
            )}
          </div>
        </Upload.Dragger>
      </Form.Item>
      <FormItemHiddenLabel>
        {err && <p className="text-danger ml-1 mt-1">{err}</p>}
        <span className="mt-1 block ml-auto pl-1">Recommend resolution is 128x128 with file size</span>
      </FormItemHiddenLabel>
    </div>
  );
};

export default UploadForm;
