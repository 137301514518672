/**
 * Used to validate if the given param is an array.
 *
 * @param {any} array
 * @returns {boolean} isAnArray ? true : false
 */

export function isAnArray(array: number | number[] | undefined) {
  return Array.isArray(array);
}
