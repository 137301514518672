import React from "react";
import { Button } from "@pankod/refine-antd";
import { useCreate } from "@pankod/refine-core";
// schema
import { IStudio } from "interfaces";
import dayjs from "dayjs";
import { useUserRole } from "hooks/useUserRole";

interface IProps {
  invitationExpire: Date;
  record: { role: string; userId: string; email: string };
  studio?: IStudio;
  refetchStudio: Function;
}

function ColumnUserAction({ invitationExpire, record, studio, refetchStudio }: IProps) {
  const { studioRolePermissions: optionsRole } = useUserRole();
  // hooks
  const { mutateAsync: inviteUser, isLoading: isLoadingResend } = useCreate();
  const isExpire: boolean = React.useMemo(() => {
    const timestampNow = new Date().getTime();
    return dayjs(timestampNow).isAfter(dayjs(invitationExpire));
  }, [invitationExpire]);

  const handleResendInvite = async () => {
    const roleId = optionsRole.find((item) => item.value.toString().includes(record.role))?.id;
    await inviteUser({
      resource: `studios/${studio?.id}/invitations`,
      values: [{ email: record.email, role_id: roleId }],
      successNotification: {
        type: "success",
        description: "Success",
        message: "Successfully resend invitation email.",
      },
    });
    refetchStudio();
  };
  if (isExpire) {
    return (
      <Button type="primary" loading={isLoadingResend} onClick={handleResendInvite}>
        Resend
      </Button>
    );
  }
  return <></>;
}

export default React.memo(ColumnUserAction);
