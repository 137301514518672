import { AuthProvider } from "@pankod/refine-core";
import dataProviderGraphql from "@pankod/refine-strapi-graphql";
import config from "config";
import { GraphQLClient } from "graphql-request";
const client = new GraphQLClient(config.GRAPHQL_URL);
export const gqlDataProvider = dataProviderGraphql(client as any);

export const authProviderGraphql: AuthProvider = {
  login: async ({ email, password }) => {
    try {
      const { data } = await gqlDataProvider.custom!({
        url: "",
        method: "post",
        metaData: {
          operation: "login",
          variables: {
            input: {
              value: { identifier: email, password },
              type: "UsersPermissionsLoginInput",
              required: true,
            },
          },
          fields: ["jwt"],
        },
      });

      localStorage.setItem("token", data.jwt);
      client.setHeader("Authorization", `Bearer ${data.jwt}`);

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  logout: async () => {
    localStorage.removeItem("token");
    client.setHeader("Authorization", "");
    return Promise.resolve("/");
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const jwt = localStorage.getItem("token");

    if (!jwt) {
      return Promise.reject();
    }

    client.setHeader("Authorization", `Bearer ${jwt}`);

    return Promise.resolve();
  },
  getPermissions: async () => {
    try {
      const { data } = await gqlDataProvider.custom!({
        url: "",
        method: "get",
        metaData: {
          operation: "me",
          fields: [
            {
              role: ["name", "description", "type"],
            },
          ],
        },
      });
      const { role } = data;

      return Promise.resolve(role);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getUserIdentity: async () => {
    try {
      const { data } = await gqlDataProvider.custom!({
        url: "",
        method: "get",
        metaData: {
          operation: "me",
          fields: ["id", "username", "email"],
        },
      });
      const { id, username, email } = data;
      return Promise.resolve({
        id,
        name: username,
        email,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
