const lime = {
  50: "#F4FCE3",
  100: "#E9FAC8",
  200: "#D8F5A2",
  300: "#C0EB75",
  400: "#A9E34B",
  500: "#94D82D",
  600: "#82C91E",
  700: "#74B816",
  800: "#66A80F",
  900: "#5C940D",
};

export default lime;
