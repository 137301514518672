const red = {
  50: "#FFF5F5",
  100: "#FFE3E3",
  200: "#FFC9C9",
  300: "#FFA8A8",
  400: "#FF8787",
  500: "#FF6B6B",
  600: "#FA5252",
  700: "#F03E3E",
  800: "#E03131",
  900: "#C92A2A",
};

export default red;
