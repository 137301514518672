const orange = {
  50: "#FFF4E6",
  100: "#FFE8CC",
  200: "#FFD8A8",
  300: "#FFC078",
  400: "#FFA94D",
  500: "#FF922B",
  600: "#FD7E14",
  700: "#F76707",
  800: "#E8590C",
  900: "#D9480F",
};

export default orange;
