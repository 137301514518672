import React from "react";
import { Result, Button, List } from "antd";
import { useAuth } from "hooks/useAuth";
import { useMe } from "context/MeContext";
import router from "@pankod/refine-react-router-v6";
import useLocalStorage from "hooks/useLocalStorage";
import { ImageElement } from "components/Image";
import config from "config";

const Page403: React.FC<{ message?: string; isShowBtn?: boolean; className?: string }> = ({
  message = "Sorry, you are not authorized to access this page.",
  isShowBtn = true,
  className = "",
}) => {
  const { logout } = useAuth();
  return (
    <div className={`h-[80vh] flex justify-center flex-col ${className}`}>
      <Result
        status="403"
        title="403"
        subTitle={message}
        extra={
          isShowBtn ? (
            <>
              <Button
                href="/"
                className="inline-flex items-center bg-primary text-white hover:border hover:border-black hover:text-black"
                type="link"
              >
                Back Home
              </Button>
              <Button onClick={logout}>Logout</Button>
            </>
          ) : (
            <></>
          )
        }
      />
    </div>
  );
};

export const Page403WithStudios: React.FC = () => {
  const params = router.useParams();

  const [, setValueLocalStorage] = useLocalStorage<string | undefined | null>("studioId");
  const { studios, handleScroll, isLoadingStudios } = useMe();
  const productId = params.productId || params.id;
  const onSetStudio = (id?: string | null) => {
    setValueLocalStorage(id);
    window.location.reload();
  };

  const _studios = studios?.filter((item) => item.products?.some((s) => s.id.toString() === productId?.toString()));
  return (
    <div className="flex w-full space-x-2 items-center h-full">
      <Page403 message={config.CannotAccessProductWithStudio} isShowBtn={false} className="w-1/2" />
      <div className="w-1/2 flex flex-col items-center">
        <div>You can access this product page with following studio permission:</div>
        <div className="w-1/2 mt-4 max-h-[300px] overflow-auto" onScroll={handleScroll}>
          <List
            className=" "
            itemLayout="horizontal"
            bordered={true}
            dataSource={_studios}
            loading={isLoadingStudios}
            size={"default"}
            renderItem={(item) => (
              <List.Item
                className="hover:bg-gray-100 transition duration-200 cursor-pointer"
                onClick={() => onSetStudio(item.id)}
              >
                <List.Item.Meta
                  avatar={
                    <div className="rounded-full w-[30px] h-[30px] object-contain flex items-center justify-center overflow-hidden">
                      <ImageElement src={item.icon.url} className="w-full" />
                    </div>
                  }
                  title={<span className="mt-1 block">{item.name} - Admin</span>}
                />
              </List.Item>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default Page403;
