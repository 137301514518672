import config from "config";
import React from "react";
import { testImage } from "utils/Image/testImage";
import EmptyAvatar from "assets/img/empty.png";

export const ImageElement: React.FC<{ src?: string; alt?: string; className?: string }> = ({
  src,
  alt,
  className = "",
}) => {
  const [_src, setSrc] = React.useState(src);
  React.useEffect(() => {
    src &&
      testImage(src)
        .then(() => {
          setSrc(src);
        })
        .catch(() => {
          setSrc(config.BACKEND_URL + src);
        });
  }, [src]);

  return <img className={className} src={src ? _src : EmptyAvatar} alt={alt} />;
};
