import React from "react";
import { Card, Empty, useModal } from "@pankod/refine-antd";
import { CardIntegration } from "pages/Integration/components/CardIntegration";
import { useList } from "@pankod/refine-core";
import { IIntegration } from "interfaces";
import { IntegrationModalStepCreate } from "components/Modal/IntegrationModalCreate";
import { useProductStepForm } from "context/ProductStepFormContext";
import { Enum_Integration_Service } from "schema/schema";

interface ArrIntegration {
  Internal: IIntegration[];
  External: IIntegration[];
}

export const IntegrationStep: React.FC = () => {
  const [selectedItem, setSelectedItem] = React.useState<IIntegration>();

  const { integrationData, setIntegrationData, isConfigAws } = useProductStepForm();

  const { show, modalProps, close } = useModal();
  const onShow = (item: IIntegration) => {
    show();
    setSelectedItem(item);
  };
  const integrationQueryResult = useList<IIntegration>({
    resource: "integrations",
    metaData: {
      populate: ["icon"],
    },
  });
  const data = integrationQueryResult.data?.data;
  const arr = data?.reduce((curr, item) => {
    if (item.type === "internal") {
      if (!curr.Internal) curr.Internal = [];
      curr.Internal.push(item);
    } else {
      if (!curr.External) curr.External = [];
      curr.External.push(item);
    }
    return curr;
  }, {} as ArrIntegration);
  const awsItem = arr?.External.filter((item) => item.service === Enum_Integration_Service.Aws)[0];
  React.useEffect(() => {
    if (!isConfigAws && awsItem) {
      onShow(awsItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [awsItem]);

  const isConnectedCreate = (item: IIntegration) => {
    return integrationData?.some((dataItem) => dataItem.id === item.id);
  };
  return (
    <Card className="mb-8 custom-small">
      <IntegrationModalStepCreate item={selectedItem} modalProps={modalProps} close={close} />
      {arr &&
        Object.keys(arr)
          .reverse()
          .map((key) => {
            const _arr = arr as any;
            return (
              <div key={key} className="bg-white mb-4 p-4 rounded-[6px]">
                <div className="mb-4 text-[16px] ">
                  <b>{key} Integration</b>
                </div>
                <div className="flex -mx-[2rem] flex-wrap">
                  {_arr[key]?.map((item: IIntegration) => {
                    return (
                      <CardIntegration
                        item={item}
                        key={item.id}
                        onClick={() => onShow(item)}
                        isConnectedCreate={isConnectedCreate(item)}
                        handleSetIntegrationData={setIntegrationData}
                      />
                    );
                  })}
                  {(!_arr[key] || (_arr[key] && _arr[key].length === 0)) && (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="w-full" />
                  )}
                </div>
              </div>
            );
          })}
    </Card>
  );
};
