import React from "react";
import { css } from "@emotion/react";
import { Collapse, Edit, Empty } from "@pankod/refine-antd";
import { ObjectList } from ".";
import { AwsEdit } from "pages/Integration/components/Aws/AwsEdit";
import { useParams } from "react-router-dom";
import { NewTabIcon } from "components/Icons";
import { BreadcrumbAWS } from "components/Breadcrumb/BreadcrumbBucket";
import { useNavigation } from "@pankod/refine-core";
import { useProductInfo } from "hooks/useProduct/useProductInfo";

const { Panel } = Collapse;

export const AWSPage: React.FC = () => {
  const params = useParams();
  const productId = params.productId || "";
  const { goBack } = useNavigation();
  const { productInfo, isLoadingProduct } = useProductInfo();
  const awsPayload = productInfo.awsPayload;
  return (
    <Edit
      title={"AWS Integration"}
      headerButtons={<></>}
      footerButtons={<></>}
      breadcrumb={<BreadcrumbAWS />}
      pageHeaderProps={{
        extra: "",
        onBack: goBack,
      }}
      isLoading={isLoadingProduct}
    >
      <div
        css={[
          css`
            .ant-collapse-header {
              border-bottom: 1px solid #e3e3e3;
              border-radius: 0 !important;
              width: 100%;
            }
            .ant-collapse-content-box {
              padding-bottom: 0px !important;
            }
          `,
        ]}
      >
        <Collapse defaultActiveKey={["1", "2"]} ghost>
          <Panel
            className="text-[14px]"
            header={<span className="text-[#2D2D2D] font-normal">AWS information</span>}
            key="1"
          >
            <AwsEdit />
          </Panel>
          <Panel
            className="text-[14px] w-full"
            header={<span className="text-[#2D2D2D] font-normal">Bucket management</span>}
            extra={
              <div>
                <a
                  href={`/products/show/${productId}/integrations/aws/buckets`}
                  target={"_blank"}
                  rel="noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  <NewTabIcon />
                </a>
              </div>
            }
            key="2"
          >
            {awsPayload?.bucket ? <ObjectList isEditPage /> : <Empty />}
          </Panel>
        </Collapse>
      </div>
    </Edit>
  );
};
