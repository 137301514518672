import { css } from "@emotion/react";
import { Form } from "@pankod/refine-antd";
import { PropsWithChildren } from "react";

export const FormItemHiddenLabel: React.FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className = "",
}) => {
  return (
    <Form.Item
      className={`flex flex-row ${className}`}
      label={<div className="hidden absolute">temp</div>}
      css={css`
        label {
          &:after {
            display: none !important;
          }
        }
      `}
    >
      {children}
    </Form.Item>
  );
};
