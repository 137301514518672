import React from "react";
import { Button } from "@pankod/refine-antd";
import router from "@pankod/refine-react-router-v6";
import moment from "moment";

// components
import DatePickerChart from "components/DatePicker/DatePickerChart";
import ChartLTV from "components/Chart/components/ChartLTV";
import ProductSelectField from "./components/ProductSelectField";
import AppSelectField from "./components/AppSelectField";
import DashboardField from "./components/DashboardField";

// interface
import { CHART_TYPES, DIMENSIONS, METRIC } from "utils/chart/chartConfig";
import { IProductSelect } from "interfaces";

// hooks
import useRangePicker from "hooks/useRangePicker";
import { useQuery } from "hooks/useQuery";
import { useMe } from "context/MeContext";
import Page403 from "components/Result/403";

function Appsflyer() {
  // hooks
  const { myStudio } = useMe();
  const history = router.useHistory();
  const query = useQuery();
  const queryProductId = query.get("productId");
  const queryAppId = query.get("appId");
  const queryDashboardId = query.get("dashboardId");
  const { startDate, endDate, setDatePicker, datePickerRef, defaultDateTime } = useRangePicker();
  // states
  const [productSelected, setProductSelected] = React.useState<IProductSelect | null>(null);
  const [appSelected, setAppSelected] = React.useState<IProductSelect | null>(null);
  const [dashboardSelected, setDashboardSelected] = React.useState<IProductSelect | null>(null);

  const [forceUpdateDate, setForceUpdateDate] = React.useState(0);
  const [appItem, setAppItem] = React.useState<any>(null);
  const [isShowChart, setIsShowChart] = React.useState(false);

  function handleShowDashboard() {
    setIsShowChart(true);
    const url = `${window.location.pathname}?dashboardId=${dashboardSelected?.id}&productId=${productSelected?.id}&appId=${appSelected?.id}`;
    history.replace(url);
  }

  function handleSelectProduct(_: string, option: IProductSelect) {
    setProductSelected(option);
    setAppSelected(null);
    setIsShowChart(false);
  }

  function handleSelectDashboard(dashboardItem: any) {
    setDashboardSelected(dashboardItem);
    setAppSelected(null);
    setIsShowChart(false);
  }

  function handleSelectApp(appItem: any) {
    setAppSelected({
      label: appItem.product_code,
      id: appItem.id,
      value: appItem.id,
    });
    setAppItem(appItem);
    setIsShowChart(false);
  }

  // show date picker
  React.useEffect(() => {
    if (!appItem) return;

    if (appItem?.disabled_at) {
      setDatePicker((prevState) => {
        const newDate = [...prevState];
        newDate[0] = moment(appItem.disabled_at).subtract(7, "d").format("YYYY-MM-DD");
        newDate[1] = appItem.disabled_at;
        datePickerRef.current = {
          endDate: appItem.disabled_at,
        };
        setForceUpdateDate(Date.now());
        return newDate;
      });
    } else {
      setDatePicker(defaultDateTime);
      setForceUpdateDate(Date.now());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appItem]);

  if (!myStudio?.is_primary) {
    return <Page403 />
  }

  return (
    <div>
      <p className="text-[18px] font-semibold">Appsflyer</p>
      <div className="flex justify-between min-w-[800px]">
        <div className="flex space-x-4 w-3/4 selectFieldPMP">
          <DashboardField
            queryDashboardId={queryDashboardId}
            dashboardSelected={dashboardSelected}
            handleSelectDashboard={handleSelectDashboard}
          />

          <ProductSelectField
            productSelected={productSelected}
            handleSelectProduct={handleSelectProduct}
            queryProductId={queryProductId}
          />
          <AppSelectField
            appSelected={appSelected}
            productSelected={productSelected}
            handleSelectApp={handleSelectApp}
            queryAppId={queryAppId}
          />
        </div>
        <Button
          disabled={!Boolean(productSelected && appSelected)}
          size="middle"
          type="primary"
          className="ml-8"
          onClick={handleShowDashboard}
        >
          Show dashboard
        </Button>
      </div>
      <div className="mt-4">
        <DatePickerChart
          key={forceUpdateDate}
          disabled={!Boolean(productSelected && appSelected)}
          defaultStartDate={startDate}
          defaultEndDate={endDate}
          datePickerRef={datePickerRef}
          setDatePicker={(dateString) => {
            setDatePicker(dateString);
            setIsShowChart(false);
          }}
        />
      </div>

      <div>
        <br />
        {isShowChart ? (
          <>
            <div className="w-full border border-solid border-[#E5E8ED] rounded-lg relative">
              <ChartLTV
                app={appItem}
                productId={productSelected?.id.toString()}
                defaultDimension={DIMENSIONS.Source.value}
                defaultMetric={METRIC.Attributions.value}
                defaultChartType={CHART_TYPES.COLUMN}
                defaultStartDate={startDate}
                defaultEndDate={endDate}
                widgetChart="ltv-chart:0"
              />
            </div>
            <div className="appsflyerChart flex justify-between mt-[24px]">
              <div className="appsflyerChart_box border border-solid border-[#E5E8ED] rounded-lg mr-[2%] relative">
                <ChartLTV
                  app={appItem}
                  productId={productSelected?.id.toString()}
                  defaultDimension={DIMENSIONS.MediaSource.value}
                  defaultMetric={METRIC.Revenue.value}
                  defaultChartType={CHART_TYPES.PIE}
                  defaultStartDate={startDate}
                  defaultEndDate={endDate}
                  widgetChart="ltv-chart:1"
                />
              </div>
              <div className="appsflyerChart_box border border-solid border-[#E5E8ED] rounded-lg relative">
                <ChartLTV
                  app={appItem}
                  productId={productSelected?.id.toString()}
                  defaultDimension={DIMENSIONS.EventName.value}
                  defaultMetric={METRIC.EventCount.value}
                  defaultChartType={CHART_TYPES.BAR}
                  defaultStartDate={startDate}
                  defaultEndDate={endDate}
                  widgetChart="ltv-chart:2"
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default Appsflyer;
