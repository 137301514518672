import { Avatar, List, Select } from "antd";
import EmptyAvatar from "assets/img/empty-ava.webp";
import { UsersRole } from "hooks/useUsers/useSelectUsers";
import { UserRoleOptions } from "hooks/useUserRole";
import { UsersStudioRole } from "pages/UserTeam/Create";
import { useProductStepForm } from "context/ProductStepFormContext";
import { useMe } from "context/MeContext";

export type ListUserData = {
  src?: string | null;
  name?: string | null;
  email?: string;
  id: string;
  role?: string;
};

interface ListUserProps {
  dataSource?: ListUserData[];
  hasRole?: boolean;
  usersProductRole?: UsersRole[];
  usersStudioRole?: UsersStudioRole[];
  setUsersProductRole?: (value: UsersRole[]) => void;
  setUsersStudioRole?: (value: UsersStudioRole[]) => void;
  userRoleOptions?: UserRoleOptions[];
  defaultRole?: string;
}

export const ListUser: React.FC<ListUserProps> = ({
  dataSource,
  hasRole,
  usersProductRole,
  usersStudioRole,
  setUsersProductRole,
  userRoleOptions,
  setUsersStudioRole,
  defaultRole,
}) => {
  const { setValues } = useProductStepForm();
  const me = useMe();
  const updateUsersProductRole = (val: string, item: ListUserData) => {
    const temp = usersProductRole?.map((user) => {
      if (user.email === item.email) {
        user.role = val;
      }
      return user;
    });
    if (temp) {
      setUsersProductRole?.(temp);
      setValues?.((prev: any) => ({
        ...prev,
        users: temp,
      }));
    }
  };

  const updateUsersStudioRole = (val: string, item: ListUserData) => {
    const temp = usersStudioRole?.map((user) => {
      if (user.users_permissions_user === item.id) {
        user.studio_role = val;
      }
      return user;
    });
    temp && setUsersStudioRole?.(temp);
  };

  return (
    <List
      itemLayout="horizontal"
      dataSource={dataSource}
      renderItem={(item, index) => {
        return (
          <List.Item key={index}>
            <List.Item.Meta
              avatar={
                <Avatar
                  size={"large"}
                  shape="circle"
                  src={item.src || EmptyAvatar}
                  alt="avatar"
                  className="object-fit border"
                />
              }
              title={
                <div>
                  <p className="mb-0">{item.name}</p>
                  <p className="font-normal">{item.email}</p>
                </div>
              }
            />
            {hasRole && (
              <Select
                className="w-[100px]"
                onChange={(val) => {
                  usersProductRole && updateUsersProductRole(val, item);
                  usersStudioRole && updateUsersStudioRole(val, item);
                }}
                options={userRoleOptions}
                defaultValue={item.role || defaultRole}
                placeholder="Role"
                disabled={me?.data?.id === item.id}
              />
            )}
          </List.Item>
        );
      }}
    />
  );
};
