import { css } from "@emotion/react";
import tw from "twin.macro";

// interface
import { IPropsWithChildren } from "interfaces";

export const LayoutCardTransparent: React.FC<IPropsWithChildren> = ({ children }) => {
  return (
    <div
      css={css`
        .ant-page-header .ant-spin-container > .ant-card {
          background: transparent;
          > .ant-card-body {
            ${tw`px-0`}
          }
        }
      `}
    >
      {children}
    </div>
  );
};
