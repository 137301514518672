import { Form, FormInstance, Select } from "@pankod/refine-antd";
import { FormItemHiddenLabel } from "components/Form/FormItemHiddenLabel";
import { ListUser, ListUserData } from "components/ListUser";
import { useMe } from "context/MeContext";
import { useProductStepForm } from "context/ProductStepFormContext";
import { EnumRoleProduct } from "enums";
import ProductInfo from "factory/Product";
import { useUserRole } from "hooks/useUserRole";
import { UsersAppsOptions } from "hooks/useUsers/useSelectUsers";
import React from "react";
import { Enum_Studiouserrole_Status, UserEntityFragment } from "schema/schema";
import { UsersInputProps } from "./CardUser";
interface FormItemUsersProps {
  productInfo?: ProductInfo;
  studioIds?: string[];
  users?: UserEntityFragment[];
  userOptions?: UsersAppsOptions[];
  handleScroll: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  setSearchText: (val: string) => void;
  form?: FormInstance<any> | undefined;
}
export const FormItemUsers: React.FC<FormItemUsersProps> = ({
  users,
  userOptions,
  handleScroll,
  setSearchText,
  studioIds,
}) => {
  const { data: me } = useMe();

  const userOptionsWithMe = [
    { label: me?.email, value: me?.email, email: me?.email },
    ...(userOptions ? userOptions : []),
  ];
  const creator = React.useMemo(() => {
    const res = {
      id: me?.id,
      attributes: {
        email: me?.email,
        icon: me?.icon,
        name: me?.name,
      },
    } as UserEntityFragment | undefined;
    return res;
  }, [me?.email, me?.icon, me?.id, me?.name]);
  const usersData = React.useMemo(() => {
    return users && creator ? [creator, ...users] : users;
  }, [creator, users]);
  const userOptionsFormatted = userOptionsWithMe.map((item) => {
    return {
      ...item,
      disabled: item.value === me?.email,
    };
  });

  const [usersRole, setUsersRole] = React.useState<UsersInputProps[]>([]);
  const [usersSelected, setUsersSelected] = React.useState<UserEntityFragment[]>([]);
  const { setValues, values, product } = useProductStepForm();
  const product_user_roles = product?.product_user_roles;
  const usersForm = values?.users;

  React.useMemo(() => {
    const u = usersData?.filter(
      (user) => usersForm && usersForm.some((us: { email: string | undefined }) => us.email === user.attributes?.email),
    );
    usersData && u && setUsersSelected(u);
  }, [usersForm, usersData]);

  React.useEffect(() => {
    setUsersRole(usersForm);
  }, [usersForm]);

  React.useEffect(() => {
    me?.email &&
      usersSelected.length === 0 &&
      setValues?.((prev: any) => {
        const creatorRole = { email: me?.email, role: EnumRoleProduct.Owner };
        return {
          ...prev,
          users: [creatorRole],
        };
      });
  }, [me?.email, setValues, usersSelected.length]);

  React.useEffect(() => {
    setUsersSelected((value) =>
      value.filter((item) => {
        const studioUserRoleIds = item.attributes?.studio_user_roles?.data
          .filter((d) => d.attributes?.status === Enum_Studiouserrole_Status.Accepted)
          .map((s) => s.attributes?.studio?.data?.id || "");
        return studioUserRoleIds?.some((s) => studioIds?.includes(s));
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studioIds]);
  const dataSourceListUser: ListUserData[] = [
    ...new Set(
      React.useMemo(() => {
        return usersSelected.map((item) => {
          const role =
            me?.id === item.id
              ? EnumRoleProduct.Owner
              : product_user_roles?.find((p) => p.users_permissions_user?.id === item.id)?.role?.name ||
                usersForm?.find((u: any) => u.email === item.attributes?.email)?.name;
          return {
            src: item.attributes?.icon,
            name: item.attributes?.name,
            email: item.attributes?.email,
            id: item.id!,
            role,
          };
        });
      }, [usersSelected, me?.id, product_user_roles, usersForm]),
    ),
  ];
  const selectedValues = dataSourceListUser.map((item) => item?.email);
  const { productRolePermissions } = useUserRole();
  console.log({ productRolePermissions });
  const defaultRole = productRolePermissions?.find(
    (item) => (item.value as string).toUpperCase() === EnumRoleProduct.Editor,
  )?.label as string | undefined;
  const _defaultRole = defaultRole?.toUpperCase();
  return (
    <>
      <Form.Item
        label={"Users"}
        rules={[
          {
            required: true,
            message: "Users is required!",
          },
        ]}
        className="w-full"
        name={"users_temp"}
        valuePropName="test_trick"
      >
        <Select
          options={userOptionsFormatted}
          placeholder="Enter email or user name"
          mode="multiple"
          optionLabelProp="email"
          showSearch
          value={selectedValues}
          onChange={(ids) => {
            const usersInvited =
              usersData?.filter((item) => item.attributes?.email && ids.includes(item.attributes?.email)) || [];
            setUsersSelected(usersInvited);
            const userRoleFormatted = usersInvited.map((item) => {
              return {
                email: item.attributes?.email,
                role: usersForm?.find((u: any) => u.email === item.attributes?.email)?.role || _defaultRole,
              };
            });
            setUsersRole(userRoleFormatted);
            setValues?.((prev: any) => ({
              ...prev,
              users: userRoleFormatted,
            }));
          }}
          onPopupScroll={handleScroll}
          onSearch={setSearchText}
          filterOption={(input, option) => (option?.email?.toLowerCase()?.indexOf(input.toLowerCase()) || 0) >= 0}
        />
      </Form.Item>
      <FormItemHiddenLabel>
        <ListUser
          dataSource={dataSourceListUser}
          hasRole
          userRoleOptions={productRolePermissions}
          usersProductRole={usersRole}
          defaultRole={defaultRole}
          setUsersProductRole={setUsersRole}
        />
      </FormItemHiddenLabel>
    </>
  );
};
