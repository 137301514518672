const pink = {
  50: "#FFF0F6",
  100: "#FFDEEB",
  200: "#FCC2D7",
  300: "#FAA2C1",
  400: "#F783AC",
  500: "#F06595",
  600: "#E64980",
  700: "#D6336C",
  800: "#C2255C",
  900: "#A61E4D",
};

export default pink;
