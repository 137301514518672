//react
import React, { useEffect, useState } from "react";
//refine core
import { useApiUrl, useCustom, useNavigation } from "@pankod/refine-core";
//antd
import { Button, Switch, Table, RefreshButton } from "@pankod/refine-antd";
import Modal from "antd/es/modal";
//antd icon
import { CopyOutlined } from "@ant-design/icons";
//antd type
import type { ColumnsType } from "antd/es/table";
// components
import { CopyToClipboardTooltip } from "components/Input/CopyToClipboard";
// context
//util
import { checkExitsIntegration } from "utils/getIntegrationConnect";
//module
import { ExportToCsv } from "export-to-csv";

//config
import { PLATFORM, StatusAppIntegration } from "config";
import { EnumRoleProduct } from "enums";
import { IAppRevenueCat } from "interfaces";
import { Enum_App_Status, Enum_Integration_Service } from "schema/schema";
import { usePermission } from "hooks/usePermission";
import { useGetMyRole } from "hooks/useGetMyRole";
import useTableProps from "hooks/useTableProps";
import { useAppKey } from "hooks/useIntegration/useAppKey";
import { useProduct } from "context/ProductContext";
import { EditIntegration } from "../EditIntegration";
import { useMe } from "context/MeContext";

const { confirm } = Modal;
export const RevenueCat: React.FC = () => {
  //state
  const [appConnect, setAppConnect] = useState<IAppRevenueCat | undefined>();
  //hook || get data
  const { productInfo, refetchProduct, isLoading: isLoadingProduct, isStale } = useProduct();
  const { isMember } = useMe();
  const {
    mutateDeleteAppKey,
    isLoadingDelete,
    mutateCreateAppKey,
    isLoadingCreate,
    tableQueryResult,
    filters,
    setFilters,
  } = useAppKey();
  const { push } = useNavigation();
  const apiUrl = useApiUrl();
  const { refetch: refetchRevenueCat, isFetching: isFetchingRefresh } = useCustom({
    url: `${apiUrl}/products/${productInfo?.id}/integrations:revenue-cat/refresh`,
    method: "get",
    queryOptions: {
      enabled: false,
      retry: 0,
    },
    successNotification: false,
    errorNotification: {
      type: "error",
      description: "Error",
      message: "Fail to refresh page!",
    },
  });
  const { combineRoleWithSuperAdmin } = usePermission();
  const { userRoleProductPermission } = useGetMyRole();
  //handle data for data table
  const apps = tableQueryResult.data?.data;
  let listProductCodeAppConnected = productInfo?.integrationPayloads?.data.find(
    (v) => v.attributes?.integration?.data?.attributes?.service === Enum_Integration_Service.RevenueCat,
  )?.attributes?.payload;
  let dataTable: IAppRevenueCat[] | undefined = apps?.map((value: any) => {
    return {
      ...value, // fix missing connect_url
      integration_service: Enum_Integration_Service.RevenueCat,
      app_id: value.id,
      project_id: listProductCodeAppConnected?.project_id,
      icon: value.icon,
      name: value.name,
      bundle_id: value.bundle_id,
      product_code: value.product_code,
      market: value.market,
      status: value.status,
      platform: value.platform === "IOS" ? PLATFORM.iOS : PLATFORM.Android,
      app_id_revenucat: listProductCodeAppConnected?.apps && listProductCodeAppConnected?.apps[value.product_code]?.id,
      apiKey: listProductCodeAppConnected?.apps && listProductCodeAppConnected?.apps[value.product_code]?.api_key?.key,
      isConnected:
        listProductCodeAppConnected?.apps && listProductCodeAppConnected?.apps[value.product_code]
          ? !Boolean(listProductCodeAppConnected?.apps[value.product_code][StatusAppIntegration])
          : false,
    };
  });
  useEffect(() => {
    if (!isStale && !checkExitsIntegration(productInfo, Enum_Integration_Service.RevenueCat) && productInfo?.id) {
      push(`/products/show/${productInfo?.id}#integration`);
      return;
    }
  }, [isStale, productInfo, push]);

  //function
  const handleRefresh = async () => {
    await refetchRevenueCat().then(() => tableQueryResult.refetch());
  };
  const handleExportCsvFile = () => {
    const dataToExportFile = dataTable?.map((data) => {
      return {
        ID: data.app_id || "-",
        Name: data.name || "-",
        Market: data.market || "-",
        AppCode: data.product_code || "-",
        AppKey: data.apiKey || "-",
        Platform: data.platform || "-",
        BundleId: data.bundle_id || "-",
        ViewIntegration: Boolean(data.apiKey)
          ? `https://app.revenuecat.com/projects/${data.project_id}/apps/${data.app_id_revenucat}`
          : "-",
        Connected: Boolean(data.apiKey) ? "Yes" : "No",
      };
    });
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      title: "",
      filename: "RevenueCat Integration",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: [
        "App Id",
        "Name",
        "Market",
        "AppCode",
        "Api Key",
        "Platform",
        "Bundle Id",
        "View Integration",
        "Connected",
      ],
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(dataToExportFile);
  };
  const refreshData = () => {
    refetchProduct();
    tableQueryResult.refetch();
  };
  const handleConnect = async (appSelected: any) => {
    await mutateCreateAppKey({
      resource: `products/${productInfo?.id}/integrations:revenue-cat/app`,
      values: {
        app_id: Number(appSelected?.app_id),
      },
      successNotification: {
        type: "success",
        description: "Success",
        message: `Product have succesfully connected to ${appConnect?.name}`,
      },
      errorNotification: {
        type: "error",
        description: "Error",
        message: `Fail to connect to ${appConnect?.name}`,
      },
    });
    refreshData();
    setAppConnect(undefined);
  };

  const renderText = (text: string, platform: boolean, type: "Key" | "ID") => {
    return (
      <div>
        {platform ? (
          <span className="text-[#1890FF] text-[14px] leading-[22px] font-normal cursor-pointer">
            {type} <CopyToClipboardTooltip text={text} />
          </span>
        ) : (
          <span className="cursor-not-allowed">
            <span className="text-[#BFBFBF] text-[14px] leading-[22px] font-normal">{type}</span>
            <span className="text-[#D9D9D9] text-[14px] leading-[22px] font-normal ml-[10px]">
              <CopyOutlined />
            </span>
          </span>
        )}
      </div>
    );
  };

  const { columnsPropsAppName, columnsPropsAppCode, columnsPropsMarket, columnsPropsAppIcon } = useTableProps({
    filters,
    setFilters,
  });

  //column table
  const columns: ColumnsType<IAppRevenueCat> = React.useMemo(() => {
    const handleChangeConnect = (record: IAppRevenueCat) => {
      if (record.isConnected) {
        confirm({
          title: `Are you sure you want to disconnect Revenuecat?`,
          okText: "Yes",
          cancelText: "No",
          async onOk() {
            handleDisconnectApp(record);
          },
          async onCancel() {
            setAppConnect(undefined);
          },
        });
      } else {
        if (record.status !== Enum_App_Status.Active) {
          confirm({
            title: `You have to active application before connect application integration`,
            closable: true,
            centered: true,
            className: "no-footer",
          });
          return;
        } else {
          handleConnect(record);
        }
      }
    };
    const handleDisconnectApp = async (record: IAppRevenueCat) => {
      await mutateDeleteAppKey({
        resource: `products/${productInfo?.id}/integrations:revenue-cat/disconnect?app_id=${record.app_id}`,
        id: "",
        successNotification: {
          type: "success",
          description: "Success",
          message: `Product have successfully disconnect to ${record.name}`,
        },
        errorNotification: {
          type: "error",
          description: "Error",
          message: `Fail to disconnect from  ${record.name}`,
        },
      });
      refreshData();
      setAppConnect(undefined);
    };
    return [
      {
        ...columnsPropsAppIcon,
      },
      {
        title: "App name",
        key: "name",
        width: "30%",
        ...columnsPropsAppName,
      },
      {
        ...columnsPropsAppCode,
      },
      {
        title: "Market",
        dataIndex: "market",
        key: "market",
        ...columnsPropsMarket,
      },
      {
        title: "Platform",
        dataIndex: "platform",
        key: "platform",
        filters: [...new Set(dataTable?.map((item) => item.platform))]?.map((item) => {
          return {
            value: item,
            text: item,
          };
        }),
        onFilter: (value, record) => {
          return record.platform.includes(value.toString());
        },
        render: (text) => {
          return <span className="text-[14px] leading-[22px] font-normal">{text}</span>;
        },
      },
      {
        title: "API key",
        dataIndex: "apiKey",
        key: "apiKey",
        render: (text, record) => {
          return renderText(text, record.isConnected, "Key");
        },
      },
      {
        title: "View on Integration",
        key: "link",
        render: (_text, record) => {
          return (
            <>
              <a
                className={` font-normal w-full inline-block ${
                  !record.isConnected ? "cursor-not-allowed text-gray-400" : "text-blue-500"
                }  `}
                href={`https://app.revenuecat.com/projects/${record.project_id}/apps/${record.app_id_revenucat}`}
                target="_blank"
                rel="noreferrer"
                onClick={(e) => {
                  !record.isConnected && e.preventDefault();
                }}
              >
                View details
              </a>
            </>
          );
        },
      },
      {
        title: "Connected",
        dataIndex: "isConnected,platform",
        key: "isConnected",
        render: (_, record) => {
          return (
            <Switch
              loading={(isLoadingDelete || isLoadingCreate) && record.app_id === appConnect?.app_id}
              disabled={
                productInfo?.isDisabled ||
                isFetchingRefresh ||
                (!combineRoleWithSuperAdmin(userRoleProductPermission !== EnumRoleProduct.Viewer) && isMember) ||
                ((isLoadingDelete || isLoadingCreate) && record.app_id !== appConnect?.app_id)
              }
              checked={record.isConnected}
              onClick={() => {
                handleChangeConnect(record);
                setAppConnect(record);
              }}
              checkedChildren={"ON"}
              unCheckedChildren={"OFF"}
            />
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTable]);
  const isDisabled =
    productInfo?.isDisabled ||
    (!combineRoleWithSuperAdmin(userRoleProductPermission !== EnumRoleProduct.Viewer) && isMember) ||
    isLoadingCreate ||
    isLoadingDelete ||
    isLoadingProduct;

  const isLoading =
    tableQueryResult.isFetching || isLoadingProduct || tableQueryResult.isLoading || isFetchingRefresh || isStale;

  return (
    <EditIntegration title="RevenueCat Integration" isLoading={isLoading} hiddenDelete={true} actionButtons={<></>}>
      <div className="w-full flex justify-end mb-4">
        <RefreshButton
          className="mr-2"
          disabled={isDisabled}
          loading={isFetchingRefresh}
          onClick={(e) => {
            e.preventDefault();
            handleRefresh();
          }}
        />
        <Button type="primary" onClick={handleExportCsvFile}>
          Export
        </Button>
      </div>
      <Table rowKey="bundle_id" columns={columns} dataSource={dataTable} />
    </EditIntegration>
  );
};
