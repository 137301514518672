import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Form, FormInstance, Select, Tag } from "@pankod/refine-antd";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import React from "react";
import { isDuplicatedFieldFormList } from "utils/form";
import { UserRoleOptions } from "hooks/useUserRole";
import { UsersAppsOptions } from "hooks/useUsers/useSelectUsers";
import { checkValidEmail } from "utils/checkValidEmail";
import { IStudioUser } from "interfaces";
import { EnumRoleProduct, EnumRoleStudio } from "enums";

interface TFormListAddUser {
  type: "studioUsers" | "productUsers";
  onFinish: (event: any) => Promise<void>;
  form: FormInstance<{}>;
  optionRoles?: UserRoleOptions[];
  optionUsers: UsersAppsOptions[] | undefined;
  studioUsers?: IStudioUser[];
  isLoadingProduct?: boolean;
}
export default function FormListAddUser({
  type,
  onFinish,
  form,
  optionRoles,
  optionUsers,
  studioUsers,
  isLoadingProduct,
}: TFormListAddUser) {
  const renderTag = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    let color = "default";

    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    // check format email
    const isValidEmail = checkValidEmail(value);
    const isExistEmailInStudio = studioUsers?.some((studioUser) => studioUser.users_permissions_user?.email === value);
    if (!isValidEmail || isExistEmailInStudio) {
      color = "red";
    }

    return (
      <Tag
        key={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        color={color}
        className="mb-[3px] mt-[3px]"
      >
        {label}
      </Tag>
    );
  };
  return (
    <Form layout="vertical" onFinish={onFinish} form={form}>
      <div className="mb-[22px] text-[14px] font-normal leading-[22px]">
        Enter one ore more members below. Then select a role to grant access for them to your projects
      </div>
      <div className="flex justify-between">
        <div className="grow flex-[50%] mr-[30px]">
          <Form.List
            name="addUserEmail"
            initialValue={[{ role: type === "studioUsers" ? EnumRoleStudio.Member : EnumRoleProduct.Editor }]}
            rules={[
              {
                validator: (_rules, values: { emails: string[]; role: string | number }[]) => {
                  if (Array.isArray(values) && values.length > 0) {
                    if (isDuplicatedFieldFormList("role", values)) {
                      return Promise.reject(new Error("Role cannot be duplicated. Please check it again."));
                    }
                    if (isDuplicatedFieldFormList("emails", values, true)) {
                      return Promise.reject(new Error("One user cannot be assigned more than one role."));
                    }
                    //check email for user info from studio
                    if (type === "studioUsers") {
                      if (values.every((val) => val?.emails?.every((email) => email))) {
                        const isValidEmail: boolean = values.every((value) =>
                          value?.emails?.every((email) => checkValidEmail(email)),
                        );
                        if (!isValidEmail) {
                          return Promise.reject(new Error("The email address is wrong format."));
                        }

                        const totalEmails = values.reduce((acc, item) => {
                          acc = acc + item?.emails?.length;
                          return acc;
                        }, 0);
                        if (totalEmails > 9) {
                          return Promise.reject(new Error("Limit invite 8 email."));
                        }
                        const existEmailsInStudio = studioUsers?.filter((user) =>
                          values.some((item) => item.emails?.includes(user.users_permissions_user?.email || "")),
                        );
                        if (existEmailsInStudio && existEmailsInStudio?.length > 0) {
                          return Promise.reject(new Error("The emails address are existed in Studio."));
                        }
                      }
                    }
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name }, index) => (
                  <div key={key} className="flex justify-between relative pr-4">
                    <MinusCircleOutlined
                      className={` ${
                        index === 0 ? "hidden" : ""
                      } self-baseline text-[16px] text-red-500 absolute top-[38px] right-[-10px]`}
                      onClick={() => remove(name)}
                    />
                    <div className="grow flex-[50%] mr-[0.5rem]">
                      <div className="text-[14px] font-semibold text-[#000] mb-[8px]">New member</div>
                      <Form.Item
                        fieldKey={key}
                        name={[name, "emails"]}
                        rules={[
                          {
                            required: true,
                            message: "Email is required!",
                          },
                        ]}
                      >
                        <Select
                          loading={isLoadingProduct}
                          className="w-full"
                          mode={type === "productUsers" ? "multiple" : "tags"}
                          {...(type === "studioUsers" && { tagRender: (props) => renderTag(props) })}
                          placeholder="Please input value & enter"
                          showSearch={true}
                          options={optionUsers}
                        />
                      </Form.Item>
                    </div>
                    <div className="flex-[20%] grow">
                      <div className="text-[14px] font-semibold text-[#000] mb-[8px]">Role</div>
                      <Form.Item
                        fieldKey={key}
                        name={[name, "role"]}
                        rules={[
                          {
                            required: true,
                            message: "Role is required!",
                          },
                        ]}
                      >
                        <Select options={optionRoles} placeholder="Role" />
                      </Form.Item>
                    </div>
                  </div>
                ))}
                {errors.length > 0 && <div className="text-[red] mb-4">{errors}</div>}
                {fields.length < (optionRoles?.length || 3) && (
                  <span className="cursor-pointer text-gray-6 text-[14px]" onClick={() => add()}>
                    <PlusCircleOutlined /> ADD ANOTHER ROLE
                  </span>
                )}
              </>
            )}
          </Form.List>
        </div>
      </div>
    </Form>
  );
}
