import { TDataSelected } from "pages/Metabase/Metabase";
import { TDashboardInfo } from "./../../pages/Metabase/Metabase";
import { IBookmarks } from "interfaces";
import { getIdBookmark } from "./getIdBookmark";
import { BaseRecord, CreateResponse } from "@pankod/refine-core";
import { FormInstance } from "@pankod/refine-antd";

interface TPropsShowDashboard {
  history: any;
  listBookmark: IBookmarks[] | undefined;
  dashboardInfo: TDashboardInfo;
  productInfo: TDataSelected[];
  appInfo: TDataSelected[];
  form: FormInstance<{}>;
  getToken: any;
  deleteBookmark: any;
  addRecentDashboard: any;
  refetchListBookmark: any;
}
export const handleShowDashboard = async ({
  history,
  listBookmark,
  dashboardInfo,
  productInfo,
  appInfo,
  form,
  getToken,
  deleteBookmark,
  addRecentDashboard,
  refetchListBookmark,
}: TPropsShowDashboard) => {
  await getToken({
    resource: "metabase/get-url",
    values: {
      id: dashboardInfo?.identifier,
      type: dashboardInfo?.type?.toLowerCase(),
      ama_app_id: appInfo?.map((appInfo: TDataSelected) => appInfo.value),
    },
    successNotification: false,
    errorNotification: false,
  }).then((data: CreateResponse<BaseRecord>) => {
    form.setFieldsValue({
      iframeUrl: data.data.data.attributes.data.attributes.url,
    });
  });
  const url = `${window.location.pathname}?dashboardId=${dashboardInfo?.value}&productId=${
    productInfo && productInfo.map((e: TDataSelected) => e.value)
  }&appId=${appInfo && appInfo.map((appInfo: TDataSelected) => appInfo.value)}`;
  history.replace(url);

  const listRecent = listBookmark?.filter((item) => item.type === "RECENT");
  let idExist = getIdBookmark({
    listData: listRecent,
    dashboardInfo,
    productInfo,
    appInfo,
  });
  if (idExist) {
    await deleteBookmark({
      resource: "bookmarks",
      id: idExist,
      successNotification: false,
      errorNotification: false,
    });
  }
  await addRecentDashboard({
    resource: "bookmarks",
    values: {
      type: "RECENT",
      dashboard_id: dashboardInfo?.value,
      product_ids: productInfo?.map((v: TDataSelected) => v.value),
      app_ids: appInfo?.map((v: TDataSelected) => v.value),
    },
    successNotification: false,
    errorNotification: false,
  });
  refetchListBookmark();
};
