import React from "react";
import { useList } from "@pankod/refine-core";
import { useParams } from "react-router-dom";
import { AppsFlyerEdit } from "./components/AppsFlyer/AppsFlyerEdit";
import { EventGatewayEdit } from "./components/EventGateway/EventGatewayEdit";
import { AmaPassportEdit } from "./components/AmaPassport/AmaPassportEdit";
import { ApplovinMaxEdit } from "./components/ApplovinMax/ApplovinMaxEdit";
import { IronSource } from "./components/IronSource";
import { RevenueCat } from "./components/RevenueCat/RevenueCat";
import { Enum_Integration_Service } from "schema/schema";
import Page404 from "components/Result/404";
import { Daremi } from "./components/Daremi/Daremi";
import { ProductContextProvider } from "context/ProductContext";

export const IntegrationShow: React.FC = () => {
  const params = useParams();
  const serviceName = params.serviceName || "";
  const { data, isFetchedAfterMount } = useList({
    resource: "integrations",
    config: {
      filters: [
        {
          field: "service",
          operator: "eq",
          value: serviceName.toUpperCase(),
        },
      ],
    },
  });

  const integrationData = data?.data[0];

  const service = integrationData?.service;
  let ConfigComponent = <></>;
  switch (service) {
    case Enum_Integration_Service.RevenueCat:
      ConfigComponent = <RevenueCat />;
      break;
    case Enum_Integration_Service.Appsflyer:
      ConfigComponent = <AppsFlyerEdit />;
      break;
    case Enum_Integration_Service.EventGateway:
      ConfigComponent = <EventGatewayEdit data={integrationData} />;
      break;
    case Enum_Integration_Service.AmaPassport:
      ConfigComponent = <AmaPassportEdit data={integrationData} />;
      break;
    case Enum_Integration_Service.ApplovinMax:
      ConfigComponent = <ApplovinMaxEdit data={integrationData} />;
      break;
    case Enum_Integration_Service.IronSource:
      ConfigComponent = <IronSource />;
      break;
    case Enum_Integration_Service.Daremi:
      ConfigComponent = <Daremi />;
      break;
  }
  if (integrationData) {
    return <ProductContextProvider>{ConfigComponent}</ProductContextProvider>;
  }

  if (!integrationData && isFetchedAfterMount) {
    return <Page404 />;
  }
  return null;
};
