import { ExportButton } from "@pankod/refine-antd";
import { ExportToCsv, Options } from "export-to-csv";
import { IObject } from "interfaces";
import { bytesToSize } from "utils/byteToSize";
import { isFolder } from "utils/folderAndFile";
const ExportCloudfrontButton: React.FC<{
  triggerExport: () => void;
  isLoading: boolean;
}> = ({ triggerExport, isLoading }) => <ExportButton type="primary" onClick={triggerExport} loading={isLoading} />;

interface ExportCloudfrontData {
  data: IObject[];
  bucketName: string;
  prefix?: string | null;
}

export const useExportCloudfront = (input: ExportCloudfrontData) => {
  const { bucketName, prefix, data } = input;
  const now = new Date();
  const title = `Cloudfront-${bucketName}/${prefix}-${now.toLocaleTimeString()}`;
  const options: Options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useTextFile: false,
    useBom: true,
    filename: title,
    useKeysAsHeaders: true,
  };

  const csvExporter = new ExportToCsv(options);
  const formatData = data.map((item) => {
    return {
      Name: item.Name,
      Type: isFolder(item.Name) ? "-" : item.Type,
      Size: isFolder(item.Name) ? "-" : bytesToSize(item.Size),
      LastModified: isFolder(item.Name) ? "-" : item.LastModified,
      CloudfrontLink: item.cloudfrontLink,
    };
  });
  const onGenerateCSV = () => {
    csvExporter.generateCsv(formatData);
  };

  return {
    ExportCloudfrontButton,
    triggerExport: onGenerateCSV,
  };
};
