import { Enum_App_Status } from "schema/schema";

export const getStatusProduct = (apps: any) => {
  let statusAppOfProduct: any = [
    ...new Set(apps?.map((item: any) => (item.attributes ? item.attributes.status : item.status))),
  ];
  let statusProduct: string;
  if (statusAppOfProduct.includes(Enum_App_Status.Active)) {
    statusProduct = Enum_App_Status.Active;
  } else if (statusAppOfProduct.includes(Enum_App_Status.Development)) {
    statusProduct = Enum_App_Status.Development;
  } else {
    statusProduct = Enum_App_Status.Inactive;
  }
  return statusProduct;
};
