const blue = {
  50: "#E7F5FF",
  100: "#D0EBFF",
  200: "#A5D8FF",
  300: "#74C0FC",
  400: "#4DABF7",
  500: "#339AF0",
  600: "#0081F0",
  700: "#006FD2",
  800: "#005FB6",
  900: "#004D95",
};

export default blue;
