import config from "config";
import { IStudioUser } from "interfaces";
import React from "react";
import { normalizeData } from "utils/MappingData";

export const useFetchStudioUser = (userId?: string) => {
  const token = localStorage.getItem(config.TOKEN_KEY);
  const [isLoading, setIsLoading] = React.useState(true);
  const [userStudio, setUserStudio] = React.useState<IStudioUser[] | undefined>();

  React.useEffect(() => {
    if (userId && token) {
      fetch(
        `${config.BACKEND_API_URL}/studio-user-roles?filters[users_permissions_user][id][$eq]=${userId}&populate[0]=studio&populate[1]=role.permissions&pagination[pageSize]=100`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
        .then((res) => {
          if (res.status !== 200) {
            setIsLoading(false);
            throw new Error(`Couldn't fetch studio users. Status: ${res.status}`);
          }
          return res;
        })
        .then((res) => res.json())
        .then((res) => {
          const data = normalizeData(res?.data);
          setUserStudio(data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [userId, token]);

  return {
    isLoadingStudioUser: isLoading,
    userStudio,
    setIsLoadingStudioUser: setIsLoading,
  };
};
