import { Layout } from "antd";
import React from "react";
import { useWebSettingQuery } from "schema/schema";

export const Footer: React.FC = () => {
  const [webSetting] = useWebSettingQuery();
  const version = webSetting.data?.webSetting?.data?.attributes?.version || "1.0.0";

  return (
    <Layout.Footer className="bg-white text-center py-3 text-[10px] mt-auto">
      Version PMP {version} Power by Amanotes
    </Layout.Footer>
  );
};
