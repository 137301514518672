import { useMe } from "context/MeContext";
import ProductInfo from "factory/Product";
import { useDebounce } from "hooks/useDebounce";
import React from "react";
import { Enum_Studiouserrole_Status, useStudiosQuery, useUsersPermissionsUsersQuery } from "schema/schema";
import { useUserRole } from "../useUserRole";

export type UsersRole = {
  email?: string | null;
  role?: string | null;
  users_permissions_user?: string | null;
  product_user_role?: string | null;
  product?: string;
};

export type UsersAppsOptions = {
  value: string | null | undefined;
  label: string | null | undefined;
  email: string | undefined;
};

interface OptionUsers {
  idsFiltered?: string[];
  productInfo?: ProductInfo;
}

export const useSelectUsers = (options?: OptionUsers) => {
  const idsFiltered = options?.idsFiltered;
  const productInfo = options?.productInfo;
  const { data: me } = useMe();
  const ids = idsFiltered && me?.id ? [...idsFiltered, me?.id] : me?.id ? [me?.id] : [];
  const isOps = me?.isOps;
  const studioMeIds = me?.studio_user_roles?.map((item) => item?.studio?.id || "-1");

  const newStudioIds = productInfo?.studios?.map((item) => item.id || "-1");
  const [page, setPage] = React.useState(1);
  const [studiosResult] = useStudiosQuery({
    variables: {
      filters: !isOps
        ? {
            id: {
              in: studioMeIds,
            },
          }
        : {},
    },
  });
  const studios = studiosResult.data?.studios?.data;
  const studioOptions = studios?.map((studio) => {
    return {
      value: studio.id,
      label: studio.attributes?.name,
    };
  });

  const [studioIds, setStudioIds] = React.useState<string[]>([]);
  const defaultStudios = React.useMemo(() => {
    const ids =
      me?.studio_user_roles?.map((item) => {
        return item?.studio?.id!;
      }) || [];
    return ids;
  }, [me?.studio_user_roles]);
  const [usersRole, setUsersRole] = React.useState<UsersRole[]>([]);
  const [searchText, setSearchText] = React.useState("");
  const debouncedSearchText = useDebounce(searchText, 500);
  const [queryResult, fetchMore] = useUsersPermissionsUsersQuery({
    variables: {
      filters: {
        studio_user_roles: {
          studio: {
            id: {
              in: productInfo?.studios && productInfo?.studios?.length > 0 ? newStudioIds : studioIds,
            },
          },
          status: {
            eq: Enum_Studiouserrole_Status.Accepted.toUpperCase(),
          },
        },
        email: {
          containsi: debouncedSearchText,
        },
        id: {
          notIn: ids,
        },
      },
      pagination: {
        limit: 200,
      },
    },
  });
  const meta = queryResult.data?.usersPermissionsUsers?.meta;
  const usersData = queryResult?.data?.usersPermissionsUsers?.data;
  const handleScroll = React.useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const ele = e.currentTarget;
      const isBottom = ele.scrollHeight - ele.scrollTop === ele.clientHeight;

      if (isBottom && meta?.pagination.page && meta?.pagination.page < meta.pagination.pageCount) {
        setPage(page + 1);
        fetchMore();
      }
    },
    [fetchMore, meta, page, setPage],
  );
  const usersAppsOptions: UsersAppsOptions[] | undefined = usersData?.map((item) => {
    return {
      value: item.attributes?.email,
      label: item.attributes?.email || "",
      email: item.attributes?.email,
    };
  });

  const { productRolePermissions } = useUserRole();
  const defaultRole = productRolePermissions?.find((item) => item.label.toUpperCase() === "EDITOR")?.label as
    | string
    | undefined;
  const _defaultRole = defaultRole?.toUpperCase();

  const meSelected = React.useMemo(() => {
    setUsersRole([
      {
        email: me?.email,
        role: _defaultRole,
      },
    ]);
    return {
      id: me?.id,
      attributes: {
        name: me?.name,
        icon: me?.icon,
        email: me?.email,
      },
    };
  }, [_defaultRole, me]);

  return {
    usersData: usersData && [...new Set(usersData)],
    usersRole,
    setUsersRole,
    meSelected,
    usersAppsOptions,
    defaultStudios,
    studioOptions,
    studioIds,
    setStudioIds,
    isLoadingUser: queryResult.fetching,
    defaultRole,
    userRoleOptions: productRolePermissions,
    isLoadingStudio: studiosResult.fetching,
    handleScroll,
    setSearchText,
    refetchUsers: fetchMore,
  };
};
