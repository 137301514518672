//react
import React, { useEffect, useState } from "react";

//csv module
import { ExportToCsv } from "export-to-csv";

//refine core
import { BaseRecord, CreateResponse, useNavigation } from "@pankod/refine-core";

//antd
import { Button, Switch, Table } from "@pankod/refine-antd";
import Modal from "antd/es/modal";
//antd icon
import { CopyOutlined } from "@ant-design/icons";

//interface
import { IApp, StoreEntity } from "interfaces/index";

// components
import { CopyToClipboardTooltip } from "components/Input/CopyToClipboard";

// context

//util
import { checkExitsIntegration } from "utils/getIntegrationConnect";

//config
import { PLATFORM, StatusAppIntegration } from "config";
import { EnumRoleProduct } from "enums";
import { Enum_App_Status, Enum_Integration_Service } from "schema/schema";
import useTableProps from "hooks/useTableProps";
import { usePermission } from "hooks/usePermission";
import { useGetMyRole } from "hooks/useGetMyRole";
import { useAppKey } from "hooks/useIntegration/useAppKey";
import { useProduct } from "context/ProductContext";
import { EditIntegration } from "../EditIntegration";
import { useMe } from "context/MeContext";

interface DataType {
  app_id: number;
  name: string;
  market: string;
  platform: string;
  bundle_id: string;
  connect_url: string;
  description: string;
  short_description: string;
  product_code: string;
  reward: string;
  interstitial: string;
  banner: string;
  status: string;
  isConnected: boolean;
}
const { confirm } = Modal;
export type IStores = {
  apps: StoreEntity[];
  apiKeys: IApp[];
};

export const ApplovinMaxEdit: React.FC<{ data: any }> = () => {
  //state
  const [selectItem, setSelectItem] = useState<DataType | undefined>();

  //hook || get data

  const { productInfo, refetchProduct, isLoading: isLoadingProduct, isStale } = useProduct();
  const {
    mutateDeleteAppKey,
    isLoadingDelete,
    mutateCreateAppKey,
    isLoadingCreate,
    tableQueryResult,
    filters,
    setFilters,
  } = useAppKey();
  const apps: any = tableQueryResult.data?.data;
  const { isMember } = useMe();
  const { push } = useNavigation();
  const { combineRoleWithSuperAdmin } = usePermission();
  const { userRoleProductPermission } = useGetMyRole();

  //handle data for table
  let listProductCodeAppConnected =
    productInfo?.integrationPayloads?.data.find(
      (v) => v.attributes?.integration?.data?.attributes?.service === Enum_Integration_Service.ApplovinMax,
    )?.attributes?.payload?.apps || {};
  let dataTable: DataType[] = apps?.map((value: any) => {
    return {
      app_id: value.id,
      name: value.name,
      market: value.market,
      platform: value.platform === "IOS" ? PLATFORM.iOS : PLATFORM.Android,
      connect_url: value.connect_url,
      description: value.description,
      short_description: value.short_description,
      status: value.status,
      bundle_id: value.bundle_id,
      product_code: value.product_code,
      reward: listProductCodeAppConnected && listProductCodeAppConnected[value.product_code]?.REWARD,
      interstitial: listProductCodeAppConnected && listProductCodeAppConnected[value.product_code]?.INTER,
      banner: listProductCodeAppConnected && listProductCodeAppConnected[value.product_code]?.BANNER,
      isConnected:
        listProductCodeAppConnected && listProductCodeAppConnected[value.product_code]
          ? !listProductCodeAppConnected[value.product_code][StatusAppIntegration]
          : false,
      icon: value.icon,
    };
  });
  //useEffect
  useEffect(() => {
    if (!isStale && !checkExitsIntegration(productInfo, Enum_Integration_Service.ApplovinMax) && productInfo?.id) {
      push(`/products/show/${productInfo?.id}#integration`);
    }
  }, [isStale, productInfo, push]);
  //function

  const renderText = (text: string, platform: boolean, type: "Key" | "ID") => {
    return (
      <div>
        {text && platform && (
          <span className="text-[#1890FF] text-[14px] leading-[22px] font-normal cursor-pointer">
            {text.toString().length > 4
              ? `...${text.toString().substring(text.toString().length - 4, text.toString().length)}`
              : text}
            <CopyToClipboardTooltip text={text} />
          </span>
        )}
        {!platform && (
          <>
            <span className="text-[#BFBFBF] text-[14px] leading-[22px] font-normal">{type}</span>
            <span className="text-[#D9D9D9] text-[14px] leading-[22px] font-normal ml-[10px]">
              <CopyOutlined />
            </span>
          </>
        )}
      </div>
    );
  };

  const handleExportCsvFile = () => {
    const dataToExportFile = dataTable.map((data) => {
      return {
        ID: data.app_id || "-",
        Name: data.name || "-",
        Market: data.market || "-",
        Platform: data.platform || "-",
        AppCode: data.product_code || "-",
        BundleId: data.bundle_id || "-",
        Reward: data.reward || "-",
        Interstitial: data.interstitial || "-",
        Banner: data.banner || "-",
        ViewIntegration: data.isConnected ? `https://dash.applovin.com/o/mediation/ad_units/281134` : "-",
        Connected: data.isConnected ? "Yes" : "No",
      };
    });
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      title: "",
      useTextFile: false,
      filename: "Applovin Max Integration",
      useBom: true,
      useKeysAsHeaders: false,
      headers: [
        "App Id",
        "Name",
        "Market",
        "Platform",
        "AppCode",
        "Bundle Id",
        "Reward",
        "Interstitial",
        "Banner",
        "View Integration",
        "Connected",
      ],
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(dataToExportFile);
  };

  const { columnsPropsAppName, columnsPropsAppCode, columnsPropsMarket, columnsPropsAppIcon } = useTableProps({
    filters,
    setFilters,
  });
  const columns: any = React.useMemo(() => {
    const handleChangeConnected = (record: DataType) => {
      let promise: Promise<CreateResponse<BaseRecord>>;
      if (!record.isConnected && record.status !== Enum_App_Status.Active) {
        confirm({
          title: `You have to active application before connect application integration`,
          closable: true,
          centered: true,
          className: "no-footer",
        });
        return;
      }
      setSelectItem(record);
      const app_id = record.app_id;
      confirm({
        title: `Are you sure you want to switch ${record.isConnected ? "Off" : "On"} ?`,
        okText: "Yes",
        cancelText: "No",
        onOk() {
          if (!record.isConnected) {
            promise = mutateCreateAppKey({
              resource: `products/${productInfo?.id}/integrations:applovin-max/ad-unit`,
              values: {
                app_id,
              },
              successNotification: {
                type: "success",
                description: "Success",
                message: `Product have successfully connected to ${record.name}`,
              },
              errorNotification: {
                type: "error",
                description: "Error",
                message: `Fail to connect to ${record.name}`,
              },
            });
          } else {
            promise = mutateDeleteAppKey({
              resource: `products/${productInfo?.id}/integrations:applovin-max/disconnect?app_id=${app_id}`,
              id: "",
              successNotification: {
                type: "success",
                description: "Success",
                message: `Product have successfully disconnect to ${record.name}`,
              },
              errorNotification: {
                type: "error",
                description: "Error",
                message: `Fail to disconnect from ${record.name}`,
              },
            });
          }
          Promise.resolve(promise).then(
            () => {
              refetchProduct();
              setSelectItem(undefined);
            },
            (error) => {
              setSelectItem(undefined);
            },
          );
        },
        onCancel() {
          setSelectItem(undefined);
        },
      });
    };
    return [
      {
        ...columnsPropsAppIcon,
      },
      {
        title: "App",
        key: "name",
        width: "20%",
        ...columnsPropsAppName,
      },
      {
        ...columnsPropsAppCode,
      },
      {
        title: "Market",
        dataIndex: "market",
        key: "market",
        width: "10%",
        ...columnsPropsMarket,
      },
      {
        title: "Platform",
        dataIndex: "platform",
        key: "platform",
        width: "12%",
        filters: [...new Set(dataTable?.map((item) => item.platform))].map((item) => {
          return {
            value: item,
            text: item,
          };
        }),
        onFilter: (value: string, record: DataType) => {
          return record.platform.includes(value.toString());
        },
        render: (text: string) => {
          return <span className="text-[14px] leading-[22px] font-normal">{text}</span>;
        },
      },
      {
        title: "Reward",
        dataIndex: "reward",
        key: "reward",
        width: "12%",
        render: (text: string, record: DataType) => {
          return renderText(text, record.isConnected, "ID");
        },
      },
      {
        title: "Interstitial",
        dataIndex: "interstitial",
        key: "interstitial",
        width: "12%",
        render: (text: string, record: DataType) => {
          return renderText(text, record.isConnected, "ID");
        },
      },
      {
        title: "Banner",
        dataIndex: "banner",
        key: "banner",
        width: "12%",
        render: (text: string, record: DataType) => {
          return renderText(text, record.isConnected, "ID");
        },
      },
      {
        title: "View on Integration",
        width: "12%",
        render: (_: any, record: DataType) => (
          <>
            {record.isConnected ? (
              <a
                className="text-blue-500 font-normal w-full inline-block"
                href={`https://dash.applovin.com/o/mediation/ad_units/281134`}
                target="_blank"
                rel="noreferrer"
              >
                View details
              </a>
            ) : (
              <div className="text-gray-400">View details</div>
            )}
          </>
        ),
      },
      {
        title: "Connected",
        width: "10%",
        render: (record: DataType) => {
          return (
            <Switch
              disabled={
                productInfo?.isDisabled ||
                (!combineRoleWithSuperAdmin(userRoleProductPermission !== EnumRoleProduct.Viewer) && isMember) ||
                (selectItem && selectItem.bundle_id !== record.bundle_id)
              }
              loading={selectItem && selectItem.bundle_id === record.bundle_id && (isLoadingCreate || isLoadingDelete)}
              checked={record.isConnected}
              onClick={() => {
                handleChangeConnected(record);
              }}
              checkedChildren={"ON"}
              unCheckedChildren={"OFF"}
            />
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTable]);
  return (
    <EditIntegration
      title="AppLovin Integration"
      hiddenDelete={true}
      actionButtons={<></>}
      isLoading={isLoadingProduct || tableQueryResult.isFetching || isStale}
    >
      <div className="w-full flex justify-end mb-4">
        <Button type="primary" onClick={handleExportCsvFile}>
          Export
        </Button>
      </div>
      <Table
        rowKey="bundle_id"
        columns={columns}
        dataSource={dataTable}
        pagination={{
          pageSizeOptions: [10, 20, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
      />
    </EditIntegration>
  );
};
