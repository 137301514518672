import { ExclamationCircleOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { Avatar, Button, Form, Modal, ModalProps } from "@pankod/refine-antd";
import { IIntegration, IIntegrationDataCreate } from "interfaces";
import { AwsCreate } from "pages/Integration/components/Aws/AwsCreate";
import { OtherIntegrationCreate } from "pages/Integration/components/Common/OtherIntegartionCreate";
import { RevenueCatCreate } from "pages/Integration/components/RevenueCat/RevenueCatCreate";
import React from "react";
import { PropsWithChildren } from "react";
import { useProductStepForm } from "context/ProductStepFormContext";
import { Enum_Integration_Service } from "schema/schema";

type IntegrationModalCreateProps = {
  modalProps: ModalProps;
  close: () => void;
  item?: IIntegration;
  onFinish?: (e: any) => void;
};

type IntegrationModalStepCreateProps = IntegrationModalCreateProps;

type IntegrationModalDetailCreateProps = IntegrationModalCreateProps & {
  handleIntegration: (e: any) => void;
};

const IntegrationModal: React.FC<PropsWithChildren<IntegrationModalCreateProps>> = ({
  modalProps,
  item,
  close,
  onFinish,
}) => {
  const [form] = Form.useForm();
  const { integrationData } = useProductStepForm();
  const index = integrationData?.findIndex((IntegrationItem) => IntegrationItem.id === item?.id);
  const payload = index && integrationData && integrationData[index]?.payload;
  const handleConfirm = () => {
    form.submit();
  };
  let ConfigComponent: any = React.Fragment;

  if (item?.service) {
    switch (item?.service) {
      case Enum_Integration_Service.RevenueCat:
        ConfigComponent = RevenueCatCreate;
        break;
      case Enum_Integration_Service.Aws:
        ConfigComponent = () => <AwsCreate item={item} />;
        break;
      default:
        ConfigComponent = () => <OtherIntegrationCreate name={item?.name} />;
    }
  }
  return (
    <Modal
      {...modalProps}
      centered
      maskClosable={false}
      className="rounded-[10px] shadow-md w-[600px]"
      title={
        <div className="flex">
          <Avatar size={32} shape="square" src={item?.icon?.url} />
          <p className="font-bold text-[16px] ml-[35px] mt-[5px] text-white">Config {item?.name}</p>
        </div>
      }
      css={css`
        .ant-modal-header {
          background-color: #4f006a;
          height: 46px;
          padding-top: 8px;
        }
        .ant-modal-title {
          margin-top: 0px; !importan;
        }
        .ant-modal-close .anticon{
          color: #FF7875;
          font-size: 24px;
        }
        .ant-modal-footer{
          border-top: none;
        }
        .ant-modal-body {
          padding: 24px 0;
        }
      `}
      footer={[
        <div key="footer">
          <Button htmlType="button" className="bg-gray-400 text-white " onClick={close}>
            Close
          </Button>
          <Button htmlType="button" type="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </div>,
      ]}
    >
      <Form
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        labelAlign="right"
        form={form}
        onFinish={onFinish}
        initialValues={{
          RevenueCat: payload || [{ application: "" }],
          ironSource: payload,
          ...payload,
        }}
      >
        <ConfigComponent />
      </Form>
    </Modal>
  );
};
export const IntegrationModalStepCreate: React.FC<PropsWithChildren<IntegrationModalStepCreateProps>> = ({
  close,
  modalProps,
  item,
}) => {
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const { isConfigAws, setIsConfigAws, setIntegrationData } = useProductStepForm();
  const isExist = (prev: any[], id?: string) => prev.some((dataItem) => dataItem.id === id);

  const handleIntegration = (e: any) => {
    if (e.RevenueCat) e = e.RevenueCat;
    item?.service &&
      setIntegrationData?.((prev: IIntegrationDataCreate[]) => {
        const payload = e;
        if (isExist(prev, item?.id)) {
          const index = prev.findIndex((IntegrationItem) => IntegrationItem.id === item?.id);
          prev[index] = {
            ...prev[index],
            payload,
          };
          return prev;
        }
        return [
          ...prev,
          {
            id: item?.id,
            payload,
          },
        ];
      });
    if (item?.service === Enum_Integration_Service.Aws && !isConfigAws) {
      setIsConfigAws(true);
    }
    close();
  };
  const showConfirm = () => {
    confirm({
      centered: true,
      title: `If you don’t config AWS, your product will use PMP default set up`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        close();
        setIsConfigAws(true);
      },
      onCancel() {},
    });
  };
  const handleCloseConfirm = () => {
    if (item?.service !== Enum_Integration_Service.Aws || isConfigAws) {
      close();
    } else {
      showConfirm();
    }
  };
  React.useEffect(() => {
    modalProps.visible && form.resetFields();
  }, [form, modalProps.visible]);
  return (
    <IntegrationModal modalProps={modalProps} close={handleCloseConfirm} item={item} onFinish={handleIntegration} />
  );
};

export const IntegrationModalDetailCreate: React.FC<PropsWithChildren<IntegrationModalDetailCreateProps>> = ({
  close,
  modalProps,
  item,
  handleIntegration,
}) => {
  const handleClose = () => {
    close?.();
  };
  return (
    <div>
      <IntegrationModal modalProps={modalProps} close={handleClose} item={item} onFinish={handleIntegration} />
    </div>
  );
};
