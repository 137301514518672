import { Avatar, Form, Input } from "@pankod/refine-antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import React from "react";
import { IIntegration } from "interfaces";
import { useProductStepForm } from "context/ProductStepFormContext";
import config from "config";
import { FormItemHiddenLabel } from "components/Form/FormItemHiddenLabel";
import { TooltipCustom } from "components/Input/Tooltip";

export const AwsCreate: React.FC<{
  item: IIntegration;
}> = ({ item }) => {
  const { setIntegrationData } = useProductStepForm();
  React.useEffect(() => {
    setIntegrationData?.((integrations) => {
      const isExistAWS = integrations.find((i) => i.id === item.id);
      return isExistAWS
        ? integrations
        : [
            ...integrations,
            {
              id: item.id,
              payload: { bucket: config.BUCKET_NAME },
            },
          ];
    });
  }, [setIntegrationData, item.id]);

  return (
    <div className="p-[20px]">
      <div className="flex justify-center mt-[6px] mb-[14px]">
        <Avatar size={32} shape="square" src={item?.icon?.url} />
        <p className="font-bold text-[16px] ml-[10px] mt-[5px]">S3 & Cloudfront</p>
      </div>
      <Form.Item
        label={
          <div>
            <b>S3 -</b> Bucket name
            <TooltipCustom title="Make sure your bucket name is available"></TooltipCustom>
          </div>
        }
        name="bucket"
        rules={[{ required: true, message: "Bucket name is required!" }]}
      >
        <Input placeholder={config.BUCKET_NAME} />
      </Form.Item>
      <Form.Item
        label={
          <div>
            <span>Prefix</span>{" "}
            <TooltipCustom
              title={
                <>
                  <b>Example: </b>
                  You want to access to S3 URI <br />
                  <i>ama-awsconfig/AWSLogs/945185781776</i> <br />
                  Your bucket is <b>ama-awsconfig</b>
                  <br />
                  Your prefix is <b>AWSLogs/945185781776</b>
                </>
              }
            ></TooltipCustom>
          </div>
        }
        name="prefix"
      >
        <Input placeholder={"sub/sub"} />
      </Form.Item>

      <Form.Item
        label={
          <div>
            <b>Cloudfront </b> domain <InfoCircleOutlined />
          </div>
        }
        name="cloudfront"
        rules={[{ required: false, message: "Cloudfront domain" }]}
      >
        <Input placeholder="Enter your Cloudfront" />
      </Form.Item>
      <FormItemHiddenLabel>
        <span className=" text-red-600">
          Note: You have to configure AWS before move to other integration and make sure you enter right AWS
          information, if not you have to contact the administrator to update it
        </span>
      </FormItemHiddenLabel>
    </div>
  );
};
