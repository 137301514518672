import { Button, Icons, Tooltip } from "@pankod/refine-antd";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
const { CopyOutlined } = Icons;

interface CopyToClipboardOptions {
  options?: {
    debug?: boolean;
    format?: "text/html" | "text/plain";
    message?: string;
  };
  text: string;
  className?: string;
  children?: React.ReactNode;
}

export const CopyToClipboardControl: React.FC<CopyToClipboardOptions> = ({ options, text, className }) => {
  const [copied, setCopied] = React.useState(false);
  const unmounted = React.useRef<boolean>(false);

  React.useEffect(() => {
    return (): void => {
      unmounted.current = true;
    };
  }, []);

  React.useEffect(() => {
    if (copied) {
      setTimeout(() => {
        !unmounted.current && setCopied(false);
      }, 1500);
    }
  }, [copied]);

  return (
    <span
      className={`${className ?? ""}`}
      onClick={(e): void => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <CopyToClipboard
        children={<CopyOutlined className="ml-2" />}
        options={options}
        text={text}
        onCopy={(): void => setCopied(true)}
      />
      {copied && <span className="ml-1 font-normal text-[12px]">Copied</span>}
    </span>
  );
};

export const CopyS3: React.FC<CopyToClipboardOptions & { disabled?: boolean; title?: string }> = ({
  options,
  text,
  disabled,
  title = "Copy S3 URI",
}) => {
  return (
    <CopyToClipboardTooltip disabled={disabled} text={text} options={options}>
      <Button disabled={disabled}>
        <CopyOutlined /> {title}
      </Button>
    </CopyToClipboardTooltip>
  );
};

export const CopyToClipboardTooltip: React.FC<CopyToClipboardOptions & { disabled?: boolean }> = ({
  options,
  text,
  children,
  disabled,
}) => {
  const [copied, setCopied] = React.useState(false);
  const unmounted = React.useRef<boolean>(false);

  React.useEffect(() => {
    return (): void => {
      unmounted.current = true;
    };
  }, []);

  React.useEffect(() => {
    if (copied) {
      setTimeout(() => {
        !unmounted.current && setCopied(false);
      }, 1500);
    }
  }, [copied]);

  return (
    <span
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <CopyToClipboard
        options={options}
        text={text}
        onCopy={(): void => {
          if (!disabled) {
            setCopied(true);
          }
        }}
      >
        <Tooltip placement="top" visible={copied} trigger={"click"} title={"Copied"}>
          {!children ? <CopyOutlined className="ml-2" /> : children}
        </Tooltip>
      </CopyToClipboard>
    </span>
  );
};
