import { css } from "@emotion/react";
import { Typography } from "@pankod/refine-antd";
import { FirebaseIcon } from "components/Icons";
import React from "react";
import { FirebaseFormCreate } from "./FirebaseFormCreate";
export const FirebaseStep: React.FC = () => {
  return (
    <div
      css={css`
        .form-list-item {
          &:nth-last-of-type(2) {
            margin-bottom: 0 !important;
          }
        }
      `}
    >
      <Typography.Title level={5} className="text-center mb-[2rem] flex items-center justify-center text-[#F89800]">
        <FirebaseIcon />
        Firebase
      </Typography.Title>
      <FirebaseFormCreate isCreateProduct={true} />
    </div>
  );
};
