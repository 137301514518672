import { AuthProvider, HttpError } from "@pankod/refine-core";
import { DataProvider as DataProvider$1 } from "@pankod/refine-core";

import axios from "axios";
import { DataProvider } from "@pankod/refine-strapi-v4";
import config from "config";

export const axiosInstance = axios.create();
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let customError: HttpError = {
      ...error,
      message: error.response?.data?.error?.message,
      statusCode: error.response?.status,
    };

    if (error.response?.status === 429) {
      customError = {
        ...customError,
        message: error.response?.data.message,
        statusCode: error.response?.status,
      };
    }

    if (error.response?.status === 403) {
      customError = {
        ...customError,
        message: error.response?.data?.error?.message || config.UnAuthorizedActionErrorMessage,
        statusCode: error.response?.status,
      };
    }

    return Promise.reject(customError);
  },
);
const apiUrlBE = config.BACKEND_URL + "/api";
export const CustomRestDataProvider = (apiUrl: string, httpClient: any = axiosInstance): DataProvider$1 => ({
  ...DataProvider(apiUrl, httpClient),
  deleteOne: async ({ resource, id }) => {
    const url = id ? `${apiUrl}/${resource}/${id}` : `${apiUrl}/${resource}`;
    const r = await httpClient.delete(`${url}`);
    return r;
  },
});
export const restDataProvider = CustomRestDataProvider(apiUrlBE, axiosInstance);

export const authProviderRest: AuthProvider = {
  login: () => Promise.resolve(),
  logout: () => {
    localStorage.removeItem(config.TOKEN_KEY);
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 403) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    const token = localStorage.getItem(config.TOKEN_KEY);
    const studioId = localStorage.getItem("studioId");
    if (token) {
      axiosInstance.defaults.headers = {
        Authorization: `Bearer ${token}`,
        "x-current-studio": studioId,
      };
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const meRaw = localStorage.getItem("user");
    const me = meRaw && JSON.parse(meRaw);

    if (me) {
      return Promise.resolve({
        ...me,
      });
    }

    return Promise.reject();
  },
};
