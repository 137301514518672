import { useCustom } from "@pankod/refine-core";
import { GetBucketLocationOutput } from "aws-sdk/clients/s3";
import config from "config";
import { useMe } from "context/MeContext";

interface BucketLocationData {
  bucketName: string;
  productId: string;
}

export const useBucketLocation = (data: BucketLocationData) => {
  const me = useMe();

  const { bucketName, productId } = data;
  const {
    data: dataLocation,
    isSuccess: isSuccessLocation,
    isFetching: isLoadingLocation,
  } = useCustom<GetBucketLocationOutput>({
    url: `${config.S3_URL}/getBucketLocation`,
    method: "get",
    queryOptions: {
      enabled: Boolean(bucketName) && Boolean(me?.data?.id),
      retry: 0,
    },
    config: {
      query: {
        bucketName,
        userId: me?.data?.id,
        productId: productId,
      },
    },
  });
  const region = dataLocation?.data.LocationConstraint || "us-east-1";

  return {
    isSuccessLocation,
    isLoadingLocation,
    region,
  };
};
