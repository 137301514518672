import { DateField, Table, TextField } from "@pankod/refine-antd";
import { getLastPartOfPath, isFolder } from "utils/folderAndFile";
import { Link, useLocation } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import { IObject } from "interfaces";
import { bytesToSize } from "utils/byteToSize";
import { CopyToClipboardTooltip } from "components/Input/CopyToClipboard";
import ProductInfo from "factory/Product";
import { useGetMyRole } from "hooks/useGetMyRole";
import { EnumRoleProduct } from "enums";

interface BucketTableProps {
  dataSource: IObject[];
  loading: boolean;
  rowSelection?: any;
  components: any;
  setEditingKey: (val: string) => void;
  bucketName: string;
  prefix?: string | null;
  isEditing: (record: IObject) => boolean;
  handleSave: (row: any) => void;
  productInfo?: ProductInfo;
}

export const BucketTable: React.FC<BucketTableProps> = ({
  handleSave,
  dataSource,
  loading,
  rowSelection,
  components,
  setEditingKey,
  bucketName,
  prefix,
  isEditing,
  productInfo,
}) => {
  const location = useLocation();
  const { userRoleProductPermission } = useGetMyRole();
  const isDisabled = (value: any) => {
    if (userRoleProductPermission === EnumRoleProduct.Viewer || productInfo?.isDisabled) {
      return true;
    }
    return isFolder(value);
  };
  return (
    <Table
      dataSource={dataSource}
      rowKey="Name"
      loading={loading}
      rowSelection={rowSelection}
      components={components}
      scroll={{ x: "100vw", y: "100%" }}
    >
      <Table.Column
        width={"25%"}
        dataIndex="Name"
        key="Name"
        title="Name"
        render={(value, record) => {
          return (
            <div className="flex justify-between items-center">
              <Link to={`${location.pathname}?prefix=${value}`} className={`${!isFolder(value) ? "text-black" : ""}`}>
                {getLastPartOfPath(value)}
              </Link>
              {!isDisabled(value) ? (
                <EditOutlined className="cursor-pointer" onClick={() => setEditingKey(record.key)} />
              ) : null}
            </div>
          );
        }}
        onCell={(record: any) => ({
          record,
          editable: !isFolder(record.Name) ? true : false,
          dataIndex: "Name",
          title: "Name",
          editing: isEditing(record),
          setEditingKey,
          bucketName,
          handleSave: handleSave,
          prefixS3: prefix,
          productInfo,
        })}
        sorter={(a: IObject, b: IObject) => a.Name.localeCompare(b.Name)}
      />
      <Table.Column
        width={"5%"}
        dataIndex="Type"
        key="Type"
        title="Type"
        render={(value) => <TextField value={value} />}
      />
      <Table.Column
        width={"15%"}
        dataIndex="LastModified"
        key="LastModified"
        title="Last modified"
        render={(value) => value && <DateField format="YYYY-MM-DD hh:ss" value={value} />}
        sorter={(a: IObject, b: IObject) => {
          const aDate = new Date(b.LastModified || "");
          const bDate = new Date(a.LastModified || "");
          return aDate.getTime() - bDate.getTime();
        }}
      />
      <Table.Column
        width={"10%"}
        dataIndex="Size"
        key="Size"
        title="Size"
        render={(value) => <TextField value={bytesToSize(value)} />}
        sorter={(a: IObject, b: IObject) => (a.Size || 0) - (b.Size || 0)}
      />

      <Table.Column
        width={"45%"}
        dataIndex="cloudfrontLink"
        key="cloudfrontLink"
        title="CloudFront"
        render={(value, record: any) =>
          !isFolder(record.Name) ? (
            <div className="flex items-center">
              <TextField value={value} />
              {value !== "-" && <CopyToClipboardTooltip text={value} />}
            </div>
          ) : (
            "-"
          )
        }
      />
    </Table>
  );
};
