import { useAuth0 } from "@auth0/auth0-react";
import config from "config";

export const useAuth = () => {
  const { logout: logoutAuth0 } = useAuth0();

  const logout = () => {
    window.localStorage.removeItem(config.TOKEN_KEY);
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("studioId");
    logoutAuth0({ returnTo: window.location.origin });
  };
  return { logout };
};
