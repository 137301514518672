import { Authenticated } from "@pankod/refine-core";
import { IntegrationProvider } from "context/IntegrationContext";
import { ProductContextProvider } from "context/ProductContext";
import { EnumIntegrationName } from "enums";
import { IRoute } from "interfaces";
import { Appsflyer } from "pages/Appsflyer";
import AppsFlyerSetting from "pages/Integration/components/AppsFlyer/AppsFlyerSetting";
import ChartIntegration from "pages/Integration/components/ChartIntegration";
import { EditAppDaremi } from "pages/Integration/components/Daremi/EditAppDaremi";
import { RegisterAppDaremi } from "pages/Integration/components/Daremi/RegisterAppDaremi";
import { IntegrationShow } from "pages/Integration/edit";
import { Metabase } from "pages/Metabase";
import { ObjectList } from "pages/Product/bucket";
import { AWSPage } from "pages/Product/bucket/edit";

export const integrationRoutes: IRoute[] | any = [
  {
    element: (
      <Authenticated>
        <ObjectList />
      </Authenticated>
    ),
    layout: true,
    path: "products/show/:productId/integrations/aws",
  },
  {
    element: (
      <Authenticated>
        <AWSPage />
      </Authenticated>
    ),
    layout: true,
    path: "products/show/:productId/integrations/edit/aws",
  },
  {
    element: (
      <Authenticated>
        <ObjectList />
      </Authenticated>
    ),
    layout: true,
    path: "products/show/:productId/integrations/aws/buckets",
  },
  {
    element: (
      <Authenticated>
        <IntegrationShow />
      </Authenticated>
    ),
    path: `products/show/:productId/integrations/:serviceName`,
    parent: "products",
    layout: true,
  },

  {
    element: (
      <Authenticated>
        <ProductContextProvider>
          <EditAppDaremi />
        </ProductContextProvider>
      </Authenticated>
    ),
    path: `products/show/:productId/integrations/edit/${EnumIntegrationName.Daremi}/setting/:appId`,
    parent: "products",
    layout: true,
  },
  {
    element: (
      <Authenticated>
        <ProductContextProvider>
          <RegisterAppDaremi />
        </ProductContextProvider>
      </Authenticated>
    ),
    path: `products/show/:productId/integrations/daremi/register/:appId`,
    parent: "products",
    layout: true,
  },
  {
    element: (
      <Authenticated>
        <IntegrationProvider>
          <AppsFlyerSetting />
        </IntegrationProvider>
      </Authenticated>
    ),
    path: `products/show/:productId/integrations/edit/${EnumIntegrationName.AppsFlyer}/setting/:appId`,
    layout: true,
  },
  {
    element: (
      <Authenticated>
        <IntegrationProvider>
          <ChartIntegration />
        </IntegrationProvider>
      </Authenticated>
    ),
    path: `products/show/:productId/integrations/edit/:serviceName/setting/:appId/chart`,
    layout: false,
  },
  {
    element: (
      <Authenticated>
        <Metabase />
      </Authenticated>
    ),
    path: `metabase`,
    layout: true,
  },
  {
    element: (
      <Authenticated>
        <Appsflyer />
      </Authenticated>
    ),
    path: `appsflyer`,
    layout: true,
  },
];
