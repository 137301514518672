import React, { createContext, ReactNode, useContext } from "react";
import { useMeLocalStorage } from "hooks/useUsers/useMeLocalStorage";
import useLocalStorage from "hooks/useLocalStorage";
import { capitalizeFirstLetter } from "utils/string";
import { useList, useOne } from "@pankod/refine-core";
import { IStudio, IUser } from "interfaces";
import config from "config";
import { uniqBy } from "lodash";
import { useAuthUser } from "hooks/useAuth/useAuthUser";

type MeContextProps = {
  creator: IUser | undefined | null;
  studios: IStudio[] | undefined;
  isValidStudio?: boolean;
  getMyRole: (studioId?: string | null) => string;
  data: IUser | undefined | null;
  handleScroll: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  myStudio: IStudio | undefined;
  isLoadingStudios?: boolean;
  can: boolean;
  isLoadingAuth: boolean;
  isMember: boolean;
  isAdmin: boolean;
  shouldSuggestStudios: boolean;
};

const MeContext = createContext<MeContextProps>({} as MeContextProps);

export const useMe = () => {
  return useContext(MeContext);
};

type Props = {
  children: ReactNode;
};

export const MeProvider = ({ children }: Props) => {
  const me = useMeLocalStorage();
  const [studioId] = useLocalStorage<string | null | undefined>("studioId");
  const meId = me?.id;
  const isOps = me?.isOps;

  const data = me;
  const [studios, setStudios] = React.useState<IStudio[] | undefined>();
  const [page, setPage] = React.useState(1);
  const {
    data: dataStudios,
    refetch,
    isFetching,
  } = useList<IStudio>({
    queryOptions: {
      enabled: Boolean(studioId),
      retry: 0,
    },
    resource: "studios",
    metaData: {
      populate: ["icon", "studio_user_roles.users_permissions_user", "studio_user_roles.role.permissions", "products"],
    },
    errorNotification: false,
    successNotification: false,
    config: studioId
      ? {
          filters: !isOps
            ? [
                {
                  field: "studio_user_roles][users_permissions_user][id]",
                  operator: "eq",
                  value: meId,
                },
                {
                  field: "id",
                  operator: "ne",
                  value: studioId,
                },
              ]
            : [
                {
                  field: "id",
                  operator: "ne",
                  value: studioId,
                },
              ],
          pagination: { current: page, pageSize: config.INITIAL_PAGE_SIZE },
          sort: [
            {
              field: "name",
              order: "asc",
            },
          ],
        }
      : {},
  });

  const { data: myStudioData } = useOne<IStudio>({
    resource: "studios",
    id: studioId || "",
    queryOptions: {
      enabled: Boolean(studioId),
      retry: 0,
    },
    metaData: {
      populate: ["icon", "studio_user_roles.role.permissions", "studio_user_roles.users_permissions_user"],
    },
    errorNotification: false,
    successNotification: false,
  });
  const myStudio = myStudioData?.data;
  React.useEffect(() => {
    !isFetching &&
      setStudios((prev) =>
        uniqBy([...(prev ? prev : []), ...(dataStudios?.data ? dataStudios?.data : [])], (item) => item.id),
      );
  }, [dataStudios?.data, isFetching]);

  const handleScroll = React.useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const ele = e.currentTarget;
      const isBottom = ele.scrollHeight - ele.scrollTop === ele.clientHeight;

      if (isBottom && dataStudios?.total && dataStudios?.total > page * config.INITIAL_PAGE_SIZE) {
        setPage((prev) => prev + 1);
        refetch();
      }
    },
    [dataStudios?.total, page, refetch],
  );
  const isValidStudio =
    studios?.some((item) => item?.id?.toString() === studioId?.toString()) ||
    myStudio?.id.toString() === studioId?.toString();
  const myRole = myStudio?.studio_user_roles?.find(
    (item) => item.users_permissions_user?.id.toString() === meId.toString(),
  )?.role?.name;
  const getMyRole = React.useCallback(
    (studioId?: string | null) => {
      if (isOps) return config.SUPER_ADMIN;
      return capitalizeFirstLetter(
        studios
          ?.find((item) => item?.id === studioId)
          ?.studio_user_roles?.find((item) => item.users_permissions_user?.id.toString() === meId.toString())?.role
          ?.name ||
          myRole ||
          config.SUPER_ADMIN,
      );
    },
    [isOps, meId, myRole, studios],
  );
  const { can, isLoadingAuth, isMember, isAdmin, shouldSuggestStudios } = useAuthUser(me);
  const value = {
    data,
    creator: data,
    studios,
    isValidStudio,
    getMyRole,
    handleScroll,
    myStudio,
    isLoadingStudios: isFetching,
    can,
    isLoadingAuth,
    isMember,
    isAdmin,
    shouldSuggestStudios,
  };

  return (
    <>
      <MeContext.Provider value={value}>{children}</MeContext.Provider>
    </>
  );
};
