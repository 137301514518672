import React, { useEffect, useState } from "react";

import { ITreeMenu, useNavigation, useMenu } from "@pankod/refine-core";
import { AntdLayout, Menu, Grid, Icons } from "@pankod/refine-antd";
import { antLayoutSider, antLayoutSiderMobile } from "./styles";
import logo from "assets/img/logo-white.png";
import { css } from "@emotion/react";
import { LeftOutlined } from "@ant-design/icons";
import { IWebSetting } from "interfaces/chart";
import useLocalStorage from "hooks/useLocalStorage";
const { UnorderedListOutlined } = Icons;

export const Sider: React.FC = () => {
  const [webSetting, setWebSetting] = useLocalStorage<IWebSetting | undefined>("webSetting");
  const [isCollapsed, setIsCollapsed] = useState<boolean | undefined>(webSetting?.isSiderToggle);
  const isSiderToggle = webSetting?.isSiderToggle;
  const { menuItems, selectedKey } = useMenu();
  const { push } = useNavigation();
  const breakpoint = Grid.useBreakpoint();

  const isMobile = !breakpoint.lg;

  const renderTreeView = (tree: ITreeMenu[], selectedKey: string): any => {
    return tree.map((item: ITreeMenu) => {
      const { icon, label, name, children, parentName } = item;
      const isSelected = selectedKey.includes(name || "");
      const isRoute = !(parentName !== undefined && children.length === 0);

      return {
        className: `block my-0 px-[10px]  ${isSelected ? "menu-item-selected drop-shadow-md font-bold" : ""} `,
        key: name,
        label,
        icon: icon ?? (isRoute && <UnorderedListOutlined />),
        children: children.length > 0 && renderTreeView(children, selectedKey),
      };
    });
  };
  const CollapButton: React.FC = () => {
    return (
      <div
        className="group p-0 z-20 cursor-pointer absolute right-[-19px] top-[44px] h-[40px] w-[40px] bg-transparent flex justify-center items-center"
        onClick={() => {
          setWebSetting({
            ...webSetting,
            isSiderToggle: !webSetting?.isSiderToggle,
          });
          setIsCollapsed(!isCollapsed);
        }}
        title={isSiderToggle ? "Expand" : "Collapse"}
      >
        <div className="transition-all ease-in-out delay-150 duration-300 p-0 h-[24px] w-[24px] rounded-full bg-white border border-purple-sider-toggle flex justify-center opacity-0 items-center group-hover:bg-[#E6F7FF] group-hover:opacity-100 ">
          <LeftOutlined className={`text-[8px]  text-purple-sider-toggle ${isSiderToggle && "rotate-180"}`} />
        </div>
      </div>
    );
  };
  const layoutContent = document.getElementById("contentLayout");

  useEffect(() => {
    if (isSiderToggle) {
      layoutContent?.classList.remove("ml-[200px]");
      layoutContent?.classList.add("ml-[60px]");
    } else {
      layoutContent?.classList.remove("ml-[60px]");
      layoutContent?.classList.add("ml-[200px]");
    }
  }, [isSiderToggle, layoutContent]);

  return (
    <AntdLayout.Sider
      collapsed={isCollapsed && isSiderToggle}
      collapsedWidth={60}
      breakpoint="lg"
      style={isMobile ? antLayoutSiderMobile : antLayoutSider}
      className="shadow-xl ant-sidebar group"
    >
      {isCollapsed && <CollapButton />}

      <div
        className="h-full"
        onMouseEnter={() => {
          if (!isSiderToggle) return;
          setIsCollapsed(false);
        }}
        onMouseLeave={() => {
          if (!isSiderToggle) return;
          setIsCollapsed(true);
        }}
      >
        {!isCollapsed && <CollapButton />}
        <a href="/" className="logoSidebar flex items-center px-2 mt-4 relative">
          <img src={logo} alt="" className="w-12 h-full mr-2 opacity-[0.9]" />
          {(!isCollapsed || !isSiderToggle) && <h1 className="text-white text-[30px] font-bold mb-0">PMP</h1>}
        </a>
        <Menu
          className="mt-4"
          selectedKeys={[selectedKey]}
          mode="inline"
          onClick={({ key }) => {
            push(key as string);
          }}
          items={renderTreeView(menuItems, selectedKey)}
          css={css`
            .ant-menu-item {
              padding: 0 0 0 15px !important;
              margin-bottom: 0 !important;
            }
          `}
        />
      </div>
    </AntdLayout.Sider>
  );
};
