import { Form, FormInstance, Select } from "@pankod/refine-antd";
import { useList } from "@pankod/refine-core";
import { IStore } from "interfaces";
import React from "react";
import { TDataSelected } from "../Metabase";

interface TPropsSelectApps {
  form: FormInstance<{}>;
}
export default function SelectApps({ form }: TPropsSelectApps) {
  const dashboardInfo = Form.useWatch("dashboardInfo");
  const productInfo = Form.useWatch("productInfo");
  const appInfo = Form.useWatch("appInfo");
  const optionsProduct: TDataSelected[] = Form.useWatch("optionsProduct"); // TODO: will delete this line when fixed on BE
  const optionsApp: TDataSelected[] = Form.useWatch("optionsApp");

  const { isFetching: isLoadingListApps } = useList<IStore>({
    resource: "apps",
    config: {
      ...(productInfo && {
        filters: [
          {
            field: "product][id]",
            operator: "in",
            value: productInfo?.map((v: TDataSelected) => v.value),
          },
        ],
      }),
    },

    queryOptions: {
      onSuccess(data) {
        // TODO: will delete this line when fixed on BE
        const hasProduct =
          optionsProduct?.length > 0
            ? optionsProduct.some((product) => productInfo.some((info: any) => product.value === info.value))
            : false;

        form.setFieldsValue({
          optionsApp: hasProduct
            ? productInfo?.length > 0
              ? data.data.map((app) => ({
                  label: app.product_code,
                  value: app.id,
                }))
              : []
            : [],
        });

        // uncomment this line when fixed on BE
        // form.setFieldsValue({
        //   optionsApp:
        //     productInfo?.length > 0
        //       ? data.data.map((app) => ({
        //           label: app.product_code,
        //           value: app.id,
        //         }))
        //       : [],
        // });
      },
    },
  });
  const isDisable = productInfo?.length === 0 || !dashboardInfo;

  return (
    <Select
      allowClear
      value={optionsApp?.length > 0 ? appInfo : []} // TODO: will delete this line when fixed on BE
      // value={appInfo}  // uncomment this line when fixed on BE
      mode="multiple"
      className="w-[100%]"
      optionLabelProp="text"
      dropdownClassName="custom-select"
      disabled={isDisable}
      loading={isLoadingListApps}
      placeholder="App id"
      maxTagCount={"responsive"}
      onChange={(_, options: TDataSelected | TDataSelected[]) => {
        form.setFieldsValue({
          appInfo: options,
          iframeUrl: "",
        });
      }}
      options={optionsApp}
    />
  );
}
