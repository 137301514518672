import { IResourceComponentsProps } from "@pankod/refine-core";
import { Edit, Form, useForm } from "@pankod/refine-antd";
import React from "react";

import { LayoutCardTransparent } from "components/layout/layout/LayoutCardTransparent";
import { GeneralInfoStep } from "./StepsForm/GeneralInfoStep";
import { IProduct, IStudio } from "interfaces";
import { ProductStepFormProvider } from "context/ProductStepFormContext";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { LayoutCreateProduct } from "components/layout/layout/LayoutCreateProduct";

export const EditProduct: React.FC<IResourceComponentsProps> = () => {
  const [formLoading, setFormLoading] = React.useState(false);
  const { formProps, form, queryResult, saveButtonProps } = useForm<IProduct>({
    metaData: {
      populate: ["product_user_roles.users_permissions_user", "product_user_roles.", "studios", "icon"],
    },
    redirect: "show",
    successNotification: {
      type: "success",
      description: "Success",
      message: "Product information is successfully updated",
    },
    errorNotification: {
      type: "error",
      description: "Error",
      message: "Fail to update product information",
    },
  });
  const formItemLayout = {
    labelCol: { span: 6 },
  };
  const product = queryResult?.data?.data;

  const studiosOptions = React.useMemo(() => {
    return product?.studios?.map((studio: IStudio) => {
      return {
        value: studio?.id.toString() || "",
        label: studio?.name,
      };
    });
  }, [product?.studios]);
  const studios = studiosOptions?.map((item) => item.value);
  const [values, setValues] = React.useState<any>();

  return (
    <LayoutCardTransparent>
      <Edit
        saveButtonProps={{
          ...saveButtonProps,
          disabled: product?.disabled || formLoading,
        }}
        isLoading={queryResult?.isFetching}
        headerButtons={<></>}
      >
        <ProductStepFormProvider
          product={product}
          form={form}
          formLoading={formLoading}
          setFormLoading={setFormLoading}
          setValues={setValues}
          values={values}
        >
          <Form
            {...formProps}
            {...formItemLayout}
            className="mt-[30px]"
            form={form}
            initialValues={{
              ...formProps.initialValues,
              studios,
            }}
            onFinish={(e) => {
              if (product?.disabled) return;
              const input = mediaUploadMapper(e);
              const newInput = {
                ...input,
                icon: input?.icon[0],
                ...values,
              };
              return formProps.onFinish?.(newInput);
            }}
          >
            <LayoutCreateProduct>
              <GeneralInfoStep isEdit editStudioOptions={studiosOptions} />
            </LayoutCreateProduct>
          </Form>
        </ProductStepFormProvider>
      </Edit>
    </LayoutCardTransparent>
  );
};
