export const capitalizeFirstLetter = (val: string) => {
  const _val = val.toLowerCase();
  return _val.charAt(0).toUpperCase() + _val.slice(1);
};
export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const renameKeys = (obj: { [x: string]: any }) => {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = camelToSnakeCase(key) || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
};

export const convertCredentialsToSnakeCase = (credentials?: string) => {
  try {
    const _credentials = credentials ? JSON.parse(credentials) : "";
    const newCredentials = credentials ? renameKeys(_credentials) : "";
    return newCredentials ? JSON.stringify(newCredentials) : "";
  } catch {
    return "";
  }
};

export const getFirstLetter = (name: string): string => {
  return name[0].toUpperCase();
};

// input: 20301093
// output: 20.301.093

export function numberWithCommas(x: number): string {
  const separator = ".";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}
