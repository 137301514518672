import React from "react";
import { Result, Button } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { useNavigation } from "@pankod/refine-core";
import { useAuth } from "hooks/useAuth";

const NotInAStudio: React.FC = () => {
  const { push } = useNavigation();
  const { logout } = useAuth();
  return (
    <div className="flex justify-center w-screen h-screen">
      <Result
        className="place-self-center "
        icon={<SmileOutlined />}
        title="You are not in a studio!"
        subTitle="Please, contact your admin"
        extra={
          <>
            <Button
              onClick={() => push("/")}
              className="inline-flex items-center bg-primary text-white hover:border hover:border-black hover:text-black"
            >
              Back Home
            </Button>
            <Button
              onClick={logout}
              className="inline-flex items-center bg-primary text-white hover:border hover:border-black hover:text-black"
            >
              Logout
            </Button>
          </>
        }
      />
    </div>
  );
};

export default NotInAStudio;
