import React from "react";
import { css } from "@emotion/react";
import { useParams, useNavigate } from "react-router-dom";
import { Show, Tabs } from "@pankod/refine-antd";
import { AntDesignOutlined } from "@ant-design/icons";
import { useOne } from "@pankod/refine-core";

// components
import { LayoutCardTransparent } from "components/layout/layout/LayoutCardTransparent";
import Page403 from "components/Result/403";
import UserInfo from "./components/UserInfo";
import ProductInfo from "./components/ProductInfo";
import GeneralInfo from "./components/GeneralInfo";

// interface
import { IStudio } from "interfaces";

const { TabPane } = Tabs;
export const StudioShow: React.FC = () => {
  // state
  const [activeKey, setActiveKey] = React.useState("general");
  // hooks
  const params = useParams();
  const navigate = useNavigate();

  const {
    data,
    isLoading,
    refetch: refetchStudio,
    error,
  } = useOne<IStudio>({
    resource: "studios",
    id: params.id || "",
    metaData: {
      populate: [
        "icon",
        "products.icon",
        "studio_user_roles.users_permissions_user",
        "studio_user_roles.role.permissions",
        "products.apps",
      ],
    },
    queryOptions: {
      retry: 0,
    },
    errorNotification: false,
  });
  React.useEffect(() => {
    const hash = window.location.hash.replace("#", "") || "general";
    setActiveKey(hash);
  }, []);

  const studio = data?.data;
  const err = error as any;

  if (err?.statusCode === 403) {
    return <Page403 message={err?.message} />;
  }

  return (
    <LayoutCardTransparent>
      <Show
        title={studio?.name || ""}
        isLoading={isLoading}
        pageHeaderProps={{
          extra: <></>,
        }}
        actionButtons={false}
        canEdit={false}
      >
        <Tabs
          animated
          activeKey={activeKey}
          onTabClick={(key) => {
            navigate(`/studios/show/${studio?.id}#${key}`, {
              replace: true,
            });
            setActiveKey(key);
          }}
          css={css`
            .ant-tabs-tab-active .ant-tabs-tab-btn {
              color: #1890ff;
            }
          `}
        >
          <TabPane
            tab={
              <>
                <AntDesignOutlined />
                <span className="text-[14px]">General info</span>
              </>
            }
            key="general"
            animated
          >
            <GeneralInfo studio={studio} isLoading={isLoading} />
          </TabPane>
          <TabPane
            tab={
              <>
                <AntDesignOutlined />
                <span className="text-[14px]">Product info</span>
              </>
            }
            key="product"
            animated
          >
            <ProductInfo studio={studio} refetchStudio={refetchStudio} />
          </TabPane>
          <TabPane
            tab={
              <>
                <AntDesignOutlined />
                <span className="text-[14px]">User info</span>
              </>
            }
            key="user"
            animated
          >
            <UserInfo refetchStudio={refetchStudio} studio={studio} />
          </TabPane>
        </Tabs>
      </Show>
    </LayoutCardTransparent>
  );
};
