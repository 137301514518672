import dayjs from "dayjs";

// interfaces
import { ITransformData, IConvertData, ISPLineChart } from "interfaces/chart";

// utils
import { sortBy } from "utils/sortBy";

// helpers
import { mappingDataTouchPoints } from "../chartHelper/commonHelper";
import { mappingSeriesReversedSPLineChart, mappingOtherMetricBySPLine } from "../chartHelper/splineHelper";

interface ISource {
  [key: string]: string | any;
}

export const transformSPLineData = ({ data, dimension, metric, type }: ITransformData): IConvertData => {
  const splineData: ISource[] = data ? data.data : [];
  const groupBy = data["grouped-by"];

  if (splineData.length === 0 || !groupBy) {
    return {
      categories: [],
      series: [],
    };
  }

  let categories = Object.keys(groupBy).sort((a: string, b: string) => sortBy(a, b));
  let series: ISource[] = [];

  if (metric === "touchpoints") {
    // metric is touchpoints
    const temp = mappingDataTouchPoints(
      [...splineData].sort((a, b) => sortBy(a.date, b.date)).slice(1),
      categories,
      type,
    );
    series = temp.series;
    categories = temp.categories;
  } else {
    // other metric
    const temp: ISPLineChart = mappingOtherMetricBySPLine(splineData, categories, dimension, metric);
    series = mappingSeriesReversedSPLineChart(temp.series, dimension);
    categories = temp.categories;
  }

  const days = categories.reduce((acc, currItem) => {
    const key = dayjs(currItem).format("MMM D");
    acc = {
      ...acc,
      [key]: currItem,
    };
    return acc;
  }, {});

  return {
    series: series.map((item: any) => ({
      ...item,
      isEmpty: item.data.every((ele: number | null) => ele === null || ele === 0),
    })),
    categories: [...categories].map((ele) => dayjs(ele).format("MMM D")),
    days,
  };
};

export default transformSPLineData;
