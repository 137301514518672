import React from "react";
import { Button, Icons } from "@pankod/refine-antd";
import { css } from "@emotion/react";
const { UploadOutlined, LoadingOutlined } = Icons;
export const UploadButton: React.FC<{
  title?: string;
  onClick?: () => void;
  loading?: boolean;
  disable?: boolean;
}> = ({ title = "Upload S3", onClick, loading, disable }) => {
  return (
    <Button
      className="flex items-center"
      css={css`
        svg {
          margin-right: 0px;
        }
        .anticon {
          margin-right: 5px;
        }
      `}
      type={loading || disable ? "default" : "primary"}
      disabled={loading || disable}
      onClick={onClick}
      icon={loading ? <LoadingOutlined /> : <UploadOutlined />}
    >
      {title}
    </Button>
  );
};
