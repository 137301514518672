import { Form, Input } from "@pankod/refine-antd";

// interface
import { IPropsWithChildren } from "interfaces";

export const FormLayout: React.FC<IPropsWithChildren> = ({ children }) => {
  return (
    <div className="">
      <Form.Item
        label="Studio name"
        name="name"
        rules={[
          {
            required: true,
            max: 50,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Website"
        name="website"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
        rules={[
          {
            max: 500,
          },
        ]}
      >
        <Input.TextArea rows={2} />
      </Form.Item>
      <div className="-mx-2">{children}</div>
    </div>
  );
};
