import { Modal, Table, Tag } from "@pankod/refine-antd";
import { useApiUrl, useCustomMutation, useNavigation } from "@pankod/refine-core";
import React from "react";
import { PLATFORM } from "config";
import { useAppKey } from "hooks/useIntegration/useAppKey";
import { useParams } from "react-router-dom";
import { Enum_App_Status, Enum_Integration_Service } from "schema/schema";
import { checkExitsIntegration } from "utils/getIntegrationConnect";
import useTableProps from "hooks/useTableProps";
import { useProduct } from "context/ProductContext";
import { useApp } from "hooks/useApp";
import { EditIntegration } from "../EditIntegration";
import { EnumDaremiStatus, EnumRoleProduct } from "enums";
import { IStore } from "interfaces";
import { useMe } from "context/MeContext";
import { usePermission } from "hooks/usePermission";
import { useGetMyRole } from "hooks/useGetMyRole";
const { confirm, destroyAll } = Modal;

interface DataType {
  app_id: string;
  icon: string;
  app_name: string;
  bundle_id: string;
  market: string;
  platform: string;
  daremi_status: string;
  status: Enum_App_Status;
  product_code: string;
  hasLink: boolean;
}

export const Daremi: React.FC = () => {
  const { tableQueryResult, filters, setFilters } = useAppKey();
  const { push } = useNavigation();
  const { productInfo, isStale } = useProduct();
  const params = useParams();
  const apiUrl = useApiUrl();
  const { getPayload, refetchPayload, productResult } = useApp();
  const productId = params.productId || params.id;
  const { mutateAsync: disconnectAppDaremi, isLoading: isDisconnectAppLoading } = useCustomMutation();
  const apps = tableQueryResult.data?.data;
  const payload = getPayload(Enum_Integration_Service.Daremi);
  const checkIsConnected = (record: IStore) => {
    const connectedApp = payload.apps[record.product_code];
    if (!connectedApp) return false;
    return connectedApp.pm_enabled;
  };
  const { combineRoleWithSuperAdmin } = usePermission();
  const { userRoleProductPermission } = useGetMyRole();

  const { isMember } = useMe();

  const isDisabledAction =
    productInfo?.isDisabled ||
    (!combineRoleWithSuperAdmin(userRoleProductPermission !== EnumRoleProduct.Viewer) && isMember);

  const getDaremiStatus = (value: IStore) => {
    const isConnected = checkIsConnected(value);
    if (!payload?.apps[value.product_code]) return EnumDaremiStatus.Unregistered;
    if (!isConnected) return EnumDaremiStatus.Registered;
    return EnumDaremiStatus.Connected;
  };

  let dataTable: DataType[] | undefined = apps?.map((value: IStore) => {
    return {
      ...value,
      app_id: value.id,
      app_name: value.name,
      platform: value.platform === "IOS" ? PLATFORM.iOS : PLATFORM.Android,
      daremi_status: getDaremiStatus(value),
      hasLink: true,
    };
  });
  const { columnsPropsAppName, columnsPropsAppCode, columnsPropsMarket, columnsPropsAppIcon } = useTableProps({
    filters,
    setFilters,
  });

  //useEffect
  React.useEffect(() => {
    if (!isStale && !checkExitsIntegration(productInfo, Enum_Integration_Service.Daremi) && productInfo.id) {
      push(`/products/show/${productInfo.id}#integration`);
      return;
    }
  }, [productInfo, push, isStale]);

  const warningToActiveApp = () => {
    confirm({
      title: `You have to active application before connect application integration`,
      closable: true,
      centered: true,
      className: "no-footer",
    });
  };

  const handleDisconnect = (record: DataType) => {
    if (record.status !== Enum_App_Status.Active) {
      warningToActiveApp();
      return;
    }
    if (record.daremi_status !== EnumDaremiStatus.Connected) return;
    confirm({
      title: `Are you sure you want to disconnect with Daremi?`,
      okText: "Yes",
      cancelText: "No",
      async onOk() {
        destroyAll();

        const result = await disconnectAppDaremi({
          url: `${apiUrl}/products/${productId}/integrations:daremi/app`,
          method: "patch",
          values: {
            data: {
              app_id: record.app_id,
              pm_enabled: false,
              pm_has_eazegames_rev: false,
              pm_subscription_enabled: false,
            },
          },
          successNotification: {
            type: "success",
            message: `You have successfully disconnect Daremi app`,
          },
          errorNotification: {
            type: "error",
            message: `Fail to disconnect Daremi app`,
          },
        });
        if (result) {
          refetchPayload();
          tableQueryResult.refetch();
        }
      },
    });
  };

  const handleEditOrRegister = (record: DataType) => () => {
    if (record.status !== Enum_App_Status.Active) {
      warningToActiveApp();
      return;
    }
    if (record.daremi_status === EnumDaremiStatus.Unregistered) push(`register/${record.app_id}`);
    push(`/products/show/${productId}/integrations/edit/${Enum_Integration_Service.Daremi}/setting/${record.app_id}`);
  };

  let columns: any[] = React.useMemo(() => {
    return [
      {
        ...columnsPropsAppIcon,
      },
      {
        title: "App name",
        key: "app_name",
        ...columnsPropsAppName,
      },
      {
        ...columnsPropsAppCode,
      },
      {
        title: "Market",
        dataIndex: "market",
        key: "market",
        ...columnsPropsMarket,
      },
      {
        title: "Platform",
        dataIndex: "platform",
        key: "platform",
        filters: [...new Set(dataTable?.map((item) => item.platform))]?.map((item) => {
          return {
            value: item,
            text: item,
          };
        }),
        onFilter: (value: string, record: DataType) => {
          return record.platform.includes(value.toString());
        },
        render: (value: string) => <span>{value}</span>,
      },
      {
        title: "Daremi status",
        dataIndex: "daremi_status",
        key: "daremi_status",
        filters: [
          { value: EnumDaremiStatus.Registered, text: EnumDaremiStatus.Registered },
          { value: EnumDaremiStatus.Unregistered, text: EnumDaremiStatus.Unregistered },
          { value: EnumDaremiStatus.Connected, text: EnumDaremiStatus.Connected },
        ],
        onFilter: (value: string, record: DataType) => {
          return record.daremi_status.includes(value.toString());
        },
        render: (value: string) => {
          let color: string;
          switch (value) {
            case EnumDaremiStatus.Registered:
              color = "orange";
              break;
            case EnumDaremiStatus.Unregistered:
              color = "red";
              break;
            case EnumDaremiStatus.Connected:
              color = "green";
              break;
            default:
              color = "";
          }

          return <>{<Tag color={color}>{value}</Tag>}</>;
        },
      },

      {
        title: "Action",
        render: (_value: string, record: DataType) => {
          const cursorType =
            record.status === Enum_App_Status.Active
              ? `${isDisabledAction ? "text-gray-400" : "text-[#1890FF] cursor-pointer"} `
              : `text-gray-400 ${!isDisabledAction && "cursor-pointer"}`;
          return (
            <>
              {record.daremi_status === EnumDaremiStatus.Connected && (
                <span
                  onClick={isDisabledAction ? () => {} : () => handleDisconnect(record)}
                  className={cursorType + " mr-10"}
                >
                  Disconnect
                </span>
              )}
              <span onClick={isDisabledAction ? () => {} : handleEditOrRegister(record)} className={cursorType}>
                {record.daremi_status !== EnumDaremiStatus.Unregistered ? "Edit" : "Register"}
              </span>
            </>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTable, payload]);
  return (
    <EditIntegration
      title="Daremi Integration"
      hiddenDelete={true}
      actionButtons={<></>}
      isLoading={
        tableQueryResult.isLoading ||
        tableQueryResult.isFetching ||
        isDisconnectAppLoading ||
        productResult.fetching ||
        productResult.stale
      }
    >
      <Table
        rowKey="app_id"
        columns={columns}
        dataSource={dataTable}
        pagination={{
          pageSizeOptions: [10, 20, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
      />
    </EditIntegration>
  );
};
