import React, { useEffect, useState } from "react";
import { AntdLayout, Typography, Dropdown, AutoComplete, Input, Button } from "@pankod/refine-antd";

import { useCan, useList, useNavigation } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { IOptions, IProduct, IStudio } from "interfaces";
import { useDebounce } from "hooks/useDebounce";
import { useMeLocalStorage } from "hooks/useUsers/useMeLocalStorage";
import { css } from "@emotion/react";
import { PoweroffOutlined, SearchOutlined } from "@ant-design/icons";
import { useMe } from "context/MeContext";
import useLocalStorage from "hooks/useLocalStorage";
import { useAuth } from "hooks/useAuth";
import { capitalizeFirstLetter } from "utils/string";
import { ImageElement } from "components/Image";
import config from "config";
import { useLocation } from "react-router-dom";
import { BannerProfileIcon } from "components/Icons";
const { Link } = routerProvider;
const { Text } = Typography;

// To be able to customize the option title
const renderTitle = (title: string) => {
  return (
    <Text strong style={{ fontSize: "16px" }}>
      {title}
    </Text>
  );
};

// To be able to customize the option item
const renderItem = (title: string, resource: string, id: number | string) => {
  return {
    value: `${title}`,
    label: (
      <Link key={id} to={`/${resource}/show/${id}`} className="w-full block">
        <Text>{title}</Text>
      </Link>
    ),
    key: `${title}-${id}-${resource}`,
  };
};

export const Header: React.FC = () => {
  const { data: me, myStudio, studios, handleScroll, getMyRole } = useMe();
  const location = useLocation();
  const { push } = useNavigation();
  const { logout } = useAuth();
  const { data: dataCanProduct } = useCan({
    resource: "products",
    action: "list",
  });
  const { data: dataCanStudio } = useCan({
    resource: "studios",
    action: "list",
  });
  const user = useMeLocalStorage();
  const enableQuickSearch = location.pathname.includes("products") || location.pathname.includes("studios");
  const [selectedStudioId, setValueLocalStorage] = useLocalStorage<string | null | undefined>("studioId");

  const onSetStudio = (id?: string | null) => {
    setValueLocalStorage(id);
    window.location.reload();
  };

  const profile = (
    <div className="relative w-[234px] bg-purple-profile border-[1px] border-purple-profile-boder rounded-md overflow-hidden">
      <div className="relative w-full bg-border-purple-profile flex flex-col items-center">
        <div className="bg-white rounded-full mt-3">
          {user?.icon && (
            <img
              className="w-[90px] h-[90px] p-1 border-purple-profile  rounded-full"
              src={user?.icon}
              alt={user?.name}
            />
          )}
        </div>

        <div className="relative w-full flex items-center">
          <BannerProfileIcon
            className="absolute top-[-10px] left-[50%] translate-x-[-50%] z-0"
            css={css`
              filter: drop-shadow(3px 1px 4px rgba(0, 0, 0, 0.1));
            `}
          />
          <div className="absolute top-[-5px] left-[50%] translate-x-[-50%] z-1 w-full text-center">
            <Typography.Paragraph
              ellipsis={{
                rows: 1,
              }}
              className="font-bold text-purple-text-profile text-[14px] mt-[10px] leading-[21px] mb-0 w-full px-[20px]"
              title={user?.email}
            >
              {user?.email}
            </Typography.Paragraph>
            <Typography.Paragraph
              ellipsis={{
                rows: 1,
              }}
              className=" text-[12px] leading-[19px] mb-0 px-[40px] w-full "
              title={""}
            >
              {myStudio?.name} -{" "}
              {me?.isOps
                ? config.SUPER_ADMIN
                : capitalizeFirstLetter(
                    myStudio?.studio_user_roles?.find(
                      (item) => item.users_permissions_user?.id.toString() === me?.id?.toString(),
                    )?.role?.name || "",
                  )}
            </Typography.Paragraph>
          </div>
        </div>

        <Button
          icon={<PoweroffOutlined className="text-[red] rotate-90 translate-y-[2px] translate-x-[-3px] font-bold" />}
          className="mb-6 mt-[4rem] px-[15px] font-normal text-[12px] shadow-none border-none"
          onClick={logout}
        >
          Sign out
        </Button>
      </div>

      <div
        className="relative w-full bg-[white]  border-t-[1px] border-[#B37FEB] pt-0 pb-1 max-h-[250px] overflow-y-auto"
        onScroll={handleScroll}
      >
        <Text className="text-gray-500 text-[12px] pt-3 px-4 top-0 bg-white pb-2 block sticky"> Other profile</Text>
        {studios?.map((studio) => {
          return (
            <div
              key={studio?.id}
              className="py-2 flex space-x-2 items-center cursor-pointer hover:bg-gray-100 px-4"
              onClick={() => onSetStudio(studio?.id)}
            >
              {
                <ImageElement
                  className="w-[24px] h-[24px] rounded-full object-contain"
                  src={studio?.icon?.url}
                  alt={studio?.icon?.alt}
                />
              }
              <Text ellipsis className="ml-2 text-[12px]">
                {studio?.name} - {getMyRole(studio?.id)}
              </Text>
            </div>
          );
        })}
      </div>
    </div>
  );

  const [value, setValue] = useState<string>("");
  const [options, setOptions] = useState<IOptions[]>([]);
  const debouncedValue = useDebounce(value, 500);
  const { refetch: refetchProducts } = useList<IProduct>({
    resource: "products",
    config: {
      filters: [
        {
          operator: "or",
          value: [
            {
              field: "name",
              operator: "containss",
              value: value,
            },
            {
              field: "product_code",
              operator: "containss",
              value: value,
            },
            {
              field: "apps][bundle_id]",
              operator: "containss",
              value: value,
            },
          ],
        },
        {
          field: "studios][id]",
          operator: "eq",
          value: selectedStudioId,
        },
      ],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const productOptionGroup = data.data.map((item) => renderItem(item.name, "products", item.id));
        if (productOptionGroup.length > 0 && value !== "") {
          setOptions((prevOptions) => [
            {
              label: renderTitle("Products"),
              options: productOptionGroup,
            },
            ...prevOptions,
          ]);
        }
      },
    },
  });

  const { refetch: refetchStudios } = useList<IStudio>({
    resource: "studios",
    config: {
      filters: [{ field: "name", operator: "containss", value }],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const studioOptionGroup = data.data.map((item) => renderItem(item.name, "studios", item.id));
        if (studioOptionGroup.length > 0 && value !== "") {
          setOptions((prevOptions) => [
            ...prevOptions,
            {
              label: renderTitle("Studios"),
              options: studioOptionGroup,
            },
          ]);
        }
      },
    },
  });
  useEffect(() => {
    setOptions([]);
    if (dataCanProduct?.can && debouncedValue) {
      refetchProducts();
    }
  }, [dataCanProduct?.can, refetchProducts, debouncedValue]);

  useEffect(() => {
    setOptions([]);
    if (dataCanStudio?.can && debouncedValue) {
      refetchStudios();
    }
  }, [debouncedValue, dataCanStudio?.can, refetchStudios]);
  useEffect(() => {
    if (!me?.isOps) {
      if (me?.studio_user_roles?.length === 0) {
        push("not-in-a-studio");
      } else {
        if (!me?.studio_user_roles?.some((studio) => studio?.status === "ACCEPTED") && me) {
          push("not-accept-invitation");
        }
      }
    }
  }, [me, push]);
  return (
    <AntdLayout.Header
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0px 24px",
        height: "64px",
      }}
      className="border-b"
    >
      <div className="flex justify-between items-center w-full relative">
        {enableQuickSearch && (
          <AutoComplete
            style={{ width: "100%", maxWidth: "250px" }}
            filterOption={false}
            options={options}
            onSearch={(value: string) => setValue(value)}
            onSelect={(_val: string, option: any) => {
              push(option.label.props.to);
            }}
            css={css`
              .ant-input-search-button {
                border-radius: 2px !important;
                background-color: white;
                box-shadow: none;
              }
              .ant-input {
                border-radius: 2px !important;
              }
              .ant-input:hover {
                border-color: black !important;
              }
            `}
          >
            <Input suffix={<SearchOutlined />} placeholder="Quick search" />
          </AutoComplete>
        )}

        {user && (
          <Dropdown className="ml-auto" overlay={profile} placement="bottomRight">
            <div className="flex flex-row items-center cursor-pointer ">
              {user?.icon && (
                <div className="relative w-[34px] h-[34px] border-[2px] border-[white] rounded-full">
                  {myStudio?.icon && (
                    <ImageElement
                      className="absolute bg-white p-[1.5px] rounded-full w-[20px] h-[20px] top-[-12px] right-[-12px] object-contain "
                      src={myStudio?.icon.url}
                      alt={myStudio?.icon.alt}
                    />
                  )}
                  {user?.icon && <ImageElement className="rounded-full" src={user?.icon} alt={user?.name} />}
                </div>
              )}
              {user.name && (
                <Text ellipsis strong className="ml-2">
                  {user.name}
                </Text>
              )}
            </div>
          </Dropdown>
        )}
      </div>
    </AntdLayout.Header>
  );
};
