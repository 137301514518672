import React from "react";

export default function useRangePicker() {
  const defaultDateTime = [
    new Date(new Date().setDate(new Date().getDate() - 7)),
    new Date(new Date().setDate(new Date().getDate() - 1)),
  ];
  const [datePicker, setDatePicker] = React.useState<any[]>(defaultDateTime);
  const [startDate, endDate] = datePicker;

  // refs
  const datePickerRef = React.useRef({
    endDate,
  });

  return {
    startDate,
    endDate,
    datePicker,
    setDatePicker,
    datePickerRef,
    defaultDateTime,
  };
}
