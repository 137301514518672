import { useNotification } from "@pankod/refine-core";
import axios from "axios";
import config from "config";
import { useMe } from "context/MeContext";
import React from "react";
import type { UploadProps as UploadAntdProps } from "antd/es/upload";
export interface UploadProps {
  fileList: any[];
  bucketName: string;
  prefix?: string | null;
  url?: string;
  metadata?: string;
}

const useUpload = () => {
  const { data: me } = useMe();
  const token = me?.auth0_access_token;
  const { open } = useNotification();
  const [loading, setLoading] = React.useState(false);
  const [called, setCalled] = React.useState(false);
  const [data, setData] = React.useState<any>();
  const [totalFiles, setTotalFiles] = React.useState(0);
  const handleUpload = async (data: UploadProps) => {
    const { fileList, prefix, bucketName, url, metadata } = data;
    try {
      const isSingle = fileList.length === 1;
      const formData = new FormData();
      fileList.forEach((file) => {
        const { originFileObj } = file;
        formData.append(
          `${isSingle ? "file" : "files"}`,
          originFileObj as Blob,
          window.btoa(originFileObj.webkitRelativePath || file.name),
        );
      });
      const prefixWithoutLastSplash = prefix?.slice(0, -1);
      formData.append("prefix", prefixWithoutLastSplash || "");
      formData.append("bucketName", bucketName);
      metadata && formData.append("metadata", metadata);
      setData(null);
      setLoading(true);
      setCalled(true);
      const res = await axios({
        method: "post",
        url,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      setData(res.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      open?.({
        type: "error",
        message: config.UnAuthorizedActionErrorMessage,
      });
    }
  };
  const handleChange: UploadAntdProps["onChange"] = ({ fileList: newFileList }) => {
    setTotalFiles(newFileList.length);
    const a = newFileList.map((file: any) => {
      return file.name === file.originFileObj.webkitRelativePath ? file.originFileObj.webkitRelativePath : file.name;
    });
    return a;
  };
  const props = {
    beforeUpload: (file: any) => {
      const isLt200M = file.size / 1024 / 1024 <= 200;
      if (!isLt200M) {
        file.status = "error";
        file.response = "File size must not exceed 200MB";
      }
      return false;
    },
    onChange: handleChange,
  };

  return {
    handleUpload,
    loading,
    props,
    called,
    data,
    totalFiles,
    setTotalFiles,
  };
};

export default useUpload;
