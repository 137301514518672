// interface
import { ITempChart, ISource, ITempSeries } from "interfaces/chart";

// themes
import { palette, systemColors } from "themes";

// utils
import { numberWithSeparator } from "utils/numberWithSeparator";

// configs
import { METRICS_TO_FORMAT_CONFIG, EVENT_KPIS, METRIC, CURRENT_SOURCE, DIMENSIONS } from "../chartConfig";

function generateMetricFormatted(xAxis: { index: number; value: number }[], metric: string): any[] {
  let xAxisFiltered = xAxis.map((xaxis) => numberWithSeparator(xaxis.value, ","));

  if (metric === EVENT_KPIS.EventConversion.value || metric === EVENT_KPIS.EventRatio.value) {
    xAxisFiltered = xAxis.map((xaxis) =>
      Intl.NumberFormat("en-US", METRICS_TO_FORMAT_CONFIG[metric].format).format(xaxis.value),
    );
  }
  if (metric === EVENT_KPIS.EventECPA.value) {
    xAxisFiltered = xAxis.map(
      (xaxis) => "$" + Intl.NumberFormat("en-US", METRICS_TO_FORMAT_CONFIG[metric].format).format(xaxis.value),
    );
  }

  return xAxisFiltered;
}

export function reOrederDescBarChart(temp: ITempChart, metric: string) {
  const seriesParsed = temp.series.map((item) => ({
    ...item,
    data: item.data.length > 0 ? item.data : new Array(temp.categories.length).fill(0),
  }));
  const xAxisSorted = seriesParsed
    .reduce((acc, currItem) => {
      return currItem.data.map((item, index) => (acc[index] ?? 0) + (item ?? 0));
    }, new Array(temp.categories.length).fill(0))
    .map((item, index) => ({ index, value: item }))
    .sort((a, b) => b.value - a.value);

  const series = seriesParsed.map((item) => {
    const datas = xAxisSorted.map((el) => item.data[el.index] ?? 0);
    item.data = [...datas].slice(0, 10);
    return item;
  });
  const categories = xAxisSorted.map((item) => temp.categories[item.index] ?? 0);
  const xAxis = generateMetricFormatted(xAxisSorted, metric);

  return { series, categories, xAxis };
}

function getItemColorBarChart(currItem: any) {
  let color = palette[0];
  if (currItem === "organic" || currItem === "non-organic") {
    color = systemColors[currItem];
  }

  return color;
}

function dimensionNotAssitsBarChart(
  source: ISource[],
  sourceUnique: any,
  categories: string[],
  dimension: string,
  metric: string,
) {
  const series = sourceUnique.map((ele: any) => {
    return {
      name: ele === "non-organic" || ele === "organic" ? CURRENT_SOURCE[ele] : ele,
      color: getItemColorBarChart(ele),
      data: categories.map((cate) => {
        const item = source.find((item) => (item?.source || item[dimension]) === ele && item[dimension] === cate);
        return item ? item[metric] ?? 0 : 0;
      }),
      total_score: 1,
    };
  });

  const xaxis = series.reduce((acc: any, currItem: any) => {
    currItem.data.forEach((number: number, index: number) => {
      acc[index] = acc[index] ? acc[index] + number : number;
    });
    return acc;
  }, []);

  return { series, xaxis };
}

function dimensionAssitsBarChart(sourceFiltered: ISource[]) {
  const tempMetricAssits = sourceFiltered.reduce(
    (acc, curr) => {
      const { assists, installs_ua } = curr;
      if (assists) {
        acc.series[0].data.push(assists);
      }
      if (installs_ua) {
        acc.series[1].data.push(installs_ua);
      }
      return acc;
    },
    {
      series: [
        { name: "Assisted installs", color: palette[6], data: [] },
        { name: "Non-organic installs", color: palette[0], data: [] },
      ],
    },
  );

  const xaxis = tempMetricAssits.series.reduce((acc: any, currItem: any) => {
    currItem.data.forEach((number: number, index: number) => {
      acc[index] = acc[index] ? acc[index] + number : number;
    });
    return acc;
  }, []);

  return { series: tempMetricAssits.series, xaxis };
}

export function otherDimensionBarData(source: ISource[], dimension: string, metric: string) {
  const sourceFiltered = source.filter((item) => item[metric] !== undefined && item[metric] !== 0);
  const categories: string[] = [...new Set(sourceFiltered.map((item) => item[dimension]))];

  if (categories.every((item) => item === "undefined" || item === "null")) {
    return { series: [], categories: [], xAxis: [] };
  }

  const sourceUnique = [...new Set(sourceFiltered.map((item) => item?.source || item[dimension]))].filter(
    (item: any) => item[metric] !== 0,
  );
  // convert PascalCase with category
  const categoriesFormatted = categories.map((item) => {
    let formatted = item;
    if (item === "organic" || item === "non-organic") {
      formatted = item.replace(/\w+/g, function (w) {
        return w[0].toUpperCase() + w.slice(1).toLowerCase();
      });
    }
    return formatted;
  });

  let series: ITempSeries[] = [];
  let xaxis: number[] = [];

  if (metric === METRIC.Assists.value) {
    const temp = dimensionAssitsBarChart(sourceFiltered);
    series = temp.series;
    xaxis = temp.xaxis;
  } else {
    // other Assists
    const temp = dimensionNotAssitsBarChart(source, sourceUnique, categories, dimension, metric);
    series = temp.series;
    xaxis = temp.xaxis;
  }

  return { series, categories: categoriesFormatted, xAxis: xaxis };
}

export function mappingSeriesReversedBarChart(series: any[], dimension: string, metric: string) {
  const noReverses = [METRIC.Revenue.value, METRIC.Assists.value];

  if (dimension === DIMENSIONS.Country.value && metric === METRIC.Revenue.value) {
    return series.reverse();
  }

  if (noReverses.includes(metric)) {
    return series;
  }

  return series.reverse();
}
