import { StarFilled } from "@ant-design/icons";
import React from "react";
import { TDataSelected } from "../Metabase";
import { Col, FormInstance, Row, Tooltip, Typography } from "@pankod/refine-antd";
import { IBookmarks, IMetabaseDashBoard, IProduct, IStore } from "interfaces";
import { handleShowDashboard } from "utils/metabase/handleShowDashboard";

interface TPropsBookmarkOption {
  type: "FAVORITE" | "RECENT";
  history: any;
  form: FormInstance<{}>;
  listBookmark?: IBookmarks[];
  deleteBookmark: any;
  isLoadingDeleteBookmark: boolean;
  getToken: any;
  addRecentDashboard: any;
  refetchListBookmark: any;
}
interface IMappingProductApp {
  id: number;
  metabase_dashboard: IMetabaseDashBoard;
  arrProductApp: {
    product: IProduct;
    apps: IStore[] | undefined;
  }[];
}
export default function BookmarkOption({
  type,
  form,
  history,
  listBookmark,
  deleteBookmark,
  isLoadingDeleteBookmark,
  getToken,
  addRecentDashboard,
  refetchListBookmark,
}: TPropsBookmarkOption) {
  const listFavorite = listBookmark?.filter((item) => item.type === "FAVORITE");
  const listRecent = listBookmark
    ?.filter((item) => item.type === "RECENT")
    .sort((a, b) => {
      const getTimeA: number = new Date(a.createdAt).getTime();
      const getTimeB: number = new Date(b.createdAt).getTime();
      return getTimeB - getTimeA;
    });

  const mappingProductsApps = (type === "FAVORITE" ? listFavorite : listRecent)?.reduce(
    (result: IMappingProductApp[], bookmark) => {
      const arrProductApp = bookmark.products.map((product) => {
        return {
          product: product,
          apps: product?.apps?.filter((app) => bookmark.apps.some((_app) => app.id === _app.id)),
        };
      });
      result.push({
        id: bookmark.id,
        metabase_dashboard: bookmark.metabase_dashboard,
        arrProductApp,
      });
      return result;
    },
    [],
  );

  const handleClick = (data: IMappingProductApp) => {
    let listAppValues: TDataSelected[] = [];
    let listProduct: TDataSelected[] = [];
    data?.arrProductApp.forEach((_arrProductApp) => {
      listProduct.push({ label: _arrProductApp.product.name, value: Number(_arrProductApp.product.id) });
      _arrProductApp.apps &&
        _arrProductApp.apps.length > 0 &&
        _arrProductApp.apps?.forEach((app) => {
          listAppValues.push({
            label: app.product_code,
            value: Number(app.id),
          });
        });
    });

    form.setFieldsValue({
      iframeUrl: "",
      dashboardInfo: {
        label: data?.metabase_dashboard?.name,
        value: data?.metabase_dashboard?.id,
        identifier: data?.metabase_dashboard?.identifier,
        type: data?.metabase_dashboard?.type,
      },
      productInfo: listProduct,
      appInfo: listAppValues,
    });
    handleShowDashboard({
      history,
      listBookmark,
      dashboardInfo: {
        label: data?.metabase_dashboard?.name,
        value: data?.metabase_dashboard?.id,
        identifier: data?.metabase_dashboard?.identifier,
        type: data?.metabase_dashboard?.type,
      },
      productInfo: listProduct,
      appInfo: listAppValues,
      form,
      getToken,
      deleteBookmark,
      addRecentDashboard,
      refetchListBookmark,
    });
  };
  const handleDeleteFavorite = async (id: number) => {
    await deleteBookmark({
      resource: "bookmarks",
      id: id || "",
      successNotification: false,
      errorNotification: false,
    });
  };
  return mappingProductsApps?.map((data) => {
    return {
      label: (
        <Tooltip
          placement="topRight"
          mouseLeaveDelay={0}
          title={
            <>
              <p className="mb-10 font-bold">{data?.metabase_dashboard?.name}</p>
              <Row className="mb-[1px]">
                <Col span={12}>Product</Col>
                <Col span={12}>App</Col>
              </Row>
              {data.arrProductApp.map((_arrProductApp, index) => {
                return (
                  <Row key={index}>
                    <Col span={12}>
                      <p className="mb-2 p-0">{_arrProductApp.product.name}</p>
                    </Col>
                    <Col span={12}>
                      {_arrProductApp?.apps?.map((v, index) => (
                        <p className="m-0 p-0 pb-[1px] text-yellow-400" key={index}>
                          {v.product_code}
                        </p>
                      ))}
                    </Col>
                  </Row>
                );
              })}
            </>
          }
        >
          <div className={type === "FAVORITE" ? "flex space-x-4 items-center" : ""}>
            {type === "FAVORITE" && (
              <div
                onClick={(e) => {
                  handleDeleteFavorite(data.id);
                  e.stopPropagation();
                  e.preventDefault();
                }}
                className={`${isLoadingDeleteBookmark}? "cursor-wait" :"cursor-pointer"`}
                // className="cursor-wait"
              >
                <StarFilled className="text-[24px] text-violet-500" />
              </div>
            )}

            <div className="w-full" onClick={() => handleClick(data)}>
              <p className="mb-0 font-semibold">{data?.metabase_dashboard?.name}</p>
              <Typography.Paragraph ellipsis={{ rows: 2 }}>
                {data.arrProductApp.map((_arrProductApp, index) => {
                  if (index < 2) {
                    return (
                      <Row key={index}>
                        <Col span={12} className="m-0 p-0 text-light-gray">
                          {_arrProductApp.product.name}
                        </Col>
                        <Col span={12}>
                          {_arrProductApp?.apps &&
                            _arrProductApp.apps.map((app, index) => {
                              return (
                                app.product_code +
                                `${_arrProductApp.apps && index < _arrProductApp.apps.length - 1 ? ", " : ""}`
                              );
                            })}
                        </Col>
                      </Row>
                    );
                  }
                  return <div key={index}></div>;
                })}
                <div>{data.arrProductApp.length > 2 && "..."}</div>
              </Typography.Paragraph>
            </div>
          </div>
        </Tooltip>
      ),
      text: data?.metabase_dashboard?.name,
    };
  });
}
