import { DatePicker } from "antd";
import React from "react";
import moment from "moment";
import { css } from "@emotion/react";

const { RangePicker } = DatePicker;

const dateFormat = "YYYY-MM-DD";

type IProps = {
  defaultEndDate: string | Date;
  defaultStartDate: string | Date;
  datePickerRef: React.MutableRefObject<{ endDate: Date }>;
  setDatePicker: React.Dispatch<React.SetStateAction<any[]>>;
  disabled?: boolean;
};

const DatePickerChart: React.FC<IProps> = ({
  defaultStartDate,
  defaultEndDate,
  datePickerRef,
  setDatePicker,
  disabled,
}) => {
  const startDateParsed = moment(defaultStartDate).format(dateFormat);
  const endDateParsed = moment(defaultEndDate).format(dateFormat);

  return (
    <div className="flex items-center">
      <RangePicker
        className="rangePickerPMP"
        css={css`
          ${disabled &&
          ` &.rangePickerPMP {
            border-color: #d9d9d9 !important;
          }
          &.rangePickerPMP .ant-picker-input > input {
            color: rgba(0, 0, 0, 0.25);
          }
          &.rangePickerPMP svg path {
            fill: rgba(0, 0, 0, 0.25);
          }`}
        `}
        {...(!disabled && { defaultValue: [moment(startDateParsed, dateFormat), moment(endDateParsed, dateFormat)] })}
        format={dateFormat}
        disabledDate={(currentDate) => currentDate.isAfter(datePickerRef.current.endDate)}
        disabled={disabled}
        onCalendarChange={(_, dateString) => {
          const [startDate, endDate] = dateString;
          if (startDate === "" || endDate === "") {
            return;
          }
          setDatePicker(dateString);
        }}
      />
    </div>
  );
};

export default DatePickerChart;
