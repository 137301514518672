// interface
import { IConvertData, IOptionMetric } from "interfaces/chart";
import { CHART_TYPES, DIMENSIONS, METRIC } from "../chartConfig";

import { getStackingBarChart } from "../chartHelper/commonHelper";

import { columnOption } from "./columnOption";
import { splineOption } from "./splineOption";
import { barOption } from "./barOption";
import { pieOption } from "./pieOption";

export const chartLtvOption = (
  chartType: string,
  data: IConvertData,
  dimensionSelected: string,
  metricSelected: string,
  optionMetrics: IOptionMetric[],
) => {
  switch (chartType) {
    case CHART_TYPES.PIE: {
      return {
        chart: {
          type: CHART_TYPES.PIE,
        },
        title: {
          text: CHART_TYPES.PIE,
          align: "left",
          style: { fontSize: "8px", opacity: 0, color: "#fff" },
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: false,
            },
            showInLegend: true,
          },
        },
        legend: {
          symbolRadius: 2,
          useHTML: true,
          align: "center",
          labelFormatter: function (this: any) {
            return `<div class="legend_truncate">${this.name}</div>`;
          },
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
            },
          ],
        },
        series:
          ([
            {
              name: "",
              colorByPoint: true,
              size: data.series.length > 5 ? "100%" : "60%",
              innerSize: data.series.length > 5 ? "85%" : "80%",
              data: data.series,
            },
          ] as any[]) || [],
        ...pieOption(dimensionSelected, metricSelected),
      };
    }
    case CHART_TYPES.BAR: {
      return {
        chart: {
          type: CHART_TYPES.BAR,
        },
        title: {
          text: CHART_TYPES.BAR,
          align: "left",
          style: { fontSize: "8px", opacity: 0, color: "#fff" },
        },
        yAxis: {
          title: {
            text: "",
          },
          labels: {
            enabled: false,
          },
          reversedStacks: true,
        },
        plotOptions: {
          series: {
            pointWidth: 12,
            borderRadius: 2,
          },
          bar: {
            stacking: getStackingBarChart(metricSelected),
            grouping: true,
            dataLabels: {
              enabled: false,
            },
          },
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
            },
          ],
        },
        xAxis: [
          {
            categories: data.categories || [],
            labels: {
              useHTML: true,
              formatter: function (this: any) {
                const isTextCapotalize = [
                  DIMENSIONS.AttributedTouchType.value,
                  DIMENSIONS.AttributionType.value,
                ].includes(dimensionSelected);
                const textCapitalize = isTextCapotalize ? "text-transform: capitalize" : "";
                return `<div style="text-align: left; width: 160px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; ${textCapitalize}">${this.value}</div>`;
              },
            },
          },
          {
            linkedTo: 0,
            opposite: true,
            categories: data.xAxis,
            className: "font-bold",
          },
        ],
        series: (data.series as []) || [],
        ...barOption(dimensionSelected, metricSelected),
      };
    }
    case CHART_TYPES.SPLINE: {
      return {
        chart: {
          type: CHART_TYPES.SPLINE,
        },
        title: {
          text: CHART_TYPES.SPLINE,
          align: "left",
          style: { fontSize: "8px", opacity: 0, color: "#fff" },
        },
        legend: {
          reversed: false,
          useHTML: true,
          align: "center",
          labelFormatter: function (this: any) {
            return `<div class="legend_truncate">${this.name}</div>`;
          },
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
            },
          ],
        },
        xAxis: {
          categories: data.categories,
          accessibility: {
            description: "Months of the year",
          },
        },
        series: (data.series as []) || [],
        ...splineOption(metricSelected, data, optionMetrics),
      };
    }
    default:
      let labelY = optionMetrics.find((option: any) => option.value === metricSelected)?.label;
      if (metricSelected === METRIC.Touchpoints.value) {
        labelY = "Clicks / Impressions";
      }
      return {
        chart: {
          type: CHART_TYPES.COLUMN,
        },
        title: {
          text: CHART_TYPES.COLUMN,
          align: "left",
          style: { fontSize: "8px", opacity: 0, color: "#fff" },
        },
        plotOptions: {
          column: {
            stacking: "normal",
          },
          series: {
            minPointLength: 3,
          },
        },
        yAxis: {
          title: {
            text: labelY,
          },
          reversedStacks: false,
        },
        xAxis: {
          categories: data?.categories,
          crosshair: true,
        },
        legend: {
          reversed: false,
          symbolRadius: 2,
          useHTML: true,
          align: "center",
          labelFormatter: function (this: any) {
            return `<div class="legend_truncate">${this.name}</div>`;
          },
        },
        series: (data?.series as []) || [],
        ...columnOption(metricSelected, data),
      };
  }
};
