// schema

import { DIMENSIONS, METRIC } from "utils/chart/chartConfig";

export const mapMetricByDimension = (dimension: string) => {
  if (dimension === DIMENSIONS.AppId.value) {
    return [
      METRIC.Attributions,
      METRIC.Impressions,
      METRIC.Clicks,
      METRIC.Cost,
      METRIC.Arpu,
      METRIC.Revenue,
      METRIC.ConvRate,
      METRIC.Touchpoints,
    ];
  }

  if (dimension === DIMENSIONS.AdGroup.value || dimension === DIMENSIONS.AdGroupId.value) {
    return [
      METRIC.Attributions,
      METRIC.Impressions,
      METRIC.Clicks,
      METRIC.Cost,
      METRIC.AvgEcpi,
      METRIC.Arpu,
      METRIC.Roas,
      METRIC.GrossProfit,
      METRIC.ROI,
      METRIC.Revenue,
      METRIC.ConvRate,
    ];
  }

  if (
    dimension === DIMENSIONS.AdSet.value ||
    dimension === DIMENSIONS.AdSetId.value ||
    dimension === DIMENSIONS.Partner.value ||
    dimension === DIMENSIONS.Channel.value ||
    dimension === DIMENSIONS.SiteId.value
  ) {
    return [
      METRIC.Attributions,
      METRIC.Impressions,
      METRIC.Clicks,
      METRIC.Cost,
      METRIC.AvgEcpi,
      METRIC.Arpu,
      METRIC.Roas,
      METRIC.GrossProfit,
      METRIC.ROI,
      METRIC.Revenue,
      METRIC.ConvRate,
    ];
  }

  if (dimension === DIMENSIONS.Source.value || dimension === DIMENSIONS.StoreProductPage.value) {
    return [
      METRIC.Attributions,
      METRIC.Impressions,
      METRIC.Clicks,
      METRIC.Cost,
      METRIC.AvgEcpi,
      METRIC.Arpu,
      METRIC.Roas,
      METRIC.GrossProfit,
      METRIC.ROI,
      METRIC.Revenue,
      METRIC.ConvRate,
      METRIC.SKANAttribution,
      METRIC.SKANRevenue,
    ];
  }

  if (dimension === DIMENSIONS.AttributedTouchType.value || dimension === DIMENSIONS.AttributionType.value) {
    return [METRIC.Attributions, METRIC.Arpu, METRIC.Revenue];
  }

  if (
    dimension === DIMENSIONS.Campaign.value ||
    dimension === DIMENSIONS.CampaignId.value ||
    dimension === DIMENSIONS.MediaSource.value
  ) {
    return [
      METRIC.Attributions,
      METRIC.Impressions,
      METRIC.Clicks,
      METRIC.Cost,
      METRIC.AvgEcpi,
      METRIC.Arpu,
      METRIC.Roas,
      METRIC.GrossProfit,
      METRIC.ROI,
      METRIC.Revenue,
      METRIC.Assists,
      METRIC.ConvRate,
      METRIC.SKANAttribution,
      METRIC.SKANRevenue,
    ];
  }

  if (dimension === DIMENSIONS.Country.value) {
    return [
      METRIC.Attributions,
      METRIC.Impressions,
      METRIC.Clicks,
      METRIC.Cost,
      METRIC.Arpu,
      METRIC.Revenue,
      METRIC.SKANAttribution,
      METRIC.SKANRevenue,
    ];
  }

  if (dimension === DIMENSIONS.EventName.value) {
    return [
      METRIC.EventConversion,
      METRIC.EventUniqueUsers,
      METRIC.EventCount,
      METRIC.EventECPA,
      METRIC.EventRatio,
      METRIC.EventRevenue,
    ];
  }

  return [];
};
