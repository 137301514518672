import React from "react";

import { LayoutProps } from "@pankod/refine-core";
import { AntdLayout, Grid, Spin } from "@pankod/refine-antd";
import { MeProvider, useMe } from "context/MeContext";
import useLocalStorage from "hooks/useLocalStorage";
import { useAuth } from "hooks/useAuth";
import { Layout403 } from "./Layout403";
import router from "@pankod/refine-react-router-v6";
import { Page403WithStudios } from "components/Result/403";
export const Layout: React.FC<LayoutProps> = (props) => {
  return (
    <MeProvider>
      <LayoutWrapper {...props} />
    </MeProvider>
  );
};

export const LayoutWrapper: React.FC<LayoutProps> = ({ children, Sider, Header, Footer, OffLayoutArea }) => {
  const [selectedStudioId] = useLocalStorage<string>("studioId");
  const { logout } = useAuth();
  React.useEffect(() => {
    if (!selectedStudioId) logout();
  }, [logout, selectedStudioId]);

  if (!selectedStudioId) return null;
  return (
    <AntdLayout style={{ minHeight: "100vh", flexDirection: "row" }} hasSider>
      {Sider && <Sider />}
      <AntdLayout id="contentLayout" className="ml-[60px]" style={{ transition: "all 0.2s ease" }}>
        {Header && <Header />}
        <Body children={children} OffLayoutArea={OffLayoutArea} />

        {Footer && <Footer />}
      </AntdLayout>
    </AntdLayout>
  );
};

export const Body: React.FC<LayoutProps> = ({ children, OffLayoutArea }) => {
  const breakpoint = Grid.useBreakpoint();

  const { action, resource } = router.useParams();
  const { can, isLoadingAuth, shouldSuggestStudios } = useMe();
  let isAuthorized = true;
  let _shouldSuggestStudios = false;
  if (resource === "products" && ["edit", "show"].includes(action)) {
    _shouldSuggestStudios = shouldSuggestStudios;
    isAuthorized = can && !isLoadingAuth;
  }

  if (!isLoadingAuth && resource === "products" && _shouldSuggestStudios) {
    return <Page403WithStudios />;
  }

  return !isLoadingAuth && isAuthorized ? (
    <AntdLayout.Content>
      <div
        className="min-h-[91vh]"
        style={{
          padding: breakpoint.sm ? 24 : 12,
        }}
      >
        {children}
      </div>
      {OffLayoutArea && <OffLayoutArea />}
    </AntdLayout.Content>
  ) : isLoadingAuth ? (
    <Spin className="w-full h-full flex flex-col justify-center items-center" />
  ) : (
    <Layout403 />
  );
};
