/* eslint-disable no-useless-escape */
import React from "react";
// refine
import { Form, Space, Button, Drawer } from "@pankod/refine-antd";

// uitls
import { useSelectUsers } from "hooks/useUsers/useSelectUsers";
import { useUpdate } from "@pankod/refine-core";
import { useProduct } from "context/ProductContext";
import { useGetMyRole } from "hooks/useGetMyRole";
import { EnumRoleProduct } from "enums";
import { usePermission } from "hooks/usePermission";
import FormListAddUser from "components/Form/FormListAddUser";

interface IProps {
  isShowDrawerUser: boolean;
  onToggleShowDrawerUser: Function;
  isLoadingProduct: boolean;
}

const DrawerUser: React.FC<IProps> = ({ isShowDrawerUser, onToggleShowDrawerUser, isLoadingProduct }) => {
  // hooks
  const { productInfo, refetchProduct } = useProduct();
  const userIds = productInfo.users?.map((item) => {
    return item.userId || "";
  });
  const { usersAppsOptions, userRoleOptions } = useSelectUsers({
    idsFiltered: userIds,
    productInfo,
  });
  const [form] = Form.useForm();
  const { mutateAsync: updateProduct, isLoading } = useUpdate();
  const cancelFc = () => {
    form.resetFields();
    onToggleShowDrawerUser();
  };
  let _userRoleOptions = userRoleOptions;
  const { userRoleProductPermission } = useGetMyRole();
  const { combineRoleWithSuperAdmin } = usePermission();
  if (!combineRoleWithSuperAdmin(userRoleProductPermission !== EnumRoleProduct.Editor)) {
    _userRoleOptions = _userRoleOptions?.filter((item) => {
      return item.value !== EnumRoleProduct.Owner;
    });
  }
  const onFinish = async (event: any) => {
    const data = event.addUserEmail || [];
    const users = [].concat.apply(
      [],
      data.map((item: any) => {
        const role_id = userRoleOptions.find((role: any) => role.value === item.role)?.id;
        const emailsFormatted = item.emails.map((email: any) => {
          return {
            email,
            role_id,
          };
        });
        return emailsFormatted;
      }),
    );
    await updateProduct({
      resource: "products",
      id: productInfo.id,
      values: {
        users: users,
      },
      errorNotification: {
        type: "error",
        description: "Error",
        message: "Fail to add user into product",
      },
      successNotification: {
        type: "success",
        description: "Success",
        message: `User is successfully added into product`,
      },
    });
    onToggleShowDrawerUser();
    refetchProduct();
    form.resetFields();
  };
  return (
    <>
      <Drawer
        destroyOnClose
        width={500}
        title={
          <div className="flex items-center justify-between">
            <span className="text-[16px] text-black leading-[24px] font-medium">Add user to {productInfo.name}</span>
          </div>
        }
        extra={
          <Space>
            <Button onClick={cancelFc}>Cancel</Button>
            <Button type="primary" onClick={form.submit} loading={isLoading}>
              OK
            </Button>
          </Space>
        }
        placement="right"
        onClose={cancelFc}
        visible={isShowDrawerUser}
      >
        <FormListAddUser
          type="productUsers"
          onFinish={onFinish}
          form={form}
          optionRoles={_userRoleOptions}
          optionUsers={usersAppsOptions}
          isLoadingProduct={isLoadingProduct}
        />
      </Drawer>
    </>
  );
};

export default React.memo(DrawerUser);
