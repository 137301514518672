import { CHART_TYPES } from "utils/chart/chartConfig";

import transformPieData from "./transformPieData";
import transformBarData from "./transformBarData";
import transformColumnData from "./transformColumnData";
import transformSPLineData from "./transformSPLineData";

const getDataTransformer = (chartType: string) => {
  switch (chartType) {
    case CHART_TYPES.PIE:
      return transformPieData;
    case CHART_TYPES.SPLINE:
      return transformSPLineData;
    case CHART_TYPES.COLUMN:
      return transformColumnData;
    case CHART_TYPES.BAR:
      return transformBarData;
    default:
      return () => ({ categories: [], series: [], xAxis: [] });
  }
};

type ITransformLtvProps = {
  data?: any;
  dimension: string;
  metric: string;
  type: string;
  idSuffix?: number;
  days?: any;
  getPointColor?: string;
};

export const transformChartLtvData = ({
  data,
  dimension,
  metric,
  type,
  idSuffix = 1,
  days,
  getPointColor,
}: ITransformLtvProps) => {
  if (!data || !data?.data?.length || !dimension || !metric || !type) return null;

  let metricText = metric;

  if (metric === "touchpoints") {
    metricText = "touchpoints";
  }

  const dataTransformer = getDataTransformer(type);
  return dataTransformer({ data, metric: metricText, dimension, type, idSuffix, days, getPointColor });
};
