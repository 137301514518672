import config from "config";
import React from "react";
import * as Urql from "urql";
import { UserEntityFragment, UsersPermissionsUsersQueryVariables, useUsersPermissionsUsersQuery } from "schema/schema";
import { useDebounce } from "../useDebounce";

export const useUsers = (options?: Omit<Urql.UseQueryArgs<UsersPermissionsUsersQueryVariables>, "query">) => {
  const [page, setPage] = React.useState(1);
  const [usersData, setUsersData] = React.useState<UserEntityFragment[] | undefined>([]);
  const [searchText, setSearchText] = React.useState("");
  const debouncedSearchText = useDebounce(searchText, 500);
  const variables = options?.variables;
  const [queryResult, fetchMore] = useUsersPermissionsUsersQuery({
    ...options,
    variables: {
      pagination: {
        pageSize: config.INITIAL_PAGE_SIZE,
        page: page * 1,
      },
      filters: {
        ...variables?.filters,
        email: {
          containsi: debouncedSearchText,
        },
      },
      ...variables,
    },
  });

  const meta = queryResult.data?.usersPermissionsUsers?.meta;
  const data = queryResult.data?.usersPermissionsUsers?.data;
  const handleScroll = React.useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const ele = e.currentTarget;
      const isBottom = ele.scrollHeight - ele.scrollTop === ele.clientHeight;

      if (isBottom && meta?.pagination.page && meta?.pagination.page < meta.pagination.pageCount) {
        setPage(page + 1);
        fetchMore();
      }
    },
    [fetchMore, meta, page, setPage],
  );

  React.useEffect(() => {
    usersData && data && setUsersData([...usersData, ...data]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    queryResult,
    fetchMore,
    page,
    setPage,
    handleScroll,
    setSearchText,
    usersData: usersData && [...new Set(usersData)],
  };
};
