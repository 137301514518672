import { useCreate, useDelete, useTable } from "@pankod/refine-core";
import { useProduct } from "context/ProductContext";
import { IStore } from "interfaces";

export const useAppKey = () => {
  //hook || get data
  const { mutateAsync: mutateDeleteAppKey, isLoading: isLoadingDelete } = useDelete();
  const { mutateAsync: mutateCreateAppKey, isLoading: isLoadingCreate } = useCreate();
  const { productInfo } = useProduct();
  const { tableQueryResult, filters, setFilters } = useTable<IStore>({
    resource: "apps",
    queryOptions: {
      enabled: Boolean(productInfo.id),
    },
    permanentFilter: [
      {
        field: "product][id]",
        operator: "eq",
        value: productInfo.id,
      },
    ],
    syncWithLocation: false,
  });
  return {
    mutateDeleteAppKey,
    isLoadingDelete,
    mutateCreateAppKey,
    isLoadingCreate,
    tableQueryResult,
    filters,
    setFilters,
  };
};
