// start date: 2021-03-01
// end date: 2021-03-05
// output = ["2021-03-01", "2021-03-02", "2021-03-03", "2021-03-04", "2021-03-05"]

import dayjs from "dayjs";

export const getDateArray = (startDate: Date, endDate: Date, type: string) => {
  let output = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    output.push(dayjs(currentDate).format("YYYY-MM-DD"));
    currentDate = dayjs(currentDate).add(1, "day").toDate();
  }

  return output;
};
