import { Button, Form, Input, Modal } from "@pankod/refine-antd";
import { useCreate, useModal, useUpdate } from "@pankod/refine-core";
import ProductInfo from "factory/Product";
import React from "react";
import { CreateDistributionResult, CreateInvalidationResult, Paths } from "aws-sdk/clients/cloudfront";
import { useMe } from "context/MeContext";
import { useProductInfo } from "hooks/useProduct/useProductInfo";

const CreateCloudfrontElement: React.FC<{
  onClick: () => void;
  hidden?: boolean;
  isDefaultBucket?: boolean;
  isLoading: boolean;
}> = ({ onClick, hidden, isLoading, isDefaultBucket }) => {
  const { productInfo } = useProductInfo();
  const awsPayload = productInfo.awsPayload;
  if (hidden) {
    return null;
  }
  return (
    <div className="flex items-center mt-4">
      {isDefaultBucket ? (
        !awsPayload?.cloudfront ? (
          <>
            {" "}
            <p className="mb-0 mr-2">Your bucket haven't created Cloudfront. To create it click </p>
            <Button onClick={onClick} loading={isLoading} type="primary">
              Create CloudFront
            </Button>
          </>
        ) : (
          <></>
        )
      ) : (
        <p className="mb-0 mr-2">You're using your own bucket. Make sure your cloudfront is available</p>
      )}
    </div>
  );
};

const CreateInvalidationElement: React.FC<{
  hidden?: boolean;
  isLoading: boolean;
}> = ({ hidden, isLoading }) => {
  const { productInfo } = useProductInfo();
  const { visible, show, close } = useModal();
  const awsPayload = productInfo.awsPayload;
  const [form] = Form.useForm();
  const { data: me } = useMe();
  const { mutateAsync: createInvalidationElement, isLoading: isLoadingInvalidation } =
    useCreate<CreateInvalidationResult>();

  const onSubmit = () => {
    form.submit();
  };
  if (hidden || !awsPayload?.distributionId) {
    return null;
  }
  return (
    <>
      <Modal
        visible={visible}
        onCancel={close}
        centered
        onOk={onSubmit}
        confirmLoading={isLoadingInvalidation}
        destroyOnClose
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={async (e) => {
            console.log({ e });
            const Items = e.paths.split("\n");
            const Quantity = Items.length;
            const paths: Paths = {
              Items,
              Quantity,
            };
            const res = await createInvalidationElement({
              resource: "createInvalidation",
              values: {
                distributionId: awsPayload?.distributionId,
                paths,
                userId: me?.id,
                productId: productInfo?.id,
              },
              dataProviderName: "s3Service",
              successNotification: {
                type: "success",
                description: "Success",
                message: "Successfully created invalidation.",
              },
            });
            if (res.data) {
              close();
              form.resetFields();
            }
          }}
        >
          <Form.Item
            label={
              <div className="flex flex-col">
                <span>
                  <span className="text-red-500 text-[10px] mr-1">*</span>Add object paths
                </span>
                <span className="block text-[10px] text-gray-500">
                  Add the path for each object that you want to remove from the CloudFront cache. You can use wildcards
                  (*).
                </span>
              </div>
            }
            required={false}
            name="paths"
            rules={[
              {
                required: true,
                message: "Paths is required",
              },
            ]}
          >
            <Input.TextArea
              rows={3}
              placeholder="/example/path/object1
/example/path/object2
/examplePathWithWildcard*"
            />
          </Form.Item>
        </Form>
      </Modal>
      <Button onClick={show} loading={isLoading} type="primary" className="ml-2">
        Create Invalidation
      </Button>
    </>
  );
};

interface CreateCloudfrontData {
  bucketName: string;
  productInfo?: ProductInfo;
  region: string;
  refetchObjects: () => void;
  refetchProduct: () => void;
}

export const useCreateCloudfront = (data: CreateCloudfrontData) => {
  const { bucketName, productInfo, region, refetchObjects, refetchProduct } = data;
  const awsIntegrationPayload = productInfo?.awsIntegrationPayload;
  const { mutateAsync: createCloudfront, isLoading } = useCreate<CreateDistributionResult>();
  const me = useMe();
  const { mutateAsync: updateAws } = useUpdate();
  const onCreateCloudfront = async () => {
    const resCloudfront = await createCloudfront({
      resource: "createCloudfront",
      values: {
        bucketName,
        prefix: `/${productInfo?.productCode}`,
        region,
        userId: me?.data?.id,
        productId: productInfo?.id,
      },
      dataProviderName: "s3Service",
      successNotification: false,
    });
    if (resCloudfront.data) {
      if (!awsIntegrationPayload?.id) return;
      const resUpdate = await updateAws({
        resource: "integration-payloads",
        id: awsIntegrationPayload?.id,
        values: {
          payload: {
            bucket: bucketName,
            cloudfront: resCloudfront?.data?.Distribution?.DomainName,
            distributionId: resCloudfront?.data?.Distribution?.Id,
          },
        },
        successNotification: {
          type: "success",
          description: "Success",
          message: "Successfully created Cloudfront.",
        },
      });
      if (resUpdate.data) {
        refetchObjects();
        refetchProduct();
      }
    }
  };

  const hidden = Boolean(productInfo?.awsPayload?.cloudfront);

  return {
    CreateCloudfrontElement,
    CreateInvalidationElement,
    onCreateCloudfront,
    isLoadingCloudfront: isLoading,
    hidden,
  };
};
