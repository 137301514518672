import React from "react";
import { ManualSetup } from "./ManualSetup";

const AppStep: React.FC<{
  onSkipStep: () => void;
}> = ({ onSkipStep }) => {
  // const { isManual, setIsManual } = useProductStepForm();
  // return (
  //   <>
  //     {!isManual ? (
  //       <MassSetup setIsManual={setIsManual} />
  //     ) : (
  //       <ManualSetup setIsManual={setIsManual} />
  //     )}
  //   </>
  // );

  return <ManualSetup onSkipStep={onSkipStep} />;
};

export default AppStep;
