import React from "react";

export const OtherIntegrationCreate: React.FC<{
  name: any;
}> = ({ name }) => {
  return (
    <>
      <div className="font-normal text-[14px] p-[20px]">Are you sure to connect with {name}?</div>
    </>
  );
};
