import { Form, FormInstance, Select } from "@pankod/refine-antd";
import { useCreate, useDelete, useList } from "@pankod/refine-core";
import useLocalStorage from "hooks/useLocalStorage";
import { useQuery } from "hooks/useQuery";
import { IBookmarks } from "interfaces";
import React from "react";
import BookmarkOption from "./BookmarkOption";
import router from "@pankod/refine-react-router-v6";

interface TPropsDashboard {
  form: FormInstance<{}>;
  listBookmark?: IBookmarks[];
  refetchListBookmark: any;
}
export default function SelectDashboard({ form, listBookmark, refetchListBookmark }: TPropsDashboard) {
  const query = useQuery();
  const history = router.useHistory();
  const { mutateAsync: getToken } = useCreate();
  const { mutateAsync: addRecentDashboard } = useCreate();
  const { mutateAsync: deleteBookmark, isLoading: isLoadingDeleteBookmark } = useDelete();
  const dashboardInfo = Form.useWatch("dashboardInfo");
  const [studioId] = useLocalStorage<string | null | undefined>("studioId");
  const { data: listDashboard } = useList({
    resource: `metabase-dashboards`,
    metaData: {
      populate: ["studios"],
    },
    queryOptions: {
      onSuccess: (data) => {
        if (query.get("dashboardId")) {
          let queryDashboardOption = data?.data?.filter((dashboard) => {
            return dashboard?.id?.toString() === query.get("dashboardId");
          })[0];
          form.setFieldsValue({
            dashboardInfo: {
              value: Number(queryDashboardOption.id),
              identifier: Number(queryDashboardOption.identifier),
              type: queryDashboardOption.type.toString(),
              label: queryDashboardOption.name.toString(),
            },
          });
        }
      },
    },
  });

  const MenuOption = React.useMemo(() => {
    if (!listDashboard?.data) return;
    const metabase_dashboards_filtered = listDashboard?.data?.filter((item) =>
      item?.studios?.some((studio: any) => studio.id?.toString() === studioId?.toString()),
    );
    return metabase_dashboards_filtered?.map((metabase) => ({
      label: <span className=" w-full">{metabase.name || ""}</span>,
      value: metabase.id,
      type: metabase.type,
      mode: metabase.mode,
      text: metabase.name,
      identifier: metabase.identifier,
    }));
  }, [studioId, listDashboard?.data]);
  const bookmarkOption = React.useCallback(
    (type: "RECENT" | "FAVORITE") => {
      return BookmarkOption({
        type: type,
        listBookmark,
        form,
        history,
        getToken,
        addRecentDashboard,
        refetchListBookmark,
        deleteBookmark,
        isLoadingDeleteBookmark,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addRecentDashboard, deleteBookmark, form, listBookmark],
  );
  const OptionDashboard = React.useMemo(() => {
    return [
      {
        ...(bookmarkOption("RECENT") && {
          label: <div className=" w-full pt-2">Recent</div>,
          options: bookmarkOption("RECENT"),
        }),
      },
      {
        ...(bookmarkOption("FAVORITE") && {
          label: <div className="border-t border-light-pink w-full pt-2">Favorite</div>,
          options: bookmarkOption("FAVORITE"),
        }),
      },
      {
        ...(MenuOption && {
          label: <div className="border-t border-light-pink w-full pt-2">All dashboard</div>,
          options: MenuOption,
        }),
      },
    ];
  }, [MenuOption, bookmarkOption]);

  return (
    <Select
      value={dashboardInfo}
      className="w-[100%]"
      dropdownClassName="custom-select"
      placeholder="Dashboard"
      optionLabelProp="text"
      listHeight={400}
      allowClear
      onClear={() => {
        form.setFieldsValue({
          appInfo: [],
          productInfo: [],
          iframeUrl: "",
        });
      }}
      onChange={(_, option: any) => {
        form.setFieldsValue({
          dashboardInfo: option && {
            id: option.value,
            type: option.type,
            name: option.text,
            label: option.text,
            value: option.value,
            identifier: option.identifier,
          },
          productInfo: [],
          appInfo: [],
          iframeUrl: "",
        });
      }}
      options={OptionDashboard}
    />
  );
}
