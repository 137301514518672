import { RcFile } from "@pankod/refine-antd";

export const isFolder = (path: string) => {
  const parts = path.split("/");
  if (!parts[parts.length - 1]) {
    return true;
  }
  return false;
};

export const getLastPartOfPath = (path: string) => {
  const parts = path.split("/");
  if (isFolder(path)) {
    return parts[parts.length - 2] + "/";
  }
  return parts[parts.length - 1];
};

export const getRestPartOfPath = (path: string) => {
  const parts = path.split("/");
  parts.shift();
  return parts.join("/");
};

export const getTypeOfFile = (fileName: string) => {
  const parts = fileName.split(".");
  return parts[parts.length - 1];
};

export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};
