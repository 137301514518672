export enum EnumTooltipContent {
  RequestBilling = `After you click Request button, We will send an email to request your admin to check it.`,
}
export enum EnumIntegrationName {
  EventGateway = "EventGateway",
  AmaPassport = "AmaPassport",
  IronSource = "ironSource",
  RevenueCat = "RevenueCat",
  ApplovinMAX = "Applovin MAX",
  AppsFlyer = "AppsFlyer",
  Daremi = "Daremi",
}

export enum EnumRoleProduct {
  Owner = "PMP_ROLE_PRODUCT_OWNER",
  Editor = "PMP_ROLE_PRODUCT_EDITOR",
  Viewer = "PMP_ROLE_PRODUCT_VIEWER",
}
export enum EnumRoleStudio {
  Admin = "PMP_ROLE_STUDIO_ADMIN",
  Manager = "PMP_ROLE_STUDIO_MANAGER",
  Member = "PMP_ROLE_STUDIO_MEMBER",
}

export enum EnumDaremiStatus {
  Registered = "Registered",
  Unregistered = "Unregistered",
  Connected = "Connected",
}
