import { useParams } from "react-router-dom";
import { useProductInfo } from "./useProduct/useProductInfo";
import { useMe } from "../context/MeContext";
import { useOne, useResource } from "@pankod/refine-core";
import { IStudio } from "interfaces";
import React from "react";

export const useGetMyRole = () => {
  const { data: me } = useMe();
  const { resource } = useResource();
  const { productInfo } = useProductInfo();
  const params = useParams();

  // studio
  const { data } = useOne<IStudio>({
    resource: "studios",
    id: params.id || "",
    metaData: {
      populate: [
        "icon",
        "products.icon",
        "studio_user_roles.users_permissions_user",
        "studio_user_roles.role.permissions",
      ],
    },
    queryOptions: {
      retry: 0,
      enabled: resource.name === "studios",
    },

    errorNotification: false,
  });
  const studio = data?.data;
  const userStudio = studio?.studio_user_roles?.find(
    (studio) => studio?.users_permissions_user?.id && studio?.users_permissions_user?.id === me?.id,
  );
  const userRoleStudioPermission = userStudio?.role?.type;
  // product
  const listProductUser = me?.product_user_roles;
  const listMyRoleProduct = listProductUser?.reduce((result: any, value) => {
    const id = value?.product?.id;
    if (id) {
      result[id] = value?.role?.type;
    }
    return result;
  }, {});
  const userProduct = React.useMemo(
    () =>
      productInfo?.productUserRoles?.data.find(
        (product) => product.attributes?.users_permissions_user?.data?.id?.toString() === me?.id.toString(),
      ),
    [me?.id, productInfo?.productUserRoles?.data],
  );
  const userRoleProductPermission = React.useMemo(() => {
    return userProduct?.attributes?.role?.data?.attributes?.type || "";
  }, [userProduct?.attributes?.role?.data?.attributes?.type]);
  const userRoleProductName = React.useMemo(() => {
    return userProduct?.attributes?.role?.data?.attributes?.name || "";
  }, [userProduct?.attributes?.role?.data?.attributes?.name]);

  const getRoleUserWithStudio = (studioName: string) => {
    return me?.studio_user_roles?.find((studio) => studio?.studio?.name === studioName)?.role?.type;
  };

  return {
    userRoleProductPermission,
    userRoleProductName,
    userRoleStudioPermission,
    listMyRoleProduct,
    getRoleUserWithStudio,
  };
};
