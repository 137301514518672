import { EditableCell, EditableRow } from "components/Table/Editable";
import { IObject } from "interfaces";
import React from "react";

interface BucketUtilData {
  bucketName: string;
  region: string;
}

export const useBucketUltil = (data: BucketUtilData) => {
  const { bucketName, region } = data;
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const [editingKey, setEditingKey] = React.useState("");
  const isEditing = (record: IObject) => record.key === editingKey;

  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const getURI = () => {
    return `s3://${bucketName}/${selectedRowKeys[0]}`;
  };

  const getURL = (prefix: React.Key) => {
    return `https://${bucketName}.s3.${region}.amazonaws.com/${prefix}`;
  };

  const rowSelection = {
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
    selectedRowKeys: selectedRowKeys,
    preserveSelectedRowKeys: true,
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return {
    selectedRowKeys,
    setSelectedRowKeys,
    editingKey,
    setEditingKey,
    isEditing,
    getURI,
    getURL,
    rowSelection,
    components,
  };
};
