import React from "react";
//refine
import { Button, Col, Form, Input, Row, useForm } from "@pankod/refine-antd";
import { useCreate, useDelete, useList } from "@pankod/refine-core";
import router from "@pankod/refine-react-router-v6";
//hook
import { useQuery } from "hooks/useQuery";

//component
import SelectDashboard from "./component/SelectDashboard";

//other
import SelectProduct from "./component/SelectProduct";
import SelectApps from "./component/SelectApps";
import StartButton from "./component/StartButton";
import { IBookmarks } from "interfaces";
import { handleShowDashboard } from "utils/metabase/handleShowDashboard";

export interface TDashboardInfo {
  type: string;
  label: string;
  value: number;
  identifier: number;
}
export interface TDataSelected {
  label: string;
  value: number;
}

function Metabase() {
  const query = useQuery();
  const history = router.useHistory();
  const [isFirstRender, setIsFirstRender] = React.useState(true);
  const { form } = useForm();

  const optionsProduct: TDataSelected[] = Form.useWatch("optionsProduct", form);
  const optionsApp: TDataSelected[] = Form.useWatch("optionsApp", form);
  const dashboardInfo = Form.useWatch("dashboardInfo", form);
  const productInfo = Form.useWatch("productInfo", form);
  const appInfo = Form.useWatch("appInfo", form);
  const iframeUrl = Form.useWatch("iframeUrl", form);
  const queryProductId = query.get("productId")?.split(",");

  //api get token
  const { mutateAsync: getToken } = useCreate();
  //api get all bookmark
  const {
    data: listBookmark,
    refetch: refetchListBookmark,
    isLoading: isLoadingGetListBookmark,
    isFetching: isFetchingGetListBookmark,
  } = useList<IBookmarks>({
    resource: "bookmarks",
    metaData: {
      populate: ["products.apps", "apps"],
    },
    config: {
      pagination: {
        pageSize: 20,
      },
    },
  });
  //api add recent
  const { mutateAsync: addRecentDashboard } = useCreate();
  //delete bookmark
  const { mutateAsync: deleteBookmark } = useDelete();

  // useEffect
  React.useEffect(() => {
    if (optionsProduct && queryProductId && isFirstRender) {
      const queryProductOptions = optionsProduct?.filter((product) =>
        queryProductId.includes(product.value?.toString()),
      );

      queryProductOptions?.length !== 0 &&
        form.setFieldsValue({
          productInfo: queryProductOptions,
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsProduct]);
  React.useEffect(() => {
    if (optionsApp?.length > 0 && query.get("appId")?.split(",") && isFirstRender) {
      const queryAppOptions = optionsApp?.filter((app) =>
        query.get("appId")?.split(",").includes(app.value.toString()),
      );
      queryAppOptions &&
        form.setFieldsValue({
          appInfo: queryAppOptions,
        });
      setIsFirstRender(false);
      handleShowDashboard({
        history,
        listBookmark: listBookmark?.data,
        dashboardInfo,
        productInfo,
        appInfo: queryAppOptions,
        form,
        getToken,
        deleteBookmark,
        addRecentDashboard,
        refetchListBookmark,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsApp]);

  return (
    <div>
      <p className="text-[18px] font-semibold">Metabases</p>
      <Form form={form} name="form_metabase">
        <div className="selectFieldPMP">
          <Row>
            <Col span={20}>
              <div className="flex flex-wrap items-center">
                <Form.Item name="dashboardInfo" className="min-w-[200px] w-[30%] mr-[10px] mb-10">
                  <SelectDashboard
                    form={form}
                    listBookmark={listBookmark?.data}
                    refetchListBookmark={refetchListBookmark}
                  />
                </Form.Item>
                <Form.Item name="productInfo" className="p-0 min-w-[200px] w-[25%] mr-[10px] mb-10">
                  <SelectProduct form={form} />
                </Form.Item>
                <Form.Item name="appInfo" className="min-w-[200px] w-[25%] mb-10">
                  <SelectApps form={form} />
                </Form.Item>
                <Form.Item name="iframeUrl">
                  <div className="hidden">
                    <Input />
                  </div>
                </Form.Item>
                <Form.Item name="optionsProduct">
                  <div className="hidden">
                    <Input />
                  </div>
                </Form.Item>
                <Form.Item name="optionsApp">
                  <div className="hidden">
                    <Input />
                  </div>
                </Form.Item>
                <StartButton
                  isLoadingGetListBookmark={isLoadingGetListBookmark}
                  isFetchingGetListBookmark={isFetchingGetListBookmark}
                  listBookmark={listBookmark?.data}
                  refetchListBookmark={refetchListBookmark}
                />
              </div>
            </Col>

            <Col lg={4} sm={24} className="flex justify-start lg:justify-end mt-2">
              <Button
                onClick={() =>
                  handleShowDashboard({
                    history,
                    listBookmark: listBookmark?.data,
                    dashboardInfo,
                    productInfo,
                    appInfo,
                    form,
                    getToken,
                    deleteBookmark,
                    addRecentDashboard,
                    refetchListBookmark,
                  })
                }
                disabled={!appInfo || appInfo?.length === 0 || optionsApp?.length === 0}
                className="mt-2 lg:mt-0 "
                size="small"
                type="primary"
              >
                Show Dashboard
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
      <div className="w-full h-full mt-8">
        {iframeUrl && optionsApp?.length > 0 && (
          <iframe
            title="iframeUrl"
            src={iframeUrl || ""}
            frameBorder="0"
            className="lg:w-full min-w-[700px] h-[800px]"
          />
        )}
      </div>
    </div>
  );
}
export default Metabase;
