import { Button, Icons, Popconfirm } from "@pankod/refine-antd";
import React from "react";
const { DownloadOutlined, DeleteOutlined } = Icons;

interface DownloadObjectButtonProps {
  onDownload: () => void;
  disabled: boolean;
}

export const DownloadObjectButton: React.FC<DownloadObjectButtonProps> = ({ onDownload, disabled }) => {
  return (
    <Button onClick={onDownload} disabled={disabled}>
      <DownloadOutlined /> Download
    </Button>
  );
};

interface DeleteObjectButtonProps {
  onDelete: () => void;
  disabled: boolean;
  loading: boolean;
}

export const DeleteObjectButton: React.FC<DeleteObjectButtonProps> = ({ onDelete, disabled, loading }) => {
  return (
    <Popconfirm title="Are you sure to delete those files?" onConfirm={onDelete}>
      <Button loading={loading} disabled={disabled} danger={!disabled}>
        <DeleteOutlined /> Delete
      </Button>
    </Popconfirm>
  );
};
