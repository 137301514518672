import { IDimension, IMetric } from "interfaces/chart";

export const CHART_TYPES = {
  PIE: "pie",
  BAR: "bar",
  COLUMN: "column",
  SPLINE: "spline",
  SYNCED_PIE: "synced-pie",
};

export const METRIC: IMetric = {
  Attributions: {
    label: "Attributions",
    value: "installs",
  },
  Impressions: {
    label: "Impressions",
    value: "impressions",
    originalValue: "impressions",
  },
  Touchpoints: {
    label: "Touchpoints",
    value: "touchpoints",
    originalValue: "touchpoints",
  },
  Clicks: {
    label: "Clicks",
    value: "clicks",
  },
  Cost: {
    label: "Cost",
    value: "install_cost",
  },
  AvgEcpi: {
    label: "Avg eCPI",
    value: "avg_ecpi",
  },
  Arpu: {
    label: "ARPU",
    value: "arpu",
  },
  Roas: {
    label: "ROAS",
    value: "roas",
  },
  GrossProfit: {
    label: "Gross Profit",
    value: "gross_profit",
  },
  ConvRate: {
    label: "Conversion rate",
    value: "conv_rate",
  },
  Revenue: {
    label: "Revenue",
    value: "revenue",
  },
  ROI: {
    label: "ROI",
    value: "roi",
  },
  Assists: {
    label: "Assisted installs",
    value: "assists",
  },
  EventConversion: {
    label: "Event conversion",
    value: "conversion_rate",
  },
  EventUniqueUsers: {
    label: "Event unique users",
    value: "unique_users",
  },
  EventCount: {
    label: "Event count",
    value: "count",
  },
  EventECPA: {
    label: "Event eCPA",
    value: "ecpa",
  },
  EventRatio: {
    label: "Event ratio",
    value: "install_rate",
  },
  EventRevenue: {
    label: "Event revenue",
    value: "revenue",
  },
  SKANAttribution: {
    label: "SKAN attribution",
    value: "skan_installs",
  },
  SKANRevenue: {
    label: "SKAN revenue",
    value: "skan_revenue",
  },
};

export const EventKpis = {
  event_name: {
    metric: "installs",
  },
};

export const defaultPaletteChart = ["#51CF66", "#339AF0"];

export const paletteChart = [
  "#5476FD",
  "#66D9E8",
  "#AE3EC9",
  "#91A7FF",
  "#FCC419",
  "#DA77F2",
  "#845EF7",
  "#15AABF",
  "#FF922B",
  "#339AF0",
  "#FF8787",
  "#9775FA",
  "#51CF66",
  "#FFA94D",
  "#A9E34B",
  "#38D9A9",
  "#FCC2D7",
  "#99E9F2",
  "#0CA678",
  "#EEBEFA",
  "#F76707",
  "#FFE066",
];

export const DIMENSIONS: IDimension = {
  AppId: {
    label: "App ID",
    value: "app_id",
  },
  AdGroup: {
    label: "Ad",
    value: "adgroup",
  },
  AdGroupId: {
    label: "Ad ID",
    value: "adgroup_id",
  },
  AdSet: {
    label: "Adset",
    value: "adset",
  },
  AdSetId: {
    label: "Adset ID",
    value: "adset_id",
  },
  Partner: {
    label: "Agency",
    value: "partner",
  },
  StoreProductPage: {
    label: "Store product page",
    value: "store_product_page",
  },
  AttributedTouchType: {
    label: "Attributed touch type",
    value: "attributed_touch_type",
  },
  AttributionType: {
    label: "Attribution type",
    value: "attribution_type",
  },
  Campaign: {
    label: "Campaign",
    value: "campaign",
  },
  CampaignId: {
    label: "Campaign ID",
    value: "campaign_id",
  },
  Channel: {
    label: "Channel",
    value: "channel",
  },
  Country: {
    label: "Geo",
    value: "country",
  },
  SiteId: {
    label: "Site ID",
    value: "site_id",
  },
  Source: {
    label: "Source",
    value: "source",
  },
  MediaSource: {
    label: "Media Source",
    value: "media_source",
  },
  EventName: {
    label: "Event Name",
    value: "event_name",
  },
};

export const VIEW_PROPERTIES = {
  FILTER_STATE: "filterState",
  WIDGETS: "widgets",
  WIDGETS_LAYOUT: "widgetsLayout",
  KPI_LAYOUTS: "kpiLayouts",
};

export const GROUPINGS = {
  MEDIA_SOURCE: "media_source",
  CAMPAIGN: "campaign",
  CAMPAIGN_ID: "campaign_id",
  COUNTRY: "country",
  STATE: "state",
  DMA: "dma",
  ADGROUP: "adgroup",
  ADSET: "adset",
  AD_PLACEMENT: "ad_placement",
  PARTNER: "partner",
  DATE: "date",
  SOURCE: "source",
  AD_GROUP_ID: "adgroup_id",
  AD_SET_ID: "adset_id",
  SITE_ID: "site_id",
  CHANNEL: "channel",
  ATTRIBUTED_TOUCH_TYPE: "attributed_touch_type",
  ATTRIBUTION_TYPE: "attribution_type",
  EVENT_NAME: "event_name",
  APPID: "app_id",
  CUSTOM_DIMENSION: "custom_dimension",
  FILTER_DATA: "filter_data",
  VIEW_TYPE: "view_type",
  STORE_PRODUCT_PAGE: "store_product_page",
  INSTALL_STORE: "install_store",
  IS_SSOT: "isSsot",
  SSOT: "ssot",
  SKAN_AMBIGUOUS_EVENT: "skan_ambiguous_event",
  ATTRIBUTION_SOURCE: "attribution_entity",
};

export const KPIS = {
  TOTAL_ATTRIBUTIONS: "installs",
  REATTR: "installs_reattr",
  RETARGETING: "installs_retarget",
  INSTALL: "installs_ua",
  IMPRESSIONS: "impressions",
  CLICKS: "clicks",
  CLICK: "click",
  REVENUE: "revenue",
  REVENUE_AW: "revenue_aw",
  SESSIONS: "sessions",
  ROI: "roi",
  ROI_AW: "roi_aw",
  ARPU: "arpu",
  ARPU_AW: "arpu_aw",
  AVG_ECPI: "avg_ecpi",
  INSTALL_COST: "install_cost",
  CLICK_INSTALLS: "click_installs",
  IMPRESSION_INSTALLS: "impression_installs",
  CONV_RATE: "conv_rate",
  LOYALS: "loyals",
  LOYAL_RATE: "loyal_rate",
  UNINSTALLS: "uninstalls",
  UNINSTALL_RATE: "uninstall_rate",
  ROAS: "roas",
  ROAS_AW: "roas_aw",
  GROSS_PROFIT: "gross_profit",
  GROSS_PROFIT_AW: "gross_profit_aw",
  IN_APPS: "inapps",
  ASSISTS: "assists",
  SKAN_DUPLICATES: "skan_duplicates",
};

export const EVENT_KPIS = {
  CONVERSION_RATE: "conversion_rate",
  UNIQUE_USERS: "unique_users",
  COUNT: "count",
  REVENUE: "revenue",
  ECPA: "ecpa",
  INSTALL_RATE: "install_rate",
  EventConversion: {
    label: "Event conversion",
    value: "conversion_rate",
  },
  EventUniqueUsers: {
    label: "Event unique users",
    value: "unique_users",
  },
  EventCount: {
    label: "Event count",
    value: "count",
  },
  EventECPA: {
    label: "Event eCPA",
    value: "ecpa",
  },
  EventRatio: {
    label: "Event ratio",
    value: "install_rate",
  },
  EventRevenue: {
    label: "Event revenue",
    value: "revenue",
  },
};

export const SOURCE = {
  NON_ORGANIC: "non-organic",
  ORGANIC: "organic",
};

export const NON_ADAPTIVE_KPIS = [
  KPIS.ROI,
  KPIS.ARPU,
  KPIS.AVG_ECPI,
  KPIS.CLICK_INSTALLS,
  KPIS.IMPRESSION_INSTALLS,
  KPIS.CONV_RATE,
  KPIS.LOYAL_RATE,
  KPIS.UNINSTALL_RATE,
  KPIS.ROAS,
  KPIS.GROSS_PROFIT,
  EVENT_KPIS.CONVERSION_RATE,
  EVENT_KPIS.ECPA,
  EVENT_KPIS.INSTALL_RATE,
];

export const KPIS_TYPES = {
  USER_ACQUISITION: "ua",
  RETARGETING: "retargeting",
  REVENUE: "revenue",
  REVENUE_AW: "revenue_aw",
  COST: "cost",
  TOUCHPOINTS: "touchpoints",
  ECPI: "ecpi",
  DUPLICATES: "duplicates",
};

export const ATTRIBUTION_ENTITY = {
  SKAN: "skan",
  APPS_FLYER: "af-attribution",
};

export const VIEW_TYPES = {
  USER_ACQUISITION: "ua",
  RETARGETING: "retargeting",
  UNIFIED: "unified",
};

export const DATA_DATE_FORMAT = "yyyy-MM-dd";

// get type and digit fraction format for the given metric
export const getMetricsFormat: any = {
  revenue: {
    type: "currency",
    format: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  roi: {
    format: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  avg_ecpi: {
    type: "currency",
    format: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  arpu: {
    type: "currency",
    format: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  cpi: {
    type: "currency",
    format: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  install_cost: {
    type: "currency",
    format: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  ecpa: {
    type: "currency",
    format: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  conversion_rate: {
    type: "percent",
    format: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  conv_rate: {
    type: "percent",
    format: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  loyal_rate: {
    type: "percent",
    format: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  uninstall_rate: {
    type: "percent",
    format: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  roas: {
    type: "percent",
    format: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  retention: {
    type: "percent",
    format: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  gross_profit: {
    type: "currency",
    format: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  install_rate: {
    type: "percent",
    format: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  event_revenue: {
    type: "currency",
    format: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
};

export const METRICS_TO_FORMAT_CONFIG: any = {
  skan_revenue: { type: "currency", format: { minimumFractionDigits: 2, maximumFractionDigits: 2 } },
  revenue: { type: "currency", format: { minimumFractionDigits: 2, maximumFractionDigits: 2 } },
  revenue_aw: { type: "currency", format: { minimumFractionDigits: 2, maximumFractionDigits: 2 } },
  roi: { adative: false, format: { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 } },
  roi_aw: { adative: false, format: { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 } },
  avg_ecpi: { type: "currency", adative: false, format: { minimumFractionDigits: 2, maximumFractionDigits: 2 } },
  arpu: { type: "currency", adative: false, format: { minimumFractionDigits: 2, maximumFractionDigits: 2 } },
  arpu_aw: { type: "currency", adative: false, format: { minimumFractionDigits: 2, maximumFractionDigits: 2 } },
  install_cost: { type: "currency", format: { minimumFractionDigits: 2, maximumFractionDigits: 2 } },
  ecpa: { adative: false, format: { minimumFractionDigits: 2, maximumFractionDigits: 2 } },
  conversion_rate: {
    type: "percent",
    adative: false,
    format: { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 },
  },
  conv_rate: {
    type: "percent",
    adative: false,
    format: { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 },
  },
  loyal_rate: {
    type: "percent",
    adative: false,
    format: { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 },
  },
  uninstall_rate: {
    type: "percent",
    adative: false,
    format: { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 },
  },
  roas: {
    type: "percent",
    adative: false,
    format: { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 },
  },
  roas_aw: {
    type: "percent",
    adative: false,
    format: { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 },
  },
  gross_profit: { type: "currency", format: { minimumFractionDigits: 2, maximumFractionDigits: 2 } },
  gross_profit_aw: { type: "currency", format: { minimumFractionDigits: 2, maximumFractionDigits: 2 } },
  install_rate: {
    type: "percent",
    adative: false,
    format: { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 },
  },
};

type ICurrentSource = {
  [key: string]: string;
};
export const CURRENT_SOURCE: ICurrentSource = {
  organic: "Organic",
  "non-organic": "Non-organic",
};

export const DASHBOARD_LABEL_MAP = {
  [VIEW_TYPES.USER_ACQUISITION]: "User Acquisition",
  [VIEW_TYPES.RETARGETING]: "Retargeting",
  [VIEW_TYPES.UNIFIED]: "Unified",
};
