import React from "react";
import { CanAccess } from "@pankod/refine-core";
import { EditButton } from "@pankod/refine-antd";

export const CanAccessEditButton: React.FC<{
  resource: string;
  action: string;
  status: boolean;
  id?: string;
}> = ({ resource, action, status, id }) => {
  return (
    <CanAccess
      resource={resource}
      action={action}
      params={{ id }}
      fallback={
        <EditButton className="shadow-none border-0" type="text" size="small" recordItemId={id} disabled={true} />
      }
    >
      <EditButton
        className="shadow-none border-0 text-color-primary"
        type="text"
        size="small"
        recordItemId={id}
        disabled={!status}
      />
    </CanAccess>
  );
};
