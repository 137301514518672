import React from "react";
import { Typography } from "@pankod/refine-antd";

// components
import { TagStatus } from "components/Tag";

// interface
import { IStudio } from "interfaces";
import { Enum_App_Status } from "schema/schema";
import { ImageElement } from "components/Image";
import { GeneralInfoSkeleton } from "components/Loading/GeneralInfoSkeleton";

const { Text } = Typography;

interface IProps {
  studio?: IStudio;
  isLoading?: boolean;
}

function GeneralInfo({ studio, isLoading }: IProps) {
  const [isExpand, setIsExpand] = React.useState(true);
  const [isEllipsis, setIsEllipsis] = React.useState(false);
  const description = studio?.description?.split("\n");

  return (
    <div className="flex w-full">
      {isLoading && <GeneralInfoSkeleton isStudioInfo />}
      {!isLoading && (
        <>
          <div className="">
            <span className="w-[242px] h-[242px] flex">
              <ImageElement className="w-full h-full object-contain" src={studio?.icon?.url} alt={studio?.icon?.alt} />
            </span>
          </div>

          <div className="ml-[40px] flex flex-col justify-between w-full">
            <div>
              <div className="flex justify-between items-center">
                <Text className="leading-[28px] font-medium text-[20px] inline-flex w-full">{studio?.name}</Text>
              </div>
              <div className="mt-[14px]">
                <TagStatus status={studio?.status ? Enum_App_Status.Active : Enum_App_Status.Inactive} />
              </div>
              <div className="mt-[12px]">
                <a
                  target="_blank"
                  href={studio?.website}
                  className="font-normal text-[#FAAA06] text-[14px]"
                  rel="noreferrer"
                >
                  {studio?.website}
                </a>
              </div>
              <div className="mt-[16px]">
                <Typography.Paragraph
                  ellipsis={
                    isExpand
                      ? {
                          rows: 4,
                          symbol: "more",
                          onEllipsis: (e) => setIsEllipsis(!isEllipsis),
                        }
                      : false
                  }
                  className="text-[14px] mt-4 text-black font-normal leading-[22px] whitespace-pre-wrap"
                >
                  {description?.map((p, index) => (
                    <p key={index} className="m-0 p-0">
                      {p}
                    </p>
                  ))}
                </Typography.Paragraph>
                {isEllipsis && (
                  <span className="cursor-pointer text-[14px] text-[#1890ff]" onClick={() => setIsExpand(!isExpand)}>
                    Show {isExpand ? "more" : "less"}
                  </span>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default GeneralInfo;
