import { Form, Icons, Input, Radio, RadioChangeEvent } from "@pankod/refine-antd";
import { TooltipCustom } from "components/Input/Tooltip";
import React from "react";
const { InfoCircleOutlined } = Icons;
export const RevenueCatCreate: React.FC = () => {
  const [valueRadio, setValueRadio] = React.useState("new");

  const onChangeRadio = (e: RadioChangeEvent) => {
    setValueRadio(e.target.value);
  };

  return (
    <div className="max-h-[500px] overflow-y-scroll pl-[24px] pr-[24px] ">
      <Radio.Group onChange={onChangeRadio} className="mb-2" value={valueRadio}>
        <Radio value={"new"}>
          {" "}
          <p className="mb-0 text-[14px]">
            <strong>Create a new project</strong>{" "}
          </p>{" "}
        </Radio>
        <Radio value={"existing"}>
          {" "}
          <p className="mb-0 text-[14px]">
            <strong>Use an existing project</strong>{" "}
          </p>{" "}
        </Radio>
      </Radio.Group>
      {valueRadio === "new" && (
        <div className="font-normal text-[14px] p-[20px]">Are you sure to connect with RevenueCat?</div>
      )}

      {valueRadio === "existing" && (
        <div>
          <Form.Item
            className="mt-[15px] mb-0 text-[14px]"
            label={
              <>
                <span>Project URL</span>
                <TooltipCustom
                  placement="top"
                  title={
                    <>
                      https://app.revenuecat.com/projects/
                      <strong className="font-bold">123abc</strong>
                      /apps
                    </>
                  }
                >
                  <InfoCircleOutlined />
                </TooltipCustom>
              </>
            }
            rules={[
              {
                required: true,
                message: "Project URL is required !",
              },
              {
                pattern: /^https:\/\/app.revenuecat.com\/projects\/(\w*)\/apps/,
                message: "Project URL not valid !",
              },
            ]}
            name="project_url"
          >
            <Input
              // onChange={(e) => setProjectUrl(e.target.value)}
              placeholder="Example"
            />
          </Form.Item>
        </div>
      )}
    </div>
  );
};
