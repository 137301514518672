import React from "react";
import { Result } from "antd";
import { SmileOutlined } from "@ant-design/icons";

const Page500: React.FC = () => {
  return (
    <div className="flex justify-center w-screen h-screen">
      <Result
        className="place-self-center "
        icon={<SmileOutlined />}
        title="Internal server error"
        subTitle={
          <span>
            We're unable to find out what's happening! We suggest you to <a href="/">go back home</a>
          </span>
        }
        status={500}
      />
    </div>
  );
};

export default Page500;
