import { ImageElement } from "components/Image";

interface ProductProps {
  imageUrl: string;
  disabled?: boolean;
}
export const ProductIconAndPlatform: React.FC<ProductProps> = ({ imageUrl, disabled }) => {
  return (
    <div className={`w-[48px] h-[48px] relative ${disabled ? "grayscale" : ""}`}>
      <ImageElement src={imageUrl} className="rounded-sm h-full w-full object-contain" />
    </div>
  );
};
