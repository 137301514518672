const violet = {
  50: "#F3F0FF",
  100: "#E5DBFF",
  200: "#D0BFFF",
  300: "#B197FC",
  400: "#9775FA",
  500: "#845EF7",
  600: "#7950F2",
  700: "#7048E8",
  800: "#6741D9",
  900: "#5F3DC4",
};

export default violet;
