import { MinusCircleOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { Button, Form, FormInstance, Input } from "@pankod/refine-antd";
import { Option, useCreate } from "@pankod/refine-core";
import { TooltipCustom } from "components/Input/Tooltip";
import { useProductStepForm } from "context/ProductStepFormContext";
import React from "react";
import { BlockItem } from "./BlockItem";
import { FormListFieldData } from "antd/lib/form";
interface FormItemProps {
  keyField: number;
  name: number;
  index: number;
  remove: (val: number) => void;
  form?: FormInstance<{}>;
  firebasesCreateOptions?: Option[];
  fields: FormListFieldData[];
}

export const FormItem: React.FC<FormItemProps> = ({
  keyField,
  name,
  index,
  remove,
  form: formCreate,
  firebasesCreateOptions,
  fields,
}) => {
  const { values, form, setValues, setFormLoading } = useProductStepForm();

  const _form = form || formCreate;

  const value = values?.linking_apps && values.linking_apps[index];
  const bundle_id = value?.bundle_id;

  const [bundleId, setBundleId] = React.useState(bundle_id);
  const { mutateAsync: getAppSummary, data, isLoading, isError } = useCreate();

  const onRemove = () => {
    if (isLoading) return;
    remove(name);
    bundle_id &&
      setValues?.((values: any) => ({
        ...values,
        linking_apps: values?.linking_apps?.filter((v: any) => v.bundle_id !== bundle_id),
      }));
    setBundleId("");
  };
  const namePath = React.useMemo(() => {
    return ["linking_apps", name, "connect_url"];
  }, [name]);

  const inputVal = Form.useWatch(namePath);
  const isApple = /appstoreconnect.apple.com\/apps\/(\d*)\/appstore\/info/.test(inputVal);
  const isAndroid = /play.google.com\/console\/(.*)developers\/\d*\/app\/\d*\/app-dashboard(.*)/.test(inputVal);

  const onGetAppSummary = async () => {
    const value = _form?.getFieldValue(namePath);
    try {
      const data = await getAppSummary({
        resource: "apps/verify",
        values: {
          platform: isApple ? "IOS" : "ANDROID",
          connect_url: value,
        },
        successNotification: false,
        errorNotification: false,
      });

      const { name, ...attributes } = data?.data?.data?.attributes;
      setValues?.((values: any) => ({
        ...values,
        linking_apps: values?.linking_apps
          ? [...values?.linking_apps, { ...attributes, app_store_name: name }]
          : [{ ...attributes, app_store_name: name }],
      }));
    } catch (err) {
      console.error({ err });
    }
  };

  React.useEffect(() => {
    setFormLoading?.(isLoading);
  }, [isLoading, setFormLoading]);
  console.log({ isLoading });

  // Trigger validate connect url after verify
  React.useEffect(() => {
    data && _form?.validateFields([namePath]);
  }, [_form, data, name, namePath]);

  const _data = data?.data?.data?.attributes;
  const _savedData = _data || value;

  const isValidUrl = Boolean(bundleId || _data);

  return (
    <div className={`relative form-list-item mb-[3rem] ${fields.length - 1 === index ? "mb-0" : "mb-[3rem]"}`}>
      {!(firebasesCreateOptions && index === 0) && (
        <MinusCircleOutlined
          className={`self-baseline text-[16px] text-red-500 absolute top-[10px] right-[-30px]`}
          onClick={onRemove}
          disabled={isLoading}
        />
      )}
      <Form.Item
        fieldKey={keyField}
        name={[name, "connect_url"]}
        validateTrigger={["onChange", "onBlur"]}
        rules={[
          {
            required: true,
            validator: (_rules, value) => {
              const isValid =
                /appstoreconnect.apple.com\/apps\/(\d*)\/appstore\/info/.test(value) ||
                /play.google.com\/console\/(.*)developers\/\d*\/app\/\d*\/app-dashboard(.*)/.test(value);
              if (bundle_id) {
                return Promise.resolve();
              }
              if (isValid && isError) {
                return Promise.reject(new Error("Could not find bundle id. Please try another connect URL!"));
              }
              if (isValid && !_data) {
                return Promise.reject(new Error("Your connect URL hasn't verified yet. Please verify it!"));
              }
              if (isValid && _data) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Connect URL is not valid!"));
            },
          },
        ]}
        label={
          <div>
            <span>Connect URL</span>
            <TooltipCustom placement="top" title="Apple store connect URL or Play store connect URL" />
          </div>
        }
        className="mb-0"
      >
        <Input.Search
          placeholder="Paste your link here"
          css={css`
            cursor: ${isValidUrl ? "not-allowed" : "unset"};
            button {
              font-size: 12px !important;
              padding-inline: 23px;
              height: 30px;
            }
            input {
              pointer-events: ${isValidUrl ? "none" : "unset"};

              background: ${isValidUrl ? "#f5f5f5" : "white"};
            }
          `}
          enterButton={
            <Button disabled={isValidUrl || (!isApple && !isAndroid)} onClick={onGetAppSummary} loading={isLoading}>
              Verify App
            </Button>
          }
        />
      </Form.Item>
      {isValidUrl && (
        <BlockItem
          keyField={keyField}
          name={name}
          _savedData={_savedData}
          firebasesCreateOptions={firebasesCreateOptions}
          index={index}
          data={_data}
          _form={_form}
        />
      )}
      {_savedData && (
        <>
          <Form.Item fieldKey={keyField} name={[name, "bundle_id"]} hidden initialValue={_savedData?.bundle_id}>
            <Input />
          </Form.Item>
          <Form.Item fieldKey={keyField} name={[name, "icon"]} initialValue={_savedData?.icon} hidden>
            <Input />
          </Form.Item>
          <Form.Item fieldKey={keyField} name={[name, "platform"]} initialValue={_savedData?.platform} hidden>
            <Input />
          </Form.Item>
          <Form.Item fieldKey={keyField} name={[name, "app_store_name"]} initialValue={_savedData?.name} hidden>
            <Input />
          </Form.Item>
        </>
      )}
    </div>
  );
};
