const indigo = {
  50: "#EDF2FF",
  100: "#DBE4FF",
  200: "#BAC8FF",
  300: "#91A7FF",
  400: "#748FFC",
  500: "#5476FD",
  600: "#4A3BF8",
  700: "#0703D8",
  800: "#0F0AE2",
  900: "#001DA8",
};

export default indigo;
