import { useTable } from "@pankod/refine-antd";
import { HttpError, useResource } from "@pankod/refine-core";
import config from "config";
import { useMe } from "context/MeContext";
import useLocalStorage from "hooks/useLocalStorage";
import { IProduct, IUser } from "interfaces";

export const useProductTable = (studioId?: string) => {
  const [user] = useLocalStorage<IUser>("user");
  const { resource } = useResource();
  const { isMember, data: me } = useMe();
  console.log({ isMember });
  // hooks
  const resultTable = useTable<IProduct, HttpError, any>({
    resource: "products",
    initialPageSize: config.INITIAL_PAGE_SIZE,
    metaData: {
      populate: ["icon", "studios", "product_user_roles.users_permissions_user", "apps"],
    },
    initialSorter: [
      {
        field: "product_status",
        order: "asc",
      },
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    permanentFilter:
      me?.isOps || !isMember || resource === "studios"
        ? [
            {
              field: "studios][id]",
              operator: "eq",
              value: studioId,
            },
          ]
        : [
            {
              field: "studios][id]",
              operator: "eq",
              value: studioId,
            },
            {
              field: "product_user_roles][users_permissions_user][id]",
              operator: "eq",
              value: user.id,
            },
          ],

    queryOptions: {
      retry: 0,
    },
    onSearch: () => {
      return [];
    },
    defaultSetFilterBehavior: "replace",
    successNotification: false,
    errorNotification: false,
  });
  return resultTable;
};
