// interface
import { ISeriesSPLineChart } from "interfaces/chart";

// utils
import { sortBy } from "utils/sortBy";

// configs
import { DIMENSIONS, METRIC } from "../chartConfig";

// helpers
import { formatterLabelChart, getNameFomatter, getItemColor } from "./commonHelper";

export function mappingSeriesReversedSPLineChart(series: any[], dimension: string) {
  if (dimension === DIMENSIONS.EventName.value || dimension === DIMENSIONS.Source.value) {
    return series.reverse();
  }

  return series;
}

export function mappingOtherMetricBySPLine(splineData: any[], categories: string[], dimension: string, metric: string) {
  const firstItemCategory = categories[0];
  const categoriesSpliced = categories.slice(1);
  const splineSorted = [...splineData]
    .sort((a, b) => sortBy(a.date, b.date))
    .filter((item) => item.date !== firstItemCategory);

  const series: ISeriesSPLineChart[] = splineSorted.reduce((acc, currItem, currIndex) => {
    const index = categoriesSpliced.indexOf(currItem.date);
    const item = acc.find((item: any) => item?.orignialName === currItem[dimension]);
    let cost = currItem[metric] ?? 0;

    if (metric === METRIC.ConvRate.value) {
      const numberParsed: any = formatterLabelChart(cost, metric);
      const res = numberParsed.match(/(\d+\.?\d*)%/);
      cost = Number(res[1]);
    }

    if (item) {
      item.data[index] = cost;
    } else {
      acc.push({
        name: getNameFomatter(dimension, currItem[dimension]),
        orignialName: currItem[dimension],
        data: categoriesSpliced.map((_, i) => (i === index ? cost : 0)),
        color: getItemColor(dimension, currIndex, currItem),
      });
    }
    return acc;
  }, []);

  return {
    series,
    categories: categoriesSpliced,
  };
}
