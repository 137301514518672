import { Create, Form, Button } from "@pankod/refine-antd";
import { BreadcrumbAWS } from "components/Breadcrumb/BreadcrumbBucket";
import { LayoutCardTransparent } from "components/layout/layout/LayoutCardTransparent";
import { LayoutCreateProduct } from "components/layout/layout/LayoutCreateProduct";
import { useProductInfo } from "hooks/useProduct/useProductInfo";
import React from "react";
import { Option, useCreate, useNavigation } from "@pankod/refine-core";
import { ManualSetup } from "pages/Product/StepsForm/AppStep/ManualSetup";

const StoresCreate: React.FC = () => {
  const { push, goBack } = useNavigation();
  const [form] = Form.useForm();
  const formItemLayout = {
    labelCol: { span: 6 },
  };
  const { productInfo } = useProductInfo();
  const firebasesCreateOptions: Option[] =
    productInfo.firebases?.map((item) => {
      return {
        value: item.attributes?.project_id || "",
        label: item.attributes?.project_name || "",
      };
    }) || [];

  const { mutateAsync: createApps, isLoading } = useCreate();
  return (
    <LayoutCardTransparent>
      <Create
        breadcrumb={<BreadcrumbAWS />}
        title="Add Apps"
        headerProps={{
          onBack: goBack,
          extra: <></>,
        }}
        footerButtons={
          <>
            <Button
              onClick={() => {
                goBack();
              }}
            >
              Close
            </Button>
            <Button type="primary" loading={isLoading} onClick={() => form.submit()}>
              Add app
            </Button>
          </>
        }
      >
        <Form
          {...formItemLayout}
          className="mt-[30px]"
          form={form}
          onFinish={async (e) => {
            const manualApps = e.linking_apps?.map((item: any) => {
              const platformGenerator = item.platform === "ANDROID" ? "a" : "i";

              return {
                ...item,
                product_code: `${item.product_code.toUpperCase()}${platformGenerator}`,
              };
            });
            const res = await createApps({
              resource: `products/${productInfo.id}/apps`,
              values: manualApps,
              errorNotification(error: any) {
                return {
                  type: "error",
                  description: "Error",
                  message: error.message,
                };
              },
              successNotification: {
                type: "success",
                description: "Success",
                message: "New application is successfully created",
              },
            });
            if (res.data) {
              push(`/products/show/${productInfo.id}#Store`);
            }
          }}
        >
          <LayoutCreateProduct>
            <ManualSetup form={form} isCreate firebasesCreateOptions={firebasesCreateOptions} />
          </LayoutCreateProduct>
        </Form>
      </Create>
    </LayoutCardTransparent>
  );
};

export default StoresCreate;
