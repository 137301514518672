import React from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Button } from "@pankod/refine-antd";

// context
import { useIntegration } from "context/IntegrationContext";

// interface
import { CHART_TYPES, DIMENSIONS, METRIC } from "utils/chart/chartConfig";

// components
import ChartLTV from "./components/ChartLTV";
import DatePickerChart from "components/DatePicker/DatePickerChart";

// hooks
import useRangePicker from "hooks/useRangePicker";

function Chart() {
  // states
  const [forceUpdateChart, setForceUpdateChart] = React.useState(0);
  // hooks
  const { startDate, endDate, setDatePicker, datePickerRef } = useRangePicker();
  const { appId } = useParams();
  const { productInfo } = useIntegration();
  const appPayload = productInfo?.apps?.find((ele) => ele.id === appId);

  // initial date picker
  React.useEffect(() => {
    if (!appPayload) return;

    if (appPayload?.attributes?.disabled_at) {
      setDatePicker((prevState) => {
        const newDate = [...prevState];
        newDate[0] = moment(appPayload?.attributes?.disabled_at).subtract(7, "d").format("YYYY-MM-DD");
        newDate[1] = appPayload?.attributes?.disabled_at;
        datePickerRef.current = {
          endDate: appPayload?.attributes?.disabled_at,
        };
        setForceUpdateChart(Date.now());
        return newDate;
      });
    } else {
      setForceUpdateChart(Date.now());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appPayload]);

  function handleChangeDatePicker() {
    setForceUpdateChart(Date.now());
  }

  return (
    <>
      {forceUpdateChart ? (
        <div className="flex items-center">
          <DatePickerChart
            key={forceUpdateChart}
            defaultStartDate={startDate}
            defaultEndDate={endDate}
            setDatePicker={setDatePicker}
            datePickerRef={datePickerRef}
          />
          <Button size="middle" type="primary" className="ml-8" onClick={handleChangeDatePicker}>
            Show dashboard
          </Button>
        </div>
      ) : null}

      <br />

      <div className="w-full border border-solid border-[#E5E8ED] rounded-lg relative">
        {forceUpdateChart ? (
          <ChartLTV
            key={forceUpdateChart}
            app={appPayload}
            productId={productInfo.id}
            defaultDimension={DIMENSIONS.Source.value}
            defaultMetric={METRIC.Attributions.value}
            defaultChartType={CHART_TYPES.COLUMN}
            defaultStartDate={startDate}
            defaultEndDate={endDate}
            widgetChart="ltv-chart:0"
          />
        ) : null}
      </div>
      <div className="appsflyerChart flex justify-between mt-[24px]">
        <div className="appsflyerChart_box border border-solid border-[#E5E8ED] rounded-lg mr-[2%] relative">
          {forceUpdateChart ? (
            <ChartLTV
              key={forceUpdateChart}
              app={appPayload}
              productId={productInfo.id}
              defaultDimension={DIMENSIONS.MediaSource.value}
              defaultMetric={METRIC.Revenue.value}
              defaultChartType={CHART_TYPES.PIE}
              defaultStartDate={startDate}
              defaultEndDate={endDate}
              widgetChart="ltv-chart:1"
            />
          ) : null}
        </div>
        <div className="appsflyerChart_box border border-solid border-[#E5E8ED] rounded-lg relative">
          {forceUpdateChart ? (
            <ChartLTV
              key={forceUpdateChart}
              app={appPayload}
              productId={productInfo.id}
              defaultDimension={DIMENSIONS.EventName.value}
              defaultMetric={METRIC.EventCount.value}
              defaultChartType={CHART_TYPES.BAR}
              defaultStartDate={startDate}
              defaultEndDate={endDate}
              widgetChart="ltv-chart:2"
            />
          ) : null}
        </div>
      </div>
    </>
  );
}

export default Chart;
