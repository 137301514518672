import { IResourceComponentsProps, useNavigation, useNotification } from "@pankod/refine-core";
import { Button, Col, Create, Form, Row, useForm } from "@pankod/refine-antd";

import React, { useCallback, useEffect, useState } from "react";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { FormLayout } from "./components/FormLayout";
import { useUploadForm } from "hooks/useUploadForm";
import { UserFormItem } from "./components/FormLayout/UserFormItem";
import { UsersStudioRole } from "./type";
import { useMe } from "context/MeContext";
import { useUsers } from "hooks/useUsers/useUsers";
import { useUserRole } from "hooks/useUserRole";

type IUserRoleBody = {
  email: string;
  role_id: string;
};

export const StudioCreate: React.FC<IResourceComponentsProps> = () => {
  const { open } = useNotification();
  const [usersData, setUsersData] = useState<IUserRoleBody[]>([]);
  const { studioRolePermissions: optionsRole } = useUserRole();

  const { formProps, saveButtonProps, form, onFinish, mutationResult } = useForm<any>({
    redirect: false,
    onMutationError: (err) => {
      const res = err.response;
      const errDetails = res?.data?.error?.details?.errors;
      let message = "";
      if (Array.isArray(errDetails)) {
        if (errDetails.length === 1) {
          message = "Studio name already exists!";
        } else {
          message = `Couldn't create studio. Check all the inputs and try again.`;
        }
      }
      open?.({
        type: "error",
        description: "Error",
        message,
      });
    },
    errorNotification: false,
  });

  const { push, goBack } = useNavigation();
  const me = useMe();
  const { usersData: pmpUsers, queryResult } = useUsers({
    variables: {
      filters: {
        id: {
          notIn: [me.data?.id ?? ""],
        },
      },
      pagination: {
        page: 1,
        pageSize: 10000,
      },
    },
  });

  useEffect(() => {
    if (mutationResult?.isSuccess) {
      const id = mutationResult?.data?.data?.data?.id;
      const url = id ? "/studios/show/" + id : "/studios";
      push(url);
    }
  }, [mutationResult, push]);

  const { UploadForm, setLoading, loading } = useUploadForm();

  const setUsersRole = useCallback(
    (usersRole: UsersStudioRole[]) => {
      const mappingData = usersRole.map((o) => {
        const role = optionsRole?.find((role) => role.value === o.studio_role);
        return {
          email: o.email ?? "",
          role_id: role?.id ?? "",
        };
      });

      setUsersData(mappingData);
      form.setFieldsValue({
        usersRole: usersRole,
      });
    },
    [optionsRole, form],
  );

  return (
    <Create
      title="Create new Studio"
      actionButtons={false}
      saveButtonProps={{
        ...saveButtonProps,
        disabled: loading,
      }}
      pageHeaderProps={{
        onBack: () => push(`/studios`),
      }}
    >
      <Form
        {...formProps}
        layout="horizontal"
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        className="w-2/3 flex flex-col justify-center"
        onFinish={async (e) => {
          const input = mediaUploadMapper(e);
          await onFinish?.({ ...input, icon: input?.icon[0], usersRole: usersData });
        }}
        form={form}
      >
        <FormLayout>
          <UserFormItem
            usersData={
              pmpUsers?.map((user) => ({
                src: user.attributes?.icon ?? "",
                name: user.attributes?.name ?? "",
                email: user.attributes?.email ?? "",
                id: user.id ?? "",
              })) ?? []
            }
            isLoading={queryResult.fetching}
            setUsersRole={setUsersRole}
            optionsRole={optionsRole ?? []}
          />
          <UploadForm setLoading={setLoading} loading={loading} />
        </FormLayout>
        <Form.Item className="w-full flex justify-end">
          <Row>
            <Col span={24} style={{ textAlign: "right" }}>
              <Button
                className="mr-4"
                type="default"
                onClick={() => {
                  goBack();
                }}
              >
                Close
              </Button>
              <Button
                size="middle"
                type="primary"
                onClick={() => {
                  form.submit();
                }}
              >
                Create studio
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Create>
  );
};
