const grape = {
  50: "#F8F0FC",
  100: "#F3D9FA",
  200: "#EEBEFA",
  300: "#E599F7",
  400: "#DA77F2",
  500: "#CC5DE8",
  600: "#BE4BDB",
  700: "#AE3EC9",
  800: "#9C36B5",
  900: "#862E9C",
};

export default grape;
