import React from "react";
import { useCan, useResource } from "@pankod/refine-core";
import router from "@pankod/refine-react-router-v6";
import Page403, { Page403WithStudios } from "./403";
import Page404 from "./404";
import config from "config";

const CatchAll: React.FC = () => {
  const { resources, resource } = useResource();

  const params = router.useParams();
  const { data } = useCan({
    resource: resource.name,
    action: params?.action || "",
    params: { id: params?.id },
  });
  const pathsCustom: string[] = [];
  const pathsInResources = resources.map((item) => {
    return item.route || "";
  });
  const paths = pathsCustom.concat(pathsInResources);
  if (!paths.includes(params?.resource)) {
    return <Page404 />;
  }
  if (data && !data.can) {
    if (data.reason === config.CannotAccessProductWithStudio) {
      return <Page403WithStudios />;
    }
    return <Page403 message={config.UnAuthorizedActionErrorMessage} isShowBtn={false} />;
  }

  return <Page404 />;
};

export default CatchAll;
