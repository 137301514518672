import { Button, Create, Form, Icons, Modal, Steps, useModal } from "@pankod/refine-antd";
import { ProductStepFormProvider } from "context/ProductStepFormContext";
import React from "react";
import { GeneralInfoStep } from "./StepsForm/GeneralInfoStep";
import { IntegrationStep } from "./StepsForm/IntegrationStep";
import { Confirm } from "./StepsForm/ConfirmStep";
import { useStepsForm } from "sunflower-antd";
import { IIntegrationDataCreate } from "interfaces";
import { css } from "@emotion/react";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { useCreate, useList, useNavigation } from "@pankod/refine-core";
import { Enum_App_Status, Enum_Product_Status, Enum_Workflow_Phase_Name } from "schema/schema";
import { Link } from "react-router-dom";
import { LayoutCreateProduct } from "components/layout/layout/LayoutCreateProduct";
import { useMe } from "context/MeContext";
import AppStep from "./StepsForm/AppStep";
import { FirebaseStep } from "./StepsForm/FirebaseStep";

const { LoadingOutlined } = Icons;
export const productStatusOptions = [
  {
    value: Enum_Product_Status.Enabled,
    label: Enum_Product_Status.Enabled,
    text: Enum_Product_Status.Enabled,
  },
  {
    value: Enum_Product_Status.Disabled,
    label: Enum_Product_Status.Disabled,
    text: Enum_Product_Status.Enabled,
  },
];

export const appStatusOptions = [
  {
    value: Enum_App_Status.Active,
    label: Enum_App_Status.Active,
    text: Enum_App_Status.Active,
  },
  {
    value: Enum_App_Status.Development,
    label: Enum_App_Status.Development,
    text: Enum_App_Status.Development,
  },
  {
    value: Enum_App_Status.Inactive,
    label: Enum_App_Status.Inactive,
    text: Enum_App_Status.Inactive,
  },
];

export const ProductCreate: React.FC = () => {
  const { data: me } = useMe();
  const { push } = useNavigation();
  const [isErrorWorkflow, setIsErrorWorkflow] = React.useState(false);
  const [formLoading, setFormLoading] = React.useState(false);
  const [integrationData, setIntegrationData] = React.useState<IIntegrationDataCreate[]>([]);
  const [isManual, setIsManual] = React.useState(true);

  const { show, modalProps, close } = useModal();
  const { mutateAsync: createProduct, error, data, isLoading: isLoadingCreate } = useCreate();
  const [values, setValues] = React.useState<any>({});
  const { current, gotoStep, stepsProps, formProps, form } = useStepsForm({
    isBackValidate: false,
    async submit() {
      return;
    },
  });
  const updateValues = () => {
    const formValues = form.getFieldsValue() as any;
    let value = { ...values, ...formValues, integrations: integrationData };
    setValues(value);
  };
  const skipStep = () => {
    gotoStep(current + 1);
  };
  const formList = [
    <GeneralInfoStep />,
    <FirebaseStep />,
    <AppStep onSkipStep={skipStep} />,
    <IntegrationStep />,
    <Confirm />,
  ];
  const formItemLayout = {
    labelCol: { span: 6 },
  };
  const onSubmit = async () => {
    const { massUpload, users_temp, linking_apps_mass, ...input } = mediaUploadMapper(values);
    show();
    const manualApps = input.linking_apps?.map((item: any) => {
      const platformGenerator = item.platform === "ANDROID" ? "a" : "i";
      return {
        ...item,
        product_code: `${item.product_code.toUpperCase()}${platformGenerator}`,
      };
    });
    const linking_apps = !isManual ? linking_apps_mass : manualApps;
    const firebase_configurations = values?.firebase_configurations?.map((item: any) => {
      const isNew = item.type === "new";
      return isNew
        ? {
            name: item.name,
            is_request_billing: Boolean(item.is_request_billing),
            project_id: item.project_id,
          }
        : { console_url: item.console_url };
    });
    const users = values?.users?.filter((item: any) => item.email !== me?.email);
    await createProduct({
      resource: "products",
      values: {
        ...input,
        linking_apps,
        firebase_configurations,
        product_code: input.product_code.toUpperCase(),
        users,
      },
      successNotification: false,
    });
  };

  const workflow_id = data?.data?.data?.attributes?.workflow_id;

  const { data: dataWorkflows } = useList({
    resource: "workflows",
    queryOptions: {
      refetchInterval: !isErrorWorkflow ? 3600 : false,
      enabled: Boolean(workflow_id),
    },
    config: {
      filters: [
        {
          field: "workflow_id",
          operator: "eq",
          value: workflow_id,
        },
      ],
    },
    successNotification: false,
    errorNotification: false,
  });
  const workflows = dataWorkflows?.data;
  const workflows_mapping = [
    {
      phase_name: Enum_Workflow_Phase_Name.FirebaseConfigurations,
      title: "Firebase config",
    },
    {
      phase_name: Enum_Workflow_Phase_Name.LinkingApps,
      title: "Link app",
    },
    {
      phase_name: Enum_Workflow_Phase_Name.Integrations,
      title: "Integrations",
    },
  ];
  const getDataWorkflowByPhaseName = React.useCallback(
    (phase_name: Enum_Workflow_Phase_Name) => {
      const workflow = workflows?.find((item) => item.phase_name === phase_name);
      if (workflow?.error) {
        setIsErrorWorkflow(true);
      }
      return workflow;
    },
    [workflows],
  );
  React.useEffect(() => {
    let timeoutID: NodeJS.Timeout;
    if (workflows?.length === 0) {
      timeoutID = setTimeout(() => {
        window.location.href = `/products/show/${data?.data.data?.attributes?.product_id}`;
      }, 3600 * 2);
    }
    return () => {
      // 👇️ clear timeout when component unmounts
      clearTimeout(timeoutID);
    };
  }, [data?.data.data?.attributes?.product_id, workflows]);

  const isLoading = isLoadingCreate;

  const onCloseProgressBar = () => {
    !error
      ? Modal.confirm({
          title: `Are you sure you want to close?`,
          okText: "Yes",
          cancelText: "No",
          content: "After close this progress bar we will redirect you to product listing page",
          onOk: () => {
            close();
            push("/products");
          },
        })
      : close();
  };

  return (
    <Create footerButtons={<></>} goBack={<></>}>
      <Steps
        {...stepsProps}
        onChange={() => {
          return;
        }}
      >
        <Steps.Step title="General Info" />
        <Steps.Step title="Firebase config" />
        <Steps.Step title="Link app" />
        <Steps.Step title="Integration" />
        <Steps.Step title="Confirmation" />
      </Steps>
      <ProductStepFormProvider
        values={values}
        setValues={setValues}
        current={current}
        integrationData={integrationData}
        setIntegrationData={setIntegrationData}
        form={form}
        setFormLoading={setFormLoading}
        formLoading={formLoading}
        setIsManual={setIsManual}
        isManual={isManual}
      >
        <Form
          {...formProps}
          {...formItemLayout}
          className="mt-[30px]"
          initialValues={{
            ...formProps.initialValues,
            users_temp: [me?.email],
          }}
          form={form}
        >
          <LayoutCreateProduct isIntegrationStep={current === 3}>
            {formList[current]}
            <div className="flex justify-end space-x-2">
              {current > 0 && (
                <Button
                  className="bg-gray-400 text-white border-0"
                  onClick={() => {
                    gotoStep(current - 1);
                    if (current === 1) {
                      // firebase step
                      updateValues();
                    }
                  }}
                  disabled={formLoading}
                >
                  Previous
                </Button>
              )}
              {current < formList.length - 1 && (
                <Button
                  className="bg-[#1890ff] text-white border-0"
                  onClick={() => {
                    updateValues();
                    gotoStep(current + 1);
                  }}
                  disabled={formLoading}
                >
                  Next
                </Button>
              )}
              {current === formList.length - 1 && (
                <Button className="bg-[#1890ff] text-white border-0" onClick={onSubmit}>
                  Create product
                </Button>
              )}
            </div>
          </LayoutCreateProduct>
        </Form>
        <Modal
          {...modalProps}
          centered
          className="rounded-[10px] shadow-md max-w-[750px] w-full"
          title={null}
          maskClosable={false}
          okButtonProps={{
            hidden: true,
          }}
          closeIcon={<></>}
          cancelText="Close"
          onCancel={onCloseProgressBar}
        >
          {workflows && workflows.length > 0 ? (
            <p>Creating product ... Please wait a bit.</p>
          ) : workflows?.length === 0 ? (
            <p>
              Successfully created product. Redirecting to detail page... <LoadingOutlined spin />
            </p>
          ) : (
            error && (
              <>
                {error && <p className="text-danger mb-1">{error.message}</p>}
                <p className="text-danger">
                  Could not create product. Please contact admin for more details or go back to{" "}
                  <Link to="/">homepage</Link>
                </p>
              </>
            )
          )}

          <Steps
            onChange={() => {
              return;
            }}
            className=" w-full"
            css={css`
              .ant-steps-item-icon {
                width: 32px !important;
                height: 32px !important;
              }
            `}
          >
            <Steps.Step status={error ? "error" : data ? "finish" : "process"} title="General Info" />
            {workflows_mapping.map((item, index) => {
              const workflow = getDataWorkflowByPhaseName(item.phase_name);
              return (
                <Steps.Step
                  key={index}
                  icon={
                    ((workflow && !workflow?.error) || isLoading) && <LoadingOutlined className="text-white" spin />
                  }
                  status={workflow?.error ? "error" : workflow || isLoading ? "process" : error ? "wait" : "finish"}
                  title={item.title}
                />
              );
            })}
          </Steps>
          <p className="mt-3 mb-0">You can close the progress bar & view created product in Product Listings later</p>
        </Modal>
      </ProductStepFormProvider>
    </Create>
  );
};
