import { useOne } from "@pankod/refine-core";
import { useProductInfo } from "hooks/useProduct/useProductInfo";
import { IStore } from "interfaces";
import { useParams } from "react-router-dom";
import { Enum_Integration_Service } from "schema/schema";

export const useApp = () => {
  const params = useParams();
  const appId = params.appId || "";
  const { productInfo, productResult, refetchProduct, isLoadingProduct } = useProductInfo();
  const { data, isLoading, isFetching, isStale } = useOne<IStore>({
    resource: "apps",
    id: appId,
    metaData: {
      populate: ["firebase"],
    },
    queryOptions: {
      enabled: Boolean(appId),
    },
  });
  const app = data?.data;
  const getPayload = (serviceName: Enum_Integration_Service) => {
    return productInfo.integrationPayloads?.data.find(
      (v) => v.attributes?.integration?.data?.attributes?.service === serviceName,
    )?.attributes?.payload;
  };

  return {
    app,
    isLoading,
    isFetching,
    getPayload,
    isLoadingProduct,
    productResult,
    isStaleApp: isStale,
    refetchPayload: refetchProduct,
  };
};
