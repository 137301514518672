import { IProduct } from "interfaces";
import {
  AppEntityFragment,
  Enum_Integration_Service,
  Enum_Productuserrole_Status,
  Enum_Product_Status,
  FileFragment,
  FirebaseEntity,
  ProductEntityFragment,
  StudioEntityFragment,
  UserEntityFragment,
} from "schema/schema";
import { getImageInfoFromFile } from "./Image";

export interface UsersProductProps {
  id: string;
  roleId?: string | null;
  user?: UserEntityFragment | null;
  role?: string;
  userId?: string | null;
  status?: Enum_Productuserrole_Status | null;
}
class ProductInfo {
  constructor(protected product: ProductEntityFragment | null | undefined) {}

  get id(): string {
    return this.product?.id || "";
  }

  get name(): string {
    return this.product?.attributes?.name ?? "";
  }

  get productCode(): string {
    return this.product?.attributes?.product_code ?? "";
  }

  get description(): string {
    return this.product?.attributes?.description ?? "";
  }

  get isDisabled() {
    return this.product?.attributes?.status === Enum_Product_Status.Disabled;
  }

  get iconFile(): FileFragment | undefined | null {
    return this.product?.attributes?.icon.data;
  }

  get icon(): { url: string | undefined; alt: string | undefined } | undefined {
    return getImageInfoFromFile(this.iconFile);
  }

  get status(): Enum_Product_Status | undefined {
    return this.product?.attributes?.status;
  }

  get studios(): StudioEntityFragment[] | undefined {
    return this.product?.attributes?.studios?.data;
  }

  get firebases(): FirebaseEntity[] {
    return this.product?.attributes?.firebases?.data || [];
  }

  get integrationPayloads() {
    return this.product?.attributes?.integration_payloads;
  }

  get awsIntegrationPayload() {
    return this.product?.attributes?.integration_payloads?.data.find(
      (item) => item.attributes?.integration?.data?.attributes?.service === Enum_Integration_Service.Aws,
    );
  }

  get awsPayload() {
    return this.awsIntegrationPayload?.attributes?.payload;
  }

  get _prefix(): string | undefined | null {
    return !this.awsPayload?.prefix?.endsWith("/") ? this.awsPayload?.prefix + "/" : this.awsPayload?.prefix;
  }

  get prefix(): string | undefined | null {
    return this.awsPayload?.prefix?.endsWith("/") ? this.awsPayload?.prefix.slice(0, -1) : this.awsPayload?.prefix;
  }

  get users(): UsersProductProps[] | undefined {
    return this.product?.attributes?.product_user_roles?.data.map((item) => {
      return {
        id: item.id!,
        user: item.attributes?.users_permissions_user?.data,
        userId: item.attributes?.users_permissions_user?.data?.id,
        status: item.attributes?.status,
        role: item.attributes?.role?.data?.attributes?.name,
      };
    });
  }

  get apps(): AppEntityFragment[] | undefined {
    return this.product?.attributes?.apps?.data;
  }

  get productUserRoles() {
    return this.product?.attributes?.product_user_roles;
  }
}

export class IProductInfo {
  constructor(protected product: IProduct) {}
  get awsIntegrationPayload() {
    return this.product?.integration_payloads?.find(
      (item) => item.integration?.service === Enum_Integration_Service.Aws,
    );
  }

  get awsPayload() {
    return this.awsIntegrationPayload?.payload;
  }
}

export default ProductInfo;
