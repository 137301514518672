import ProductInfo from "factory/Product";
import React from "react";
import { createContext, ReactNode, useContext } from "react";
import { useProductQuery } from "schema/schema";
import { useParams } from "react-router-dom";
import { OperationContext } from "urql";

interface IntegrationContextValue {
  productInfo: ProductInfo;
  isLoadingProduct?: boolean;
  refetchProduct: (opts?: Partial<OperationContext> | undefined) => void;
}

const IntegrationContext = createContext<IntegrationContextValue>({} as IntegrationContextValue);

export const useIntegration = () => {
  return useContext(IntegrationContext);
};

type Props = {
  children: ReactNode;
};

export const IntegrationProvider = ({ children }: Props) => {
  const params = useParams();
  const productId = params.productId || "";
  const [productResult, refetchProduct] = useProductQuery({
    variables: {
      id: productId,
    },
    requestPolicy: "cache-and-network",
    pause: !Boolean(productId),
  });

  const productData = productResult?.data?.product?.data;
  const productInfo = new ProductInfo(productData);
  const value = {
    productInfo,
    isLoadingProduct: productResult.fetching || productResult.stale,
    refetchProduct,
  };

  return (
    <>
      <IntegrationContext.Provider value={value}>{children}</IntegrationContext.Provider>
    </>
  );
};
