import { Breadcrumb } from "antd";
import { useNavigation } from "@pankod/refine-core";
import { useProductInfo } from "hooks/useProduct/useProductInfo";
import React from "react";

export type BreadcrumbItem = {
  title: string;
  link: string;
  type: string;
  disabled?: boolean;
};

export interface BreadcrumbBucketProps {
  breadcrumbItems: BreadcrumbItem[];
  productId: string;
}
export const BreadcrumbBucket: React.FC<BreadcrumbBucketProps> = ({ breadcrumbItems }) => {
  const { push } = useNavigation();
  return (
    <Breadcrumb className="my-4">
      {breadcrumbItems.map((item, key) => {
        return (
          <Breadcrumb.Item
            key={key}
            className="cursor-pointer"
            onClick={() => {
              if (item.disabled) return;
              if (item.type === "prefix") {
                push(`?prefix=${item.link}`);
              }
              if (item.type === "rootPrefix") {
                push(``);
              }
            }}
          >
            {item.title}
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

export const BreadcrumbAWS: React.FC = () => {
  const { push } = useNavigation();
  const { productInfo, productId } = useProductInfo();

  const items = React.useMemo(() => {
    return [
      {
        title: "Home",
        to: `/`,
      },
      {
        title: "Products",
        to: `/products`,
      },
      {
        title: productInfo.name,
        to: `/products/show/${productId}`,
      },
    ];
  }, [productId, productInfo.name]);
  return React.useMemo(() => {
    return (
      <Breadcrumb>
        {items.map((item, index) => {
          return (
            <Breadcrumb.Item className="cursor-pointer" key={index} onClick={() => push(item.to)}>
              {item.title}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    );
  }, [items, push]);
};
