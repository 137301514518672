import { css } from "@emotion/react";
import { Button, Form, Icons, Input } from "@pankod/refine-antd";
import { useList, useUpdate } from "@pankod/refine-core";
import { TooltipCustom } from "components/Input/Tooltip";
import config from "config";
import { useMe } from "context/MeContext";
import { EnumRoleProduct } from "enums";
import { useBucketLocation } from "hooks/useBucket/useBucketLocation";
import { useCreateCloudfront } from "hooks/useBucket/useCreateCloudFront";
import { useGetMyRole } from "hooks/useGetMyRole";
import { useProductInfo } from "hooks/useProduct/useProductInfo";
import { IIntegration, IIntegrationPayload } from "interfaces";
import React from "react";
const { EditOutlined, CloseOutlined } = Icons;
export const AwsEdit: React.FC = () => {
  const [isEditBucket, setIsEditBucket] = React.useState(false);
  const [isEditCloudfront, setIsEditCloudfront] = React.useState(false);
  const [isEditDistribution, setIsEditDistribution] = React.useState(false);

  const [form] = Form.useForm();
  const bucket = Form.useWatch("bucket", form);
  const { productInfo, refetchProduct, productId } = useProductInfo();
  const awsIntegrationPayload = productInfo.awsIntegrationPayload;
  const awsPayload = productInfo.awsPayload;
  const bucketName = awsPayload?.bucket;
  const isDefaultBucket = bucketName === config.BUCKET_NAME;
  const { mutateAsync: updateAWS, isLoading } = useUpdate<IIntegrationPayload>();
  const { data } = useList<IIntegration>({
    resource: "integrations",
    config: {
      filters: [
        {
          field: "service",
          operator: "eq",
          value: "AWS",
        },
      ],
    },
    metaData: {
      populate: ["icon"],
    },
  });
  const { region } = useBucketLocation({
    bucketName,
    productId,
  });
  const { CreateCloudfrontElement, onCreateCloudfront, isLoadingCloudfront, CreateInvalidationElement } =
    useCreateCloudfront({
      bucketName,
      productInfo,
      region,
      refetchObjects: () => {},
      refetchProduct,
    });

  React.useEffect(() => {
    form.resetFields();
  }, [form, awsPayload]);
  const onUpdateAWS = async (e: any) => {
    if (!awsIntegrationPayload?.id) return;
    const res = await updateAWS({
      resource: "integration-payloads",
      id: awsIntegrationPayload?.id,
      values: {
        payload: {
          ...awsPayload,
          cloudfront: e.cloudfront || awsPayload?.cloudfront,
          distributionId: e.distributionId || awsPayload?.distributionId,
        },
      },
      successNotification: {
        type: "success",
        description: "Success",
        message: "AWS information is successfully updated",
      },
      errorNotification: {
        type: "error",
        description: "Error",
        message: `Fail to update AWS information`,
      },
    });
    if (res.data) {
      refetchProduct();
      setIsEditBucket(false);
      setIsEditCloudfront(false);
      setIsEditDistribution(false);
    }
  };
  const { userRoleProductPermission: userRolePermission } = useGetMyRole();
  const { isMember } = useMe();
  const isViewer = userRolePermission === EnumRoleProduct.Viewer;
  const isDisabled = !(!productInfo.isDisabled || !isViewer || !isMember);
  React.useEffect(() => {
    isEditBucket && bucket !== config.BUCKET_NAME && form.resetFields(["cloudfront"]);
  }, [bucket, form, isEditBucket]);
  const item = data?.data[0];
  if (!item) return null;

  return (
    <div
      className="flex justify-between"
      css={css`
        .ant-form-item-label {
          width: 180px !important;
        }
      `}
    >
      <Form
        form={form}
        initialValues={{
          bucket: bucketName,
          cloudfront: isEditBucket && bucket !== config.BUCKET_NAME ? "" : awsPayload?.cloudfront,
          distributionId: awsPayload?.distributionId,
        }}
        onFinish={(e) => {
          onUpdateAWS(e);
        }}
        className="w-1/2"
      >
        <Form.Item
          label={
            <div className="font-bold">
              <strong>S3 - Bucket name</strong>{" "}
              <TooltipCustom title="If this bucket not working you have to contact the administrator to update it"></TooltipCustom>
            </div>
          }
          name="bucket"
          rules={[{ required: true, message: "Bucket name is required!" }]}
          className={"mb-1"}
        >
          {bucketName}
        </Form.Item>
        <Form.Item
          label={
            <div className="font-bold">
              <strong>Prefix</strong>
            </div>
          }
          name="bucket"
          className={"mb-1"}
        >
          {awsPayload?.prefix}
        </Form.Item>
        <Form.Item
          label={
            <div>
              <b>Cloudfront domain</b>
              <TooltipCustom
                title={
                  <p className="mb-0 mr-2">
                    Go to this{" "}
                    <a
                      target={"_blank"}
                      href="https://us-east-1.console.aws.amazon.com/cloudfront/v3/home?region=ap-southeast-1#/distributions"
                      rel="noreferrer"
                    >
                      link
                    </a>{" "}
                    to find your cloudfront
                  </p>
                }
              ></TooltipCustom>
            </div>
          }
          name="cloudfront"
          className={"mb-1"}
          rules={[
            {
              required: bucket === config.BUCKET_NAME || !isEditCloudfront ? false : true,
              message: "Cloudfront domain is required!",
            },
          ]}
        >
          {isEditCloudfront || (isEditBucket && bucket !== config.BUCKET_NAME) ? (
            <Input
              disabled={isLoading}
              placeholder="Enter your cloudfront"
              suffix={<CloseOutlined className="cursor-pointer" onClick={() => setIsEditCloudfront(false)} />}
            />
          ) : (
            awsPayload?.cloudfront && (
              <span>
                {awsPayload?.cloudfront}{" "}
                {!isDefaultBucket && (
                  <EditOutlined
                    className={isDisabled ? `text-gray-500 cursor-default` : `ml-4 cursor-pointer`}
                    onClick={() => (isDisabled ? () => {} : setIsEditCloudfront(true))}
                  />
                )}
              </span>
            )
          )}
        </Form.Item>
        <div className="flex">
          <Form.Item
            label={
              <div>
                <b>DistributionId </b>
                <TooltipCustom
                  title={
                    <p className="mb-0 mr-2">
                      Go to this{" "}
                      <a
                        target={"_blank"}
                        href="https://us-east-1.console.aws.amazon.com/cloudfront/v3/home?region=ap-southeast-1#/distributions"
                        rel="noreferrer"
                      >
                        link
                      </a>{" "}
                      to find your cloudfront
                    </p>
                  }
                ></TooltipCustom>
              </div>
            }
            name="distributionId"
            className={"mb-1"}
          >
            {isEditDistribution || (isEditBucket && bucket !== config.BUCKET_NAME) ? (
              <Input
                disabled={isLoading}
                placeholder="Enter your distributionId"
                suffix={<CloseOutlined className="cursor-pointer" onClick={() => setIsEditDistribution(false)} />}
              />
            ) : (
              <span>
                {awsPayload?.distributionId}{" "}
                {!isDefaultBucket && (
                  <EditOutlined
                    className={isDisabled ? `text-gray-500 cursor-default` : `ml-4 cursor-pointer`}
                    onClick={() => (isDisabled ? () => {} : setIsEditDistribution(true))}
                  />
                )}
              </span>
            )}
          </Form.Item>
          <CreateInvalidationElement isLoading={false} />
        </div>
        {!isDisabled && (
          <>
            <CreateCloudfrontElement
              onClick={onCreateCloudfront}
              isLoading={isLoadingCloudfront}
              isDefaultBucket={isDefaultBucket}
            />
          </>
        )}
      </Form>
      <Button onClick={form.submit} disabled={!(isEditBucket || isEditCloudfront || isEditDistribution)} type="primary">
        Save
      </Button>
    </div>
  );
};
