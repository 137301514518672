import { useCan } from "@pankod/refine-core";
import ProductInfo from "factory/Product";
import React, { useContext } from "react";
import { createContext } from "react";
import { useParams } from "react-router-dom";
import { useProductQuery } from "schema/schema";

import { IPropsWithChildren } from "interfaces";

interface ProductContextProps {
  productInfo: ProductInfo;
  refetchProduct: () => void;
  canDelete?: boolean;
  isLoading: boolean;
  isStale: boolean;
}

export const ProductContext = createContext<ProductContextProps>({} as ProductContextProps);

export const useProduct = () => {
  return useContext(ProductContext);
};

export const ProductContextProvider: React.FC<IPropsWithChildren> = ({ children }) => {
  const params = useParams();
  const productId = params.productId || params.id;
  const [productResult, refetchProduct] = useProductQuery({
    variables: {
      id: productId || "",
    },
    requestPolicy: "network-only",
    pause: !Boolean(productId),
  });
  const onRefetchProduct = () => {
    refetchProduct({
      requestPolicy: "cache-and-network",
    });
  };
  const { data } = useCan({
    resource: "products",
    action: "delete",
    params: { id: params?.id },
  });
  const canDelete = data?.can;
  const product = productResult.data?.product?.data;
  const productInfo = new ProductInfo(product);

  const value = {
    refetchProduct: onRefetchProduct,
    canDelete,
    productInfo,
    isLoading: productResult.fetching,
    isStale: productResult.stale,
  };

  return <ProductContext.Provider value={value}>{children}</ProductContext.Provider>;
};
