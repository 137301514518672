import { AndroidOutlined, AppleOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { Form, FormInstance, Input, Select } from "@pankod/refine-antd";
import { Option, useList } from "@pankod/refine-core";
import { FormItemHiddenLabel } from "components/Form/FormItemHiddenLabel";
import { useProductStepForm } from "context/ProductStepFormContext";
import { useDebounce } from "hooks/useDebounce";
import useMarkets from "hooks/useMarkets";
import { useProductInfo } from "hooks/useProduct/useProductInfo";
import { appStatusOptions } from "pages/Product/create";
import React, { useState } from "react";

interface BlockItemProps {
  firebasesCreateOptions?: Option[];
  _savedData: any;
  keyField: number;
  name: number;
  index: number;
  data: any;
  _form?: FormInstance<any>;
}

export const BlockItem: React.FC<BlockItemProps> = ({
  firebasesCreateOptions,
  _savedData,
  keyField,
  name,
  index,
  data,
  _form,
}) => {
  const { values, setValues } = useProductStepForm();
  const { productInfo } = useProductInfo();

  const optionsMarket = useMarkets();
  const firebases = values?.firebase_configurations;
  const value = values?.linking_apps && values.linking_apps[index];
  const optionsFirebase: Option[] | undefined =
    firebases?.map((item: any) => {
      const isNew = item.type === "new";
      return {
        value: isNew ? item.project_id : item.project_id_curr,
        label: isNew ? item.name : item.name_curr,
      };
    }) || firebasesCreateOptions;
  const namePath = React.useMemo(() => {
    return ["linking_apps", name, "product_code"];
  }, [name]);

  const defaultMarket = "US";
  const platformGenerator = data?.platform === "ANDROID" ? "a" : "i";
  const pProductCode = values?.product_code || productInfo?.productCode || "";
  const appCodeGenerator = value?.product_code || `${pProductCode.toUpperCase()}${defaultMarket.toLocaleUpperCase()}`;
  const [appCode, setAppCode] = useState<string>(appCodeGenerator);

  const [isTouchedAppCode, setIsTouchedAppCode] = useState(false);
  const debouncedAppCode = useDebounce(appCode, 1000);
  const {
    refetch: checkUniqueAppCode,
    isFetching: isCheckUniqueAppCodeLoading,
    data: dataCheckUniqueAppCode,
  } = useList({
    resource: "apps",
    config: {
      filters: [
        {
          field: "product_code",
          operator: "eq",
          value: appCode + platformGenerator,
        },
      ],
    },
    queryOptions: {
      enabled: false,
    },
    successNotification: false,
    errorNotification: false,
  });

  const isUniqueAppCode = Boolean(dataCheckUniqueAppCode?.data.length === 0);

  React.useEffect(() => {
    if (!value?.confirm && !isUniqueAppCode && !isTouchedAppCode && dataCheckUniqueAppCode) {
      setAppCode((a) => a + "1");
    }
  }, [_form, dataCheckUniqueAppCode, isTouchedAppCode, isUniqueAppCode, namePath, value?.confirm]);

  React.useEffect(() => {
    if (!isTouchedAppCode && isUniqueAppCode && appCode) {
      _form?.resetFields([namePath]);
    }
  }, [_form, isTouchedAppCode, isUniqueAppCode, namePath, appCode]);

  React.useEffect(() => {
    debouncedAppCode && checkUniqueAppCode();
  }, [checkUniqueAppCode, debouncedAppCode, dataCheckUniqueAppCode]);

  React.useEffect(() => {
    !isCheckUniqueAppCodeLoading && _form?.validateFields([namePath]);
  }, [_form, namePath, isCheckUniqueAppCodeLoading]);

  React.useEffect(() => {
    if (data) {
      setValues?.((values: any) => ({
        ...values,
        linking_apps: values?.linking_apps,
      }));
    }
    // eslint-disable-next-line
  }, [data]);

  React.useEffect(() => {
    if (!value?.product_code) {
      _form?.resetFields([namePath]);
    }
  }, [_form, namePath, value?.product_code]);

  const getValidateStatus = React.useCallback(() => {
    let status: "" | "error" | "success" | "warning" | "validating" | undefined = "error";
    if (isCheckUniqueAppCodeLoading) {
      status = "validating";
      return status;
    }
    status = isUniqueAppCode ? "success" : "error";
    return status;
  }, [isCheckUniqueAppCodeLoading, isUniqueAppCode]);
  const validateStatus = getValidateStatus();
  return (
    <>
      <FormItemHiddenLabel className="mt-[10px]">
        <div className="px-6 py-4 bg-ocean rounded flex justify-between items-center">
          <div className="flex">
            <img width={50} src={_savedData?.icon} alt="" />
            <div className="flex flex-col justify-center ml-2">
              <p className="mb-1 font-bold">{_savedData?.name || _savedData?.app_store_name}</p>
              <p className="mb-0 italic text-[10px]">{_savedData?.bundle_id}</p>
            </div>
          </div>
          <div className="flex flex-col border-l items-center w-[90px] pl-4">
            {_savedData?.platform === "IOS" ? (
              <AppleOutlined className="text-[30px]" />
            ) : (
              <AndroidOutlined className="text-[30px]" />
            )}
            <span className="text-[11px]">{_savedData?.platform === "IOS" ? "Apple" : "Google"} Store</span>
          </div>
        </div>
      </FormItemHiddenLabel>
      <Form.Item
        fieldKey={keyField}
        label={<>Firebase</>}
        name={[name, "project_id"]}
        rules={[{ required: true, message: "Firebase is required!" }]}
      >
        <Select options={optionsFirebase} />
      </Form.Item>
      <Form.Item
        fieldKey={keyField}
        label="App name"
        name={[name, "name"]}
        initialValue={_savedData?.name || _savedData?.app_store_name}
        rules={[{ required: true, max: 30, min: 4, message: "App name must be between 4 and 30 characters" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        fieldKey={keyField}
        label="Market"
        name={[name, "market"]}
        rules={[{ required: true }]}
        initialValue={defaultMarket}
      >
        <Select
          options={optionsMarket}
          filterOption={(input, option) =>
            (option!.label as unknown as string).toLowerCase().includes(input.toLowerCase())
          }
          showSearch
        />
      </Form.Item>
      <Form.Item
        fieldKey={keyField}
        label="Status"
        name={[name, "status"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select options={appStatusOptions} placeholder="Select status" />
      </Form.Item>

      <Form.Item
        initialValue={appCode}
        fieldKey={keyField}
        label="App code"
        name={[name, "product_code"]}
        validateTrigger={["onBlur", "onChange"]}
        hasFeedback
        validateStatus={validateStatus}
        rules={[
          {
            required: true,
          },
          () => ({
            validator: () => {
              if (isUniqueAppCode) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("App code must be unique"));
            },
          }),
        ]}
      >
        <Input
          onChange={(e) => {
            setAppCode(e.target.value);
            !isTouchedAppCode && setIsTouchedAppCode(true);
          }}
          css={css`
            input {
              text-transform: uppercase;
            }
          `}
          suffix={
            <span className="mr-3 border-l border-r items-center pl-2 flex bg-gray-100 h-full w-[100px] ">
              {appCode.toUpperCase() || appCodeGenerator}
              {platformGenerator}
            </span>
          }
        />
      </Form.Item>
    </>
  );
};
