import { KPIS_TYPES, CHART_TYPES, KPIS, EVENT_KPIS } from "utils/chart/chartConfig";
import { isAString } from "utils/chart/chartHelper/commonHelper";

/**
 * Used in order to get the unsupported chartTypes for a specific kpi key.
 *
 * @param isEventKpi
 * @param {string} kpiKey
 * @return {array} of not supported chartTypes
 */

const allEventKpisValues = Object.values(EVENT_KPIS).map((ele: any) => ele?.value);

function getUnSupportedChartTypes({ isEventKpi = false, kpiKey = "" }) {
  if (!isAString(kpiKey)) throw new Error("kpiKey param must be a valid string");

  const unsupportedChartTypes = [];

  switch (kpiKey) {
    case KPIS_TYPES.TOUCHPOINTS:
      unsupportedChartTypes.push(CHART_TYPES.BAR, CHART_TYPES.PIE);
      break;

    case KPIS.CONV_RATE:
    case KPIS.AVG_ECPI:
      unsupportedChartTypes.push(CHART_TYPES.COLUMN, CHART_TYPES.PIE);
      break;

    case KPIS.REVENUE:
      unsupportedChartTypes.push(CHART_TYPES.SPLINE, CHART_TYPES.COLUMN);
      break;

    case KPIS.ROI:
    case KPIS.ROAS:
    case KPIS.ARPU:
    case KPIS.GROSS_PROFIT:
    case KPIS.ASSISTS:
      unsupportedChartTypes.push(CHART_TYPES.SPLINE, CHART_TYPES.COLUMN, CHART_TYPES.PIE);
      break;

    case KPIS.ROI_AW:
    case KPIS.ROAS_AW:
    case KPIS.ARPU_AW:
      unsupportedChartTypes.push(CHART_TYPES.COLUMN, CHART_TYPES.PIE);
      break;

    case KPIS.GROSS_PROFIT_AW:
      unsupportedChartTypes.push(CHART_TYPES.COLUMN);
      break;

    default:
      if (isEventKpi && allEventKpisValues.includes(kpiKey))
        unsupportedChartTypes.push(CHART_TYPES.SPLINE, CHART_TYPES.COLUMN);
      if ([EVENT_KPIS.ECPA, EVENT_KPIS.CONVERSION_RATE, EVENT_KPIS.INSTALL_RATE].includes(kpiKey))
        unsupportedChartTypes.push(CHART_TYPES.PIE);
  }

  return unsupportedChartTypes;
}

export { getUnSupportedChartTypes };
