import dayjs from "dayjs";

// interface
import { IExtractMetric, IAggregatedArray, ISortDimension, ITempColumn, ITopList } from "interfaces/chart";

// themes
import { palette, othersColor, systemColors } from "themes";

// utils
import { sortBy } from "utils/sortBy";

// configs
import { KPIS_TYPES, KPIS } from "../chartConfig";

// helpers
import { getLabel } from "./commonHelper";

const extractMetric = ({ data, metric, dimension }: IExtractMetric) => {
  const aggregatedArray: IAggregatedArray = {};
  data.forEach((item: any) => {
    if (aggregatedArray[item[dimension]]) {
      aggregatedArray[item[dimension]] = item[metric] + (aggregatedArray[item[dimension]] || 0);
    } else {
      aggregatedArray[item[dimension]] = item[metric];
    }
  });

  return aggregatedArray;
};

export const sortDimensions = ({ data, metric, dimension }: ISortDimension) => {
  const aggregatedArray = extractMetric({ data, metric, dimension });
  if (Object.keys(aggregatedArray).every((item) => item === "undefined")) return [];

  const sortedArray = Object.keys(aggregatedArray)
    .filter((x) => aggregatedArray[x])
    .map((item) => ({ [item]: aggregatedArray[item] }))
    .sort((a, b: any) => (Object.values(b) as any) - (Object.values(a) as any));

  return sortedArray;
};

export const getTopList = ({ sortedDimList, metric }: ITopList): string[] => {
  if (metric === KPIS_TYPES.TOUCHPOINTS) {
    return [KPIS.IMPRESSIONS, KPIS.CLICKS];
  }
  return sortedDimList.slice(0, 10).map((item: any, index: number) => Object.keys(item)[0]);
};

export function mappingPaletteColorColumnChart(
  sourceUnique: any,
  currItem: any,
  topList: string[],
  dimension: string,
  color: string,
) {
  let paletteColor = color;
  if (sourceUnique.includes("organic") || sourceUnique.includes("non-organic")) {
    const otherSource: string[] = [];
    sourceUnique.forEach((item: any) => {
      if (item !== "organic" && item !== "non-organic") {
        otherSource.push(item);
      }
    });
    otherSource.forEach((item, index) => {
      if (item === currItem[dimension]) {
        paletteColor = palette[index];
      }
    });
  } else {
    topList.forEach((name, index: number) => {
      if (name === currItem[dimension]) {
        paletteColor = palette[index];
      }
    });
  }

  return paletteColor;
}

export function mappingOtherMetricByColumn(source: any[], categories: string[], dimension: string, metric: string) {
  let sourceUnique = [...new Set(source.map((item) => item[dimension]))];
  const sortedDimList = sortDimensions({ data: source, dimension, metric });
  const topList = getTopList({ sortedDimList, metric });
  let series: (ITempColumn | undefined)[] = source.reduce((acc, currItem) => {
    const index = categories.indexOf(currItem.date);
    const item = acc.find((item: any) => item?.fullName === currItem[dimension]);
    const cost = currItem[metric] ?? null;
    const name = getLabel(dimension, currItem);

    if (item) {
      item.data[index] = cost;
    } else {
      const paletteColor = mappingPaletteColorColumnChart(sourceUnique, currItem, topList, dimension, palette[0]);
      const currentColor = systemColors[currItem[dimension]];
      acc.push({
        name,
        fullName: currItem[dimension],
        data: categories.map((_, i) => (i === index ? cost : null)),
        color: currItem[dimension] === "organic" || currItem[dimension] === "non-organic" ? currentColor : paletteColor,
      });
    }
    return acc;
  }, []);

  series = topList.map((name) => {
    return series.find((i: any) => i.fullName === name);
  });

  return {
    series,
  };
}

export const mappingOtherSeriesWithColumn = (data: any, categories: string[], metric: string) => {
  if (!data.others || data?.others?.length === 0) {
    return null;
  }

  let res: any = {
    name: "Others",
    fullName: "Others",
    data: [],
    color: othersColor,
    yAxis: 0,
  };
  const sortedOthers = data.others.sort((a: any, b: any) => sortBy(a.date, b.date));
  categories.forEach((cate, i) => {
    const item = sortedOthers.find((item: any) => item.date === cate);
    if (item) {
      res.data[i] = item[metric] ?? null;
    } else {
      res.data[i] = null;
    }
  });
  return res;
};

export function getComparesCalculationByColumnChart(series: any, categories: string[]) {
  const total: number[] = series.reduce((acc: number[], curr: any) => {
    const nums = curr.data.map((num: number, index: number) => {
      const origialNum = num ?? 0;
      const prevNum = acc[index] ?? 0;
      if (acc[index]) {
        return prevNum + origialNum;
      } else {
        return origialNum;
      }
    });
    return nums;
  }, []);
  const percentages = total.map((num: number, index: number) => {
    if (index === 0) {
      return 0;
    } else {
      const prevNumber = total[index - 1];
      const diff = prevNumber - num;
      const res = (diff / prevNumber) * 100;
      return -Math.round(res);
    }
  });

  return categories.reduce((acc, currItem, index) => {
    const key = dayjs(currItem).format("MMM D");
    acc = {
      ...acc,
      [key]: {
        day: currItem,
        value: percentages[index],
      },
    };
    return acc;
  }, {});
}
