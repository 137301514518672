import { numberWithPercentage } from "utils/numberWithPercentage";
import { IConvertData } from "interfaces/chart";
import dayjs from "dayjs";

// helpers
import { formatterLabelChart } from "../chartHelper/commonHelper";

export const columnOption = (metric: string, data: IConvertData) => {
  let options: any = {
    tooltip: {
      shared: true,
      borderRadius: 5,
      useHTML: true,
      backgroundColor: "#fff",
      borderColor: "rgb(229 232 237 / 1",
      padding: 15,
      hasTotal: true,
      outside: true,
      formatter: function (this: any) {
        let contents = ``;
        const sum = this.points.reduce((acc: number, currItem: any) => {
          const value = currItem.y;
          return acc + value;
        }, 0);
        const result = formatterLabelChart(sum, metric);
        const dayFormatter = dayjs(data.comparesCalculation[this.x].day).format("MMM D, YYYY");

        let compareRatio: any = 0;
        if (data.comparesCalculation) {
          compareRatio = data.comparesCalculation[this.x].value;
        }
        const isDown = compareRatio < 0;
        const hidePercentage = compareRatio === Number.POSITIVE_INFINITY || compareRatio === Number.NEGATIVE_INFINITY;

        function renderBgColor() {
          if (compareRatio === 0) {
            return "#f5f6f8";
          }
          return isDown ? "#fcf4f6" : "#edf6f4";
        }

        function renderColor() {
          if (compareRatio === 0) {
            return "#000";
          }
          return isDown ? "#e27691" : "#1e805e";
        }

        contents = `
          <div style="min-width: 300px">
            <div style="display: flex; justify-content: space-between; align-items: center;">
              <div style="color:{point.color}; font-weight: bold; margin-right: 10px; margin-bottom: 3px;">
                ${dayFormatter}
              </div>
              <div>
                ${
                  hidePercentage
                    ? ``
                    : `
                      <span style="
                          margin-right:10px; 
                          display: inline-block; 
                          text-align: right; 
                          border-radius: 5px;
                          padding: 5px;
                          backgroundColor: ${renderBgColor()}; 
                          color:${renderColor()}; 
                        "
                      >
                        ${compareRatio > 0 ? `+${compareRatio}` : `${compareRatio}`} %
                      </span>
                    `
                }
                <b style="min-width: 50px; display: inline-block; text-align: right"">${result}</b>
              </div>
            </div>
            <hr style="margin: 5px 0 10px 0;" />
          </div>`;

        this.points
          .filter((item: any) => item.y !== 0)
          .forEach((item: any) => {
            const name = item.series.name;
            const number = formatterLabelChart(item.y, metric);
            const percentage = numberWithPercentage(item.percentage);

            contents += `
            <div style="display: flex; justify-content: space-between; align-item: center; margin-top: 15px; min-width: 300px">
              <div style="display: flex; align-items: center; margin-right: 10px">
                <span style="width: 8px; height: 8px; border-radius: 50%; margin-right: 7px; background-color: ${item.series.color}"></span>
                <span>${name}</span>
              </div>
              <div>
                <b style="margin-right:10px; display: inline-block; text-align: right">${percentage}</b>
                <b style="min-width: 50px; display: inline-block; text-align: right"">${number}</b>
              </div>
            </div>
          `;
          });

        return contents;
      },
    },
  };

  return {
    tooltip: options.tooltip,
  };
};
