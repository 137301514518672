import { useMe } from "context/MeContext";

export const usePermission = () => {
  const { data: me } = useMe();

  const isSuperAdmin = me?.isOps;

  const combineRoleWithSuperAdmin = (isAuthorized = false) => {
    if (isSuperAdmin) return true;
    return isAuthorized;
  };
  return {
    combineRoleWithSuperAdmin,
  };
};

/*
 ----------product
 - add new product : superAdmin, owner, editer
 - change status pmp : superAdmin, owner
 - enable product : superAdmin, owner
 - edit general info : superAdmin, owner, editer
 - disable request billing: superAdmin, owner
 - add new firebase :superAdmin, owner
 - add, remove user management : superAdmin, owner, editer
 - store info (addnew, edit): superAdmin, owner, editer
 - integration info : + connect, disconect integration :superAdmin, owner, editer
                      + connect, disconnect app: superAdmin, owner, editer
                      + refresh :superAdmin, owner, editer
---------------studio
- change studio status : superAdmin, admin
- edit studio : superAdmin, admin,
- add new product : superAdmin, admin,
- change pmp status : superAdmin, admin, 
- add, remove user : superAdmin, admin, manager
- change role user: superAdmin, admin, manager
*/
