import { useList } from "@pankod/refine-core";
import router from "@pankod/refine-react-router-v6";
import { EnumRoleStudio } from "enums";
import useLocalStorage from "hooks/useLocalStorage";
import { IProductUser, IStudioUser, IUser } from "interfaces";
import React from "react";

export const useAuthUser = (me?: IUser) => {
  const { id, action, resource } = router.useParams();

  const [studioId] = useLocalStorage<string>("studioId");
  const { data: userProductResult, isFetching: isFetchingProduct } = useList<IProductUser>({
    resource: "product-user-roles",
    config: {
      filters: [
        {
          field: "users_permissions_user][id]",
          operator: "eq",
          value: me?.id,
        },
        {
          field: "product][id]",
          operator: "eq",
          value: id,
        },
      ],
    },
    queryOptions: {
      enabled: Boolean(id),
      retry: 0,
    },
    metaData: {
      populate: ["role.permissions"],
    },
    errorNotification: false,
    successNotification: false,
  });
  const userProduct = userProductResult?.data && userProductResult?.data[0];

  const { data: userStudioResult, isFetching: isFetchingStudio } = useList<IStudioUser>({
    resource: "studio-user-roles",
    config: {
      filters: [
        {
          field: "users_permissions_user][id]",
          operator: "eq",
          value: me?.id,
        },
        {
          field: "studio][id]",
          operator: "eq",
          value: studioId,
        },
      ],
    },
    queryOptions: {
      retry: 0,
      enabled: Boolean(studioId),
    },
    metaData: {
      populate: ["role"],
    },
    errorNotification: false,
    successNotification: false,
  });

  const userStudio = userStudioResult?.data && userStudioResult?.data[0];
  // check this product in current selected studio or not?
  const { data: resultProductInStudio, isFetching: isFetchingPStudio } = useList({
    resource: "products",
    config: {
      filters: [
        {
          field: "studios][id]",
          operator: "eq",
          value: studioId,
        },
        {
          field: "id",
          operator: "eq",
          value: id,
        },
      ],
    },
    queryOptions: {
      enabled: Boolean(studioId && id),
      retry: 0,
    },
    errorNotification: false,
    successNotification: false,
  });
  const dataProductInStudio = resultProductInStudio?.data && resultProductInStudio?.data[0];
  const productPermissions = userProduct?.role?.permissions;
  const [shouldSuggestStudios, setShouldSuggestStudios] = React.useState(false);
  let _action: string;

  switch (action) {
    case "edit":
      _action = "update";
      break;
    case "show":
      _action = "findOne";
      break;
    case "list":
      _action = "find";
      break;
    default:
      _action = action;
      break;
  }
  const _resource = resource.endsWith("s") ? resource.slice(0, -1) : resource;
  let serviceName = _resource;
  const uid = `${_resource}.${serviceName}.${_action}`;
  // Base on Studio selected
  const [can, setCan] = React.useState(true);
  const isLoading = isFetchingProduct || isFetchingStudio || isFetchingPStudio;
  const myCurrentProductStatus = userProduct?.status;
  const userStudioRole = userStudio?.role?.type;
  const isMember = Boolean(userStudioRole && (userStudioRole as EnumRoleStudio) === EnumRoleStudio.Member);
  const isAdmin = Boolean(userStudioRole && (userStudioRole as EnumRoleStudio) === EnumRoleStudio.Admin);
  const isAccessed = Boolean(productPermissions?.some((item) => item.action.includes(uid)));
  React.useEffect(() => {
    if (isLoading || userProduct || (dataProductInStudio && !isMember) || me?.isOps) {
      setCan(true);
      return;
    }
    if (["edit", "show"].includes(action)) {
      if ((myCurrentProductStatus && myCurrentProductStatus !== "ACCEPTED") || !userProduct || !isAccessed) {
        setCan(false);
      } else {
        setCan(true);
      }
    }
  }, [
    action,
    dataProductInStudio,
    isAccessed,
    isLoading,
    isMember,
    me?.isOps,
    myCurrentProductStatus,
    productPermissions,
    uid,
    userProduct,
  ]);

  React.useEffect(() => {
    if (can && !dataProductInStudio && !isFetchingPStudio) {
      setShouldSuggestStudios(true);
    } else {
      setShouldSuggestStudios(false);
    }
  }, [can, dataProductInStudio, isFetchingPStudio]);

  return { can, isLoadingAuth: isLoading, userStudioRole, isMember, isAdmin, shouldSuggestStudios };
};
