import { TDataSelected } from "pages/Metabase/Metabase";

export const compareArray = (arr1: TDataSelected[] | undefined, arr2: TDataSelected[] | undefined) => {
  if (!arr1 || !arr2 || arr1.length === 0 || arr2.length === 0) return false;
  return (
    arr1.length === arr2.length &&
    arr1.every(function (valuesArr1) {
      return arr2.find((valueArr2) => valuesArr1.value === valueArr2.value && valuesArr1.label === valueArr2.label);
    })
  );
};
