import { Authenticated } from "@pankod/refine-core";
import { MeProvider } from "context/MeContext";
import { IRoute } from "interfaces";
import { SelectStudio } from "pages/Auth/SelectStudio";

export const studioRoutes: IRoute[] = [
  {
    element: (
      <Authenticated>
        <MeProvider>
          <SelectStudio />
        </MeProvider>
      </Authenticated>
    ),
    path: `select-studio`,
    layout: false,
  },
];
