import { MemoryAdapter, newModel } from "casbin.js";

export const model = newModel(`
[request_definition]
r = sub, obj, act

[policy_definition]
p = sub, obj, act, eft

[role_definition]
g = _, _

[policy_effect]
e = some(where (p.eft == allow)) && !some(where (p.eft == deny))

[matchers]
m = g(r.sub, p.sub) && keyMatch(r.obj, p.obj) && regexMatch(r.act, p.act)
`);

export const adapter = new MemoryAdapter(`
p, owner, products, (list)|(create)
p, owner, products/*, (edit)|(show)|(delete)
p, owner, products/*, field

p, editor, products, (list)|(create)
p, editor, products/*, (edit)|(show)

p, viewer, products (list)
p, viewer, products/*, (show)
p, viewer, products/show/*/integrations/create, field, deny

p, admin, studios, (list)
p, admin, studios/*, (edit)|(show)|(delete)
p, admin, studios/*, field

p, manager, studios, (list)
p, manager, studios/*, (edit)|(show)|(delete)
p, manager, studios/*, field

p, member, studios, (list)
p, member, studios/*, (show)
p, member, studios/*, field

p, guest, studios, (list)|(create)
p, guest, products, (list)|(create)
`);
