import { ExclamationCircleOutlined, StarFilled, StarOutlined } from "@ant-design/icons";
import { Form, Modal } from "@pankod/refine-antd";
import { useCreate, useDelete } from "@pankod/refine-core";
import { IBookmarks } from "interfaces";
import React from "react";
import { getIdBookmark } from "utils/metabase/getIdBookmark";
import { TDataSelected } from "../Metabase";

const { confirm } = Modal;

interface TPropsStartButton {
  isLoadingGetListBookmark: boolean;
  isFetchingGetListBookmark: boolean;
  listBookmark?: IBookmarks[];
  refetchListBookmark: any;
}
export default function StartButton({
  listBookmark,
  refetchListBookmark,
  isLoadingGetListBookmark,
  isFetchingGetListBookmark,
}: TPropsStartButton) {
  const listFavorite = React.useMemo(() => {
    return listBookmark?.filter((item) => item.type === "FAVORITE");
  }, [listBookmark]);
  const [idBookmarkSelected, setIdBookmarkSelected] = React.useState<number>();
  const dashboardInfo = Form.useWatch("dashboardInfo");
  const productInfo = Form.useWatch("productInfo");
  const appInfo = Form.useWatch("appInfo");

  //add bookmark api
  const { mutateAsync: addFavorite, isLoading: isLoadingAddFavorite } = useCreate();
  //delete bookmark
  const { mutateAsync: deleteBookmark, isLoading: isLoadingDeleteBookmark } = useDelete();
  const isLoadingStartButton =
    isLoadingGetListBookmark || isFetchingGetListBookmark || isLoadingDeleteBookmark || isLoadingAddFavorite;

  //css start button
  const stylesStart = `text-[24px] text-violet-500 ml-4 mb-10 ${
    isLoadingStartButton ? "cursor-wait" : `${appInfo && appInfo?.length > 0 && "cursor-pointer"}`
  }`;
  //function
  const handleAddBookMark = async () => {
    if (listFavorite && listFavorite?.length > 9) {
      confirm({
        centered: true,
        title: `You reach limit of favorites dashboard!`,
        icon: <ExclamationCircleOutlined />,
        closable: true,
        className: "no-footer",
      });
      return;
    }
    if (isLoadingStartButton) return;
    await addFavorite({
      resource: "bookmarks",
      values: {
        type: "FAVORITE",
        dashboard_id: dashboardInfo?.value,
        product_ids: productInfo?.map((v: TDataSelected) => v.value),
        app_ids: appInfo?.map((v: TDataSelected) => v.value),
      },
      successNotification: false,
      errorNotification: false,
    });
    refetchListBookmark();
  };
  const handleDeleteBookMark = async () => {
    if (isLoadingStartButton) return;
    await deleteBookmark({
      resource: "bookmarks",
      id: idBookmarkSelected || "",
      successNotification: false,
      errorNotification: false,
    });
    refetchListBookmark();
  };
  const isBookmarked = React.useMemo(() => {
    if (!listFavorite) return false;
    let idExist = getIdBookmark({ listData: listFavorite, dashboardInfo, productInfo, appInfo });
    if (idExist) {
      setIdBookmarkSelected(idExist);
      return true;
    }
    return false;
  }, [appInfo, dashboardInfo, listFavorite, productInfo]);

  if (isBookmarked) {
    return <StarFilled className={stylesStart} onClick={handleDeleteBookMark} />;
  }
  return (
    <StarOutlined className={stylesStart} {...(appInfo && appInfo?.length > 0 && { onClick: handleAddBookMark })} />
  );
}
