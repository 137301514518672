import React from "react";
import { Skeleton } from "@pankod/refine-antd";
import * as Highcharts from "highcharts";
import Exporting from "highcharts/modules/exporting";
import { DatabaseOutlined } from "@ant-design/icons";

// interfaces
import { IChartProps } from "interfaces/chart";
import { CHART_TYPES } from "utils/chart/chartConfig";

// utils
import { chartLtvOption } from "utils/chart/chartOption/commonOption";

// components
import FilterChart from "./FilterChart";

// hooks
import { useFilterChart } from "hooks/useFilterChart";
import { useLtvChartData } from "hooks/useLtvChartData";

Exporting(Highcharts);

function ChartLTV({
  app,
  productId,
  defaultChartType,
  defaultDimension,
  defaultMetric,
  defaultStartDate,
  defaultEndDate,
  widgetChart,
}: IChartProps) {
  // refs
  const chartRef = React.useRef(null);
  // hooks
  const {
    optionMetrics,
    optionDimensions,
    metricSelected,
    dimensionSelected,
    onChangeDimension,
    onChangeMetric,
    chartTypeSelected,
    setChartTypeSelected,
  } = useFilterChart(defaultDimension, defaultMetric, defaultChartType);

  const { data, isFetched, chartType } = useLtvChartData({
    productId,
    app,
    dimensionSelected,
    metricSelected,
    chartTypeSelected,
    widgetChart,
    defaultStartDate,
    defaultEndDate,
  });

  React.useEffect(() => {
    if (!data || !chartRef.current) return;
    const options: any = chartLtvOption(chartType, data, dimensionSelected, metricSelected, optionMetrics);

    Highcharts.chart(chartRef.current, {
      ...options,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderChart = (_isFetched: boolean) => {
    switch (_isFetched) {
      case false:
        return <Skeleton />;
      case true: {
        let xAxis: any = false;
        let series: boolean | undefined = data?.series.length === 0;
        if (chartType === CHART_TYPES.BAR) {
          xAxis =
            data?.xAxis?.length === 0
              ? true
              : data?.xAxis?.every(
                  (xaxis: string | number) => xaxis === null || Number(xaxis) === 0 || xaxis === undefined,
                );
        }
        if (chartType === CHART_TYPES.SPLINE) {
          series = data?.series?.every((serie) => serie.isEmpty);
        }
        if (chartType === CHART_TYPES.PIE) {
          series = data?.series?.every((serie) => serie.y === 0);
        }
        const isNotData = series || xAxis || !data;
        if (isNotData) {
          return (
            <div className="flex flex-col items-center justify-center">
              <DatabaseOutlined className="text-[24px]" />
              <div className="font-bold text-[16px] mt-2">No data available</div>
            </div>
          );
        }
        return <div ref={chartRef} className={`rounded-lg w-full ${chartTypeSelected}`} />;
      }
      default:
        return null;
    }
  };

  return (
    <>
      <div>
        <FilterChart
          chartType={chartType}
          optionMetrics={optionMetrics}
          optionDimensions={optionDimensions}
          dimensionSelected={dimensionSelected}
          metricSelected={metricSelected}
          onChangeDimension={onChangeDimension}
          onChangeMetric={onChangeMetric}
          onChangeDropdown={(value: string) => setChartTypeSelected(value)}
        />
      </div>

      <div className="flex flex-col items-center justify-center p-4 min-h-[420px] w-full">{renderChart(isFetched)}</div>
    </>
  );
}

export default ChartLTV;
