import { css } from "@emotion/react";
import { Button, Col, Form, Modal, ModalProps, Row, Select, Spin, Typography } from "@pankod/refine-antd";
import React from "react";
import { useCreate } from "@pankod/refine-core";
import { ImageElement } from "components/Image";

interface IUser {
  id: string | undefined;
  username: string | undefined;
  email: string | undefined;
}
interface IErrorData {
  code: string;
  name: string;
  icon: string;
  id: string;
  user: IUser;
}
interface IRemoveUserModal {
  modalProps: ModalProps;
  handleClose: () => void;
  isLoading: boolean;
  errorData: IErrorData[];
  studioUsers: IUser[];
  handleDelete: () => void;
}
const RemoveUserModal: React.FC<IRemoveUserModal> = ({
  modalProps,
  handleClose,
  isLoading,
  errorData,
  studioUsers,
  handleDelete,
}) => {
  const [form] = Form.useForm();
  const { mutateAsync: createTransferRole, isLoading: createTransferRoleLoading } = useCreate();

  const handleFinish = async (values: any) => {
    // get value key
    const keys = Object.keys(values);

    let promises: any[] = [];
    keys.forEach((key) => {
      const productId = key.split("__")[1];
      promises.push(
        createTransferRole({
          resource: "product-user-role/transfer-owner",
          values: {
            userId: values[key],
            productId,
          },
          successNotification: false,
          errorNotification: false,
        }),
      );
    });

    await Promise.all(promises);
    if (errorData[0].user.id) {
      handleDelete();
    }
    handleClose();
  };

  const handleSetValueAllInput = (productId: string) => {
    const values = form.getFieldsValue();
    const keys = Object.keys(values);
    const value = values[`userId__${productId}`];
    const temp: any = {};
    keys.forEach((key) => {
      temp[key] = value;
    });

    form.setFieldsValue(temp);
  };

  const renderFooter = () => {
    return (
      <div key="footer">
        <Button
          htmlType="button"
          disabled={isLoading}
          className="bg-gray-400 text-white border-none font-normal"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          htmlType="button"
          type="primary"
          className="text-white border-none font-normal"
          onClick={() => {
            form.submit();
          }}
          loading={isLoading || createTransferRoleLoading}
        >
          Confirm
        </Button>
      </div>
    );
  };

  if (!errorData.length) {
    return <></>;
  }

  return (
    <Modal
      {...modalProps}
      centered
      maskClosable={false}
      width={800}
      title="Notification for Transfer Ownership"
      css={css`
        .ant-modal-header {
          background-color: #6d0192;
        }
        .ant-modal-title {
          color: white;
        }
        .ant-modal-close-x {
          color: #ff4d4f;
        }
        .ant-modal-footer {
          background-color: #f1f1f1;
        }
        .ant-form-item {
          margin-bottom: 0px;
        }
        .ant-form-item-feedback-icon {
          display: none;
        }
      `}
      footer={renderFooter()}
    >
      <Spin spinning={createTransferRoleLoading}>
        <div className=" relative">
          <div className="z-20 relative">
            <Typography.Text className="text-base	">
              User {errorData[0].user.username} is the only product owner of your studio for below products. Please
              Transfer Ownership before remove user.
            </Typography.Text>
            <Form
              className="mt-[20px]"
              form={form}
              name="basic"
              layout="horizontal"
              onFinish={handleFinish}
              onFinishFailed={(values) => {
                console.log("value ", values);
              }}
              autoComplete="off"
            >
              <Row className="flex items-center bg-[#FAFAFA] h-[55px] p-[20px]">
                <Col span={5} className="text-sm">
                  <Typography.Text className="font-bold text-sm">Product code</Typography.Text>
                </Col>
                <Col span={8}>
                  <Typography.Text className="font-bold text-sm">Product</Typography.Text>
                </Col>
                <Col span={11}>
                  <Typography.Text className="font-bold text-sm">Transfer Ownership to</Typography.Text>
                </Col>
              </Row>
              {errorData.map((product) => {
                return (
                  <Row key={product.code} className="flex items-center p-[20px] bg-[#E6F7FF]">
                    <Col span={5} className="text-sm">
                      {product.code}
                    </Col>
                    <Col span={8} className="flex items-center text-sm">
                      <ImageElement className="mr-10 w-[48px] h-[48px]" src={product.icon} alt={"Monster Escape"} />
                      <Typography.Text className="text-sm mr-10">{product.name}</Typography.Text>
                      <div className="h-full items-center">
                        <div className="bg-[#52C41A] rounded-full w-1.5	h-1.5"></div>
                      </div>
                    </Col>
                    <Col span={11} className="flex items-center w-full">
                      <div className="flex flex-col w-full">
                        <Form.Item
                          className="w-full"
                          label=""
                          name={`userId__${product.id}`}
                          hasFeedback
                          rules={[{ required: true, message: "Please input user!" }]}
                        >
                          <Select>
                            {studioUsers
                              .filter((user) => errorData[0].user.id !== user.id)
                              .map((user) => {
                                return (
                                  <Select.Option key={`studio_${user.id}`} value={user.id}>
                                    {user.email}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                        {errorData.length > 1 && (
                          <Typography.Text
                            className="text-[#1759FF] text-xs cursor-pointer"
                            onClick={() => {
                              handleSetValueAllInput(product.id);
                            }}
                          >
                            Replace all for listed product
                          </Typography.Text>
                        )}
                      </div>
                    </Col>
                  </Row>
                );
              })}
            </Form>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default RemoveUserModal;
