const yellow = {
  50: "#FFF9DB",
  100: "#FFF3BF",
  200: "#FFEC99",
  300: "#FFE066",
  400: "#FFD43B",
  500: "#FCC419",
  600: "#FAB005",
  700: "#F59F00",
  800: "#F08C00",
  900: "#E67700",
};

export default yellow;
