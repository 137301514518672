import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";

// configs
import config from "config";

// components
import App from "./App";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

const root = ReactDOM.createRoot(document.getElementById("root") as any);

root.render(
  <Auth0Provider
    domain={config.REACT_APP_AUTH_0_DOMAIN as string}
    clientId={config.REACT_APP_AUTH_0_CLIENT_ID as string}
    redirectUri={window.location.origin}
    cacheLocation="localstorage"
    audience="https://amanotes.auth0.com/api/v2/"
    authorizeTimeoutInSeconds={3}
  >
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Auth0Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
