import Libhoney from "libhoney";
import { v4 as uuidv4 } from "uuid";

let libHoney = new Libhoney({
  writeKey: "81Onhd9xcLS2EbB6GSxn2A",
  dataset: "pmp-portal-dev",
  // disabled: true // uncomment when testing or in developmentq
});

type IEventDTO = {
  event_name?: string;
  parentId?: string;
  traceId?: string;
  spanId?: string;
  route: string;
  data: any;
  user: string;
  duration?: number;
  eventAnnotationType: string;
  params: any;
  status: number;
};

type IHoneycombEvent = {
  eventName?: string;
  eventNameChild?: string;
  traceId?: string;
  startTime?: number;
  parentId?: string;
  spanId?: string;
  duration?: number;
  numberOfRequest?: number;
  honeyData?: any;
  params?: {};
  status?: number;
  end?: number;
  data?: any;
};

class Honeycomb extends Libhoney {
  sendEvent(eventDTO: IEventDTO) {
    const event = libHoney.newEvent();
    event.addField("event_name", eventDTO.event_name);
    event.addField("trace.parent_id", eventDTO.parentId);
    event.addField("trace.trace_id", eventDTO.traceId);
    event.addField("trace.span_id", eventDTO.spanId);
    event.addField("route", eventDTO.route);
    event.addField("user", eventDTO.user);
    event.addField("duration_ms", eventDTO.duration);
    event.addField("meta.annotation_type", eventDTO.eventAnnotationType);
    event.addField("data", eventDTO.data);
    event.addField("meta.kind", "client");
    event.addField("status", eventDTO.status);
    event.addField("params", eventDTO.params);
    event.send();
  }

  initTraceIdAndStarTime() {
    return {
      traceId: uuidv4(),
      startTime: new Date().getTime(),
    };
  }

  initHoneycomb({ eventName, eventNameChild }: IHoneycombEvent) {
    const { traceId, startTime } = this.initTraceIdAndStarTime();
    const { honeyDataParent, honeyDataCallApi } = this.pushDataEventParentHoney({
      eventName,
      traceId,
      startTime,
      eventNameChild,
    });

    return {
      eventName,
      eventNameChild,
      traceId,
      startTime,
      honeyDataParent,
      honeyDataCallApi,
    };
  }

  pushDataEventParentHoney({ eventName, traceId, startTime, eventNameChild }: IHoneycombEvent) {
    const spanId = uuidv4();

    // parent event
    const honeyDataParent = {
      event_name: eventName,
      parentId: null,
      traceId,
      spanId,
      start: startTime,
      end: null,
      params: {},
      data: {},
      duration: 0,
      eventAnnotationType: "parent",
      route: window.location.href,
      user: "truong.nn@amanots.com",
    };

    // child event
    const honeyDataCallApi = {
      event_name: `${eventName}/${eventNameChild}`,
      parentId: spanId,
      traceId,
      spanId: uuidv4(),
      start: startTime,
      end: null,
      params: {},
      data: {},
      duration: 0,
      eventAnnotationType: "child",
      route: window.location.href,
      user: "truong.nn@amanots.com",
    };
    return {
      honeyDataParent,
      honeyDataCallApi,
    };
  }

  sendTraceEvent({ honeyData }: IHoneycombEvent) {
    this.sendEvent(honeyData.honeyDataParent);
    this.sendEvent(honeyData.honeyDataCallApi);
  }

  sendEventCallApi({
    honeyData,
    status,
    eventNameChild,
    spanId,
    data = {},
    params = {},
    end = new Date().getTime(),
  }: IHoneycombEvent) {
    const duration = end - honeyData.honeyDataCallApi.start;
    honeyData.honeyDataCallApi = {
      ...honeyData.honeyDataCallApi,
      event_name: eventNameChild
        ? `${honeyData.eventName}/${eventNameChild}`
        : `${honeyData.eventName}/${honeyData.eventNameChild}`,
      data,
      end,
      duration,
      status,
      params,
    };

    if (spanId) {
      honeyData.honeyDataCallApi.spanId = spanId;
    }

    this.sendEvent(honeyData.honeyDataCallApi);
  }

  sendEventParent({ honeyData, end = new Date().getTime() }: IHoneycombEvent) {
    const duration = end - honeyData.honeyDataCallApi.start;
    honeyData.honeyDataParent = {
      ...honeyData.honeyDataParent,
      duration,
    };
    this.sendEvent(honeyData.honeyDataParent);
  }
}

const honeycomb = new Honeycomb();

export default honeycomb;
