import { TDashboardInfo } from "./../../pages/Metabase/Metabase";
import { TDataSelected } from "pages/Metabase/Metabase";
import { IBookmarks } from "interfaces";
import { compareArray } from "./compareArray";
interface TCheckBookmark {
  listData: IBookmarks[] | undefined;
  dashboardInfo: TDashboardInfo;
  productInfo: TDataSelected[];
  appInfo: TDataSelected[];
}
export const getIdBookmark = ({ listData, dashboardInfo, productInfo, appInfo }: TCheckBookmark) => {
  let id: number | undefined;
  listData?.forEach((item: IBookmarks) => {
    const _listProduct: TDataSelected[] = item.products.map((v) => ({
      label: v.name,
      value: Number(v.id),
    }));
    const _listAppSelected: TDataSelected[] = item.apps.map((v) => ({
      label: v.product_code,
      value: Number(v.id),
    }));
    if (
      item.metabase_dashboard?.name === dashboardInfo?.label &&
      item.metabase_dashboard?.type === dashboardInfo?.type &&
      item.metabase_dashboard?.id === dashboardInfo?.value &&
      compareArray(_listProduct, productInfo) &&
      compareArray(_listAppSelected, appInfo)
    ) {
      id = item.id;
    }
  });
  return id;
};
