import React from "react";
import { Result, Button } from "antd";

const Page404: React.FC = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button
          href="/"
          className="inline-flex items-center bg-primary text-white hover:border hover:border-black hover:text-black"
          type="link"
        >
          Back Home
        </Button>
      }
    />
  );
};

export default Page404;
