import { Authenticated } from "@pankod/refine-core";
import { IRoute } from "interfaces";
import StoresCreate from "pages/Stores/create";
export const storeRoutes: IRoute[] = [
  {
    element: (
      <Authenticated>
        <StoresCreate />
      </Authenticated>
    ),
    layout: true,
    path: "products/show/:productId/add/apps",
  },
];
