import { css } from "@emotion/react";
import { PropsWithChildren } from "react";
import tw from "twin.macro";

export const LayoutCreateProduct: React.FC<PropsWithChildren<{ className?: string; isIntegrationStep?: boolean }>> = ({
  children,
  className = "",
  isIntegrationStep,
}) => {
  return (
    <div
      className={`${className}`}
      css={
        isIntegrationStep
          ? ""
          : css`
              ${tw`max-w-[650px] mx-auto py-10`}
            `
      }
    >
      {children}
    </div>
  );
};
