import { useCreate, useCustom } from "@pankod/refine-core";
import { DownloadObjectButton } from "pages/Product/components/Bucket/ActionsBucketButton";
import config from "config";
import { useMe } from "context/MeContext";
import { message } from "@pankod/refine-antd";
import React from "react";
import { IObject } from "interfaces";

interface DownLoadObjectsData {
  bucketName: string;
  selectedRowKeys: React.Key[];
  productId: string;
  dataSource: IObject[];
}

export const useDownLoadObjects = (data: DownLoadObjectsData) => {
  const apiUrlS3 = config.S3_URL;
  const { bucketName, selectedRowKeys, productId, dataSource } = data;
  const me = useMe();

  const { refetch: downloadFile } = useCustom<any>({
    url: `${apiUrlS3}/downloadFile`,
    method: "get",
    queryOptions: {
      enabled: false,
      retry: 0,
    },
    config: {
      query: {
        bucketName,
        keys: selectedRowKeys,
        userId: me?.data?.id,
        productId: productId,
      },
    },
  });
  const totalBytes = selectedRowKeys.reduce((curr: number, obj) => {
    const bytes = dataSource.find((item) => item.Name === obj)?.Size || 0;
    return curr + bytes;
  }, 0);

  const shouldDownload = totalBytes <= 100 * 1048576; // 100mb;
  const { mutateAsync: removeZip } = useCreate();

  const onDownload = React.useCallback(async () => {
    if (!shouldDownload) {
      message.error({
        content: "Total size of files should not be larger than 100mb.",
      });
      return;
    }
    message.destroy();
    message.loading({
      duration: 0,
      content: "Files are zipping. Please wait a moment...",
    });
    const response = await downloadFile();
    if (response.error) {
      return;
    }
    message.destroy();
    if (response.isError) {
      message.error({
        content: "Could not download files",
      });
    }
    const data = response.data?.data;
    const buffer = data?.buffer.data;
    const blob = new Blob([new Uint8Array(buffer)], {
      type: "application/zip",
    });
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.setAttribute("download", `${data?.filename}.zip`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    removeZip({
      resource: "removeZip",
      values: {
        filename: data?.filename,
        userId: me?.data?.id,
        productId: productId,
      },
      dataProviderName: "s3Service",
      successNotification: false,
      errorNotification: false,
    });
  }, [downloadFile, me?.data?.id, productId, removeZip, shouldDownload]);
  return {
    DownloadObjectButton,
    onDownload,
  };
};
