import { DataProvider as DataProvider$1, HttpError } from "@pankod/refine-core";

import axios from "axios";
import { DataProvider } from "@pankod/refine-strapi-v4";
import config from "config";

export const axiosInstance = axios.create();
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };
    if (error.response?.status === 403) {
      customError = {
        ...customError,
        message: config.UnAuthorizedActionErrorMessage,
      };
    }
    return Promise.reject(customError);
  },
);
const apiUrlBE = config.S3_URL;
export const CustomRestDataProvider = (apiUrl: string, httpClient: any = axiosInstance): DataProvider$1 => ({
  ...DataProvider(apiUrl, httpClient),
  deleteOne: async ({ resource, id }) => {
    const url = id ? `${apiUrl}/${resource}/${id}` : `${apiUrl}/${resource}`;
    const r = await httpClient.delete(`${url}`);
    return r;
  },
});
export const s3ServiceProvider = CustomRestDataProvider(apiUrlBE, axiosInstance);
