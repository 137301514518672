import { Form, FormInstance } from "@pankod/refine-antd";
import { FormItemHiddenLabel } from "components/Form/FormItemHiddenLabel";
import { useProductStepForm } from "context/ProductStepFormContext";
import React from "react";
import { isDuplicatedFieldFormList } from "utils/form";
import FormItem from "./FormItem";

interface FirebaseFormCreateProps {
  isCreateProduct?: boolean;
  setIsLoading?: (val: boolean) => void;
  form?: FormInstance<any>;
}
export const FirebaseFormCreate: React.FC<FirebaseFormCreateProps> = ({ isCreateProduct, setIsLoading, form }) => {
  const { formLoading } = useProductStepForm();

  return (
    <Form.List
      name="firebase_configurations"
      initialValue={[{ name: "", type: "new" }]}
      rules={[
        {
          validator: (_rules, values) => {
            if (values && values.length === 0) {
              return Promise.reject(new Error("Need at least one firebase project."));
            }
            if (isDuplicatedFieldFormList("name", values)) {
              return Promise.reject(new Error("Firebase name cannot be duplicated. Please check it again."));
            }
            const oldValues = values?.filter((item: any) => item?.type === "old");
            if (isDuplicatedFieldFormList("console_url", oldValues)) {
              return Promise.reject(new Error("Firebase URL cannot be duplicated. Please check it again."));
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map(({ key, name }, index) => {
            return (
              <FormItem
                key={index}
                fieldKey={key}
                name={name}
                index={index}
                remove={remove}
                isCreateProduct={isCreateProduct}
                formModalCreate={form}
                setIsLoading={setIsLoading}
              />
            );
          })}
          {errors && <p className="text-danger mt-3 w-3/4 ml-auto">{errors}</p>}
          {isCreateProduct ? (
            <FormItemHiddenLabel>
              <span
                className={` ${formLoading ? "text-gray-300 cursor-not-allowed" : "text-primary-2 cursor-pointer"}`}
                onClick={() => {
                  if (formLoading) return;
                  add();
                }}
              >
                Want more Firebase project? Add more
              </span>
            </FormItemHiddenLabel>
          ) : null}
        </>
      )}
    </Form.List>
  );
};
