import { css } from "@emotion/react";
import { Alert, AntdLayout, Button } from "@pankod/refine-antd";
import { useApiUrl } from "@pankod/refine-core";
import logo from "assets/img/amanotes_icon.png";
import bgLogin from "assets/img/bg-login.jpeg";
import config from "config";
import { useQuery } from "hooks/useQuery";
import React from "react";
export const Login: React.FC = () => {
  const apiUrl = useApiUrl();
  const query = useQuery();
  const err = query.get("err")?.toString();
  React.useEffect(() => {
    err && window.localStorage.removeItem(config.TOKEN_KEY);
  }, [err]);
  const connectURL = `${apiUrl}/connect/auth0`;
  const _connectURL =
    config.REACT_APP_ENV === "local" ? `${connectURL}?callback=${window.location.origin}/connect/auth0` : connectURL;
  return (
    <AntdLayout
      css={css`
        background-image: url(${bgLogin});
        background-size: cover;
      `}
    >
      <div style={{ height: "100vh", display: "flex" }}>
        <div className="bg-black/[0.6] w-full px-4 py-4 rounded flex items-center flex-col justify-center">
          <div className="mb-4 my-auto p-4 rounded bg-white max-w-[400px] w-full">
            <div className="text-[20px] font-bold text-black mb-4 text-center">Product Management Portal</div>
            <img width={100} src={logo} alt="amanotes-logo" className="mx-auto" />
            {err === "true" && (
              <Alert
                className="mt-4"
                message="The invitation link has expired or you were accepted. Please contact PMP Admin for a new invitation link
              or login to your already activated account."
                type="error"
              />
            )}
            <Button
              size="large"
              type="primary"
              className="mt-4 w-full text-center ant-btn flex justify-center items-center border-0 text-[14px] hover:border"
              href={_connectURL}
            >
              Sign in with Auth0
            </Button>
          </div>
          <div className="mt-auto text-white">© 2022 Amanotes. All Rights Reserved.</div>
        </div>
      </div>
    </AntdLayout>
  );
};
