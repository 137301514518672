// components
import { Chart } from "components/Chart";

function ChartIntegration() {
  return (
    <div className="p-4">
      <Chart />
    </div>
  );
}

export default ChartIntegration;
