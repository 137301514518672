import { css } from "@emotion/react";
import { Button, Form, Modal, ModalProps, Spin } from "@pankod/refine-antd";
import { useCreate } from "@pankod/refine-core";
import { FirebaseFormCreate } from "pages/Product/StepsForm/FirebaseStep/FirebaseFormCreate";
import React from "react";
import router from "@pankod/refine-react-router-v6";
import { useProduct } from "context/ProductContext";

export const AddFirebaseModal: React.FC<{
  modalProps: ModalProps;
  handleClose: () => void;
  showErrorNotification?: boolean | any;
  customOnError?: (error: { statusCode: number; message: string }) => void;
}> = ({ modalProps, handleClose }) => {
  const [forceUpdate, setForceUpdate] = React.useState(Date.now());
  const { refetchProduct } = useProduct();
  const params = router.useParams();
  const product_id = params.id;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const { mutateAsync: createFirebase, isLoading: isCreateLoading } = useCreate();
  const _isLoading = isCreateLoading || isLoading;
  const onCancel = () => {
    handleClose();
  };

  return (
    <Modal
      {...modalProps}
      centered
      className="rounded-[10px] shadow-md w-[600px]"
      maskClosable={false}
      title={<p className="font-bold text-[16px] mt-[5px] text-white">Firebase</p>}
      onCancel={onCancel}
      css={css`
      .ant-modal-header {
        background-color: #4f006a;
        height: 46px;
        padding-top: 8px;
      }
      .ant-modal-title {
        margin-top: 0px; !important;
      }
      .ant-modal-close .anticon{
        color: #FF7875;
        font-size: 24px;
      }
      .ant-modal-footer{
        border-top: none;
      }
      ${_isLoading ? ".ant-modal-close{pointer-events: none;}" : ""}
      
    `}
      footer={[
        <div key="footer">
          <Button htmlType="button" className="bg-gray-400 text-white " onClick={onCancel} disabled={_isLoading}>
            Close
          </Button>
          <Button htmlType="button" type="primary" onClick={() => form.submit()} loading={_isLoading}>
            Confirm
          </Button>
        </div>,
      ]}
    >
      <Form
        name="basic"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 18 }}
        labelAlign="right"
        form={form}
        onFinish={async (firebase) => {
          const { type, isAvailable, ...input } = firebase.firebase_configurations[0];
          const res = await createFirebase({
            resource: `products/${product_id}/firebases`,
            values: {
              ...input,
              console_url: type === "old" ? input.console_url : undefined,
            },
            errorNotification: {
              type: "error",
              description: "Error",
              message: "Fail to create Firebase project",
            },
            successNotification: {
              type: "success",
              description: "Success",
              message: `Create Firebase`,
            },
          });
          if (res.data) {
            form.resetFields();
            refetchProduct();
            handleClose();
            setForceUpdate(Date.now());
          }
        }}
      >
        <Spin spinning={isCreateLoading}>
          <FirebaseFormCreate key={forceUpdate} isCreateProduct={false} setIsLoading={setIsLoading} form={form} />
        </Spin>
      </Form>
    </Modal>
  );
};
