import { useParams } from "react-router-dom";
import { useProductQuery } from "schema/schema";
import ProductInfo from "factory/Product";
import { useResource } from "@pankod/refine-core";
import React from "react";

export const useProductInfo = () => {
  const params = useParams();
  const { resource } = useResource();
  const productId = params.productId || params.id || "";
  const [productResult, refetchProduct] = useProductQuery({
    variables: {
      id: productId,
    },
    requestPolicy: "cache-first",
    pause: !resource.name.includes("products") || !Boolean(productId),
  });
  const onRefetchProduct = () => {
    refetchProduct({
      requestPolicy: "cache-and-network",
    });
  };
  const productData = React.useMemo(() => productResult?.data?.product?.data, [productResult?.data?.product?.data]);
  const productInfo = new ProductInfo(productData);

  return {
    productInfo,
    isLoadingProduct: productResult.fetching || productResult.stale,
    refetchProduct: onRefetchProduct,
    productId,
    productResult,
  };
};
