import { css } from "@emotion/react";
import { Button, Form, FormInstance, FormProps, Input, Modal, ModalProps, Select, useModal } from "@pankod/refine-antd";
import { HttpError, UseCreateReturnType } from "@pankod/refine-core";
import { UseUpdateReturnType } from "@pankod/refine-core/dist/hooks/data/useUpdate";
import AlertModal from "components/Modal/AlertModal";
import { DuplicateAppModal } from "components/Modal/DuplicateAppModal";
import { IStore } from "interfaces";
import { appStatusOptions } from "pages/Product/create";

import React from "react";

interface EditAppProps {
  modalProps: ModalProps;
  formProps: FormProps<{}>;
  form: FormInstance<{}>;
  close: () => void;
  mutationResult:
    | UseCreateReturnType<
        IStore & {
          error?: any;
        },
        HttpError,
        {}
      >
    | UseUpdateReturnType<
        IStore & {
          error?: any;
        },
        HttpError,
        {}
      >;
  queryResult: any;
}

const StoresEdit: React.FC<EditAppProps> = ({ modalProps, form, formProps, close, mutationResult, queryResult }) => {
  // hook get data
  const { show: showModalDuplicate, close: closeModalDuplicate, modalProps: modalPropsDuplicate } = useModal();
  const { show: showModalAlert, close: closeModalAlert, modalProps: modalPropsAlert } = useModal();

  const app = queryResult.data?.data;
  const handleConfirm = () => {
    const status = form.getFieldValue("status");
    if (app.status === "ACTIVE" && status !== "ACTIVE" && app.status !== status) {
      showModalAlert();
    } else {
      form.submit();
    }
  };

  const onAlertModalSubmit = () => {
    form.submit();
    closeModalAlert();
  };
  const isLoading = mutationResult?.isLoading;
  const error = mutationResult?.data?.data?.error;
  const [appDuplicated, setAppDuplicated] = React.useState<IStore>();
  React.useEffect(() => {
    if (error && !isLoading) {
      showModalDuplicate();
      setAppDuplicated(error?.details);
    }
  }, [error, isLoading, showModalDuplicate]);

  return (
    <div>
      <AlertModal
        modalProps={modalPropsAlert}
        handleClose={closeModalAlert}
        message={`If you change application status into development/inactive, all integration related to ${app?.name} will be disconnected. Do you want to disable application?`}
        handleOk={onAlertModalSubmit}
        isLoading={isLoading}
      />
      <Modal
        {...modalProps}
        centered
        closable={false}
        maskClosable={false}
        title="Application Edit"
        css={css`
              .ant-modal-header {
                background-color: #4f006a;
                height: 46px;
                padding-top: 8px;
              }
              .ant-modal-title {
                color: white;
                margin-top: 0px; !important;
              }
              .ant-modal-close .anticon{
                color: #FF7875;
                font-size: 24px;
              }
              .ant-modal-footer{
                border-top: none;
              }
              .ant-modal-body {
              }
            `}
        className="w-[500px] lg:w-[40%]"
        footer={[
          <div key="footer">
            <Button htmlType="button" className="bg-gray-400 text-white " onClick={close}>
              Close
            </Button>
            <Button type="primary" onClick={handleConfirm}>
              Confirm
            </Button>
          </div>,
        ]}
      >
        <Form
          name="Edit application"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
          className="flex flex-col justify-center"
          form={form}
          autoComplete="off"
          {...formProps}
        >
          <Form.Item
            label="Application Name"
            name="name"
            rules={[{ required: true, max: 30, min: 4, message: "App name must be between 4 and 30 characters" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item id="bundle" label="Bundle ID" name="bundle_id" rules={[{ required: true }]}>
            <Input disabled />
          </Form.Item>
          <Form.Item id="platform" label="Platform" name="platform" rules={[{ required: true }]}>
            <Input disabled />
          </Form.Item>
          <Form.Item label="Product code" name="product_code" rules={[{ required: true }]}>
            <Input disabled />
          </Form.Item>
          <Form.Item label="Status" name="status" rules={[{ required: true }]}>
            <Select placeholder="Select status" optionFilterProp="children" options={appStatusOptions} />
          </Form.Item>
        </Form>
      </Modal>
      <DuplicateAppModal
        appDuplicated={appDuplicated}
        modalPropsDuplicate={modalPropsDuplicate}
        closeModalDuplicate={closeModalDuplicate}
      />
    </div>
  );
};

export default StoresEdit;
