import { Enforcer } from "casbin.js";
import config from "config";
import { IStudioUser, IUser } from "interfaces";

export interface CanProps {
  params?: any;
  action: string;
  resource: string;
  enforcer: Enforcer;
}
export interface PerformProps {
  userStudio?: IStudioUser[];
  user?: IUser;
}

export const useCanPerform = (props: PerformProps) => {
  const { userStudio, user } = props;
  const can = (data: CanProps) => {
    const { action, resource, params } = data;
    let _action: string;

    switch (action) {
      case "edit":
        _action = "update";
        break;
      case "show":
        _action = "findOne";
        break;
      case "list":
        _action = "find";
        break;
      default:
        _action = action;
        break;
    }
    const isOps = user?.isOps;
    if (isOps || action === "list") {
      return Promise.resolve({
        can: true,
      });
    }
    const _resource = resource.endsWith("s") ? resource.slice(0, -1) : resource;
    let serviceName = _resource;
    const uid = `${_resource}.${serviceName}.${_action}`;
    let can = false;
    let reason = config.UnAuthorizedActionErrorMessage;

    if (resource === "studios") {
      const studioPermissions = userStudio?.find((item) => item.studio?.id.toString() === params?.id)?.role
        ?.permissions;

      can = Boolean(studioPermissions?.some((item) => item.action.includes(uid)));
    }
    if (resource === "products") {
      can = true;
    }

    return Promise.resolve({
      can,
      reason: !can ? reason : "",
    });
  };
  return { can };
};
