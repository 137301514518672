import { Skeleton } from "@pankod/refine-antd";

export const GeneralInfoSkeleton: React.FC<{ isStudioInfo?: boolean }> = ({ isStudioInfo }) => {
  return (
    <div className="flex w-full ">
      <div className="">
        <span className="w-[242px] h-[242px] flex">
          <Skeleton.Image className="w-full h-full object-contain" />
        </span>
      </div>

      <div className="ml-[40px] flex flex-col justify-between w-full">
        <div>
          <div className="flex justify-between items-center w-2/3">
            <Skeleton paragraph={{ rows: 0 }} className=" w-full" />
            {!isStudioInfo && <Skeleton.Button />}
          </div>
          <div className="mt-[14px] w-[150px]">
            <Skeleton paragraph={{ rows: 0 }} />
          </div>

          <div className="mt-[14px] w-[90%]">
            <Skeleton paragraph={{ rows: 3, width: "100%" }} />
          </div>
        </div>
      </div>
    </div>
  );
};
