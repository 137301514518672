import tw from "twin.macro";
import { IStore, IStudio } from "interfaces";
import { Modal, ModalProps } from "@pankod/refine-antd";
import { useProduct } from "context/ProductContext";
import { useCreate } from "@pankod/refine-core";
import React from "react";
import { css } from "@emotion/react";
import TagStatus from "components/Tag/TagStatus";
import config, { isLocal } from "config";
import { getStatusProduct } from "utils/getStatusProduct";

export const DuplicateAppModal: React.FC<{
  appDuplicated?: IStore;
  modalPropsDuplicate: ModalProps;
  closeModalDuplicate: () => void;
}> = ({ appDuplicated, closeModalDuplicate, modalPropsDuplicate }) => {
  const { mutateAsync: requestBundleId, isLoading: isLoadingRequest } = useCreate();
  const { productInfo } = useProduct();

  const generateColorStatus = (status?: string) => {
    let color = "br-green-500";
    switch (status) {
      case "ACTIVE":
        color = "bg-green-500";
        break;
      case "DEVELOPMENT":
        color = "bg-orange-400";
        break;
      case "INACTIVE":
        color = "bg-red-700";
        break;
      default:
        break;
    }
    return <span className={`ml-3 w-[6px] h-[6px] rounded-[100%] ${color}`}></span>;
  };
  const statusProduct = getStatusProduct(appDuplicated?.product?.apps);
  const icon = isLocal
    ? `${config.BACKEND_URL}${appDuplicated?.product?.icon?.url}`
    : appDuplicated?.product?.icon?.url;

  return (
    <Modal
      {...modalPropsDuplicate}
      centered
      title="Notification for duplicate bundleID"
      onOk={async () => {
        await requestBundleId({
          resource: `products/${productInfo.id}/request-bundle-id`,
          values: {
            bundle_id: appDuplicated?.bundle_id,
          },
          successNotification: {
            type: "success",
            description: "Success",
            message: "Successfully requested bundleID",
          },
        });
        closeModalDuplicate();
      }}
      okButtonProps={{
        loading: isLoadingRequest,
      }}
      cancelButtonProps={{
        disabled: isLoadingRequest,
      }}
      css={css`
        .ant-modal-close-icon {
          color: red;
        }
        .ant-modal-title {
          color: white;
        }
        .ant-modal-header {
          ${tw`bg-purple`}
        }
      `}
    >
      <div
        css={css`
          font-size: 14px !important;
        `}
      >
        <p>Your input application is duplicated BundleID with below application</p>
        <div
          css={css`
            > div {
              background: aliceblue;
              ${tw`flex py-2 h-[56px] px-3 border-b items-center`}
              > span {
                ${tw`w-[30%] font-bold`}
              }
              > div {
                ${tw`w-[70%]`}
              }
            }
          `}
        >
          <div>
            <span>Product</span>
            <div>
              <div className="flex items-center">
                <span>
                  <img width="50px" src={icon} alt="" />
                </span>
                <span className="ml-2">{appDuplicated?.product?.name}</span>
                {generateColorStatus(statusProduct)}
              </div>
            </div>
          </div>
          <div>
            <span>Studio</span>
            <div>
              {appDuplicated?.product?.studios.map((ele: IStudio) => {
                return (
                  <div key={ele.id} className="mt-1 inline-block">
                    <TagStatus status={ele.name} />
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <span>Application</span>
            <div className="flex items-center">
              <div className="flex flex-col">
                <span>{appDuplicated?.name}</span>
                <span>{appDuplicated?.bundle_id}</span>
              </div>
              {generateColorStatus(appDuplicated?.status)}
            </div>
          </div>
          <div>
            <span>Store URL</span>
            <div>
              <a target="_blank" href={appDuplicated?.connect_url} rel="noreferrer">
                View on Store
              </a>
            </div>
          </div>
          <div>
            <span>Product Owner</span>
            <div>
              <span>{appDuplicated?.product?.author?.email}</span>
            </div>
          </div>
        </div>
        <p className="mb-0 mt-4">
          If you want to reuse the bundleID, PMP will send email to inform its Product Owner. Do you still want to use
          this bundleID?
        </p>
      </div>
    </Modal>
  );
};
