import React from "react";
import { Row, Col, Typography, Alert, Select } from "@pankod/refine-antd";
import { useCustom, useApiUrl } from "@pankod/refine-core";

// assets
import QrCode from "assets/img/qr-code.png";

// context
import { useIntegration } from "context/IntegrationContext";

// interface
import { IInstallionLink } from "interfaces";

const { Title, Text } = Typography;
const { Option } = Select;

type IProps = {
  deviceId: string;
  platform?: string;
  installationLinkData: IInstallionLink;
  handleChangeDevice: (value: string) => void;
};

type IPlatform = {
  attributes: {
    os: string;
    name: string;
    "device-id": string;
    "link-parameter": string;
    "device-id-type": string;
  };
};

function Prepare({ deviceId, platform, installationLinkData, handleChangeDevice }: IProps) {
  const { productInfo } = useIntegration();

  // fetch data test device
  const apiUrl = useApiUrl();
  const {
    data: testDeviceData,
    isLoading,
    refetch: refetchTestDevice,
  } = useCustom({
    url: `${apiUrl}/products/${productInfo.id}/integrations:appsflyer/test-devices`,
    method: "get",
    queryOptions: {
      enabled: false,
      retry: 0,
    },
    config: {
      query: {
        platform,
      },
    },
  });

  React.useEffect(() => {
    productInfo?.id && refetchTestDevice();
  }, [refetchTestDevice, productInfo.id]);

  const testDevices = testDeviceData?.data.data ?? [];

  return (
    <>
      <Alert message="Make sure to remove the app from the device before running this test." type="info" showIcon />
      <Row className="mt-4 w-full">
        <Col span={24}>Choose a test device</Col>
        <Col span={24} className="mt-2">
          <Select
            showSearch
            placeholder="Select a test device"
            onChange={handleChangeDevice}
            className="w-full"
            optionFilterProp="children"
            loading={isLoading}
            optionLabelProp="label"
            filterOption={(input, option) =>
              (option!.label as unknown as string).toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA!.label as unknown as string)
                .toLowerCase()
                .localeCompare((optionB!.label as unknown as string).toLowerCase())
            }
          >
            {testDevices.map((device: IPlatform) => (
              <Option
                key={device.attributes["device-id"]}
                value={device.attributes["device-id"]}
                label={device.attributes.name}
              >
                <Title level={5}>{device.attributes.name}</Title>
                <Text>
                  {device.attributes["device-id-type"]}: {device.attributes["device-id"]}
                </Text>
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row className="mt-4 w-full">
        <Col span={24}>Scan QR with your chosen device</Col>
        <Col span={24} className="mt-2 text-center">
          {deviceId && installationLinkData?.image ? (
            <img src={installationLinkData?.image} alt="QR Code" width={256} className="inline-block" />
          ) : (
            <div className="flex justify-center mt-[32px]">
              <img src={QrCode} alt="Qr code" width={220} />
            </div>
          )}
        </Col>
      </Row>
    </>
  );
}

export default Prepare;
