import { IResourceComponentsProps, useNavigation, useNotification } from "@pankod/refine-core";
import { Edit, Form, useForm } from "@pankod/refine-antd";

import React from "react";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { LayoutCardTransparent } from "components/layout/layout/LayoutCardTransparent";
import { FormLayout } from "./components/FormLayout";
import { useUploadForm } from "hooks/useUploadForm";
import { IStudio } from "interfaces";

export const StudioEdit: React.FC<IResourceComponentsProps> = () => {
  const { open } = useNotification();
  const { push } = useNavigation();

  const { formProps, saveButtonProps, form, queryResult } = useForm<IStudio>({
    redirect: "show",
    metaData: {
      populate: ["icon", "users"],
    },
    onMutationError: (err) => {
      const res = err.response;
      const errDetails = res?.data?.error?.details?.errors;
      let message = "";
      if (Array.isArray(errDetails)) {
        if (errDetails.length === 1) {
          message = "Studio name already exists!";
        } else {
          message = `Couldn't create studio. Check all the inputs and try again.`;
        }
      }
      errDetails &&
        open?.({
          type: "error",
          description: "Error",
          message,
        });
    },
    successNotification: {
      type: "success",
      description: "Success",
      message: "Studio is successfully updated",
    },
    errorNotification: {
      type: "error",
      description: "Error",
      message: "Fail to edit studio",
    },
  });
  const { UploadForm, setLoading, loading } = useUploadForm();
  return (
    <LayoutCardTransparent>
      <Edit
        title="Edit Studio"
        saveButtonProps={{
          ...saveButtonProps,
          disabled: loading,
        }}
        isLoading={queryResult?.isLoading}
        headerProps={{
          onBack: () => push(`/studios/show/${queryResult?.data?.data.id}`),
        }}
      >
        <Form
          {...formProps}
          layout="vertical"
          onFinish={(e) => {
            const input = mediaUploadMapper(e);
            return formProps.onFinish?.({ ...input, icon: input?.icon[0] });
          }}
          form={form}
        >
          <FormLayout>
            <UploadForm data={queryResult?.data?.data} setLoading={setLoading} loading={loading} />
          </FormLayout>
        </Form>
      </Edit>
    </LayoutCardTransparent>
  );
};
