import React from "react";
import { Form, FormInstance, Input, message } from "antd";
import { css } from "@emotion/react";
import { ModalStaticFunctions } from "antd/lib/modal/confirm";
import { useCreate, useNotification } from "@pankod/refine-core";
import { useMe } from "context/MeContext";
import config from "config";
import ProductInfo from "factory/Product";
import { getLastPartOfPath } from "utils/folderAndFile";

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
  index: number;
}

export const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Record<string, string>;
  record: any;
  handleSave: (record: Record<string, string | number>) => void;
  modal: Omit<ModalStaticFunctions, "warn">;
  setErr?: (err: string) => void;
  editing: boolean;
  setEditingKey: (val: string) => void;
  bucketName: string;
  prefixS3: string | null;
  productInfo?: ProductInfo;
}

export interface ValuesType {
  bid?: number;
  dailyBudget?: number;
  maxBid?: string;
  goalBid?: string;
  sourceAppId?: string;
}

export const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  modal,
  setErr,
  editing,
  setEditingKey,
  bucketName,
  prefixS3,
  productInfo,
  ...restProps
}) => {
  const me = useMe();
  const { mutateAsync: rename } = useCreate();
  const [loading, setLoading] = React.useState(false);
  const inputRef = React.useRef<any>(null);
  const form = React.useContext(EditableContext)!;
  const formatName = () => {
    const name = record[dataIndex] as string;
    form.setFieldsValue({ [dataIndex]: getLastPartOfPath(name) });
  };
  const { open } = useNotification();
  React.useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
      formatName();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing]);

  const toggleEdit = () => {
    setEditingKey("");
    formatName();
  };
  const save = async () => {
    try {
      setLoading(true);
      const dataForm = await form.validateFields();
      const name = getLastPartOfPath(record?.Name || "");
      const res = await rename({
        resource: `renameFile`,
        values: {
          oldFilename: `${bucketName}/${prefixS3}${name}`,
          newFilename: `${bucketName}/${prefixS3}${dataForm.Name}`,
          userId: me?.data?.id,
          productId: productInfo?.id,
        },
        dataProviderName: "s3Service",
        successNotification: false,
      });
      if (res.data) {
        message.success("Rename successfully!");
      }
      dataForm.Name = handleSave({ ...record, ...dataForm });
      toggleEdit();
    } catch (errInfo) {
      handleSave({ ...record });
      open?.({
        type: "error",
        message: config.UnAuthorizedActionErrorMessage,
      });
    }
    setLoading(false);
  };

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        css={css`
          margin: 0;
          .ant-form-item-control-input {
            min-height: auto;
          }
        `}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input
          ref={inputRef}
          css={css`
            transition: none;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            outline: none;
            border: none;
            font-size: 12px;
            height: 15px;
            padding: 0 5px;
          `}
          disabled={loading}
          onPressEnter={save}
          onBlur={() => setEditingKey("")}
        />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap">{children}</div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
