import { Edit, Skeleton } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import { useApp } from "hooks/useApp";
import React from "react";
import { CollapseContentDaremi } from "./component/CollapseContent";

export const EditAppDaremi: React.FC = () => {
  const { goBack } = useNavigation();
  const { isLoading, isLoadingProduct, getPayload } = useApp();
  const [isLoadingAction, setIsLoadingAction] = React.useState(false);
  return (
    <Edit
      title="Edit App Daremi"
      actionButtons={<></>}
      pageHeaderProps={{
        extra: "",
        onBack: () => {
          if (isLoadingAction) return;
          goBack();
        },
      }}
      isLoading={isLoading || isLoadingProduct}
    >
      {(isLoading || isLoadingProduct) && <Skeleton />}
      {!isLoading && !isLoadingProduct && (
        <CollapseContentDaremi mode="update" getPayload={getPayload} setIsLoadingAction={setIsLoadingAction} />
      )}
    </Edit>
  );
};
