import { numberWithPercentage } from "utils/numberWithPercentage";

// helpers
import { formatterLabelChart } from "../chartHelper/commonHelper";

export const pieOption = (_: string, metric: string) => {
  let options: any = {
    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      borderRadius: 5,
      backgroundColor: "#fff",
      borderColor: "rgb(229 232 237 / 1",
      padding: 15,
      useHTML: true,
      outside: true,
      formatter: function (this: any) {
        const label = formatterLabelChart(this.y, metric);
        const percentage = numberWithPercentage(this.point.percentage);
        const contents = `
          <div style="display: flex; justify-content: space-between; align-item: center; margin-top: 5px; min-width: 200px">
            <div style="display: flex; align-items: center; margin-right: 10px">
              <span style="width: 8px; height: 8px; border-radius: 50%; margin-right: 7px; background-color: ${this.point.color}"></span>
              <span>${this.point.name}</span>
            </div>
            <b style="margin-right: 10px">${percentage}</b>
            <b>${label}</b>
          </div>
        `;

        return contents;
      },
    },
  };
  return {
    tooltip: options.tooltip,
  };
};
