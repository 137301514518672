const cyan = {
  50: "#E3FAFC",
  100: "#C5F6FA",
  200: "#99E9F2",
  300: "#66D9E8",
  400: "#3BC9DB",
  500: "#22B8CF",
  600: "#15AABF",
  700: "#1098AD",
  800: "#0C8599",
  900: "#0B7285",
};

export default cyan;
