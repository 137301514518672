export function testImage(url?: string, timeout = 0) {
  return new Promise(function (resolve, reject) {
    let img = new Image();
    img.src = url || "";
    img.onerror = img.onabort = function () {
      reject(false);
    };
    img.onload = function () {
      resolve(true);
    };
  });
}
