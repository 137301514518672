import React from "react";
import { Alert, Button, Space } from "@pankod/refine-antd";
import { css } from "@emotion/react";
import tw from "twin.macro";
import { Enum_Product_Status } from "schema/schema";
import { useUpdate } from "@pankod/refine-core";
import { useProductInfo } from "hooks/useProduct/useProductInfo";
import { usePermission } from "hooks/usePermission";
import { EnumRoleProduct } from "enums";
import { useGetMyRole } from "hooks/useGetMyRole";
import { useMe } from "context/MeContext";

export const DisableProduct: React.FC<{ cb?: () => void; id: string }> = ({ cb, id }) => {
  // const [, updateProduct] = useUpdateProductMutation();
  const { mutateAsync: updateProduct } = useUpdate();
  const { isAdmin } = useMe();
  const { productInfo } = useProductInfo();
  const { combineRoleWithSuperAdmin } = usePermission();
  const { userRoleProductPermission } = useGetMyRole();
  const onClick = async () => {
    await updateProduct({
      resource: "products",
      id,
      values: {
        status: !productInfo.isDisabled ? Enum_Product_Status.Disabled : Enum_Product_Status.Enabled,
      },
      successNotification: {
        type: "success",
        description: "Success",
        message: "PMP status is successfully enabled",
      },
      errorNotification: {
        type: "error",
        description: "Error",
        message: "Fail to enable PMP status",
      },
    });

    cb?.();
  };

  const isActiveStudio = productInfo.studios?.some((studio) => studio.attributes?.status);

  return (
    <Alert
      message="This product has been disabled. If you want to continue, let enable it again."
      type="warning"
      action={
        <Space>
          <Button
            disabled={
              !isActiveStudio ||
              !(combineRoleWithSuperAdmin(userRoleProductPermission === EnumRoleProduct.Owner) || isAdmin)
            }
            onClick={onClick}
            size="small"
            type="primary"
          >
            Enable
          </Button>
        </Space>
      }
      showIcon
      className="mb-4"
      css={css`
        .ant-alert-message {
          ${tw`text-red-700`}
        }
      `}
    />
  );
};
