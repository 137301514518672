import React, { useEffect } from "react";
import { css } from "@emotion/react";
import { Select, Spin } from "@pankod/refine-antd";
import { useCreate } from "@pankod/refine-core";

// ant icon
import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";

// schema
import { Enum_Productuserrole_Status, Enum_Product_Status } from "schema/schema";
import { EnumRoleProduct } from "enums";
import { useGetMyRole } from "hooks/useGetMyRole";
import { useMe } from "context/MeContext";
import { usePermission } from "hooks/usePermission";
import { UserRoleOptions } from "hooks/useUserRole";
import { useProduct } from "context/ProductContext";

interface IProps {
  typeRole: string | undefined;
  role: string | undefined;
  productId?: string;
  userId: string;
  status: string;
  optionsRole: UserRoleOptions[] | undefined;
  refetchProduct: Function;
  handleErrorData: (data: any, callback: any) => void;
  refetchUserRole: () => void;
}

function ColumnUserProductRole({
  typeRole,
  role: roleProps,
  productId,
  userId,
  status,
  optionsRole,
  refetchProduct,
  handleErrorData,
  refetchUserRole,
}: IProps) {
  // states
  const [isShowSelectRole, setIsShowSelectRole] = React.useState(false);
  const [role, setRole] = React.useState(roleProps);

  useEffect(() => {
    setRole(roleProps)
  }, [roleProps]);
  
  const { mutateAsync: updateRole, isLoading: isLoadingUpdateRole } = useCreate();
  const me = useMe();
  const { productInfo } = useProduct();
  const { userRoleProductPermission } = useGetMyRole();
  const { combineRoleWithSuperAdmin } = usePermission();
  const userRoles = React.useMemo(() => {
    return optionsRole?.map((role) => ({
      label: role.label,
      value: role.label,
    }));
  }, [optionsRole]);

  const isDisable = () => {
    if (
      productInfo.status === Enum_Product_Status.Disabled ||
      status !== Enum_Productuserrole_Status.Accepted ||
      userId === me?.data?.id ||
      (me.isMember && !combineRoleWithSuperAdmin(userRoleProductPermission !== EnumRoleProduct.Viewer)) ||
      (me.isMember &&
        !combineRoleWithSuperAdmin(userRoleProductPermission !== EnumRoleProduct.Editor) &&
        typeRole === EnumRoleProduct.Owner)
    ) {
      return true;
    }
    return false;
  };
  function handleClose() {
    setIsShowSelectRole(false);
    setRole(roleProps);
  }
  const findRolePermissionId = (role: string) => {
    return optionsRole?.find((item) => item.label === role)?.id;
  };

  const handleUpdateRole = async () => {
    const productRole = findRolePermissionId(role || "ADMIN");
    setIsShowSelectRole(false);
    await updateRole({
      resource: `products/${productId}/invitations`,
      values: {
        role_id: productRole,
        user_id: userId,
      },
      successNotification: {
        type: "success",
        message: "Updated role successfully",
        description: "Success",
      },
      errorNotification: (error: any) => {
        if (error?.response?.data?.error?.details) {
          handleErrorData(error?.response?.data?.error?.details, handleUpdateRole);
        }
        return {
          type: "error",
          description: "Error",
          message: error?.message || "Failure update role",
        };
      },
    });
    refetchProduct();
    refetchUserRole();
  };
  return (
    <Spin spinning={isLoadingUpdateRole}>
      {isShowSelectRole ? (
        <div className="flex items-center">
          <Select
            value={role}
            options={userRoles}
            placeholder="Role"
            className="w-full"
            onChange={(role: string) => setRole(role)}
            loading={!userRoles}
          />
          <div className="flex ml-[8px]">
            <CheckOutlined
              onClick={() => {
                handleUpdateRole();
              }}
              style={{
                color: "#1890ff",
              }}
            />
            <CloseOutlined
              onClick={handleClose}
              twoToneColor="red"
              className="ml-[8px]"
              style={{
                color: "red",
              }}
            />
          </div>
        </div>
      ) : (
        <div
          className="font-normal"
          onClick={!isDisable() ? () => setIsShowSelectRole(true) : () => {}}
          css={css`
            svg {
              fill: #1890ff;
              cursor: pointer;
              margin-left: 8px;
            }
          `}
        >
          <span className="min-w-[40%] inline-block">{role}</span>
          {!isDisable() && <EditOutlined />}
        </div>
      )}
    </Spin>
  );
}

export default ColumnUserProductRole;
