import { WarningFilled } from "@ant-design/icons";
import { css } from "@emotion/react";
import { Button, Modal, ModalProps, Typography } from "@pankod/refine-antd";
import React from "react";

interface IAlertModal {
  modalProps: ModalProps;
  handleClose: () => void;
  handleOk: () => void;
  message: string;
  isLoading: boolean;
}
const AlertModal: React.FC<IAlertModal> = ({ modalProps, handleClose, handleOk, message, isLoading }) => {
  return (
    <Modal
      {...modalProps}
      centered
      maskClosable={false}
      width={417}
      css={css`
        .ant-modal-content {
          border-radius: 0;
        }
        .ant-modal-body {
          background: linear-gradient(140.02deg, #6d0192 50%, #f97994 97%);
          padding: 10px 15px 25px 15px;
          overflow: hidden;
        }
        .ant-modal-close .anticon {
          display: none;
        }
        .ant-modal-footer {
          background-color: #f1f1f1;
        }
      `}
      footer={[
        <div key="footer">
          <Button
            htmlType="button"
            disabled={isLoading}
            className="bg-gray-400 text-white border-none font-normal"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            htmlType="button"
            className="bg-[#D9534F] text-white border-none font-normal"
            onClick={handleOk}
            loading={isLoading}
          >
            Confirm
          </Button>
        </div>,
      ]}
    >
      <div className=" relative">
        <WarningFilled className="text-[120px] text-[#22075E] absolute z-0 right-0 top-[-30px]" />
        <div className="z-20 relative">
          <Typography.Title className="text-[white]" level={4}>
            Warning
          </Typography.Title>
          <Typography.Text className="text-[white] re z-20 text-[14px]">{message}</Typography.Text>
        </div>
      </div>
    </Modal>
  );
};

export default AlertModal;
