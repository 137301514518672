import { Button, List, message, Modal, Table } from "@pankod/refine-antd";
import { Breadcrumb } from "antd";
import { useCustom, useList, useModal, useNotification } from "@pankod/refine-core";
import { IBuckets, IObject, IProduct } from "interfaces";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { CopyOutlined, DragOutlined } from "@ant-design/icons";
import useTableProps from "hooks/useTableProps";
import config from "config";
import { useMe } from "context/MeContext";
import ProductInfo, { IProductInfo } from "factory/Product";
import { css } from "@emotion/react";
import { getLastPartOfPath } from "utils/folderAndFile";

const CopyAndMove: React.FC<{
  disabled?: boolean;
  object: any[];
  cb?: () => void;
  resetSelectedRow?: () => void;
  productInfo?: ProductInfo;
  currentBucket: string;
}> = ({ disabled, object, cb, resetSelectedRow, productInfo, currentBucket }) => {
  const { data: me } = useMe();

  const {
    data: dataProducts,
    isLoading,
    isRefetching,
  } = useList<IProduct>({
    resource: "products",
    config: {
      filters: [
        {
          field: "product_user_roles][users_permissions_user][id]",
          operator: "eq",
          value: me?.id,
        },
      ],
      pagination: {
        pageSize: 1000,
      },
    },
    metaData: {
      populate: ["integration_payloads.integration"],
    },
  });

  const buckets = React.useMemo(() => {
    return dataProducts?.data.map((p) => {
      const product = new IProductInfo(p);
      return product?.awsPayload?.bucket;
    });
  }, [dataProducts?.data]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const objects =
    React.useMemo(() => {
      return me?.product_user_roles?.map((item) =>
        item?.product?.product_code ? item?.product?.product_code + "/" : "",
      );
    }, [me?.product_user_roles]) || [];

  const bucketsUnique = buckets ? [...new Set(buckets)] : [];
  const { visible, show, close } = useModal();
  const { open } = useNotification();
  const apiUrlS3 = config.S3_URL;
  const [path, setPath] = React.useState({
    bucketName: "",
    prefix: "",
  });
  const isDefaultBucket = path.bucketName === config.BUCKET_NAME;

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [type, setType] = React.useState("copy");
  const [breadcrumbItems, setBreadcrumbItems] = React.useState([
    {
      title: "Buckets",
      link: "",
      type: "list",
    },
  ]);
  const {
    data: copyDataResult,
    isFetching: copyLoading,
    refetch: copyQuery,
  } = useCustom<any>({
    url: `${apiUrlS3}/copyFiles`,
    method: "post",
    queryOptions: {
      enabled: false,
      retry: 0,
    },
    config: {
      payload: {
        data: {
          from: object.map((item) => `${currentBucket}/${item}`),
          to: `${path.bucketName ? path.bucketName + "/" : ""}${selectedRowKeys[0]}`,
          userId: me?.id,
          productId: productInfo?.id,
        },
      },
    },
    dataProviderName: "s3Service",
  });
  const {
    data: moveDataResult,
    isFetching: moveLoading,
    refetch: moveQuery,
  } = useCustom<any>({
    url: `${apiUrlS3}/moveFiles`,
    method: "post",
    queryOptions: {
      enabled: false,
      retry: 0,
    },
    config: {
      payload: {
        data: {
          from: object.map((item) => `${currentBucket}/${item}`),
          to: `${path.bucketName ? path.bucketName + "/" : ""}${selectedRowKeys[0]}`,
          userId: me?.id,
          productId: productInfo?.id,
        },
      },
    },
    dataProviderName: "s3Service",
  });

  const onSubmit = () => {
    try {
      type === "copy" ? copyQuery() : moveQuery();
    } catch (err) {
      open?.({
        type: "error",
        message: config.UnAuthorizedActionErrorMessage,
      });
    }
  };

  const {
    data: dataBuckets,
    isFetching: bucketLoading,
    refetch: refetchList,
  } = useCustom<IBuckets[]>({
    url: `${apiUrlS3}/listBuckets`,
    method: "get",
    queryOptions: {
      enabled: false,
      retry: 0,
    },
    config: {
      query: {
        userId: me?.id,
        productId: productInfo?.id,
      },
    },
    dataProviderName: "s3Service",
  });

  React.useEffect(() => {
    visible && !path.bucketName && refetchList();
  }, [path.bucketName, refetchList, visible]);

  const onSuccess = (title: string) => {
    setPath({
      bucketName: "",
      prefix: "",
    });
    setSelectedRowKeys([]);
    resetSelectedRow?.();
    close();
    message.success(`${title} successfully`);
  };

  React.useEffect(() => {
    if (moveDataResult && type === "move") {
      cb?.();
      onSuccess("Move");
    }
    if (copyDataResult && type === "copy") {
      onSuccess("Copy");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveDataResult, copyDataResult]);

  const listBuckets = me?.isOps
    ? dataBuckets?.data
    : dataBuckets?.data.filter((item) => bucketsUnique.includes(item.Name));

  const { data, refetch, isFetching } = useCustom<any>({
    url: `${apiUrlS3}/listObjectsInBucket`,
    method: "get",
    queryOptions: {
      enabled: visible && Boolean(path.bucketName),
    },
    config: {
      query: path.bucketName && {
        bucketName: path.bucketName,
        prefix: path.prefix,
        userId: me?.id,
        productId: productInfo?.id,
      },
    },
    dataProviderName: "s3Service",
    successNotification: false,
    errorNotification: false,
  });

  React.useEffect(() => {
    if (path.bucketName) {
      visible && refetch();
      setBreadcrumbItems([
        {
          title: "Buckets",
          link: "",
          type: "list",
        },
        {
          title: path.bucketName,
          link: path.bucketName,
          type: "bucket",
        },
      ]);
      if (path.prefix) {
        const part = path.prefix?.split("/");
        const items = part?.map((item, index) => {
          const link = part.slice(0, index + 1).join("/");
          return { title: item, link: link + "/", type: "prefix" };
        });
        setBreadcrumbItems([
          {
            title: "Buckets",
            link: "",
            type: "list",
          },
          {
            title: path.bucketName,
            link: path.bucketName,
            type: "bucket",
          },
          ...(items ? items : []),
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, refetch]);
  const isList = !path.bucketName;
  const listObjects = data?.data;
  const folders = listObjects?.CommonPrefixes as any[];
  const dataSource: IBuckets[] =
    React.useMemo(() => {
      const shouldFilter = !path.prefix && isDefaultBucket && !me?.isOps;
      return folders
        ?.filter((item) => (shouldFilter ? objects.includes(item.Prefix) : true))
        .map((item) => {
          return {
            key: uuidv4(),
            Name: item.Prefix,
            CreationDate: item.LastModified,
          };
        });
    }, [folders, isDefaultBucket, me?.isOps, objects, path.prefix]) || [];

  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
    selectedRowKeys,
    preserveSelectedRowKeys: true,
  };

  const { getColumnSearchProps } = useTableProps();

  const onNameClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, value: string) => {
    e.preventDefault();
    if (!path.bucketName) {
      setPath({
        ...path,
        bucketName: value,
      });
    } else {
      setPath({
        ...path,
        prefix: value,
      });
    }
  };

  //Reset all option
  React.useEffect(() => {
    visible && setSelectedRowKeys([]);
  }, [visible]);

  const disabledBtnModal = selectedRowKeys.length === 0;

  return (
    <>
      <Button
        disabled={disabled}
        onClick={() => {
          setType("copy");
          show();
        }}
      >
        <CopyOutlined /> Copy
      </Button>
      <Button
        disabled={disabled}
        onClick={() => {
          setType("move");
          show();
        }}
      >
        <DragOutlined /> Move
      </Button>
      <Modal
        visible={visible}
        onCancel={close}
        footer={
          <>
            <Button onClick={close}>Cancel</Button>
            <Button type="primary" onClick={onSubmit} disabled={disabledBtnModal} loading={copyLoading || moveLoading}>
              {type === "copy" ? "Copy" : "Move"}
            </Button>
          </>
        }
        destroyOnClose
        css={css`
          .ant-page-header-heading,
          .ant-table {
            box-shadow: none !important;
            margin-bottom: 0;
          }

          .ant-modal-body {
            padding: 10px;
          }
          .ant-table {
            border: 1px solid #d9d9d9;
          }
        `}
      >
        <List title={`${type === "copy" ? "Copy" : "Move"}`}>
          <div className="px-4 pb-4">
            <Breadcrumb className="mb-4">
              {breadcrumbItems.map((item, key) => {
                return (
                  <Breadcrumb.Item
                    key={key}
                    className="cursor-pointer"
                    onClick={() => {
                      if (item.type === "bucket") {
                        setPath({ prefix: "", bucketName: item.link });
                      } else if (item.type === "prefix") {
                        setPath({ ...path, prefix: item.link });
                      } else {
                        setPath({ bucketName: "", prefix: "" });
                        setBreadcrumbItems([
                          {
                            title: "Buckets",
                            link: "",
                            type: "list",
                          },
                        ]);
                      }
                    }}
                  >
                    {item.title}
                  </Breadcrumb.Item>
                );
              })}
            </Breadcrumb>
            <Table
              dataSource={isList ? listBuckets : dataSource}
              rowKey="Name"
              loading={isFetching || bucketLoading || isLoading || isRefetching}
              rowSelection={
                !isList
                  ? {
                      ...rowSelection,
                      type: "radio",
                    }
                  : undefined
              }
              scroll={{ y: 450 }}
            >
              <Table.Column
                dataIndex="Name"
                key="Name"
                title="Name"
                render={(value) => {
                  return (
                    <a href={"/"} onClick={(e) => onNameClick(e, value)}>
                      {getLastPartOfPath(value)}
                    </a>
                  );
                }}
                {...getColumnSearchProps({
                  dataIndex: "Name",
                })}
                sorter={(a: IObject, b: IObject) => a.Name.localeCompare(b.Name)}
              />
            </Table>
          </div>
        </List>
      </Modal>
    </>
  );
};

export default CopyAndMove;
