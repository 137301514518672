import { Button, Collapse, Form, Grid, Spin } from "@pankod/refine-antd";
import { useApiUrl, useCustomMutation, useList, useNavigation } from "@pankod/refine-core";
import moment from "moment";
import React from "react";
import { Enum_App_Platform, Enum_App_Status, Enum_Integration_Service } from "schema/schema";
import { DaremiAppInfo } from "./EditForm";
import { PerformanceMonitoring } from "./PerformanceMonitoring";
import { useParams } from "react-router-dom";
import { IDaremi } from "interfaces";
import { StatusAppIntegration } from "config";
import { useProduct } from "context/ProductContext";
import { convertIOSStoreIdAppsflyer } from "utils/convertIdAppsflyer";
import { useApp } from "hooks/useApp";
import { useGetMyRole } from "hooks/useGetMyRole";
import { EnumRoleProduct } from "enums";
import { useMe } from "context/MeContext";
const { Panel } = Collapse;
interface CollapseContentDaremiProps {
  mode?: "update" | "register";
  getPayload: (serviceName: Enum_Integration_Service) => any;
  setIsLoadingAction?: any;
}

export const CollapseContentDaremi: React.FC<CollapseContentDaremiProps> = ({
  mode = "register",
  getPayload,
  setIsLoadingAction,
}) => {
  const { userRoleProductPermission } = useGetMyRole();
  const { isMember } = useMe();
  const params = useParams();
  const [form] = Form.useForm();
  const { goBack } = useNavigation();
  const apiUrl = useApiUrl();
  const productId = params.productId || params.id;
  const breakpoint = Grid.useBreakpoint();
  const { isStale } = useProduct();
  const { app, isFetching } = useApp();
  const payloadIronSource = getPayload(Enum_Integration_Service.IronSource)?.apps;
  const payloadDaremi = getPayload(Enum_Integration_Service.Daremi)?.apps;
  const payloadAppsflyer = getPayload(Enum_Integration_Service.Appsflyer)?.apps;
  const appIronSource = app?.product_code && payloadIronSource && payloadIronSource[app?.product_code];
  const appDaremi: IDaremi | undefined = app?.product_code && payloadDaremi && payloadDaremi[app?.product_code];
  const appAppsFlyer = app?.product_code && payloadAppsflyer && payloadAppsflyer[app?.product_code];
  const isAppsFlyerConnected = appAppsFlyer && !Boolean(appAppsFlyer[StatusAppIntegration]);
  const isIronSourceConnected = appIronSource && !Boolean(appIronSource[StatusAppIntegration]);
  const dateFormat = "YYYY/MM/DD";
  const storeId =
    app?.platform === Enum_App_Platform.Ios ? convertIOSStoreIdAppsflyer(app?.connect_url) : app?.bundle_id;
  const redirectListingDaremi = () => {
    goBack();
  };
  // get product list dataset
  const { data: listDataset, isLoading: isLoadingDataSet } = useList<String>({
    resource: `bigquery/${app?.firebase?.project_id}/datasets`,
    errorNotification: false,
    successNotification: false,
  });

  React.useEffect(() => {
    if (
      (!isStale &&
        app &&
        ((!payloadDaremi?.[app?.product_code] && mode === "update") ||
          (payloadDaremi?.[app?.product_code] && mode === "register") ||
          !payloadDaremi)) ||
      (!isFetching && app?.status && app?.status !== Enum_App_Status.Active)
    ) {
      redirectListingDaremi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payloadDaremi, isStale, isFetching]);

  const { mutateAsync: daremiAction, isLoading: isLoadingActionDaremi } = useCustomMutation();
  React.useEffect(() => {
    setIsLoadingAction(isLoadingActionDaremi);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingActionDaremi]);
  const onSubmit = async (e: any) => {
    const { appsflyer_app_id, ironsource_app_id, bundle_id, bigquery_datasets, ...input } = e;
    const date = moment(input.pm_start_date).format("YYYY-MM-DD");
    const pm_enabled = input.pm_enabled;
    const result = await daremiAction({
      url: `${apiUrl}/products/${productId}/integrations:daremi/app`,
      method: `${mode === "register" ? "post" : "patch"}`,
      values: {
        data: {
          ...input,
          bigquery_datasets: bigquery_datasets?.join(",") || "",
          app_id: app?.id,
          pm_start_date: pm_enabled ? date : undefined,
        },
      },
      successNotification: {
        type: "success",
        message: `You have successfully ${mode === "register" ? "registered" : "updated"} Daremi app`,
      },
      errorNotification: {
        type: "error",
        message: `Fail to ${mode === "register" ? "registered" : "updated"} Daremi app`,
      },
    });
    if (result.data?.data?.id) {
      redirectListingDaremi();
    }
  };
  const appsflyer_app_id = () => {
    if (appDaremi?.appsflyer_app_id) {
      return appDaremi?.appsflyer_app_id;
    } else {
      if (isAppsFlyerConnected) {
        return storeId;
      }
    }
    return "";
  };

  const ironsource_app_id = () => {
    if (appDaremi?.ironsource_app_id) {
      return appDaremi?.ironsource_app_id;
    } else {
      if (isIronSourceConnected) {
        return appIronSource?.appKey;
      }
    }
    return "";
  };
  const iosStoreId = convertIOSStoreIdAppsflyer(app?.connect_url);

  return (
    <Spin spinning={isLoadingActionDaremi || isLoadingDataSet}>
      <Form
        form={form}
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 15 }}
        layout={`${breakpoint.sm ? "horizontal" : "vertical"}`}
        onFinish={onSubmit}
        initialValues={{
          app_id: app?.product_code,
          bundle_id: app?.platform === "IOS" ? iosStoreId : app?.bundle_id,
          appsflyer_app_id: appsflyer_app_id(),
          ironsource_app_id: ironsource_app_id(),
          pm_enabled: Boolean(appDaremi?.pm_enabled),
          pm_subscription_enabled: appDaremi?.pm_subscription_enabled,
          pm_has_eazegames_rev: appDaremi?.pm_has_eazegames_rev,
          pm_start_date: appDaremi?.pm_start_date ? moment(appDaremi.pm_start_date, dateFormat) : "",
          bigquery_datasets: (appDaremi?.bigquery_datasets ?? "").split(",").filter(Boolean),
        }}
      >
        <Collapse defaultActiveKey={["AppInfo", "PerformanceMonitoring", "EventRegister"]}>
          <Panel header={<span className="font-bold text-[14px]">App info</span>} key="AppInfo">
            <DaremiAppInfo form={form} listDataset={listDataset?.data || []} />
          </Panel>
          <Panel
            header={<span className="font-bold text-[14px]">Performance Monitoring</span>}
            key="PerformanceMonitoring"
          >
            <PerformanceMonitoring form={form} disableDatePickerProps={!appDaremi?.pm_enabled} />
          </Panel>
          <Panel header={<span className="font-bold text-[14px]">Event Register</span>} key="EventRegister">
            <span className="text-[14px] font-normal">
              Daremi will automatically register{" "}
              <a
                target="_blank"
                href="https://docs.google.com/spreadsheets/d/11nfOpPm2rvKwf_yxAdcL1IrXj6glC6MbpPaMedRLZUw/edit#gid=0"
                rel="noreferrer"
              >
                standard event lists
              </a>{" "}
              for your app
            </span>
          </Panel>
        </Collapse>
        <div className="flex justify-end pt-4 space-x-2">
          {mode === "update" && (
            <Button type="ghost" onClick={redirectListingDaremi} className="">
              Close
            </Button>
          )}
          <Button
            type="primary"
            htmlType="submit"
            className=""
            loading={isLoadingActionDaremi}
            disabled={userRoleProductPermission === EnumRoleProduct.Viewer && isMember}
          >
            {mode === "register" ? "Register" : "Update"} app
          </Button>
        </div>
      </Form>
    </Spin>
  );
};
