import React from "react";
import { Typography, Icons } from "@pankod/refine-antd";

const { Title, Text } = Typography;
const { CheckCircleOutlined, CheckOutlined } = Icons;

function Status() {
  return (
    <>
      <Title level={4} className="italic">
        Your install was recorded!
      </Title>
      <div className="text-center">
        <CheckCircleOutlined className="text-[110px] text-lime-400" />
        <ul className="mt-20 text-left mx-auto max-w-lg">
          <li className="">
            <CheckOutlined className="text-[20px] text-lime-600" />
            <Text className="ml-2">IDFA collected</Text>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Status;
