import React from "react";
import { Typography, Card, Modal, Spin, useModal } from "@pankod/refine-antd";
import { DisconnectOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { IIntegration, IIntegrationDataCreate } from "interfaces";
import { useCreate, useDelete, useNavigation, useApiUrl, useCustom } from "@pankod/refine-core";
import { useProduct } from "context/ProductContext";
import _kebabCase from "lodash/kebabCase";
import { Enum_Integration_Service } from "schema/schema";
import { usePermission } from "hooks/usePermission";
import { EnumRoleProduct } from "enums";
import { useGetMyRole } from "hooks/useGetMyRole";
import { ImageElement } from "components/Image";
import { IntegrationModalDetailCreate } from "components/Modal/IntegrationModalCreate";
import { capitalizeFirstLetter } from "utils/string";
import { ReConnectRevenueCatModal } from "components/Modal/ReConnectRevenueCatModal";
import { useMe } from "context/MeContext";

export type CardIntegrationBaseProps = {
  item: IIntegration;
  className?: string;
  to?: string;
  productId?: string | undefined;
  isConnectedCreate?: boolean;
  canEdit?: boolean;
  onClick?: () => void;
  onDisconnect?: () => void;
  mode: "create" | "edit";
  isDisabled?: boolean;
  isLoading?: boolean;
  isRevenueCatLoading?: boolean;
};

export type CardIntegrationProps = Omit<CardIntegrationBaseProps, "mode" | "canEdit" | "isLoading" | "onDisconnect"> & {
  handleSetIntegrationData?: React.Dispatch<React.SetStateAction<IIntegrationDataCreate[]>>;
};

export type CardIntegrationDetailProps = CardIntegrationProps & {
  isLoading?: boolean;
};

export const CardIntegration: React.FC<CardIntegrationProps> = (props) => {
  const { item, handleSetIntegrationData } = props;

  const handleDisconnect = () => {
    handleSetIntegrationData?.((prev) => {
      const newData = prev.filter((e) => e.id !== item.id);
      return newData;
    });
  };

  return <CardIntegrationBase {...props} mode="create" canEdit onDisconnect={handleDisconnect} isLoading={false} />;
};

export const CardIntegrationDetail: React.FC<CardIntegrationDetailProps> = (props) => {
  const apiUrl = useApiUrl();

  // handle data body
  const dataBody = (service: string, e: any) => {
    switch (service) {
      case Enum_Integration_Service.EventGateway:
        return {
          secret_key: e.secret_key ? e.secret_key : "",
        };
      case Enum_Integration_Service.AmaPassport:
        return {
          app_secret: e.app_secret ? e.app_secret : "",
        };
      case Enum_Integration_Service.RevenueCat:
        return {
          project_url: e.project_url ? e.project_url : "",
        };
      case Enum_Integration_Service.ApplovinMax:
      case Enum_Integration_Service.Appsflyer:
      case Enum_Integration_Service.IronSource:
        return {};
      default:
        return {};
    }
  };

  const getMessage = (mode: "disconnect" | "connect", type: "error" | "success", serviceName: string) => {
    const isSuccess = type === "success";
    const isConnected = mode === "connect";
    //function
    return {
      type: type,
      message: isSuccess
        ? `Product have successfully ${isConnected ? "connected" : "disconnected"} to ${serviceName}`
        : `Fail to ${isConnected ? "connected" : "disconnected"} from ${serviceName}`,
      description: capitalizeFirstLetter(type),
    };
  };
  const { push } = useNavigation();

  const { item } = props;
  const { show, modalProps, close } = useModal();
  const { show: reConnectShow, modalProps: reConnectProps, close: reConnectClose } = useModal();

  //hook get data
  const { mutateAsync: disconnectIntegration, isLoading: isLoadingDisconnected } = useDelete();
  const { mutateAsync: connectIntegration, isLoading: isLoadingCreate } = useCreate();
  const { productInfo, refetchProduct } = useProduct();
  const isLoading = isLoadingDisconnected || isLoadingCreate;
  const { combineRoleWithSuperAdmin } = usePermission();
  const { isMember } = useMe();
  const { userRoleProductPermission } = useGetMyRole();
  const [isConnected, setIsConnected] = React.useState(props.isConnectedCreate);
  React.useEffect(() => {
    setIsConnected(props.isConnectedCreate);
  }, [props.isConnectedCreate]);

  const {
    data: verifyData,
    isFetching: isVerifyLoading,
    refetch: verifyProject,
  } = useCustom({
    url: `${apiUrl}/products/${productInfo.id}/integrations:revenue-cat/verify`,
    method: "get",
    queryOptions: {
      enabled: false,
      retry: 0,
    },
    successNotification: false,
    errorNotification: false,
  });
  //function handle disconnect
  const handleDisconnect = async () => {
    const res = await disconnectIntegration({
      resource: `products/${productInfo.id}/integrations:${_kebabCase(item?.service).toLowerCase()}/disconnect`,
      id: "",
      successNotification: { ...getMessage("disconnect", "success", item.name) },
      errorNotification: { ...getMessage("disconnect", "error", item.name) },
    });
    if (res.data) {
      setIsConnected(false);
      refetchProduct();
    }
  };

  const handleConnect = async (e: any) => {
    close?.();
    const res = await connectIntegration({
      resource: `products/${productInfo.id}/integrations:${_kebabCase(item?.service).toLowerCase()}/connect`,
      values: dataBody(item?.service, e),
      successNotification: { ...getMessage("connect", "success", item.name) },
      errorNotification: { ...getMessage("connect", "error", item.name) },
    });
    if (res.data) {
      setIsConnected(true);
      refetchProduct();
    }
  };

  const handleClick = async () => {
    if (item.service === Enum_Integration_Service.Aws) {
      push(`/products/show/${productInfo.id}/integrations/edit/aws`);
      return;
    }
    if (isConnected) {
      push(`/products/show/${productInfo.id}/integrations/${item.service.toLowerCase()}`);
      return;
    }
    if (
      !props.isDisabled &&
      (combineRoleWithSuperAdmin(userRoleProductPermission !== EnumRoleProduct.Viewer) || !isMember)
    ) {
      if (item.service !== Enum_Integration_Service.RevenueCat) {
        show();
        return;
      }
      const result = await verifyProject();
      if (result?.data?.data?.data?.attributes.canCreateNew || result.error) {
        show();
      } else {
        reConnectShow();
      }
    }
  };

  return (
    <>
      <ReConnectRevenueCatModal
        modalProps={reConnectProps}
        handleClose={reConnectClose}
        projectInfo={verifyData?.data.data}
        handleConnect={handleConnect}
      />
      <IntegrationModalDetailCreate
        item={item}
        modalProps={modalProps}
        close={close}
        handleIntegration={handleConnect}
      />
      <CardIntegrationBase
        {...props}
        isConnectedCreate={isConnected}
        onClick={handleClick}
        mode="edit"
        isLoading={isLoading}
        isRevenueCatLoading={isVerifyLoading}
        canEdit={combineRoleWithSuperAdmin(userRoleProductPermission === EnumRoleProduct.Owner) || !isMember}
        onDisconnect={handleDisconnect}
      />
    </>
  );
};

const CardIntegrationBase: React.FC<CardIntegrationBaseProps> = ({
  item,
  canEdit,
  isConnectedCreate,
  onClick,
  onDisconnect,
  isLoading,
  mode,
  isRevenueCatLoading,
}) => {
  const { productInfo } = useProduct();
  const isDisabled = productInfo?.isDisabled;
  const selectable = item.selectable;
  const { confirm } = Modal;

  const showConfirm = () => {
    confirm({
      centered: true,
      title: `Are you sure to disconnect with ${item.name}?`,
      icon: <ExclamationCircleOutlined />,
      onOk: onDisconnect,
    });
  };

  const canShowDisconnectButton = React.useMemo(() => {
    return isConnectedCreate && selectable && !isDisabled && canEdit && item.can_disconnect;
  }, [canEdit, isConnectedCreate, isDisabled, item.can_disconnect, selectable]);
  const isSpinning =
    isLoading || (item.service === Enum_Integration_Service.RevenueCat && Boolean(isRevenueCatLoading));
  return (
    <div className="w-1/2 px-[2rem]">
      <Card
        className={`w-full shadow-md mb-[1.5rem] px-[10px] relative ${
          selectable ? `cursor-pointer ${isConnectedCreate ? "white-card" : " bg-begie-100"}` : "white-card"
        }`}
      >
        <Spin spinning={isSpinning} className="m-0 p-0">
          {canShowDisconnectButton && (
            <DisconnectOutlined
              className="text-dust-red absolute top-[9px] right-[9px] text-xl"
              onClick={showConfirm}
            />
          )}

          <div
            onClick={(selectable && onClick) || undefined}
            className={
              (mode === "create" &&
                isConnectedCreate &&
                item?.service !== Enum_Integration_Service.Aws &&
                "pointer-events-none") ||
              undefined
            }
          >
            <Card.Meta
              avatar={
                <div className="w-[64px] h-[64px] flex items-center justify-center">
                  <ImageElement
                    className={`${!isConnectedCreate && selectable ? "grayscale" : ""} w-full object-contain`}
                    src={item?.icon?.url}
                  />
                </div>
              }
              title={
                <Typography.Title className="font-bold mb-0" level={5}>
                  {item?.name}
                </Typography.Title>
              }
              description={
                <Typography.Paragraph
                  ellipsis={{
                    rows: 1,
                  }}
                  className="text-[13px] mb-0"
                >
                  {item?.description}
                </Typography.Paragraph>
              }
            />
          </div>
        </Spin>
      </Card>
    </div>
  );
};
