import React from "react";
import { css } from "@emotion/react";
import { Collapse, Empty, useModal, Button } from "@pankod/refine-antd";
import { useNotification } from "@pankod/refine-core";

// context
import { useProduct } from "context/ProductContext";

// components
import UserManagement from "./UserManagement";
import FirebaseItem from "./FirebaseItem";
import { AddFirebaseModal } from "components/Modal/AddFirebaseModal";
import { useGetMyRole } from "hooks/useGetMyRole";
import { usePermission } from "hooks/usePermission";
import { EnumRoleProduct } from "enums";
import { useMe } from "context/MeContext";
const { Panel } = Collapse;
export const FirebaseInfo = () => {
  // hooks
  const { open } = useNotification();
  const { productInfo } = useProduct();
  const { isMember } = useMe();
  const firebases = productInfo?.firebases;
  const { show, modalProps, close } = useModal();
  const { userRoleProductPermission } = useGetMyRole();
  const { combineRoleWithSuperAdmin } = usePermission();
  const isDisabledAddNewFirebase = !(
    !productInfo.isDisabled &&
    (combineRoleWithSuperAdmin(userRoleProductPermission === EnumRoleProduct.Owner) || !isMember)
  );
  return (
    <div
      css={[
        css`
          .ant-collapse-header {
            border-bottom: 1px solid #e3e3e3;
            border-radius: 0 !important;
          }
          .ant-collapse-content-box {
            padding-bottom: 45px !important;
          }
        `,
      ]}
    >
      <Collapse defaultActiveKey={["1", "2"]} ghost>
        <Panel
          className="text-[14px]"
          header={<span className="text-[#2D2D2D] font-normal">Firebase project info</span>}
          key="1"
        >
          <>
            {firebases?.length === 0 ? (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="w-full" />
            ) : (
              <>
                {firebases?.map((firebase, firebaseIndex) => (
                  <FirebaseItem
                    key={firebaseIndex}
                    firebase={firebase}
                    firebaseIndex={firebaseIndex}
                    userRolePermision={userRoleProductPermission}
                  />
                ))}
              </>
            )}
          </>
          <AddFirebaseModal
            modalProps={modalProps}
            handleClose={close}
            showErrorNotification={false}
            customOnError={(error) => {
              if (error.statusCode === 400) {
                open?.({
                  type: "error",
                  message: "Firebase already exist.",
                });
                return;
              }
              open?.({
                type: "error",
                message: error.message,
              });
            }}
          />
          <Button onClick={show} size="small" type="primary" disabled={isDisabledAddNewFirebase}>
            Add new project
          </Button>
        </Panel>
        <Panel
          className="text-[14px]"
          header={<span className="text-[#2D2D2D] font-normal">User Management</span>}
          key="2"
        >
          <UserManagement />
        </Panel>
      </Collapse>
    </div>
  );
};
