import React from "react";
import { Result, Button } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import config from "config";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigation } from "@pankod/refine-core";
const NotAcceptInvitation: React.FC = () => {
  const { push } = useNavigation();
  const { logout: logoutAuth0 } = useAuth0();
  return (
    <div className="flex justify-center w-screen h-screen">
      <Result
        className="place-self-center "
        icon={<SmileOutlined />}
        title="You haven't accepted invitation!"
        subTitle="Please, check your email and try again"
        extra={
          <>
            <Button
              onClick={() => push("/")}
              className="inline-flex items-center bg-primary text-white hover:border hover:border-black hover:text-black"
            >
              Back Home
            </Button>
            <Button
              onClick={() => {
                window.localStorage.removeItem(config.TOKEN_KEY);
                logoutAuth0({ returnTo: window.location.origin });
              }}
              className="inline-flex items-center bg-primary text-white hover:border hover:border-black hover:text-black"
            >
              Logout
            </Button>
          </>
        }
      />
    </div>
  );
};

export default NotAcceptInvitation;
