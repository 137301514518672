import React from "react";
import { useProductsQuery } from "schema/schema";
import { useDebounce } from "./useDebounce";

export const useCheckProductCode = (pCodeClient = "", pause = true) => {
  const [productCode, setProductCode] = React.useState(pCodeClient);

  const debouncedProductCode = useDebounce(productCode, 500);
  const [productResult, searchProductCode] = useProductsQuery({
    variables: {
      filters: {
        product_code: {
          eq: debouncedProductCode,
        },
      },
    },
    pause,
    requestPolicy: "network-only",
  });

  React.useMemo(() => {
    debouncedProductCode && searchProductCode();
  }, [debouncedProductCode, searchProductCode]);
  const pCodeServer = productResult.data?.products?.data
    ? productResult.data?.products?.data[0]?.attributes?.product_code
    : "";
  const isExisting =
    productResult.data?.products?.data && productResult.data?.products?.data.length > 0 && pCodeClient !== pCodeServer;

  return {
    isExisting,
    isProductCodeResultFetching: productResult.fetching,
    setProductCode,
    productCode,
  };
};
