import { css } from "@emotion/react";
import { Form, Icons, Input, Select, Typography } from "@pankod/refine-antd";
import config from "config";
import { useMe } from "context/MeContext";
import { useProductStepForm } from "context/ProductStepFormContext";
import { useCheckProductCode } from "hooks/useCheckProductCode";
import useLocalStorage from "hooks/useLocalStorage";
import { useUploadForm } from "hooks/useUploadForm";
import { useSelectUsers } from "hooks/useUsers/useSelectUsers";
import { uniqBy } from "lodash";
import React from "react";
import { getBase64 } from "utils/folderAndFile";
import { FormItemUsers } from "../components/User/FormItemUsers";

const { ProjectOutlined } = Icons;

interface GeneralInfoStepProps {
  isEdit?: boolean;
  editStudioOptions?: any[];
}

export const GeneralInfoStep: React.FC<GeneralInfoStepProps> = ({ isEdit, editStudioOptions }) => {
  const { data: me } = useMe();
  const [studioId] = useLocalStorage<string>("studioId");
  const meStudios: (string | null | undefined)[] | undefined = me?.studio_user_roles?.map((item) =>
    item.studio?.id.toString(),
  );
  const { form, values, product, setFormLoading } = useProductStepForm();
  const { isExisting, setProductCode, productCode, isProductCodeResultFetching } = useCheckProductCode();
  const {
    studioOptions,
    setStudioIds,
    isLoadingStudio,
    studioIds,
    usersAppsOptions,
    usersData,
    handleScroll,
    setSearchText,
  } = useSelectUsers();
  const createStudioOptions = studioOptions?.map((item) => {
    const disabled = item.value?.toString() === studioId.toString() ? true : false;
    return {
      ...item,
      disabled: isEdit ? disabled : false,
    };
  });
  const studiosNotBelongMe = isEdit ? editStudioOptions?.filter((item) => !meStudios?.includes(item.value)) : [];

  const _studioOptions =
    studiosNotBelongMe &&
    createStudioOptions &&
    uniqBy(
      [
        ...studiosNotBelongMe.map((item) => {
          return {
            ...item,
            disabled: true,
          };
        }),
        ...createStudioOptions,
      ],
      (e) => {
        return e.value;
      },
    );

  // store studioIds
  const _studios = values?.studios;
  React.useEffect(() => {
    _studios && setStudioIds(_studios);
  }, [_studios, setStudioIds]);

  const { UploadForm, setLoading, loading } = useUploadForm();

  React.useEffect(() => {
    productCode && !isProductCodeResultFetching && form?.validateFields(["product_code"]);
  }, [form, isProductCodeResultFetching, productCode]);

  React.useEffect(() => {
    setFormLoading?.(loading);
  }, [loading, setFormLoading]);

  const [iconUrl, setIconUrl] = React.useState("");
  const iconFile = values?.icon?.file?.originFileObj;
  React.useEffect(() => {
    iconFile &&
      getBase64(iconFile, (url) => {
        setIconUrl(url);
      });
  }, [iconFile]);
  const url =
    config.REACT_APP_ENV === "local" && product?.icon?.url
      ? config.BACKEND_URL + product?.icon?.url
      : product?.icon?.url;

  const icon = { url: iconUrl || url };
  return (
    <>
      <Typography.Title level={5} className="text-center mb-[2rem] flex items-center justify-center">
        <ProjectOutlined className="text-[1.5rem] mr-2" />
        Game Information
      </Typography.Title>
      <Form.Item
        label="Product name"
        name="name"
        rules={[
          {
            required: true,
            min: 4,
            max: 30,
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="Enter your product name" />
      </Form.Item>
      <Form.Item
        label="Product code"
        name="product_code"
        validateTrigger={["onBlur", "onChange"]}
        hasFeedback
        rules={[
          {
            required: true,
            whitespace: false,
            pattern: /^[a-zA-Z0-9]{1,6}$/,
            max: 6,
            message: "Product code only has numbers, letters and maximum is 6",
          },
          () => ({
            validator() {
              if (!isExisting) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Product code already exist!"));
            },
          }),
        ]}
      >
        <Input
          value={productCode}
          css={css`
            input {
              text-transform: ${productCode ? "uppercase" : "none"};
            }
          `}
          onChange={(e) => {
            const val = e.target.value.replace(/\s/g, "");
            setProductCode(val.toUpperCase());
          }}
          placeholder="Enter your product code"
          size="middle"
          autoComplete="off"
          disabled={isEdit}
        />
      </Form.Item>
      <Form.Item
        label="Studios"
        name={"studios"}
        rules={[
          {
            required: true,
          },
        ]}
        className="w-full"
      >
        <Select
          filterOption={(input, option) =>
            (option!.label as unknown as string).toLowerCase().includes(input.toLowerCase())
          }
          options={_studioOptions}
          loading={isLoadingStudio}
          placeholder="Select studio"
          mode="multiple"
          onChange={(val: string[]) => {
            setStudioIds(val);
          }}
        />
      </Form.Item>
      {!isEdit && (
        <FormItemUsers
          studioIds={studioIds}
          userOptions={usersAppsOptions}
          users={usersData}
          handleScroll={handleScroll}
          setSearchText={setSearchText}
          form={form}
        />
      )}
      <Form.Item
        className="w-full px-2"
        label="Description"
        name="description"
        rules={[
          {
            max: 500,
          },
        ]}
      >
        <Input.TextArea placeholder="Enter your product description" rows={3} />
      </Form.Item>
      <UploadForm setLoading={setLoading} loading={loading} label="Product image" data={{ icon }} />
    </>
  );
};
