import green from "./colors/green";
import blue from "./colors/blue";
import cyan from "./colors/cyan";
import indigo from "./colors/indigo";
import grape from "./colors/grape";
import yellow from "./colors/yellow";
import orange from "./colors/orange";
import violet from "./colors/violet";
import teal from "./colors/teal";
import pink from "./colors/pink";
import red from "./colors/red";
import lime from "./colors/lime";

export const othersColor = "#BFBFC1";
export const organicColor = green[500];
export const organicLightColor = green[200];
export const nonOrganicColor = blue[500];
export const nonOrganicLightColor = blue[200];
export const clicksColor = cyan[300];
export const impressionsColor = indigo[300];
export const negativeBarColor = blue[200];
export const positiveBarColor = blue[400];
export const htmlColor = "#DADADA";
interface ISystemColors {
  [key: string]: string;
}

export const systemColors: ISystemColors = {
  organic: "rgb(81, 207, 102)",
  "non-organic": "rgb(51, 154, 240)",
};

export const palette = [
  indigo[500],
  cyan[300],
  grape[700],
  indigo[300],
  yellow[500],
  grape[400],
  orange[400],
  violet[500],
  cyan[600],
  orange[500],
  blue[500],
  red[400],
  violet[400],
  green[500],
  lime[400],
  teal[400],
  pink[200],
  cyan[200],
  teal[700],
  grape[200],
  orange[700],
  yellow[300],
];
