// interfaces
import { ITransformData, IConvertData, ISource, ITempChart } from "interfaces/chart";
import { DIMENSIONS, CURRENT_SOURCE } from "utils/chart/chartConfig";

import { getNameFomatter, generateAxisFormatted, getItemColor } from "../chartHelper/commonHelper";
import { reOrederDescBarChart, otherDimensionBarData, mappingSeriesReversedBarChart } from "../chartHelper/barHelper";

export const transformBarData = ({ data, dimension, metric }: ITransformData): IConvertData => {
  const source: ISource[] = data ? data.data : [];

  if (source.length === 0) {
    return {
      categories: [],
      series: [],
      xAxis: [],
    };
  }

  const temp = source.reduce(
    (acc: ITempChart, currItem, currIndex: number) => {
      if (dimension === DIMENSIONS.EventName.value && currItem.inapps) {
        const name = currItem?.source || `${dimension + " " + currIndex}`;
        const color = getItemColor(dimension, currIndex, currItem, metric);
        const appCategories = Object.keys(currItem.inapps);
        acc.categories = [...new Set([...acc.categories, ...appCategories])];
        const seriesData = acc.categories.map((item: any) => currItem.inapps[item][metric] ?? 0);
        acc.series.push({
          name:
            currItem?.source === "non-organic" || currItem?.source === "organic"
              ? CURRENT_SOURCE[currItem.source]
              : name,
          color,
          data: seriesData,
        });
      } else {
        // other dimensions
        const { categories, series, xAxis } = otherDimensionBarData(source, dimension, metric);
        acc.categories = categories;
        acc.series = series;
        acc.xAxis = xAxis;
      }

      return acc;
    },
    {
      categories: [],
      series: [],
      xAxis: [],
    },
  );

  // re-order & change number descending series, categories by xAxis
  if (dimension === DIMENSIONS.EventName.value) {
    const { series, categories, xAxis } = reOrederDescBarChart(temp, metric);
    temp.series = series;
    temp.categories = categories;
    temp.xAxis = xAxis;
  } else {
    temp.xAxis = temp.xAxis.map((item) => generateAxisFormatted(item, metric));
  }
  const seriesReversed = mappingSeriesReversedBarChart(temp.series, dimension, metric);

  return {
    categories: [...temp.categories].map((cate) => getNameFomatter(dimension, cate)).slice(0, 10),
    xAxis: [...temp.xAxis].slice(0, 10),
    series: [...seriesReversed].slice(0, 10),
  };
};

export default transformBarData;
