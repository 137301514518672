import { SearchOutlined } from "@ant-design/icons";
import { Button, FilterDropdownProps, Input, Typography, Image } from "@pankod/refine-antd";
import { CrudFilters, CrudOperators } from "@pankod/refine-core";
import React from "react";
import type { InputRef } from "antd";
import { CopyToClipboardTooltip } from "components/Input/CopyToClipboard";
import { Link, useParams } from "react-router-dom";
import EmptyAvatar from "assets/img/empty-ava.webp";
import { convertIOSStoreIdAppsflyer } from "utils/convertIdAppsflyer";
import { Enum_App_Status } from "schema/schema";
import { EnumDaremiStatus } from "enums";

interface SearchTableProps {
  filters: CrudFilters | undefined;
  setFilters: ((filters: CrudFilters, behavior?: "merge" | "replace" | undefined) => void) &
    ((setter: (prevFilters: CrudFilters) => CrudFilters) => void);
}

interface ColumnSearchProps {
  isCustomized?: boolean;
  logicalFilters?: { field: string; operator: Exclude<CrudOperators, "or"> }[];
  placeholder?: string;
  dataIndex?: string;
  dataRender?: string;
}

const useTableProps = (data = {} as SearchTableProps) => {
  const { filters, setFilters } = data;
  const searchInput = React.useRef<InputRef>(null);
  const { productId, serviceName, id } = useParams();
  const getColumnSearchProps = React.useCallback(
    (props: ColumnSearchProps) => {
      const { logicalFilters, isCustomized, placeholder, dataIndex } = props;
      const _dataIndex = dataIndex ? dataIndex : "name";
      return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }: FilterDropdownProps) => {
          const handleSearchCustom = () => {
            const value = selectedKeys[0] ? selectedKeys[0].toString() : undefined;
            const _logicalFilters: any = logicalFilters?.map((l) => {
              return {
                field: l.field,
                operator: l.operator,
                value,
              };
            });
            const options: CrudFilters = value
              ? [
                  {
                    operator: "or",
                    value: _logicalFilters ? _logicalFilters : [],
                  },
                ]
              : [];
            filters && (options.length > 0 ? setFilters([...filters, ...options]) : setFilters(filters, "replace"));
          };
          const onSearch = () => {
            isCustomized ? handleSearchCustom() : confirm();
          };
          return (
            <div className="p-[8px] flex items-center">
              <Input
                className="m-0"
                ref={searchInput}
                placeholder={`${placeholder ? placeholder : "Search ..."}`}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={(e) => {
                  e.stopPropagation();
                  onSearch();
                }}
                allowClear
              />
              <div className="pl-1">
                <Button type="primary" onClick={onSearch} icon={<SearchOutlined />} size="small" className="w-[40px]" />
              </div>
            </div>
          );
        },
        filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
        onFilter: (value: any, record: any) =>
          record[_dataIndex] ? record[_dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownVisibleChange: (visible: boolean) => {
          if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
          }
        },
        sorter: true,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const getLinkStore = (record: any) => {
    if (["IOS", "iOS"].includes(record.platform)) {
      return ` https://apps.apple.com/app/id${convertIOSStoreIdAppsflyer(record.connect_url)}`;
    }
    return `https://play.google.com/store/apps/details?id=${record.bundle_id}`;
  };
  const columnsPropsAppName = {
    ...getColumnSearchProps({
      isCustomized: true,
      logicalFilters: [
        {
          field: "name",
          operator: "containss",
        },
        {
          field: "bundle_id",
          operator: "containss",
        },
      ],
    }),
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    render: (record: any) => {
      return (
        <div className="w-full">
          <Typography.Paragraph
            className="w-full mb-0"
            ellipsis={{
              rows: 1,
            }}
          >
            {record.hasLink &&
            (record.connected || record.daremi_status !== EnumDaremiStatus.Unregistered) &&
            record.status === Enum_App_Status.Active ? (
              <Link
                className="text-[#531DAB] font-normal w-full inline-block"
                to={`/products/show/${productId || id}/integrations/edit/${serviceName}/setting/${record.app_id}`}
              >
                {record.name || record.app_name}
              </Link>
            ) : (
              <div>{record.name || record.app_name}</div>
            )}
          </Typography.Paragraph>
          <a href={getLinkStore(record)} target="_blank" rel="noreferrer" className="text-[12px] text-[#1890FF] mb-0">
            {record.bundle_id}
            <CopyToClipboardTooltip text={record.bundle_id} />
          </a>
        </div>
      );
    },
  };

  const columnsPropsAppCode = {
    title: "App code",
    dataIndex: "product_code",
    key: "app_code",
    ...getColumnSearchProps({
      isCustomized: true,
      logicalFilters: [
        {
          field: "product_code",
          operator: "containss",
        },
      ],
    }),
    width: "10%",
    render: (value: string) => <span className="text-[14px] leading-[22px] font-normal">{value}</span>,
  };

  const columnsPropsMarket = {
    ...getColumnSearchProps({
      isCustomized: true,
      logicalFilters: [
        {
          field: "market",
          operator: "containss",
        },
      ],
    }),
    render: (value: string) => <span className="text-[14px] leading-[22px] font-normal">{value}</span>,
  };

  const columnsPropsAppIcon = {
    title: "Image",
    key: "icon",
    dataIndex: "icon",
    width: "2%",
    className: "text-[rgba(0, 0, 0, 0.85)]",
    render: (value: string) => <Image preview={false} width="48px" src={value || EmptyAvatar} />,
  };

  return {
    getColumnSearchProps,
    columnsPropsMarket,
    columnsPropsAppName,
    columnsPropsAppCode,
    columnsPropsAppIcon,
    searchInput,
  };
};

export default useTableProps;
