const getKeys = (keyName: string, arr?: any[]) => {
  return arr?.map((item) => {
    if (item) {
      return item[keyName];
    }
    return "";
  });
};

const onCheckIsDuplicated = (keys?: string[]) => {
  return keys?.some((item, idx) => {
    if (!item) return false;
    return keys?.indexOf(item) !== idx;
  });
};

export const isDuplicatedFieldFormList = (keyName: string, arr?: any[], isChildArray?: boolean) => {
  let keys = getKeys(keyName, arr);
  if (isChildArray && keys) {
    keys = [].concat.apply([], keys);
  }
  return onCheckIsDuplicated(keys);
};

export const isDuplicatedFieldWithPlatform = (keyName: string, arr?: any[]) => {
  let keys = arr?.map((item) => {
    if (item) {
      const platformGenerator = item.platform === "ANDROID" ? "a" : "i";

      return item[keyName]?.toUpperCase() + platformGenerator;
    }
    return "";
  });
  return keys?.some((item, idx) => {
    if (!item) return false;
    return keys?.indexOf(item) !== idx;
  });
};
