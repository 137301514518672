import { createContext, ReactNode, useContext, useState } from "react";
import React from "react";
import { IIntegrationDataCreate, IProduct } from "interfaces";
import { convertStepToString } from "utils/MappingData";
import { FormInstance, UploadFile } from "@pankod/refine-antd";

type ProductStepContextProps = {
  values?: any;
  setValues?: React.Dispatch<any>;
  current?: number;
  keyName: string;
  integrationData?: IIntegrationDataCreate[];
  setIntegrationData?: React.Dispatch<React.SetStateAction<IIntegrationDataCreate[]>>;
  isConfigAws: boolean;
  setIsConfigAws: React.Dispatch<React.SetStateAction<boolean>>;
  form?: FormInstance<any>;
  isOnceFirebaseCalled: boolean;
  setIsOnceFirebaseCalled: (val: boolean) => void;
  setIsManual?: (val: boolean) => void;
  isManual?: boolean;
  product?: IProduct;
  formLoading: boolean;
  setFormLoading?: (formLoading: boolean) => void;
  isVerifiedExcel: boolean;
  setIsVerifiedExcel: (val: boolean) => void;
  setFileList: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>;
  fileList: UploadFile<any>[];
};

const ProductStepFormContext = createContext<ProductStepContextProps>({} as ProductStepContextProps);

export const useProductStepForm = () => {
  return useContext(ProductStepFormContext);
};

type Props = {
  values?: any[];
  setValues?: (val: string[]) => void;
  current?: number;
  integrationData?: IIntegrationDataCreate[];
  setIntegrationData?: React.Dispatch<React.SetStateAction<IIntegrationDataCreate[]>>;
  children: ReactNode;
  form?: FormInstance<any>;
  product?: IProduct;
  formLoading: boolean;
  setFormLoading: (formLoading: boolean) => void;
  isManual?: boolean;
  setIsManual?: (val: boolean) => void;
};

export const ProductStepFormProvider = ({ children, current, ...props }: Props) => {
  const [isConfigAws, setIsConfigAws] = useState<boolean>(false);
  const [isOnceFirebaseCalled, setIsOnceFirebaseCalled] = React.useState(false);
  const [isVerifiedExcel, setIsVerifiedExcel] = React.useState(false);
  const [fileList, setFileList] = React.useState<UploadFile<any>[]>([]);

  const keyName = convertStepToString(current || 0);
  const value = {
    isConfigAws,
    setIsConfigAws,
    keyName,
    current,
    isOnceFirebaseCalled,
    setIsOnceFirebaseCalled,
    isVerifiedExcel,
    setIsVerifiedExcel,
    fileList,
    setFileList,
    ...props,
  };
  return (
    <>
      <ProductStepFormContext.Provider value={value}>{children}</ProductStepFormContext.Provider>
    </>
  );
};
