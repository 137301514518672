import React from "react";

// ant core
import { Dropdown, Menu, Select } from "@pankod/refine-antd";

// ant icons
import { MoreOutlined, PieChartOutlined, BarChartOutlined, LineChartOutlined, BarsOutlined } from "@ant-design/icons";

// interface
import { CHART_TYPES, GROUPINGS } from "utils/chart/chartConfig";

// utils
import { getUnSupportedChartTypes } from "utils/chart/chartHelper/supportedCombinationsChart";

const { Option } = Select;

type IOption = {
  label: string;
  value: string;
};

type IProps = {
  chartType: string;
  optionMetrics: IOption[];
  optionDimensions: IOption[];
  dimensionSelected: string;
  metricSelected: string;
  onChangeDimension: (value: string) => void;
  onChangeMetric: (value: string) => void;
  onChangeDropdown: (value: string) => void;
};

const defaultChartTypes = [
  {
    label: (
      <div className="flex items-center">
        <PieChartOutlined />
        <span className="ml-2 capitalize">{CHART_TYPES.PIE}</span>
      </div>
    ),
    key: CHART_TYPES.PIE,
  },
  {
    label: (
      <div className="flex items-center">
        <BarsOutlined />
        <span className="ml-2 capitalize">{CHART_TYPES.BAR}</span>
      </div>
    ),
    key: CHART_TYPES.BAR,
  },
  {
    label: (
      <div className="flex items-center">
        <BarChartOutlined />
        <span className="ml-2">Stacked bar</span>
      </div>
    ),
    key: CHART_TYPES.COLUMN,
  },
  {
    label: (
      <div className="flex items-center">
        <LineChartOutlined />
        <span className="ml-2">Line</span>
      </div>
    ),
    key: CHART_TYPES.SPLINE,
  },
];

function FilterChart({
  chartType,
  optionMetrics,
  optionDimensions,
  dimensionSelected,
  metricSelected,
  onChangeDimension,
  onChangeMetric,
  onChangeDropdown,
}: IProps) {
  const unsupportedChartTypes = getUnSupportedChartTypes({
    isEventKpi: dimensionSelected === GROUPINGS.EVENT_NAME,
    kpiKey: metricSelected,
  });
  const chartTypeOptions = defaultChartTypes.filter((type) => !unsupportedChartTypes.includes(type.key));

  const renderMenu = (
    <Menu
      activeKey={chartType}
      onClick={(event) => onChangeDropdown(event.key)}
      items={[
        {
          label: <div>Change chart type</div>,
          key: "0",
        },
        {
          type: "divider",
        },
        ...chartTypeOptions,
      ]}
    />
  );

  return (
    <div className="flex items-center p-3 justify-between">
      <div className="flex items-center">
        <div className="pr-2">Show top</div>
        <Select
          className="min-w-[170px] text-[#333]"
          showSearch
          placeholder="Select a dimension"
          optionFilterProp="children"
          onChange={onChangeDimension}
          value={dimensionSelected}
          filterOption={(input, option) =>
            (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
          }
        >
          {optionDimensions.map((dimension) => (
            <Option key={dimension.value} value={dimension.value}>
              {dimension.label}
            </Option>
          ))}
        </Select>
        <div className="px-2">by</div>
        <Select
          className="min-w-[170px] text-[#333]"
          showSearch
          placeholder="Select a metric"
          optionFilterProp="children"
          onChange={onChangeMetric}
          value={metricSelected}
          filterOption={(input, option) =>
            (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
          }
        >
          {optionMetrics.map((metric) => (
            <Option key={metric.label} value={metric.value}>
              {metric.label}
            </Option>
          ))}
        </Select>
      </div>
      <div>
        <Dropdown
          overlay={<div className="chartDropdown">{renderMenu}</div>}
          trigger={["click"]}
          placement="bottomRight"
        >
          <MoreOutlined />
        </Dropdown>
      </div>
    </div>
  );
}

export default FilterChart;
