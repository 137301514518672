import React from "react";
import { useNavigate } from "react-router-dom";
import { css } from "@emotion/react";
import { Show, Icons, Tabs, Skeleton } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";

// components
import { DisableProduct } from "components/Alert/DisableProduct";
import { LayoutCardTransparent } from "components/layout/layout/LayoutCardTransparent";
import Page404 from "components/Result/404";
import GeneralInfo from "./components/GeneralInfo";
import { FirebaseInfo } from "./components/Firebase";
import StoreInfo from "./components/Store/StoreInfo";

// context
import { ProductContextProvider, useProduct } from "context/ProductContext";

// schema
import ShowIntegrationCard from "./StepsForm/ShowIntegrationCard";

const { AntDesignOutlined } = Icons;
const { TabPane } = Tabs;

const MainContent: React.FC = () => {
  // hooks
  const {
    productInfo,
    isLoading,
    refetchProduct,
    // canDelete
  } = useProduct();
  const navigate = useNavigate();
  const { push } = useNavigation();
  const [activeKey, setActiveKey] = React.useState("general");
  React.useEffect(() => {
    const hash = window.location.hash.replace("#", "") || "general";
    setActiveKey(hash);
  }, []);
  if (!isLoading && !productInfo?.id) {
    return <Page404 />;
  }

  return (
    <div
      css={[
        css`
          .ant-tabs-nav {
            margin-bottom: 30px;
          }
        `,
        productInfo?.isDisabled
          ? css`
              .ant-card {
                background: #f9f9f9;
              }
              .ant-typography {
                color: rgba(0, 0, 0, 0.35);
              }
            `
          : css``,
      ]}
    >
      <Show
        isLoading={isLoading}
        title={productInfo?.name || ""}
        headerProps={{
          extra: <></>,
          onBack: () => push(`/products`),
        }}
      >
        {productInfo?.isDisabled && <DisableProduct id={productInfo?.id} cb={refetchProduct} />}

        <Tabs
          animated
          activeKey={activeKey}
          onTabClick={(key) => {
            navigate(`/products/show/${productInfo.id}#${key}`, {
              replace: true,
            });
            setActiveKey(key);
          }}
          css={css`
            .ant-tabs-tab-active .ant-tabs-tab-btn {
              color: #1890ff;
            }
          `}
        >
          <TabPane
            tab={
              <>
                <AntDesignOutlined />
                <span className="text-[14px]">General info</span>
              </>
            }
            key="general"
            animated
          >
            <GeneralInfo />
          </TabPane>
          <TabPane
            tab={
              <>
                <AntDesignOutlined />
                <span className="text-[14px]">Firebase info</span>
              </>
            }
            key="firebase"
          >
            {isLoading && <Skeleton className="pl-6" paragraph={{ rows: 10 }} />}
            {!isLoading && <FirebaseInfo />}
          </TabPane>
          <TabPane
            tab={
              <>
                <AntDesignOutlined />
                <span className="text-[14px]">Store info</span>
              </>
            }
            key="Store"
          >
            <StoreInfo />
          </TabPane>
          <TabPane
            tab={
              <>
                <AntDesignOutlined />
                <span className="text-[14px]">Integration info</span>
              </>
            }
            key="integration"
          >
            <ShowIntegrationCard />
          </TabPane>
        </Tabs>
      </Show>
    </div>
  );
};

export const ProductShow: React.FC = () => {
  return (
    <LayoutCardTransparent>
      <ProductContextProvider>
        <MainContent />
      </ProductContextProvider>
    </LayoutCardTransparent>
  );
};
