import { Tag } from "@pankod/refine-antd";

import { Enum_Studiouserrole_Status, Enum_App_Status, Enum_Product_Status } from "schema/schema";

interface IProps {
  status?: string;
}

function TagStatus({ status }: IProps) {
  let color = "orange";
  switch (status) {
    case Enum_Studiouserrole_Status.Accepted:
    case Enum_Product_Status.Enabled:
    case Enum_App_Status.Active:
      color = "green";
      break;
    case Enum_Studiouserrole_Status.Revoked:
    case Enum_Product_Status.Disabled:
    case Enum_App_Status.Development:
      color = "gold";
      break;
    case Enum_Studiouserrole_Status.Pending:
    case Enum_App_Status.Inactive:
      color = "red";
      break;
    default:
      break;
  }

  const label = status && status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

  return (
    <Tag color={color}>
      <span className="capitalize text-[14px]">{label}</span>
    </Tag>
  );
}

export default TagStatus;
