import { useMe } from "context/MeContext";

export const useGetHighestRole = (role?: string) => {
  const { data: me } = useMe();
  const roleNumber: any = {
    member: 0,
    manager: 1,
    admin: 2,
  };

  const rolePNumber: any = {
    viewer: 0,
    editor: 1,
    owner: 2,
  };

  let highestRole = "member";

  const getKeyByValue = (object: any, value: any) => {
    return Object.keys(object).find((key) => object[key] === value);
  };

  const roles = me?.studio_user_roles?.map((item) => {
    return roleNumber[item?.role?.name.toLowerCase() || "member"];
  });
  if (roles) {
    const max = Math.max(...roles);
    highestRole = getKeyByValue(roleNumber, max) || "member";
  }
  const roleArr = Object.keys(roleNumber);
  const idxMyRole = role ? roleNumber[role] : Math.max(roleArr.length);
  const aboveRoles = me?.isOps ? [] : roleArr.slice(idxMyRole + 1, roleArr.length).map((item) => item.toUpperCase());

  const rolePArr = Object.keys(rolePNumber);
  const idxMyPRole = role ? rolePNumber[role] : Math.max(rolePArr.length);
  const abovePRoles = me?.isOps
    ? []
    : rolePArr.slice(idxMyPRole + 1, rolePArr.length).map((item) => item.toUpperCase());

  return { highestRole, aboveRoles, abovePRoles };
};
