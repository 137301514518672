import { BreadcrumbBucket } from "components/Breadcrumb/BreadcrumbBucket";
import React from "react";

interface BreadcrumbHookData {
  bucketName: string;
  prefix?: string | null;
  setSelectedRowKeys: (val: React.Key[]) => void;
  _prefix?: string | null;
}

export const useBreadcumbBucket = (data: BreadcrumbHookData) => {
  const { bucketName, prefix, setSelectedRowKeys, _prefix } = data;

  const [breadcrumbItems, setBreadcrumbItems] = React.useState([
    {
      title: "Buckets",
      link: "",
      type: "list",
    },
  ]);
  React.useEffect(() => {
    const part = prefix?.split("/");
    const _part = _prefix?.split("/");
    const items = part?.map((item, index) => {
      const link = part.slice(0, index + 1).join("/");
      return { title: item, link: link + "/", type: "prefix", disabled: _part && index < _part.length - 1 };
    });
    setBreadcrumbItems([
      {
        title: "Buckets",
        link: "",
        type: "list",
        disabled: true,
      },
      {
        title: bucketName,
        link: bucketName,
        type: "rootPrefix",
        disabled: true,
      },
      ...(items ? items : []),
    ]);
    setSelectedRowKeys([]);
  }, [_prefix, bucketName, prefix, setSelectedRowKeys]);
  return {
    breadcrumbItems,
    setBreadcrumbItems,
    BreadcrumbBucket,
  };
};
