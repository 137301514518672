// interface
import { IMetricProps } from "interfaces/chart";

// configs
import { GROUPINGS, KPIS } from "../chartConfig";

// helpers
import { isAnArray } from "./commonHelper";

export const getMetricSourceData = ({ data, metric, dimension }: IMetricProps) =>
  dimension !== GROUPINGS.EVENT_NAME
    ? data.filter((kpi) => kpi[metric])
    : data.reduce(
        (prev, kpi) => [
          ...prev,
          ...Object.entries(kpi[KPIS.IN_APPS] ?? {}).map(([eventName, eventRow]) => ({
            [GROUPINGS.EVENT_NAME]: eventName,
            [GROUPINGS.SOURCE]: kpi[GROUPINGS.SOURCE],
            ...(eventRow as any),
          })),
        ],
        [],
      );

export function mappingOtherMetricValuePieChart(data: any, dimension: string, metric: string): number {
  return !data.others || !isAnArray(data.others)
    ? 0
    : data.others.reduce(
        (prev: any, curr: any) =>
          prev + (GROUPINGS.EVENT_NAME === dimension ? curr?.[KPIS.IN_APPS]?.[""]?.[metric] ?? 0 : curr[metric] || 0),
        0,
      );
}
