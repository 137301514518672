import React from "react";
import { useCreate } from "@pankod/refine-core";
import { Button, Popconfirm, Spin, Tooltip } from "@pankod/refine-antd";
import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons";

// schema
import { FirebaseEntity, Enum_Firebase_Billing_Status } from "schema/schema";
import { TooltipCustom } from "components/Input/Tooltip";
import { EnumRoleProduct, EnumTooltipContent } from "enums";
import { useProduct } from "context/ProductContext";
import { usePermission } from "hooks/usePermission";
import { useMe } from "context/MeContext";

interface IProps {
  firebaseIndex: number;
  firebase: FirebaseEntity;
  userRolePermision?: string;
}

function FirebaseItem({ firebase, firebaseIndex, userRolePermision }: IProps) {
  // state
  const [isStatusBilling, setisStatusBilling] = React.useState("");
  // hooks
  const { productInfo } = useProduct();
  const { mutateAsync: mutateRequestBilling, isLoading: isLoadingRequestBilling } = useCreate();
  const { combineRoleWithSuperAdmin } = usePermission();
  const { isMember } = useMe();
  const requestBilling = (firebaseId: string | any) => async () => {
    const res = await mutateRequestBilling({
      resource: `firebases/${firebaseId}/request-billing`,
      values: {},
      errorNotification: {
        type: "error",
        description: "Error",
        message: "Fail to send request billing message to system admin",
      },
      successNotification: {
        type: "success",
        description: "Success",
        message: "PMP have sent your request billing message to system admin",
      },
    });
    const status = res.data.data.attributes.billing_status;
    setisStatusBilling(status);
  };
  return (
    <Spin spinning={isLoadingRequestBilling}>
      <div className="mb-[24px]" key={firebase.id}>
        <div className="text-[15px] font-bold text-[#000] mb-[24px]">
          Firebase {firebaseIndex + 1}: {firebase.attributes?.project_name}
        </div>
        <div className="flex">
          <div className="w-3/6">
            <div className="flex text-[14px] text-[#000]">
              <div className="w-32 font-normal">Project ID:</div>
              <div className="font-normal">{firebase.attributes?.project_id}</div>
            </div>
            <div className="flex items-center text-[14px] text-[#000] mt-[20px]">
              <div className="w-32 font-normal flex items-center">
                <span className="m-0 p-0">Request Billing</span>
                <TooltipCustom
                  placement="right"
                  title={
                    <span>
                      After you click Request button, We will send an email to request your admin to check it.
                    </span>
                  }
                >
                  <InfoCircleOutlined />:{" "}
                </TooltipCustom>
              </div>
              <div className="font-normal">
                {firebase.attributes?.billing_status === Enum_Firebase_Billing_Status.Disabled &&
                  isStatusBilling === "" && (
                    <div className="flex items-center">
                      <span className="w-1.5 h-1.5 inline-block bg-[#FF4D4F] rounded-full mr-5" />
                      Disabled
                      {(combineRoleWithSuperAdmin(userRolePermision === EnumRoleProduct.Owner) || !isMember) && (
                        <Tooltip title={EnumTooltipContent.RequestBilling} placement="bottom">
                          <Popconfirm
                            title="Are you sure want to request enable billing?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={requestBilling(firebase.id)}
                            disabled={productInfo.isDisabled}
                          >
                            <Button disabled={productInfo.isDisabled} type="link" className="border-none">
                              <EditOutlined />
                            </Button>
                          </Popconfirm>
                        </Tooltip>
                      )}
                    </div>
                  )}

                {firebase.attributes?.billing_status === Enum_Firebase_Billing_Status.Enabled && (
                  <div className="flex items-center">
                    <span className="w-1.5 h-1.5 inline-block bg-green-500 rounded-full mr-5" />
                    <span>Enabled</span>
                  </div>
                )}

                {(firebase.attributes?.billing_status === Enum_Firebase_Billing_Status.Requested ||
                  isStatusBilling === Enum_Firebase_Billing_Status.Requested) && (
                  <div className="flex items-center">
                    <span className="w-1.5 h-1.5 inline-block bg-[#ffdf00] rounded-full mr-5" />
                    <span>Request to Enable</span>
                  </div>
                )}
              </div>
            </div>
            <div className="flex text-[14px] text-[#000] mt-[20px]">
              <div className="w-32 font-normal">Project details:</div>
              <div className="font-normal">
                <a
                  type="link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://console.firebase.google.com/project/${firebase.attributes?.project_id}/overview`}
                  className="underline"
                >
                  View project on Firebase
                </a>
              </div>
            </div>
          </div>
          <div className="w-3/6">
            <div className="flex text-[14px] text-[#000]">
              <div className="w-32 font-normal">Project number:</div>
              <div className="font-normal">{firebase.attributes?.project_number}</div>
            </div>
          </div>
        </div>
        <br />
      </div>
    </Spin>
  );
}

export default FirebaseItem;
