import { Form, Select } from "@pankod/refine-antd";
import { ListUser } from "components/ListUser";
import { useEffect, useState } from "react";
import { UserRoleOptions } from "hooks/useUserRole";
import { ListUserData, UsersStudioRole } from "pages/Studio/type";
import { EnumRoleStudio } from "enums";

export type IProps = {
  setUsersRole: (value: UsersStudioRole[]) => void;
  usersData: ListUserData[];
  optionsRole: UserRoleOptions[];
  isLoading?: boolean;
};

export const UserFormItem = ({ setUsersRole, usersData, optionsRole, isLoading }: IProps) => {
  const [usersSelected, setUsersSelected] = useState<ListUserData[]>([]);
  const [usersStudioRole, setUsersStudioRole] = useState<UsersStudioRole[]>([]);

  const handleChangeUser = (value: string[]) => {
    const listUser = usersData.filter((source) => (value ?? []).includes(source.id));
    setUsersSelected(listUser.map((o) => ({ ...o, role: EnumRoleStudio.Admin })));
    const userRoles = listUser.map((user) => {
      const index = usersStudioRole.findIndex((o) => o.email === user.email);
      if (index > -1) {
        return usersStudioRole[index];
      }
      return {
        email: user.email,
        studio_role: EnumRoleStudio.Admin,
      };
    });
    setUsersStudioRole(userRoles);
  };

  useEffect(() => {
    setUsersRole(usersStudioRole);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersStudioRole]);

  return (
    <Form.Item
      label="Users"
      name="usersRole"
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Select
        mode="multiple"
        options={usersData.map((user) => ({ value: user.id, label: user.email }))}
        onChange={handleChangeUser}
        loading={isLoading}
        disabled={isLoading}
        filterOption={(input, option) => {
          return (option!.label as unknown as string).toLowerCase().includes(input.toLowerCase());
        }}
      />
      <ListUser
        dataSource={usersSelected}
        hasRole={true}
        userRoleOptions={optionsRole}
        usersStudioRole={usersStudioRole}
        setUsersStudioRole={setUsersStudioRole}
      />
    </Form.Item>
  );
};
