import { METRIC, DIMENSIONS } from "../chartConfig";
import { formatterLabelChart } from "../chartHelper/commonHelper";
import { numberWithPercentage } from "utils/numberWithPercentage";

export const barOption = (dimension: string, metric: string) => {
  const hideLegendByMetric = [
    METRIC.EventConversion.value,
    METRIC.EventECPA.value,
    METRIC.EventRatio.value,
    METRIC.AvgEcpi.value,
    METRIC.GrossProfit.value,
    METRIC.ConvRate.value,
    METRIC.AvgEcpi.value,
    METRIC.Arpu.value,
    METRIC.Roas.value,
    METRIC.ROI.value,
  ];
  const hideLegend = hideLegendByMetric.includes(metric);

  let options: any = {
    legend: {
      reversed: true,
      symbolRadius: 2,
      enabled: !hideLegend,
    },
    tooltip: {
      outside: true,
      distance: 10,
      shared: true,
      borderRadius: 5,
      useHTML: true,
      backgroundColor: "#fff",
      borderColor: "rgb(229 232 237 / 1",
      padding: 15,
      formatter: function (this: any) {
        let contents = ``;
        const sum = this.points.reduce((acc: number, currItem: any) => {
          const value = currItem.y;
          return acc + value;
        }, 0);
        const result = formatterLabelChart(sum, metric);
        const isShowHeader =
          metric !== METRIC.ConvRate.value &&
          metric !== METRIC.ROI.value &&
          metric !== METRIC.AvgEcpi.value &&
          metric !== METRIC.GrossProfit.value &&
          metric !== METRIC.EventECPA.value &&
          metric !== METRIC.EventConversion.value &&
          metric !== METRIC.EventRatio.value &&
          metric !== METRIC.Arpu.value &&
          metric !== METRIC.Roas.value;

        const isTextCapotalize = [DIMENSIONS.AttributedTouchType.value, DIMENSIONS.AttributionType.value].includes(
          dimension,
        );
        const textCapitalize = isTextCapotalize ? "text-transform: capitalize" : "";

        if (isShowHeader) {
          contents = `
          <div style="min-width: 250px">
            <div style="display: flex; justify-content: space-between">
              <div style="color:{point.color}; font-weight: bold; margin-right: 10px; margin-bottom: 3px; ${textCapitalize}">${this.x}</div>
              <b>${result}</b>
            </div>
            <hr style="margin: 5px 0 10px 0;" />
          </div>
        `;
        }

        this.points
          .filter((item: any) => item.y !== 0)
          .reverse()
          .forEach((item: any) => {
            const number = formatterLabelChart(item.y, metric);
            let name = item.series.name;
            const isDimensionEventName =
              dimension === DIMENSIONS.EventName.value &&
              (metric === METRIC.EventECPA.value ||
                metric === METRIC.EventConversion.value ||
                metric === METRIC.EventRatio.value);
            const isDimensionCountry = dimension === DIMENSIONS.Country.value && metric === METRIC.Arpu.value;
            const isNameFromPoint = isDimensionEventName || isDimensionCountry;
            const percentage = numberWithPercentage(item.percentage);
            const isAssits = metric === METRIC.Assists.value;

            if (isNameFromPoint) {
              name = item.x;
            }

            contents += `
            <div style="display: flex; justify-content: space-between; align-item: center; margin-top: 15px; min-width: 250px">
              <div style="display: flex; align-items: center; margin-right: 10px">
                <span style="width: 8px; height: 8px; border-radius: 50%; margin-right: 7px; background-color: ${
                  item.series.color
                }"></span>
                <span>${name}</span>
              </div>
              <div>
                ${
                  isAssits
                    ? `<b style="margin-right:10px; display: inline-block; text-align: right">${percentage}</b>`
                    : ""
                }
                <b style="min-width: 50px; display: inline-block; text-align: right"">${number}</b>
              </div>
            </div>
          `;
          });

        return contents;
      },
    },
  };
  return {
    tooltip: options.tooltip,
    legend: options.legend,
  };
};
