import React from "react";
import { css } from "@emotion/react";
import { Button, Edit, Icons, Popconfirm } from "@pankod/refine-antd";
import { useCan, useNavigation } from "@pankod/refine-core";
import { useIntegration } from "context/IntegrationContext";
import { BreadcrumbAWS } from "components/Breadcrumb/BreadcrumbBucket";
const { DeleteOutlined } = Icons;

export const EditIntegration: React.FC<{
  isLoading: boolean;
  onDelete?: () => Promise<void>;
  hiddenDelete?: boolean;
  title?: string;
  actionButtons?: any;
  children?: React.ReactNode;
}> = ({ children, isLoading, onDelete, title = "Edit integration", hiddenDelete = false, actionButtons }) => {
  const { goBack } = useNavigation();
  const onConfirm = async () => {
    await onDelete?.();
    goBack();
  };
  const { productInfo } = useIntegration();
  const isDisabled = productInfo?.isDisabled;

  const { data: dataCan } = useCan({
    resource: "products",
    action: "delete",
    params: { id: productInfo?.id },
  });
  const can = dataCan?.can && !isDisabled;
  return (
    <Edit
      title={title}
      actionButtons={
        actionButtons ||
        (!hiddenDelete && (
          <Popconfirm title="Are you sure?" onConfirm={onConfirm} disabled={!can}>
            <Button disabled={!can} danger icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Popconfirm>
        ))
      }
      pageHeaderProps={{
        extra: "",
        onBack: goBack,
      }}
      isLoading={isLoading}
      breadcrumb={<BreadcrumbAWS />}
    >
      <div
        css={css`
          .ant-form-item-label {
            padding-bottom: 0px !important;
          }
        `}
      >
        {children}
      </div>
    </Edit>
  );
};
