import React from "react";
import { Typography, Alert, Button, Spin } from "@pankod/refine-antd";

// assets
import SdkPending from "assets/img/sdk-pending.png";

const { Title, Text } = Typography;

type IProps = {
  handleRestartTest: VoidFunction;
};

function Install({ handleRestartTest }: IProps) {
  const [isOpenApp, setIsOpenApp] = React.useState(false);
  const [isPending, setIsPending] = React.useState(false);
  let timeout = React.useRef<NodeJS.Timeout | null>(null);

  function handleOpenApp() {
    setIsOpenApp(true);
  }

  React.useEffect(() => {
    if (!isOpenApp) return;

    timeout.current = setTimeout(() => {
      setIsPending(true);
    }, 60000);

    return () => {
      clearTimeout(timeout.current as NodeJS.Timeout);
      setIsPending(false);
    };
  }, [isOpenApp]);

  if (!isOpenApp) {
    return (
      <>
        <Title level={4} className="italic">
          Please install the app
        </Title>
        <Alert
          className="items-baseline mb-3 mt-3"
          type="info"
          showIcon
          message={
            <>
              <Title level={5}>Note: Don't open the app yet!</Title>
              <Text>
                Once the app is installed, click the button below, and then open the app immediately. <br />
                Please make sure you accepted the ATT consent.
              </Text>
            </>
          }
        />
        <div className="text-center">
          <Button type="primary" onClick={handleOpenApp}>
            I'm ready to open app
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <Title level={4}>Open the app now</Title>
      <Text className="text-center text-[14px]">
        After we detect the app was launched, you'll get details about your SDK integration. <br />
        (This might take a few seconds)
      </Text>
      <div className="text-center mt-6">
        <img src={SdkPending} alt="" className="inline-block" />
      </div>
      <div className="text-center mt-4">
        <Spin />
      </div>

      {isPending && (
        <div className="text-center text-[12px mt-10">
          <Text>Nothing happened?</Text>
          <br />
          <Text className="cursor-pointer text-blue-700 ml-2" onClick={handleRestartTest}>
            Restart the test
          </Text>
        </div>
      )}
    </>
  );
}

export default Install;
