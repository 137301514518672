import React from "react";
import { Form, Input, Skeleton, Tag, Typography } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import { CopyToClipboardTooltip } from "components/Input/CopyToClipboard";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { checkExitsIntegration } from "utils/getIntegrationConnect";
import { Enum_Integration_Service } from "schema/schema";
import { useProduct } from "context/ProductContext";
import { EditIntegration } from "../EditIntegration";

const { Title } = Typography;

export const AmaPassportEdit: React.FC<{ data: any }> = ({ data }) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const { productInfo, isLoading: isLoadingProduct, isStale } = useProduct();
  const { push } = useNavigation();
  let AmaPassportConfig = productInfo?.integrationPayloads?.data.find(
    (v) => v.attributes?.integration?.data?.attributes?.service === Enum_Integration_Service.AmaPassport,
  )?.attributes?.payload;
  React.useEffect(() => {
    if (!isStale && !checkExitsIntegration(productInfo, Enum_Integration_Service.AmaPassport) && productInfo.id) {
      push(`/products/show/${productInfo.id}#integration`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productInfo, isStale]);

  return (
    <EditIntegration
      title="AmaPassport Integration"
      isLoading={Boolean(isLoadingProduct || isStale)}
      hiddenDelete={true}
      actionButtons={<></>}
    >
      {Boolean(isLoadingProduct || isStale) ? (
        <Skeleton />
      ) : (
        <div>
          <div className="flex items-center mb-4">
            <Title className="flex items-center m-0" level={4}>
              {productInfo.name}
            </Title>
            {AmaPassportConfig?.app_secret && (
              <Tag className="ml-4 flex items-center h-[20px]" color={`success`}>
                Connected
              </Tag>
            )}
          </div>
          <div className="-mx-2">
            <Form.Item
              label={
                <Title className="mb-0" level={5}>
                  App Secret
                </Title>
              }
              className="px-2 w-1/2 items-center"
            >
              <div className="flex items-center">
                <Input
                  type={`${visible ? "text" : "password"}`}
                  value={AmaPassportConfig?.app_secret}
                  suffix={<CopyToClipboardTooltip text={AmaPassportConfig?.app_secret || ""} />}
                  disabled
                />
                <div
                  className={`text-[14px]  cursor-pointer ml-4 p-2 text-white bg-[#BFBFBF] rounded-[4px] `}
                  onClick={() => setVisible(!visible)}
                >
                  {visible && <EyeInvisibleOutlined />}
                  {!visible && <EyeOutlined />}
                </div>
              </div>
            </Form.Item>
          </div>
        </div>
      )}
    </EditIntegration>
  );
};
