// utils
import groupBy from "lodash/groupBy";
import { numberToUsd } from "utils/numberToUsd";
import { numberWithSeparator } from "utils/numberWithSeparator";

// themes
import { organicColor, palette, othersColor } from "themes";

// interfaces
import { ITransformData, IConvertData } from "interfaces/chart";
import { SOURCE, METRIC } from "utils/chart/chartConfig";

// helpers
import { getOptionLabel } from "../chartHelper/commonHelper";
import { getMetricSourceData, mappingOtherMetricValuePieChart } from "../chartHelper/pieHelper";

export const transformPieData = ({ data, dimension, metric }: ITransformData): IConvertData => {
  const sourceData = getMetricSourceData({ data: data.data, metric, dimension });
  const groupedByDimension = groupBy(sourceData, dimension);
  const mergedData = Object.values(groupedByDimension)
    .map((kpis) =>
      kpis.reduce(
        (prev, kpi) => ({ [dimension]: kpi[dimension], [metric]: (prev[metric] ?? 0) + (kpi[metric] ?? 0) }),
        {},
      ),
    )
    .filter((kpi) => kpi[metric] > 0)
    .sort((a, b) => b[metric] - a[metric])
    .slice(0, 9);

  if (!mergedData.length) return { series: [], categories: [] };

  const seriesData = mergedData.map((kpi, index) => ({
    name: getOptionLabel(kpi[dimension], dimension),
    label: metric === METRIC.Cost.value ? numberToUsd.format(kpi[metric]) : numberWithSeparator(kpi[metric], ","),
    y: kpi[metric],
    color: kpi[dimension] === SOURCE.ORGANIC ? organicColor : palette[index],
  }));

  const otherMetricValue = mappingOtherMetricValuePieChart(data, dimension, metric);
  if (otherMetricValue > 0)
    seriesData.push({
      name: "Others",
      label:
        metric === METRIC.Cost.value
          ? numberToUsd.format(otherMetricValue)
          : numberWithSeparator(otherMetricValue, ","),
      y: otherMetricValue,
      color: othersColor,
    });

  return { series: seriesData, categories: [] };
};

export default transformPieData;
