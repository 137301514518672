import { Empty, Skeleton, Spin } from "@pankod/refine-antd";
import { useProduct } from "context/ProductContext";
import { CardIntegrationDetail } from "pages/Integration/components/CardIntegration";
import React from "react";
import { useProductStepForm } from "context/ProductStepFormContext";
import { IIntegration } from "interfaces";
import { useList } from "@pankod/refine-core";
import { checkExitsIntegration } from "utils/getIntegrationConnect";

interface IProps {}
interface ArrIntegration {
  Internal: IIntegration[];
  External: IIntegration[];
}
const ShowIntegrationCard: React.FC<IProps> = () => {
  const { productInfo, isLoading: isLoadingProuct } = useProduct();
  const { setIntegrationData } = useProductStepForm();
  const { data: integrationQueryResult, isLoading } = useList<IIntegration>({
    resource: "integrations",
    config: {
      pagination: { pageSize: 300 },
    },
    metaData: {
      populate: ["icon"],
    },
  });
  const data = integrationQueryResult?.data;
  const listIntegration = data?.reduce((curr, item) => {
    if (item.type === "internal") {
      if (!curr.Internal) curr.Internal = [];
      curr.Internal.push(item);
    } else {
      if (!curr.External) curr.External = [];
      curr.External.push(item);
    }
    return curr;
  }, {} as ArrIntegration);

  return isLoading || isLoadingProuct ? (
    <Skeleton />
  ) : (
    <Spin spinning={isLoading}>
      {data && (
        <>
          {listIntegration &&
            Object.keys(listIntegration)
              .reverse()
              .map((key, index) => {
                const _listIntegration = listIntegration as any;
                return (
                  <div key={index} className="bg-white mb-4 p-4 rounded-[6px]">
                    <div className="mb-4 text-[16px] ">
                      <b>{key} Integration</b>
                    </div>
                    <div className="flex -mx-[2rem] flex-wrap">
                      {_listIntegration[key].map((item: IIntegration) => {
                        let isConnected = checkExitsIntegration(productInfo, item.service);
                        return (
                          <CardIntegrationDetail
                            key={item.id}
                            item={item}
                            isConnectedCreate={isConnected}
                            handleSetIntegrationData={setIntegrationData}
                            isDisabled={Boolean(productInfo.isDisabled)}
                          />
                        );
                      })}
                      {(!_listIntegration[key] || (_listIntegration[key] && _listIntegration[key].length === 0)) && (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="w-full" />
                      )}
                    </div>
                  </div>
                );
              })}
        </>
      )}
    </Spin>
  );
};

export default ShowIntegrationCard;
