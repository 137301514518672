import { cacheExchange, dedupExchange } from "@urql/core";
import { multipartFetchExchange } from "@urql/exchange-multipart-fetch";
import { createClient } from "urql";
import config from "../config";

export const clientUrql = createClient({
  url: config.GRAPHQL_URL,
  exchanges: [dedupExchange, cacheExchange, multipartFetchExchange],
  fetchOptions: () => {
    const token = localStorage.getItem(config.TOKEN_KEY);
    const studioId = localStorage.getItem("studioId");

    return {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
        "x-current-studio": studioId ?? "",
      },
    };
  },
});
