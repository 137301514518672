import { useList, useNavigation } from "@pankod/refine-core";
import { ImageElement } from "components/Image";
import { useMe } from "context/MeContext";
import useLocalStorage from "hooks/useLocalStorage";
import React from "react";
import BgStudio from "assets/img/1.png";
import { css } from "@emotion/react";
import { LoadingFullPage } from "components/Loading/FullPage";
import { IStudio } from "interfaces";
import { Button, Spin } from "@pankod/refine-antd";
import { useAuth } from "hooks/useAuth";
import { SmileOutlined } from "@ant-design/icons";
export const SelectStudio: React.FC = () => {
  const { push } = useNavigation();
  const { data: me, handleScroll, getMyRole } = useMe();
  const { data: dataStudios, isFetching } = useList<IStudio>({
    resource: "studios",
    metaData: {
      populate: ["icon", "studio_user_roles.users_permissions_user"],
    },

    config: {
      filters: !me?.isOps
        ? [
            {
              field: "studio_user_roles][users_permissions_user][id]",
              operator: "eq",
              value: me?.id,
            },
          ]
        : [],
    },
    queryOptions: {
      enabled: Boolean(me?.id),
      retry: 0,
    },
    errorNotification: false,
    successNotification: false,
  });
  const studios = dataStudios?.data;
  const defaultStudio = studios && studios[0]?.id;
  const [, setValue] = useLocalStorage<string | undefined | null>("studioId", "");
  React.useEffect(() => {
    if (defaultStudio && studios?.length === 1 && !me?.isOps) {
      setValue(defaultStudio);
      push("/");
    }
  }, [defaultStudio, me?.isOps, push, setValue, studios?.length]);

  const onSetStudio = (id?: string | null) => {
    setValue(id);
    window.location.href = "/";
  };
  if (defaultStudio && studios?.length === 1 && !me?.isOps) {
    return <LoadingFullPage />;
  }

  return (
    <Spin spinning={isFetching} wrapperClassName="">
      <div
        className={`min-h-screen w-full`}
        css={css`
          background-image: url(${BgStudio});
          background-size: cover;
          background-position: center;
        `}
      >
        <div className="w-full h-full">
          <div className="w-full h-full max-w-[940px] pt-[4rem] flex flex-col items-center mx-auto">
            <span className="font-bold text-[24px] text-white mt-[3rem]">Please select Studio Profile </span>

            <div
              className="flex w-full -mx-[1.5rem] mt-[3rem] flex-wrap min-h-[450px] overflow-y-auto mb-[2rem]"
              onScroll={handleScroll}
            >
              {studios ? (
                studios.map((item) => {
                  return (
                    <div key={item?.id} className="w-1/4 px-[1.5rem] mb-[2.5rem]">
                      <div
                        className="flex overflow-hidden border-purple-border rounded-md w-full pt-[100%] relative cursor-pointer"
                        onClick={() => onSetStudio(item?.id)}
                      >
                        <div className="absolute bg-white top-0 left-0 w-full flex flex-col items-center h-full">
                          <span className="mt-4 font-bold ">{item?.name}</span>
                          <div className=" flex-stretch block mx-auto relative h-full w-full ">
                            <ImageElement
                              className="absolute w-[80px] h-auto top-0 right-0 bottom-0 m-auto left-0"
                              src={item?.icon?.url}
                            />
                          </div>
                          <span className="font-bold mb-4 ">{getMyRole(item?.id)}</span>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <NotInAStudio />
              )}
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

const NotInAStudio: React.FC = () => {
  const { logout } = useAuth();

  return (
    <div className="flex flex-col w-full items-center justify-center">
      <SmileOutlined className="text-[60px] text-white" />
      <div className="text-[30px] text-white mb-4 mt-2">You are not in a studio! Contact your admin please</div>
      <div className="flex space-x-2">
        <Button onClick={logout} className="inline-flex items-center bg-transparent text-white border-white">
          Logout
        </Button>
      </div>
    </div>
  );
};
