import React from "react";
import { css } from "@emotion/react";
import { Select, Spin } from "@pankod/refine-antd";
import { useCreate } from "@pankod/refine-core";

// ant icon
import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";

// schema
import { Enum_Studiouserrole_Status } from "schema/schema";
import { EnumRoleStudio } from "enums";
import { useGetMyRole } from "hooks/useGetMyRole";
import { useMe } from "context/MeContext";
import { usePermission } from "hooks/usePermission";
import { IStudio } from "interfaces";
import { UserRoleOptions } from "hooks/useUserRole";

interface IProps {
  role: string;
  studio?: IStudio;
  email: string;
  status: string;
  optionsRole?: UserRoleOptions[];
  refetchStudio: Function;
  aboveRoles: string[];
}

function ColumnUserRole({ role: roleProps, studio, email, status, optionsRole, refetchStudio, aboveRoles }: IProps) {
  // states
  const [isShowSelectRole, setIsShowSelectRole] = React.useState(false);
  const [role, setRole] = React.useState(roleProps);
  //hooks
  const { mutateAsync: updateRole, isLoading: isLoadingUpdateRole } = useCreate();
  const me = useMe();
  const { userRoleStudioPermission } = useGetMyRole();
  const { combineRoleWithSuperAdmin } = usePermission();
  const userRoles = optionsRole?.map((role) => ({
    label: role.label,
    value: role.label,
  }));
  const isDisable = () => {
    if (
      status !== Enum_Studiouserrole_Status.Accepted ||
      aboveRoles.includes(role) ||
      !combineRoleWithSuperAdmin(userRoleStudioPermission !== EnumRoleStudio.Member) ||
      email === me?.data?.email ||
      (!combineRoleWithSuperAdmin(userRoleStudioPermission !== EnumRoleStudio.Manager) &&
        role === EnumRoleStudio.Admin) ||
      !studio?.status
    ) {
      return false;
    }
    return true;
    //supper admin
    //
  };
  function handleClose() {
    setIsShowSelectRole(false);
    setRole(roleProps);
  }
  const findRolePermissionId = (role: string) => {
    return optionsRole?.find((item) => item.label === role)?.id;
  };

  const handleUpdateRole = async () => {
    const studioRole = findRolePermissionId(role || "ADMIN");

    setIsShowSelectRole(false);
    await updateRole({
      resource: `studios/${studio?.id}/invitations`,
      values: [
        {
          role_id: studioRole,
          email,
        },
      ],
      successNotification: {
        type: "success",
        message: "Updated role successfully",
        description: "Success",
      },
      errorNotification: {
        type: "error",
        message: "Failure update role",
      },
    });
    refetchStudio();
  };

  return (
    <Spin spinning={isLoadingUpdateRole}>
      {isShowSelectRole ? (
        <div className="flex items-center">
          <Select
            value={role}
            options={userRoles}
            placeholder="Role"
            className="w-full"
            onChange={(role: string) => setRole(role)}
          />
          <div className="flex ml-[8px]">
            <CheckOutlined
              onClick={() => {
                handleUpdateRole();
              }}
              style={{
                color: "#1890ff",
              }}
            />
            <CloseOutlined
              onClick={handleClose}
              twoToneColor="red"
              className="ml-[8px]"
              style={{
                color: "red",
              }}
            />
          </div>
        </div>
      ) : (
        <div
          className="font-normal"
          onClick={isDisable() ? () => setIsShowSelectRole(true) : () => {}}
          css={css`
            svg {
              fill: #1890ff;
              cursor: pointer;
              margin-left: 8px;
            }
          `}
        >
          <span className="min-w-[40%] inline-block">{role}</span>

          {isDisable() && <EditOutlined />}
        </div>
      )}
    </Spin>
  );
}

export default ColumnUserRole;
