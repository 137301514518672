import { useCustom } from "@pankod/refine-core";
import config from "config";
import { useMe } from "context/MeContext";
import ProductInfo from "factory/Product";
import { IObject } from "interfaces";
import { BucketTable } from "pages/Product/components/Bucket/BucketTable";
import React from "react";
import { getRestPartOfPath, getTypeOfFile, isFolder } from "utils/folderAndFile";
import { v4 } from "uuid";
interface ListObjectsInBucketData {
  bucketName: string;
  prefix?: string | null;
  isSuccessLocation: boolean;
  productInfo?: ProductInfo;
  region: string;
}

export const useListObjectsInBucket = (data: ListObjectsInBucketData) => {
  const apiUrlS3 = config.S3_URL;
  const me = useMe();
  const { bucketName, productInfo, prefix, isSuccessLocation, region } = data;
  const {
    data: dataObject,
    refetch,
    isFetching,
  } = useCustom<any>({
    url: `${apiUrlS3}/listObjectsInBucket`,
    method: "get",
    queryOptions: {
      enabled:
        Boolean(productInfo?.productCode) &&
        Boolean(productInfo?.id) &&
        Boolean(me?.data?.id) &&
        isFolder(prefix || "") &&
        isSuccessLocation,
      retry: 0,
    },
    config: {
      query: {
        bucketName,
        prefix,
        userId: me?.data?.id,
        productId: productInfo?.id,
        region,
      },
    },
  });

  const listObjects = dataObject?.data;
  const folders = listObjects?.CommonPrefixes as any[];
  const files = listObjects?.Contents as any[];
  const [dataSource, setDataSource] = React.useState<IObject[]>([]);
  const foldersData: IObject[] =
    React.useMemo(() => {
      return folders?.map((item) => {
        return {
          key: v4(),
          Name: item.Prefix,
        };
      });
    }, [folders]) || [];

  const filesData: IObject[] =
    React.useMemo(() => {
      return files
        ?.filter((item) => !(item.Size === 0 && isFolder(item.Key)))
        ?.map((item) => {
          return {
            key: v4(),
            Name: item.Key,
            LastModified: item.LastModified,
            Size: item.Size,
            Type: getTypeOfFile(item.Key),
          };
        });
    }, [files]) || [];

  React.useMemo(() => {
    setDataSource([...foldersData, ...filesData]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, folders]);
  const isDefaultBucket = bucketName === config.BUCKET_NAME;
  const cloudfront = productInfo?.awsPayload?.cloudfront?.startsWith("https://")
    ? productInfo?.awsPayload?.cloudfront
    : productInfo?.awsPayload?.cloudfront
    ? "https://" + productInfo?.awsPayload?.cloudfront
    : "";
  const _prefix = productInfo?.prefix;

  const dataSourceFormatted = dataSource.map((item) => {
    let name = item.Name;
    if (_prefix) {
      const prefixParts = _prefix.split("/");
      const nameParts = name.split("/");
      name = nameParts.slice(prefixParts.length, nameParts.length).join("/");
    }
    return {
      ...item,
      cloudfrontLink: cloudfront
        ? isDefaultBucket
          ? `${cloudfront}/${getRestPartOfPath(item.Name)}`
          : `${cloudfront}/${name}`
        : "-",
    };
  });

  const isFile = !isFolder(prefix || "");

  return {
    dataObject,
    isFetchingObjects: isFetching,
    refetchObjects: refetch,
    dataSource: dataSourceFormatted,
    setDataSource,
    isFile,
    BucketTable,
  };
};
