import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { Icons } from "@pankod/refine-antd";

const { AppleOutlined, AndroidOutlined } = Icons;

type IProps = {
  children: React.ReactNode;
};

export const IconsBadge: React.FC<IProps> = ({ children }) => {
  return (
    <span className=" w-[22px] h-[22px] rounded-full flex items-center justify-center bg-gradient-primary drop-shadow-lg text-white bottom-[-5px] left-[-5px]">
      {children}
    </span>
  );
};

export const AndroidIcon: React.FC = () => {
  return (
    <IconsBadge>
      <AndroidOutlined />
    </IconsBadge>
  );
};

export const AppleIcon: React.FC = () => {
  return (
    <IconsBadge>
      <AppleOutlined />
    </IconsBadge>
  );
};

export const NewTabIcon = (props: Partial<CustomIconComponentProps>) => {
  const NewTabSvg = () => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4277 2.25C21.837 2.25 22.1697 2.57784 22.1776 2.98524L22.1777 3V8.25C22.1777 8.66421 21.8419 9 21.4277 9C21.0185 9 20.6857 8.67216 20.6779 8.26476L20.6777 8.25V4.81066L11.4581 14.0303C11.1652 14.3232 10.6903 14.3232 10.3974 14.0303C10.1086 13.7415 10.1046 13.2757 10.3854 12.982L10.3974 12.9697L19.6171 3.75H16.1777C15.7685 3.75 15.4357 3.42216 15.4279 3.01476L15.4277 3C15.4277 2.59072 15.7556 2.25801 16.163 2.25014L16.1777 2.25H21.4277ZM13.1534 5.25C13.5676 5.25 13.9034 5.58579 13.9034 6C13.9034 6.40928 13.5755 6.74199 13.1681 6.74986L13.1534 6.75H5.30273C4.68763 6.75 4.18782 7.24366 4.17789 7.8564L4.17773 7.875V19.125C4.17773 19.7401 4.67139 20.2399 5.28413 20.2498L5.30273 20.25H16.5527C16.8511 20.25 17.1373 20.1315 17.3482 19.9205C17.5542 19.7145 17.672 19.437 17.6775 19.1463L17.6777 19.125V10.5C17.6777 10.0858 18.0135 9.75 18.4277 9.75C18.837 9.75 19.1697 10.0778 19.1776 10.4852L19.1777 10.5V19.125C19.1777 19.8212 18.9012 20.4889 18.4089 20.9812C17.9237 21.4663 17.2682 21.7419 16.583 21.7498L16.5527 21.75H5.30273C3.86748 21.75 2.70127 20.5981 2.67809 19.1684L2.67773 19.125V7.875C2.67773 6.43975 3.8296 5.27353 5.25933 5.25035L5.30273 5.25H13.1534Z"
        fill="#595959"
      />
    </svg>
  );
  return <Icon component={NewTabSvg} {...props} />;
};

export const FirebaseIcon = (props: Partial<CustomIconComponentProps>) => {
  const FirebaseIconSvg = () => (
    <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9494 8.06624L13.2619 7.47937L10 1.36999C9.68437 0.778119 8.88125 0.928744 8.7775 1.59124L5.56813 21.4812C5.56121 21.5216 5.5847 21.561 5.62348 21.5741C5.66227 21.5871 5.70481 21.57 5.72375 21.5337L12.9494 8.06624ZM23.6735 7.06531C23.9175 7.14414 24.0973 7.35218 24.14 7.60499L27 25L16.9838 30.7381C16.3542 31.0884 15.5883 31.0884 14.9587 30.7381L5 25L22.9794 7.22999C23.1619 7.04997 23.4295 6.98648 23.6735 7.06531ZM13.8325 8.53937V8.54249L16.0694 12.6562L16.0381 12.6875L7.79125 20.84C7.70335 20.9276 7.56447 20.9375 7.46506 20.8632C7.36566 20.7888 7.33583 20.6528 7.395 20.5437L13.8325 8.53937ZM16.8125 11.9231L14.7106 8.06249L14.7087 8.05999L14.3962 7.48874L15.4206 5.57437C15.68 5.07562 16.3169 5.12499 16.5787 5.62499L18.8419 9.91749L16.8125 11.9231Z"
        fill="#FFC53D"
      />
    </svg>
  );
  return <Icon component={FirebaseIconSvg} {...props} />;
};

export const BannerProfileIcon = (props: Partial<CustomIconComponentProps>) => {
  const BannerProfileIconSvg = () => (
    <svg width="208" height="62" viewBox="0 0 208 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_169332_189509" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70.0656 1.94788C68.8586 0.741314 67.2469 0 65.5402 0H4C1.79086 0 0 1.79086 0 4V50.4741C0 52.4891 1.46184 54.1835 3.46251 54.4239C17.2157 56.0771 68.937 62 104 62C139.063 62 190.784 56.0771 204.537 54.4239C206.538 54.1835 208 52.4891 208 50.4741V4C208 1.79086 206.209 0 204 0H142.46C140.753 0 139.141 0.741315 137.934 1.94788C129.249 10.6302 117.251 16 104 16C90.7486 16 78.7514 10.6302 70.0656 1.94788Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.0656 1.94788C68.8586 0.741314 67.2469 0 65.5402 0H4C1.79086 0 0 1.79086 0 4V50.4741C0 52.4891 1.46184 54.1835 3.46251 54.4239C17.2157 56.0771 68.937 62 104 62C139.063 62 190.784 56.0771 204.537 54.4239C206.538 54.1835 208 52.4891 208 50.4741V4C208 1.79086 206.209 0 204 0H142.46C140.753 0 139.141 0.741315 137.934 1.94788C129.249 10.6302 117.251 16 104 16C90.7486 16 78.7514 10.6302 70.0656 1.94788Z"
        fill="white"
      />
      <path
        d="M3.46251 54.4239L3.58185 53.4311L3.58185 53.4311L3.46251 54.4239ZM204.537 54.4239L204.418 53.4311L204.418 53.4311L204.537 54.4239ZM70.0656 1.94788L69.3587 2.65513L70.0656 1.94788ZM4 1H65.5402V-1H4V1ZM1 4C1 2.34315 2.34315 1 4 1V-1C1.23858 -1 -1 1.23858 -1 4H1ZM1 50.4741V4H-1V50.4741H1ZM3.58185 53.4311C2.09812 53.2527 1 51.9977 1 50.4741H-1C-1 52.9806 0.825558 55.1142 3.34317 55.4168L3.58185 53.4311ZM104 61C69.0059 61 17.3424 55.0851 3.58185 53.4311L3.34317 55.4168C17.089 57.0691 68.868 63 104 63V61ZM204.418 53.4311C190.658 55.0851 138.994 61 104 61V63C139.132 63 190.911 57.0691 204.657 55.4168L204.418 53.4311ZM207 50.4741C207 51.9977 205.902 53.2527 204.418 53.4311L204.657 55.4168C207.174 55.1142 209 52.9806 209 50.4741H207ZM207 4V50.4741H209V4H207ZM204 1C205.657 1 207 2.34315 207 4H209C209 1.23858 206.761 -1 204 -1V1ZM142.46 1H204V-1H142.46V1ZM137.227 1.24064C128.722 9.74317 116.976 15 104 15V17C117.527 17 129.776 11.5172 138.641 2.65513L137.227 1.24064ZM104 15C91.0244 15 79.2785 9.74317 70.7726 1.24063L69.3587 2.65513C78.2243 11.5172 90.4728 17 104 17V15ZM142.46 -1C140.459 -1 138.601 -0.132075 137.227 1.24064L138.641 2.65513C139.682 1.6147 141.047 1 142.46 1V-1ZM65.5402 1C66.9531 1 68.3178 1.6147 69.3587 2.65513L70.7726 1.24063C69.3993 -0.132074 67.5407 -1 65.5402 -1V1Z"
        fill="#EFDBFF"
        mask="url(#path-1-inside-1_169332_189509)"
      />
    </svg>
  );
  return <Icon component={BannerProfileIconSvg} {...props} />;
};
