import { Button, Spin, Typography } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import { ImageElement } from "components/Image";
import { GeneralInfoSkeleton } from "components/Loading/GeneralInfoSkeleton";

// components
import { TagStatus } from "components/Tag";
import { useMe } from "context/MeContext";

// context
import { useProduct } from "context/ProductContext";
import { EnumRoleProduct } from "enums";
import { useGetMyRole } from "hooks/useGetMyRole";
import { usePermission } from "hooks/usePermission";
import React from "react";
import { getStatusProduct } from "utils/getStatusProduct";

const { Text } = Typography;

function GeneralInfo() {
  const { isMember } = useMe();
  const [isExpand, setIsExpand] = React.useState(true);
  const [isEllipsis, setIsEllipsis] = React.useState(false);

  const { productInfo, isLoading, isStale } = useProduct();
  const { push } = useNavigation();
  const { combineRoleWithSuperAdmin } = usePermission();
  const { userRoleProductPermission } = useGetMyRole();
  const description = productInfo.description.split("\n");
  const statusProduct = getStatusProduct(productInfo.apps);
  return (
    <Spin spinning={isStale}>
      <div className="flex w-full">
        {isLoading && <GeneralInfoSkeleton />}
        {!isLoading && (
          <>
            <div className="">
              <span className="w-[242px] h-[242px] flex">
                <ImageElement
                  className="w-full h-full object-contain"
                  src={productInfo?.icon?.url}
                  alt={productInfo?.icon?.alt}
                />
              </span>
            </div>
            <div className="ml-[40px] flex flex-col justify-between w-2/3">
              <div>
                <div className="flex justify-between items-center">
                  <Text className="leading-[28px] font-medium text-[20px] inline-flex w-full">
                    {productInfo?.name} - {productInfo.productCode.toUpperCase()}
                  </Text>
                  {(combineRoleWithSuperAdmin(userRoleProductPermission !== EnumRoleProduct.Viewer) || !isMember) && (
                    <Button
                      disabled={productInfo.isDisabled}
                      onClick={() => {
                        push(`/products/edit/${productInfo.id}`);
                      }}
                      type="primary"
                    >
                      Edit
                    </Button>
                  )}
                </div>
                <div className="mt-2">
                  <TagStatus status={statusProduct} />
                </div>
                <ul className="mt-3 flex items-center">
                  {productInfo?.studios?.map((studio, index) => {
                    return (
                      <li
                        key={index}
                        className="general_studio flex items-center text-color-info-studio font-normal text-[14px]"
                      >
                        <span>{studio.attributes?.name}</span>
                      </li>
                    );
                  })}
                </ul>
                <Typography.Paragraph
                  ellipsis={
                    isExpand
                      ? {
                          rows: 4,
                          symbol: "more",
                          onEllipsis: (e) => setIsEllipsis(!isEllipsis),
                        }
                      : false
                  }
                  className="text-[14px] mt-4 text-black font-normal leading-[22px] whitespace-pre-wrap"
                >
                  {description.map((p, index) => (
                    <p key={index} className="m-0 p-0">
                      {p}
                    </p>
                  ))}
                </Typography.Paragraph>
                {isEllipsis && (
                  <span className="cursor-pointer text-[14px] text-[#1890ff]" onClick={() => setIsExpand(!isExpand)}>
                    Show {isExpand ? "more" : "less"}
                  </span>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </Spin>
  );
}

export default GeneralInfo;
