import React, { useState } from "react";

//antd
import { Popconfirm, useTable, useModal } from "@pankod/refine-antd";
import { RowSelectMethod } from "antd/lib/table/interface";
import { Table } from "antd";
import { FilterFilled, UsergroupAddOutlined, UsergroupDeleteOutlined } from "@ant-design/icons";

// context
import { useProduct } from "context/ProductContext";

// components
import { ProductIconAndPlatform } from "components/ProductIcon";
import DrawerUser from "./DrawerUser";
import { useUserRole } from "hooks/useUserRole";
import { HttpError, useCreate } from "@pankod/refine-core";
import { useMe } from "context/MeContext";
import { EnumRoleProduct } from "enums";
import { usePermission } from "hooks/usePermission";
import { useGetMyRole } from "hooks/useGetMyRole";
import useTableProps from "hooks/useTableProps";
import ColumnUserProductRole from "pages/Studio/components/Column/ColumnUserProductRole";
import { Enum_Productuserrole_Status, Enum_Product_Status } from "schema/schema";
import { TagStatus } from "components/Tag";
import { useSelectUsers } from "hooks/useUsers/useSelectUsers";
import { IProductUser } from "interfaces";
import config from "config";
import RemoveUserModal from "components/Modal/RemoveUserModal";
import { useGetHighestRole } from "hooks/useGetHigherRole";

function UserManagement() {
  // state
  const [isShowDrawerUser, setIsShowDrawerUser] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [isSelectedAll, setIsSelectedAll] = React.useState(false);
  const [errorData, setErrorData] = useState([]);
  const [studioUsers, setStudioUsers] = useState([]);
  const { modalProps, close, show } = useModal();
  const [removeUserModalFn, setRemoveUserModalFn] = useState<() => void>();

  // hooks
  const me = useMe();
  const { productInfo, refetchProduct, isStale: isLoadingProduct } = useProduct();
  const { isLoading } = useCreate();
  const { productRolePermissions } = useUserRole();
  const { refetchUsers } = useSelectUsers({
    productInfo,
  });
  const { mutateAsync: removeUsers } = useCreate();

  const { userRoleProductPermission, userRoleProductName } = useGetMyRole();
  const { combineRoleWithSuperAdmin } = usePermission();
  const { abovePRoles } = useGetHighestRole(me.isMember ? userRoleProductName.toLowerCase() : undefined);
  const optionsRoleFiltered = React.useMemo(() => {
    return productRolePermissions?.filter((item) => item.value && !abovePRoles.includes(item.label as string));
  }, [abovePRoles, productRolePermissions]);
  const { tableProps, setFilters, filters, tableQueryResult } = useTable<IProductUser, HttpError, any>({
    resource: "product-user-roles",
    initialPageSize: config.INITIAL_PAGE_SIZE,
    metaData: {
      populate: ["users_permissions_user", "product", "role"],
    },
    permanentFilter: [
      {
        field: "product][id]",
        operator: "eq",
        value: productInfo.id,
      },
    ],
    queryOptions: {
      retry: 0,
    },
    onSearch: () => {
      return [];
    },
    defaultSetFilterBehavior: "replace",
    successNotification: false,
    errorNotification: false,
  });

  const onToggleShowDrawerUser = React.useCallback(() => {
    setIsShowDrawerUser((prevState) => !prevState);
    if (!isShowDrawerUser) {
      refetchUsers({
        requestPolicy: "network-only",
      });
    }
    tableQueryResult.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowDrawerUser, refetchUsers]);

  const handleRemoveUser = async () => {
    if (selectedRowKeys.length === 0) return;

    const dataDeletes = selectedRowKeys.filter((selectedkey) => {
      const index = tableQueryResult.data?.data?.findIndex((o) => o.id === selectedkey);
      return index !== undefined && index > -1;
    });

    await removeUsers({
      resource: "products/remove-users",
      values: dataDeletes,
      successNotification: {
        type: "success",
        description: "Success",
        message: `User is successfully remove from product`,
      },
      errorNotification: (error: any) => {
        handleErrorData(error?.response?.data?.error?.details, handleRemoveUser);

        return {
          type: "error",
          description: "Error",
          message: error?.message,
        };
      },
    });
    tableQueryResult.refetch();
  };

  const handleErrorData = (errorDetail: any, callBackFn: any) => {
    if (!errorDetail) {
      return;
    }
    setRemoveUserModalFn(() => callBackFn);
    setErrorData(errorDetail);
    let users: any = [];
    errorDetail?.forEach((product: any) => {
      users = [...users, ...product.studio_users];
    });
    setStudioUsers(users);
    show();
  }

  const isRowSelection = (userId?: number | string | null, roleName?: string) => {
    if (combineRoleWithSuperAdmin(false)) {
      return userId?.toString() !== me?.data?.id?.toString();
    } else {
      return !(
        productInfo.status === Enum_Product_Status.Disabled ||
        userId?.toString() === me?.data?.id?.toString() ||
        (me.isMember && !combineRoleWithSuperAdmin(userRoleProductPermission !== EnumRoleProduct.Viewer)) ||
        (me.isMember &&
          combineRoleWithSuperAdmin(userRoleProductPermission === EnumRoleProduct.Editor) &&
          roleName === "OWNER")
      );
    }
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[], _: any, info: { type: RowSelectMethod }) => {
    const pMeUser = productInfo?.users?.filter((item) => {
      return !isRowSelection(item.userId, item.role);
    });
    let _newSelectedRowKeys: React.Key[] = [];
    if (info.type === "all") {
      setIsSelectedAll(!isSelectedAll);
      if (!isSelectedAll) {
        _newSelectedRowKeys = newSelectedRowKeys.filter(
          (item) => !pMeUser?.find((item1) => item1.id.toString() === item.toString()),
        );
      }
    } else {
      _newSelectedRowKeys = newSelectedRowKeys;
    }
    setSelectedRowKeys(_newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    renderCell: (_value: boolean, record: IProductUser, _index: number, originNode: React.ReactNode) => {
      return isRowSelection(record.users_permissions_user?.id, record.role?.name) ? originNode : <></>;
    },
  };

  const { getColumnSearchProps } = useTableProps({
    setFilters,
    filters,
  });

  const tableColumns: any = React.useMemo(
    () => [
      {
        title: "Image",
        dataIndex: "avatar",
        width: "5%",
        className: `text-center`,
        render: (_: string, record: IProductUser) => {
          return (
            <>
              <div className="flex items-center justify-center space-x-2">
                <ProductIconAndPlatform imageUrl={record.users_permissions_user?.icon || ""} />
              </div>
            </>
          );
        },
      },
      {
        title: "User Email",
        dataIndex: "email",
        key: "[users_permissions_user][email]",
        with: "80%",
        ...getColumnSearchProps({
          isCustomized: true,
          logicalFilters: [
            {
              field: "users_permissions_user][email]",
              operator: "containss",
            },
          ],
        }),
        render: (_: string, record: IProductUser) => {
          return <p>{record.users_permissions_user?.email}</p>;
        },
      },

      {
        title: "Role",
        dataIndex: "role",
        key: "role",
        with: "35%",
        className: "text-[14px]",
        render: (_role: string, record: IProductUser) => {
          return (
            <ColumnUserProductRole
              typeRole={record.role?.type}
              role={record.role?.name}
              userId={record.users_permissions_user?.id || ""}
              productId={productInfo.id}
              optionsRole={optionsRoleFiltered}
              refetchProduct={refetchProduct}
              status={record.status}
              handleErrorData={handleErrorData}
              refetchUserRole={() => {
                tableQueryResult.refetch();
              }}
            />
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        with: "20%",
        className: "text-[14px]",
        filters: [
          {
            text: Enum_Productuserrole_Status.Accepted,
            value: Enum_Productuserrole_Status.Accepted,
          },
          {
            text: Enum_Productuserrole_Status.Pending,
            value: Enum_Productuserrole_Status.Pending,
          },
        ],
        onFilter: (value: string, record: IProductUser) => record.status.includes(value),
        filterIcon: (filtered: boolean) => <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />,
        render: (_: any, record: IProductUser) => <TagStatus status={record.status} />,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getColumnSearchProps, optionsRoleFiltered, productInfo.id, refetchProduct],
  );
  return (
    <>
      {!productInfo.isDisabled && combineRoleWithSuperAdmin(userRoleProductPermission !== EnumRoleProduct.Viewer) && (
        <div className="flex justify-end mb-[30px]">
          <div className="px-4 py-2 cursor-pointer">
            <span className="text-[#24A0ED] text-[14px] font-normal" onClick={onToggleShowDrawerUser}>
              <UsergroupAddOutlined /> Add
            </span>
          </div>
          <div className="px-4 py-2 cursor-pointer">
            <Popconfirm
              placement="topLeft"
              title="Are you sure to delete these users?"
              onConfirm={handleRemoveUser}
              okText="Yes"
              cancelText="No"
              disabled={isLoading || selectedRowKeys.length === 0}
            >
              <span className="text-[#FF605C] text-[14px] font-normal">
                <UsergroupDeleteOutlined /> Remove
              </span>
            </Popconfirm>
          </div>
        </div>
      )}
      <div className="w-full">
        <Table
          {...tableProps}
          rowKey="id"
          rowSelection={rowSelection}
          columns={tableColumns}
          pagination={{
            ...tableProps.pagination,
            pageSizeOptions: [10, 20, 50],
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </div>

      <DrawerUser
        isShowDrawerUser={isShowDrawerUser}
        onToggleShowDrawerUser={onToggleShowDrawerUser}
        isLoadingProduct={isLoadingProduct}
      />
      <RemoveUserModal
        modalProps={modalProps}
        handleClose={close}
        isLoading={false}
        errorData={errorData}
        handleDelete={removeUserModalFn ?? handleRemoveUser}
        studioUsers={studioUsers}
      />
    </>
  );
}

export default UserManagement;
