import { InfoCircleOutlined } from "@ant-design/icons";
import React from "react";
import { Tooltip } from "@pankod/refine-antd";

interface ITooltip {
  placement?:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom";
  title: any;
  children?: React.ReactNode;
}
export const TooltipCustom: React.FC<ITooltip> = ({ placement, title }) => {
  return (
    <Tooltip
      className="ml-2"
      placement={placement}
      color="#BAE7FF"
      arrowPointAtCenter={true}
      showArrow={false}
      overlayInnerStyle={{
        border: "solid",
        borderWidth: "1px",
        borderColor: "#1890FF",
        color: "black",
      }}
      title={title}
    >
      <InfoCircleOutlined />
    </Tooltip>
  );
};
