import { AuthHelper } from "@pankod/refine-strapi-v4";
import config from "config";
import React from "react";
import useRunOnce from "./useRunOnce";
import { useFetchStudioUser } from "./useFetchStudioUsers";
import { useAuth0 } from "@auth0/auth0-react";
import { IUser } from "interfaces";

export const useAuthGetRole = () => {
  const apiUrlBE = config.BACKEND_URL + "/api";
  const [user, setUser] = React.useState<IUser>();
  const strapiAuthHelper = AuthHelper(apiUrlBE);
  const token = localStorage.getItem(config.TOKEN_KEY);
  const { userStudio, isLoadingStudioUser, setIsLoadingStudioUser } = useFetchStudioUser(user?.id);
  const { logout: logoutAuth0 } = useAuth0();
  useRunOnce(() => {
    if (token) {
      const fetchDataUser = async () => {
        try {
          const { data } = await strapiAuthHelper.me(localStorage.getItem(config.TOKEN_KEY) || "");
          if (data) {
            setUser(data as unknown as IUser);
            localStorage.setItem("user", JSON.stringify(data));
          }
        } catch (err: any) {
          const status = err?.response?.status;
          if (status === 401) {
            localStorage.removeItem("user");
            localStorage.removeItem(config.TOKEN_KEY);
            logoutAuth0({ returnTo: window.location.origin });
          }
        }
      };
      fetchDataUser();
    } else {
      setIsLoadingStudioUser(false);
    }
  });

  return {
    user,
    isLoadingStudioUser,
    userStudio,
  };
};
