import { Refine } from "@pankod/refine-core";
import { notificationProvider, ReadyPage, Icons } from "@pankod/refine-antd";
import "assets/css/index.css";
import "styles/antd.less";
import "assets/scss/app.scss";
import routerProvider from "@pankod/refine-react-router-v6";
import { Title, Header, Sider, Footer, Layout, OffLayoutArea } from "components/layout";
import { Login } from "pages/Auth/login";
import { ProductList } from "pages/Product";
import { ConfigProvider } from "antd";
import { ProductShow } from "pages/Product/show";
import { Provider } from "urql";
import { clientUrql } from "lib/urql";
import { EditProduct } from "pages/Product/edit";
import { authProviderRest, restDataProvider } from "lib/RestProvider";
import { gqlDataProvider } from "lib/GraphqlProvider";
import { StudioList } from "pages/Studio";
import { StudioShow } from "pages/Studio/show";
import { StudioCreate } from "pages/Studio/create";
import { StudioEdit } from "pages/Studio/edit";
import { newEnforcer } from "casbin.js";
import { adapter, model } from "utils/AccessControl/accessControl";
import { LoadingFullPage } from "components/Loading/FullPage";
import { useAuthGetRole } from "hooks/useAuthGetRole";
import CatchAll from "components/Result/CatchAll";
import { CanProps, useCanPerform } from "utils/AccessControl/Can";
import { s3ServiceProvider } from "lib/S3ServiceProvider";
import { ProductCreate } from "pages/Product/create";
import { BorderVerticleOutlined, ClusterOutlined } from "@ant-design/icons";

import { Routes } from "routes";
import React from "react";

const { AppstoreAddOutlined } = Icons;

function App() {
  const validateMessages = {
    // eslint-disable-next-line no-template-curly-in-string
    required: "${label} is required!",
    // ...
  };

  const { isLoadingStudioUser, user, userStudio } = useAuthGetRole();

  const { can } = useCanPerform({ user, userStudio });
  if (isLoadingStudioUser) {
    return <LoadingFullPage />;
  }
  return (
    <Provider value={clientUrql}>
      <ConfigProvider form={{ validateMessages }}>
        <Refine
          notificationProvider={notificationProvider}
          ReadyPage={ReadyPage}
          catchAll={<CatchAll />}
          accessControlProvider={{
            can: async ({ resource, action, params }) => {
              const enforcer = await newEnforcer(model, adapter);
              const props: CanProps = {
                params,
                action,
                resource,
                enforcer,
              };
              return can(props);
            },
          }}
          routerProvider={{
            ...routerProvider,
            routes: [...Routes],
          }}
          authProvider={authProviderRest}
          dataProvider={{
            default: restDataProvider,
            graphql: gqlDataProvider,
            s3Service: s3ServiceProvider,
          }}
          resources={[
            {
              name: "products",
              icon: <AppstoreAddOutlined />,
              list: ProductList,
              create: ProductCreate,
              show: ProductShow,
              edit: EditProduct,
            },
            {
              name: "studios",
              icon: <ClusterOutlined />,
              list: StudioList,
              show: StudioShow,
              create: StudioCreate,
              edit: StudioEdit,
            },
            {
              name: "analytics",
            },
            {
              parentName: "analytics",
              name: "appsflyer",
              icon: <BorderVerticleOutlined />,
              list: StudioList,
            },
            {
              parentName: "analytics",
              name: "metabase",
              icon: <BorderVerticleOutlined />,
              list: StudioList,
            },
            // {
            //   parentName: "analytics",
            //   name: "amapassport",
            //   icon: <BorderVerticleOutlined />,
            //   list: StudioList,
            // },
            // {
            //   parentName: "analytics",
            //   name: "eventgateway",
            //   icon: <BorderVerticleOutlined />,
            //   list: StudioList,
            // },
          ]}
          LoginPage={Login}
          Title={Title}
          Header={Header}
          Sider={Sider}
          Footer={Footer}
          Layout={Layout}
          OffLayoutArea={OffLayoutArea}
        />
      </ConfigProvider>
    </Provider>
  );
}

export default App;
