import { MinusCircleOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { Button, Form, FormInstance, Input, Radio, Spin, Switch } from "@pankod/refine-antd";
import { useApiUrl, useCreate, useCustomMutation } from "@pankod/refine-core";
import { TooltipCustom } from "components/Input/Tooltip";
import { useProductStepForm } from "context/ProductStepFormContext";
import { EnumTooltipContent } from "enums";
import React from "react";
import { FirebaseIcon } from "components/Icons";
import { useDebounce } from "hooks/useDebounce";
import config from "config";
import { FormItemHiddenLabel } from "components/Form/FormItemHiddenLabel";

interface ExistingProjectFirebaseProps {
  fieldKey: number;
  name: number;
  isLoadingVerifyFirebase?: boolean;
  dataVerifyFirebase: any;
  onVerifyFirebase: () => void;
  isCreateProduct?: boolean;
  console_url?: string;
}

const ExistingProjectFirebase: React.FC<ExistingProjectFirebaseProps> = ({
  fieldKey,
  name,
  isLoadingVerifyFirebase,
  dataVerifyFirebase,
  onVerifyFirebase,
  isCreateProduct,
  console_url,
}) => {
  const { values } = useProductStepForm();
  const value = values?.firebase_configurations && values.firebase_configurations[name];
  const regexFirebaseUrl = /https:\/\/console.firebase.google.com\/.*project\/(.*)[\\/?].*/;
  const data = dataVerifyFirebase?.data?.attributes;
  const isConfirm = Boolean(data);
  const project_name = data?.displayName || value?.name_curr;
  const project_id = data?.projectId || value?.project_id;
  const isAvailable = data?.isAvailable || value?.isAvailable;

  const isDisabled = isLoadingVerifyFirebase || ((Boolean(dataVerifyFirebase) || value?.confirm) && isAvailable);

  return (
    <>
      <Form.Item
        fieldKey={fieldKey}
        name={[name, "console_url"]}
        label={
          <div>
            <span>Firebase URL</span>
          </div>
        }
        rules={[
          {
            required: true,
            message: "",
            whitespace: false,
          },
          () => ({
            validator: (_, v) => {
              if (value?.confirm && isAvailable) {
                return Promise.resolve();
              }
              if (!v) {
                return Promise.reject(new Error("Firebase URL is required"));
              }
              if (!regexFirebaseUrl.test(v)) {
                return Promise.reject(new Error("Invalid Firebase URL. Please check your Firebase URL again"));
              }
              if (regexFirebaseUrl.test(v) && !data) {
                return Promise.reject(new Error("Your connect URL hasn't verified yet. Please verify it!"));
              }
              if (!isAvailable) {
                return Promise.reject(new Error("This firebase already exists. Please try another"));
              }
              if (v && regexFirebaseUrl.test(v) && data) {
                return Promise.resolve();
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input.Search
          disabled={isDisabled}
          placeholder="Paste your link here"
          enterButton={
            <Button loading={isLoadingVerifyFirebase} onClick={onVerifyFirebase} disabled={isDisabled || !console_url}>
              {isDisabled ? "Verified" : "Verify project"}
            </Button>
          }
          css={css`
            button {
              font-size: 12px !important;
              background-color: #1890ff;
              color: ${isDisabled || !console_url ? "rgba(0, 0, 0, 0.25)" : "#ffffff"} !important;
              border: ${isDisabled || !console_url ? "1px solid #d9d9d9" : "0"} !important;
              &:hover {
                border: 1px solid black !important;
                color: black !important;
              }
            }
          `}
        />
      </Form.Item>
      {project_name && (
        <FormItemHiddenLabel>
          <div className="px-6 py-4 bg-ocean rounded flex justify-between items-center">
            <div className="flex">
              <div className="flex flex-col justify-center ml-2">
                <p className="mb-1 font-bold">{project_name || "Noname"}</p>
                <p className="mb-0 italic text-[10px]">{project_id}</p>
              </div>
            </div>
            <div className="flex flex-col  items-center  ">
              <FirebaseIcon className="text-[30px] brightness-0" />
            </div>
          </div>
        </FormItemHiddenLabel>
      )}
      {isConfirm && isCreateProduct && (
        <Form.Item fieldKey={fieldKey} name={[name, "confirm"]} hidden initialValue={isConfirm}>
          <Input />
        </Form.Item>
      )}
      {project_name && (
        <Form.Item fieldKey={fieldKey} name={[name, "name_curr"]} hidden initialValue={project_name}>
          <Input value={project_name} />
        </Form.Item>
      )}
      <FormItemHiddenLabel>
        <span>
          Please <b>verify project</b> to confirm that ama_tech@amanotes.com is added as Product Owner of above Firebase
          project
        </span>
      </FormItemHiddenLabel>
    </>
  );
};

interface FormItemProps {
  fieldKey: number;
  name: number;
  index: number;
  remove: (val: number) => void;
  isCreateProduct?: boolean;
  formModalCreate?: FormInstance<any>;
  setIsLoading?: (val: boolean) => void;
}

const FormItem: React.FC<FormItemProps> = ({
  fieldKey,
  name,
  remove,
  isCreateProduct,
  formModalCreate,
  setIsLoading,
}) => {
  const { setFormLoading, formLoading, values, setValues, form } = useProductStepForm();
  const _form = form || formModalCreate;
  const apiUrl = useApiUrl();

  const namePathConsoleUrl = React.useMemo(() => {
    return ["firebase_configurations", name, "console_url"];
  }, [name]);

  const namePathFirebaseId = React.useMemo(() => {
    return ["firebase_configurations", name, "project_id"];
  }, [name]);

  const {
    mutateAsync: generateFirebaseId,
    data: resultGenerateFirebaseId,
    isLoading: isLoadingGenerateFirebaseId,
  } = useCustomMutation();

  const onGenerateFirebaseId = React.useCallback(
    async (name?: string) => {
      return await generateFirebaseId({
        url: `${apiUrl}/firebases/generate-project-id`,
        method: "post",
        values: name
          ? {
              data: { name },
            }
          : {},
        successNotification: false,
        errorNotification: false,
      });
    },
    [apiUrl, generateFirebaseId],
  );
  const _dataGenerateFirebase = resultGenerateFirebaseId?.data?.data?.attributes;

  React.useEffect(() => {
    !isLoadingGenerateFirebaseId && _dataGenerateFirebase && _form?.resetFields([namePathFirebaseId]);
  }, [_dataGenerateFirebase, _form, isLoadingGenerateFirebaseId, namePathFirebaseId]);

  const value = values?.firebase_configurations && values.firebase_configurations[name];

  React.useEffect(() => {
    !value && onGenerateFirebaseId();
  }, [onGenerateFirebaseId, value]);
  const projectId = _dataGenerateFirebase?.projectId;
  const [radioValue, setRadioValue] = React.useState<string>(value?.type || "new");
  const [firebaseName, setFirebaseName] = React.useState("");
  const debouncedFirebaseName = useDebounce(firebaseName, 1000);
  React.useMemo(() => {
    debouncedFirebaseName &&
      debouncedFirebaseName.length >= config.MIN_LENGTH_FIREBASE_NAME &&
      onGenerateFirebaseId?.(debouncedFirebaseName);
  }, [debouncedFirebaseName, onGenerateFirebaseId]);
  const { mutateAsync: verifyFirebase, isLoading: isLoadingVerifyFirebase, data } = useCreate();
  const dataVerifyFirebase = data?.data;

  const console_url = Form.useWatch(namePathConsoleUrl);

  const onVerifyFirebase = async () => {
    await verifyFirebase({
      resource: "firebases/verify-project",
      values: {
        console_url,
      },
      errorNotification: false,
      successNotification: false,
    });
  };
  React.useEffect(() => {
    isCreateProduct && setFormLoading && setFormLoading(isLoadingGenerateFirebaseId);
    setIsLoading?.(isLoadingGenerateFirebaseId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingGenerateFirebaseId, setFormLoading, setIsLoading]);
  React.useEffect(() => {
    isCreateProduct && setFormLoading && setFormLoading(isLoadingVerifyFirebase);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingVerifyFirebase, setFormLoading]);

  const _projectId = projectId;
  const _projectIdCurr = dataVerifyFirebase?.data?.attributes?.projectId;
  const isAvailable = dataVerifyFirebase?.data?.attributes?.isAvailable;
  React.useEffect(() => {
    if (dataVerifyFirebase?.data?.attributes?.projectId) {
      _form?.validateFields([namePathConsoleUrl]);
    }
  }, [dataVerifyFirebase?.data?.attributes?.projectId, _form, namePathConsoleUrl]);

  const onRemove = () => {
    remove(name);
    setValues?.((_values: any) => {
      const _firebase_configurations = value
        ? _values?.firebase_configurations?.filter((item: any) => item.project_id !== value?.project_id)
        : _values?.firebase_configurations;
      return {
        ..._values,
        firebase_configurations: _firebase_configurations,
      };
    });
  };
  return (
    <Spin spinning={isLoadingGenerateFirebaseId}>
      <div className={`${isCreateProduct ? "mb-[3rem]" : ""} relative form-list-item`}>
        {isCreateProduct && (
          <MinusCircleOutlined
            className={`self-baseline text-[16px] text-red-500 absolute top-[10px] right-[-30px]`}
            onClick={onRemove}
          />
        )}
        <Form.Item fieldKey={fieldKey} label="This is" name={[name, "type"]} className="mb-4" initialValue={radioValue}>
          <Radio.Group
            onChange={(e) => {
              setRadioValue(e.target.value);
            }}
          >
            {[
              {
                label: "New project",
                value: "new",
              },
              {
                label: "Existing project",
                value: "old",
              },
            ].map((item) => {
              return (
                <Radio key={item.value} value={item.value}>
                  {item.label}
                </Radio>
              );
            })}
          </Radio.Group>
        </Form.Item>
        <div>
          {radioValue !== "new" ? (
            <ExistingProjectFirebase
              dataVerifyFirebase={dataVerifyFirebase}
              onVerifyFirebase={onVerifyFirebase}
              isLoadingVerifyFirebase={isLoadingVerifyFirebase}
              fieldKey={fieldKey}
              name={name}
              isCreateProduct={isCreateProduct}
              console_url={console_url}
            />
          ) : (
            <>
              <Form.Item
                fieldKey={fieldKey}
                label="Firebase name"
                name={[name, "name"]}
                validateTrigger={["onChange", "onBlur"]}
                initialValue=""
                rules={[
                  {
                    required: true,
                    max: 50,
                    min: config.MIN_LENGTH_FIREBASE_NAME,
                    message: "Firebase name at least 4 character.",
                  },
                ]}
              >
                <Input onChange={(e) => setFirebaseName(e.target.value)} />
              </Form.Item>
              <Form.Item
                fieldKey={fieldKey}
                label={
                  <>
                    Request Billing{" "}
                    <TooltipCustom title={EnumTooltipContent.RequestBilling} placement="top"></TooltipCustom>
                  </>
                }
                className="mb-0"
                name={[name, "is_request_billing"]}
                valuePropName="checked"
                initialValue={false}
              >
                <Switch defaultChecked={false} checkedChildren="Yes" unCheckedChildren="No" className="ml-2" />
              </Form.Item>
            </>
          )}
        </div>

        {!formLoading && _projectId && (
          <Form.Item fieldKey={fieldKey} name={[name, "project_id"]} initialValue={_projectId} hidden={true}>
            <Input />
          </Form.Item>
        )}
        {!formLoading && _projectIdCurr && (
          <>
            <Form.Item fieldKey={fieldKey} name={[name, "project_id_curr"]} initialValue={_projectIdCurr} hidden={true}>
              <Input />
            </Form.Item>
            <Form.Item fieldKey={fieldKey} name={[name, "isAvailable"]} initialValue={isAvailable} hidden={true}>
              <Input />
            </Form.Item>
          </>
        )}
      </div>
    </Spin>
  );
};

export default FormItem;
