import { useCustom, useNotification } from "@pankod/refine-core";
import { DeleteObjectButton } from "pages/Product/components/Bucket/ActionsBucketButton";
import config from "config";
import { useMe } from "context/MeContext";

interface DeleteObjectsData {
  bucketName: string;
  selectedRowKeys: React.Key[];
  setSelectedRowKeys: (val: React.Key[]) => void;
  productId: string;
  refetch: () => void;
}

export const useDeleteObjects = (data: DeleteObjectsData) => {
  const { open } = useNotification();
  const apiUrlS3 = config.S3_URL;
  const { bucketName, selectedRowKeys, productId, setSelectedRowKeys, refetch } = data;
  const me = useMe();
  const { refetch: deleteFile, isFetching: deleteLoading } = useCustom<any>({
    url: `${apiUrlS3}/deleteFiles`,
    method: "post",
    queryOptions: {
      enabled: false,
      retry: 0,
    },
    config: {
      payload: {
        data: {
          bucketName,
          filespath: selectedRowKeys.map(String),
          userId: me?.data?.id,
          productId: productId,
        },
      },
      query: {
        userId: me?.data?.id,
        productId: productId,
      },
    },
    errorNotification: {
      type: "error",
      message: config.UnAuthorizedActionErrorMessage,
    },
  });

  const onDelete = async () => {
    const res = await deleteFile();
    if (res.error) {
      return;
    }
    const data = res.data?.data;
    if (data.length > 0) {
      refetch();
      open?.({
        message: "Successfully",
        type: "success",
      });
      setSelectedRowKeys([]);
    }
  };

  return {
    DeleteObjectButton,
    onDelete,
    deleteLoading,
  };
};
