import React from "react";
import differenceInDays from "date-fns/differenceInDays";
import format from "date-fns/format";
import subDays from "date-fns/subDays";
import zonedTimeToUtc from "date-fns-tz/zonedTimeToUtc";
import dayjs from "dayjs";
import { useCustom, useApiUrl } from "@pankod/refine-core";

// interface
import { IStartDateByChartType } from "interfaces/chart";
import { CHART_TYPES, GROUPINGS, NON_ADAPTIVE_KPIS, KPIS, KPIS_TYPES, DATA_DATE_FORMAT } from "utils/chart/chartConfig";
import { AppEntity } from "schema/schema";

// utils
import { isAnArray } from "utils/isAnArray";
import { transformChartLtvData } from "utils/chart/transformData/transformChartLtvData";
import { getUnSupportedChartTypes } from "utils/chart/chartHelper/supportedCombinationsChart";

type IBaseQuery = {
  app_id: number;
  start_date: string;
  end_date: string;
  groupings?: string[];
  kpis?: string[];
  event_kpis?: string[];
  limit?: number[] | number;
  sort_by?: string[][];
  is_ssot_request?: boolean;
};

type ILimitArray = {
  chartType: string;
  daysCount: number;
  groupings: any[];
};

type ILtvChartProps = {
  productId: string | undefined;
  app: AppEntity | undefined;
  dimensionSelected: string;
  metricSelected: string;
  chartTypeSelected: string;
  widgetChart: string;
  defaultStartDate: Date;
  defaultEndDate: Date;
  metadata?: {
    isSsotRequest?: boolean;
  };
};

const getGroupingsArray = (chosenDimension: string, chosenMetric: string, chartType: string) => {
  if (chartType === CHART_TYPES.SPLINE || chartType === CHART_TYPES.COLUMN) {
    return [chosenDimension, "date"];
  }
  if (chartType === CHART_TYPES.SYNCED_PIE) {
    return [chosenDimension];
  }
  const groupingsArray = [chosenDimension];
  if (!NON_ADAPTIVE_KPIS.includes(chosenMetric) && chosenMetric !== KPIS.ASSISTS) groupingsArray.push(GROUPINGS.SOURCE);
  return [...new Set(groupingsArray)];
};

const getLimitArray = ({ chartType, daysCount, groupings }: ILimitArray) => {
  switch (chartType) {
    case CHART_TYPES.SPLINE:
    case CHART_TYPES.COLUMN:
      return [10, daysCount];
    case CHART_TYPES.PIE:
    case CHART_TYPES.BAR:
      return groupings.length === 1 ? 10 : [10, 2];
    case CHART_TYPES.SYNCED_PIE:
      return 9;
    default:
      return [10, 2];
  }
};

const getMetricsArray = (chosenDimension: string, chosenMetric: string) => {
  let metricsArray = chosenDimension === GROUPINGS.EVENT_NAME ? [] : [chosenMetric];
  if (chosenMetric === KPIS.ASSISTS) metricsArray = [KPIS.ASSISTS, KPIS.INSTALL];
  if (chosenMetric === KPIS_TYPES.TOUCHPOINTS) metricsArray = [KPIS.IMPRESSIONS, KPIS.CLICKS];
  return !metricsArray.length ? [KPIS.TOTAL_ATTRIBUTIONS] : metricsArray;
};

const getSortBy = (chosenDimension: string, chosenMetric: string) => {
  let metric = chosenMetric;
  if (chosenDimension === GROUPINGS.EVENT_NAME) metric = KPIS.TOTAL_ATTRIBUTIONS;
  if (chosenMetric === KPIS_TYPES.TOUCHPOINTS) metric = KPIS.CLICKS;
  return [[metric, "desc"]];
};

const getEventMetricsArray = (chosenDimension: string, chosenMetric: string) =>
  chosenDimension === GROUPINGS.EVENT_NAME ? [chosenMetric] : [];

const DATA_URL = (widgetId: string, limit: number | number[] | undefined) => {
  if (isAnArray(limit)) return `trend-chart?widget=${widgetId}`;
  return `data?widget=${widgetId}`;
};

export const getStartDate = ({ startDate, chartType }: IStartDateByChartType) => {
  if (chartType !== CHART_TYPES.COLUMN && chartType !== CHART_TYPES.SPLINE) {
    return startDate;
  }
  const utcTime = zonedTimeToUtc(startDate, Intl.DateTimeFormat().resolvedOptions().timeZone);
  return format(subDays(utcTime, 1), DATA_DATE_FORMAT);
};

const buildLtvDataQuery = ({ kpis, groupings, limit, sort_by, event_kpis, ...rest }: IBaseQuery) => ({
  kpis: kpis || [KPIS.TOTAL_ATTRIBUTIONS, KPIS.CLICKS, KPIS.IMPRESSIONS],
  sort_by: sort_by ?? [[KPIS.TOTAL_ATTRIBUTIONS, "desc"]],
  limit,
  exclusions: {},
  groupings,
  ...(event_kpis ? { event_kpis: event_kpis } : {}),
  ...rest,
});
export const useLtvChartData = ({
  productId,
  app,
  dimensionSelected,
  metricSelected,
  chartTypeSelected,
  widgetChart,
  defaultStartDate,
  defaultEndDate,
  metadata = {},
}: ILtvChartProps) => {
  // hooks
  const apiUrl = useApiUrl();
  let startDate: any = defaultStartDate;
  const endDate = defaultEndDate;

  const { baseQuery, chartType } = React.useMemo(() => {
    let chartType = "";
    // get chart type
    if (!metricSelected) chartType = CHART_TYPES.BAR;
    let fortmatterStartDate = startDate;

    const unsupportedChartTypes = getUnSupportedChartTypes({
      isEventKpi: dimensionSelected === GROUPINGS.EVENT_NAME,
      kpiKey: metricSelected,
    });
    const chartTypeOptions = Object.values(CHART_TYPES).filter(
      (chartType) => !unsupportedChartTypes.includes(chartType),
    );
    const chartTypeChanged = chartTypeOptions.some((option) => option === CHART_TYPES.BAR)
      ? CHART_TYPES.BAR
      : chartTypeOptions[0];

    chartType = chartTypeOptions.some((option) => option === chartTypeSelected) ? chartTypeSelected : chartTypeChanged;

    if (chartType === CHART_TYPES.COLUMN || chartType === CHART_TYPES.SPLINE) {
      fortmatterStartDate = dayjs(startDate).subtract(1, "day").format("YYYY-MM-DD");
    }

    // get baseQuery
    const newBaseQuery: IBaseQuery = {
      app_id: Number(app?.id),
      start_date: dayjs(fortmatterStartDate).format("YYYY-MM-DD"),
      end_date: dayjs(endDate).format("YYYY-MM-DD"),
    };
    newBaseQuery.groupings = getGroupingsArray(dimensionSelected, metricSelected, chartType);
    newBaseQuery.kpis = getMetricsArray(dimensionSelected, metricSelected);
    newBaseQuery.event_kpis = getEventMetricsArray(dimensionSelected, metricSelected);
    newBaseQuery.limit = getLimitArray({
      chartType,
      groupings: newBaseQuery.groupings,
      daysCount: differenceInDays(new Date(endDate), new Date(fortmatterStartDate)) + 2,
    });
    newBaseQuery.sort_by = getSortBy(dimensionSelected, metricSelected);
    newBaseQuery.is_ssot_request = metadata?.isSsotRequest || false;
    return {
      baseQuery: newBaseQuery,
      chartType,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app?.id, dimensionSelected, metricSelected, chartTypeSelected]);

  const urlChart = DATA_URL(widgetChart, baseQuery.limit);
  const query = buildLtvDataQuery(baseQuery);
  const {
    data,
    refetch: refetchChart,
    isFetching,
    status,
  } = useCustom({
    url: `${apiUrl}/products/${productId}/integrations:appsflyer/${urlChart}`,
    method: "post",
    queryOptions: {
      enabled: false,
      retry: 0,
    },
    config: {
      payload: {
        data: query,
      },
    },
  });

  // initial chart data
  React.useEffect(() => {
    if (!app?.id) return;
    refetchChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app?.id, dimensionSelected, metricSelected, chartTypeSelected]);

  const isFetched = !isFetching && status === "success";
  const transformData = React.useMemo(() => {
    return transformChartLtvData({
      data: isFetched ? data?.data.data.attributes : null,
      dimension: dimensionSelected,
      metric: metricSelected,
      type: chartType,
      days: [dayjs(startDate).format("YYYY-MM-DD"), dayjs(endDate).format("YYYY-MM-DD")],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, dimensionSelected, metricSelected]);

  return {
    baseQuery,
    isFetched,
    chartType,
    data: transformData,
  };
};
