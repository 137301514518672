import { IRole } from "./../interfaces/index.d";
import { useList } from "@pankod/refine-core";
export type UserRoleOptions = {
  value: string | number;
  label: string;
  text?: string;
  id?: string;
};

interface RolePermissionObject {
  studio: UserRoleOptions[];
  product: UserRoleOptions[];
}
export const useUserRole = () => {
  const { data, isLoading } = useList({
    resource: "users-permissions/roles",
  });
  const _data = data as any;
  const roles: IRole[] = _data?.data?.roles;
  const rolePermissions: RolePermissionObject = roles?.reduce(
    (curr: RolePermissionObject, item: IRole) => {
      const obj: UserRoleOptions = {
        value: item.type,
        label: item.name,
        text: item.name,
        id: item.id,
      };
      if (item.type.includes("PRODUCT")) {
        curr.product.push(obj);
      } else if (item.type.includes("STUDIO")) {
        curr.studio.push(obj);
      }
      return curr;
    },
    { product: [], studio: [] } as RolePermissionObject,
  );

  const getRoleIdByName = (name: string) => {
    return roles?.find((item) => item.type === name)?.id;
  };

  return {
    data,
    isLoading,
    productRolePermissions: rolePermissions?.product,
    studioRolePermissions: rolePermissions?.studio,
    getRoleIdByName,
  };
};
