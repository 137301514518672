import React from "react";
import { Select } from "antd";

import { useWebSettingQuery } from "schema/schema";

// interface
import { IProductSelect } from "interfaces";

type IProps = {
  dashboardSelected: IProductSelect | null;
  handleSelectDashboard: (dashboardItem: any) => void;
  queryDashboardId: string | null;
};

export default function DashboardField({ queryDashboardId, dashboardSelected, handleSelectDashboard }: IProps) {
  const [webSetting] = useWebSettingQuery();

  const dasshboards = React.useMemo(() => {
    if (!webSetting) return [];
    return webSetting ? webSetting?.data?.webSetting?.data?.attributes?.appsflyer_dashboards : [];
  }, [webSetting]);

  const MenuOption = React.useMemo(() => {
    if (!dasshboards) return [];
    return dasshboards?.map((metabase: any) => ({
      label: metabase.name,
      value: metabase.id,
      id: metabase.id,
      name: metabase.name,
    }));
  }, [dasshboards]);

  // init dashboard when has queryString on url.
  React.useEffect(() => {
    if (dashboardSelected?.id || !queryDashboardId || !dasshboards) return;
    const dashboardItem = dasshboards?.find((item: any) => item.id === queryDashboardId) || null;
    if (dashboardItem) {
      handleSelectDashboard(dashboardItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryDashboardId, dasshboards]);

  return (
    <Select
      value={dashboardSelected?.id}
      className="w-[252px]"
      dropdownClassName="custom-select"
      placeholder="Dashboard"
      optionLabelProp="name"
      listHeight={400}
      allowClear
      options={MenuOption}
      onChange={(value, option) => {
        const dashboardItem = dasshboards?.find((item: any) => item.id === value);
        handleSelectDashboard(dashboardItem);
      }}
    />
  );
}
