const teal = {
  50: "#E6FCF5",
  100: "#C3FAE8",
  200: "#96F2D7",
  300: "#63E6BE",
  400: "#38D9A9",
  500: "#20C997",
  600: "#12B886",
  700: "#0CA678",
  800: "#099268",
  900: "#087F5B",
};

export default teal;
