import React from "react";
import { Button, Table, useModalForm, useTable } from "@pankod/refine-antd";
// ant icons
import { EditFilled } from "@ant-design/icons";
// components
import { TagStatus } from "components/Tag";
// context
import { useProduct } from "context/ProductContext";
// schema
import { Enum_App_Platform, Enum_App_Status } from "schema/schema";
import { capitalize } from "lodash";
import { useNavigation } from "@pankod/refine-core";
import config, { PLATFORM } from "config";
import { useGetMyRole } from "hooks/useGetMyRole";
import { EnumRoleProduct } from "enums";
import { usePermission } from "hooks/usePermission";
import useTableProps from "hooks/useTableProps";

import StoresEdit from "pages/Stores/edit";
import { IStore } from "interfaces";
import { useMe } from "context/MeContext";
const StoreInfo: React.FC = () => {
  const { push } = useNavigation();
  // states
  const { isMember } = useMe();
  const { refetchProduct, productInfo } = useProduct();
  const { combineRoleWithSuperAdmin } = usePermission();
  const { userRoleProductPermission } = useGetMyRole();
  const { tableProps, tableQueryResult, filters, setFilters } = useTable({
    resource: "apps",
    queryOptions: {
      enabled: Boolean(productInfo.id),
    },
    permanentFilter: [
      {
        field: "product][id]",
        operator: "eq",
        value: productInfo.id,
      },
    ],
    syncWithLocation: false,
    successNotification: false,
    errorNotification: false,
  });
  const apps = React.useMemo(() => {
    return tableQueryResult.data?.data.map((app: any) => {
      return {
        ...app,
        firebase: productInfo.apps?.find((item: any) => item.attributes.bundle_id === app.bundle_id)?.attributes
          ?.firebase?.data?.attributes,
      };
    });
  }, [tableQueryResult.data?.data, productInfo.apps]);
  const { show, modalProps, close, form, formProps, mutationResult, queryResult } = useModalForm<
    IStore & { error?: any }
  >({
    resource: "apps",
    action: "edit",
    redirect: false,
    onMutationSuccess: () => {
      refetchProduct();
    },
    successNotification: (data: any) => {
      const error = data?.data?.error;
      console.log({ data, error });

      return !error
        ? {
            type: "success",
            description: "Success",
            message: "Application information is successfully updated",
          }
        : {
            type: "error",
            description: "Error",
            message: error.message,
          };
    },
    errorNotification: false,
  });
  const { columnsPropsAppName, columnsPropsAppIcon, columnsPropsAppCode } = useTableProps({
    filters,
    setFilters,
  });
  const isEdit = () => {
    return (
      !productInfo.isDisabled &&
      combineRoleWithSuperAdmin(userRoleProductPermission !== EnumRoleProduct.Viewer || !isMember)
    );
  };
  const columns = React.useMemo(() => {
    return [
      {
        ...columnsPropsAppIcon,
      },
      {
        title: "App name",
        key: "name",
        width: "30%",
        className: "text-[rgba(0, 0, 0, 0.85)]",
        ...columnsPropsAppName,
      },
      {
        ...columnsPropsAppCode,
        width: "13%",
      },
      {
        title: "Firebase project",
        dataIndex: "firebase",
        key: "firebase_name",
        width: "30%",
        className: "text-[rgba(0, 0, 0, 0.85)]",
        render: (value: any) => (
          <div>
            {!value && <span>-</span>}
            {value && (
              <a
                type="link"
                target="_blank"
                rel="noopener noreferrer"
                href={`https://console.firebase.google.com/project/${value.project_id}/overview`}
                // className="underline"
              >
                {value.project_name}
              </a>
            )}
          </div>
        ),
      },
      {
        title: "Platform",
        dataIndex: "platform",
        key: "platform",
        width: "20%",
        className: "text-[rgba(0, 0, 0, 0.85)]",
        filters: [
          {
            value: Enum_App_Platform.Android,
            text: Enum_App_Platform.Android,
          },
          {
            value: Enum_App_Platform.Ios,
            text: Enum_App_Platform.Ios,
          },
        ],
        render: (platform: string) => (
          <div className="font-normal">{platform === "IOS" ? PLATFORM.iOS : PLATFORM.Android}</div>
        ),
      },
      {
        title: "App status",
        dataIndex: "status",
        key: "status",
        width: "20%",
        className: "text-[rgba(0, 0, 0, 0.85)]",
        filters: [
          {
            value: Enum_App_Status.Development,
            text: capitalize(Enum_App_Status.Development),
          },
          {
            value: Enum_App_Status.Active,
            text: capitalize(Enum_App_Status.Active),
          },
          {
            value: Enum_App_Status.Inactive,
            text: capitalize(Enum_App_Status.Inactive),
          },
        ],
        // filterSearch: true,

        render: (value: string) => <TagStatus status={value} />,
      },
      {
        title: "Action",
        dataIndex: "id",
        key: "id",
        width: "10%",
        className: "text-[rgba(0, 0, 0, 0.85)]",
        render: (value: string) => {
          return (
            <>
              {!isEdit() && (
                <span className="text-[#d9d9d9] m-0 cursor-pointer">
                  <span className="mr-2">Edit</span>
                  <EditFilled />
                </span>
              )}
              {isEdit() && (
                <span onClick={() => show(value)} className="text-[#1890FF] m-0 cursor-pointer">
                  <span className="mr-2">Edit</span>
                  <EditFilled />
                </span>
              )}
            </>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, productInfo]);
  return (
    <>
      <StoresEdit
        modalProps={modalProps}
        formProps={formProps}
        form={form}
        close={close}
        mutationResult={mutationResult}
        queryResult={queryResult}
      />
      <div className="flex justify-end w-full mb-[16px]">
        <Button type="primary" onClick={() => push(`/products/show/${productInfo.id}/add/apps`)} disabled={!isEdit()}>
          Add new
        </Button>
      </div>
      <Table
        {...tableProps}
        loading={false}
        rowKey="bundle_id"
        columns={columns}
        dataSource={apps}
        pagination={{
          pageSizeOptions: config.INITIAL_PAGE_SIZE_OPTIONS,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
      />
    </>
  );
};

export default StoreInfo;
