import UploadForm from "components/Upload/UploadForm";
import React from "react";
export type DataUpload = {
  icon?: { url?: string; alt?: string };
};

export const useUploadForm = () => {
  const [loading, setLoading] = React.useState(false);

  return {
    UploadForm,
    loading,
    setLoading,
  };
};
