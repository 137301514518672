/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import React from "react";
import { css } from "@emotion/react";
import { v4 as uuidv4 } from "uuid";

// refine
import { List, Table, useTable, Switch, Button, useModal, CreateButton } from "@pankod/refine-antd";

// ant icons
import { FilterFilled } from "@ant-design/icons";

// interface
import type { ColumnsType } from "antd/es/table/interface";
import { IStudio } from "interfaces";

// components
import { ProductIconAndPlatform } from "components/ProductIcon";
import { CanAccessEditButton } from "components/Button/CanAccessButton";

// configs
import config from "config";
import useTableProps from "hooks/useTableProps";
import { useUpdate } from "@pankod/refine-core";
import AlertModal from "components/Modal/AlertModal";
import { useGetMyRole } from "hooks/useGetMyRole";
import { EnumRoleStudio } from "enums";
import { usePermission } from "hooks/usePermission";
import { useMe } from "context/MeContext";

import honeycomb from "lib/Honeycomb";

export const StudioList: React.FC = () => {
  // refs
  const honeyDataRef = React.useRef<any>(null);
  const { data: me } = useMe();
  // state
  const [selectedStudio, setSelectedStudio] = React.useState<IStudio>();
  const [messageTableError, setMessageTableError] = React.useState("Data Empty");
  // hooks
  const { tableProps, tableQueryResult, setFilters, filters } = useTable<IStudio>({
    initialPageSize: config.INITIAL_PAGE_SIZE,
    metaData: {
      populate: ["icon", "studio_user_roles.users_permissions_user"],
    },
    initialSorter: [
      {
        field: "createdAt",
        order: "asc",
      },
    ],
    queryOptions: {
      retry: 0,
    },
    permanentFilter: !me?.isOps
      ? [
          {
            field: "studio_user_roles][users_permissions_user][id]",
            operator: "eq",
            value: me?.id,
          },
        ]
      : [],
    successNotification: false,
    errorNotification: false,
  });
  const { show, modalProps, close } = useModal();
  const { getRoleUserWithStudio } = useGetMyRole();
  const { combineRoleWithSuperAdmin } = usePermission();
  const { error, isLoading, refetch: refetchStudio } = tableQueryResult;

  // run once to init data honeycomb
  React.useEffect(() => {
    honeyDataRef.current = honeycomb.initHoneycomb({
      eventName: "studio-list",
      eventNameChild: "fetch-studio-list",
    });
    honeycomb.sendTraceEvent({
      honeyData: honeyDataRef.current,
    });
  }, []);

  React.useEffect(() => {
    if (!isLoading && error) {
      const err = error as any;
      const statusCode = err?.response?.data?.error?.status;
      if (statusCode === 403) {
        setMessageTableError(`You don't belong to any team. Please contact to your manager for more details.`);
      }
      honeycomb.sendEventCallApi({
        honeyData: honeyDataRef.current,
        status: statusCode,
        data: {
          message: err?.response?.data?.error?.message,
        },
      });
      honeycomb.sendEventParent({
        honeyData: honeyDataRef.current,
      });
    }

    // send call api to honeycomb
    if (!isLoading) {
      honeycomb.sendEventCallApi({
        honeyData: honeyDataRef.current,
        status: 200,
      });
      honeycomb.sendEventParent({
        honeyData: honeyDataRef.current,
      });
    }
  }, [error, isLoading]);

  const studiosFormatted = tableProps.dataSource?.map((studio) => {
    return {
      ...studio,
      studioInfo: {
        name: studio?.name,
        icon: studio.icon?.url,
      },
    };
  });
  const { getColumnSearchProps } = useTableProps({
    setFilters,
    filters,
  });

  const { mutateAsync: onChangeStatusStudio, isLoading: changeStudioLoading } = useUpdate();

  const handleChangeStudioStatus = async (studio?: IStudio) => {
    const isStudioEnabled = studio?.status;

    studio?.id &&
      (await onChangeStatusStudio({
        resource: "studios",
        id: studio?.id,
        values: {
          status: isStudioEnabled ? false : true,
        },
        errorNotification: {
          type: "error",
          description: "Error",
          message: `${isStudioEnabled ? "Fail to inactive Studio status" : "Fail to active Studio status"}`,
        },
        successNotification: {
          type: "success",
          description: "Success",
          message: `${isStudioEnabled ? "Studio is successfully inactive" : "Studio is successfully active"}`,
        },
      }));

    honeycomb.sendEventCallApi({
      honeyData: honeyDataRef.current,
      status: 200,
      eventNameChild: "change-studio-status",
      spanId: uuidv4(),
      params: {
        studioId: studio?.id,
        status: isStudioEnabled ? false : true,
      },
    });
    honeycomb.sendEventParent({
      honeyData: honeyDataRef.current,
    });

    refetchStudio();
    close();
  };
  const showModalWarning = (studio: IStudio) => async () => {
    const isStudioEnabled = studio.status;
    setSelectedStudio(studio);
    if (!isStudioEnabled) {
      await handleChangeStudioStatus(studio);
    } else {
      show();
    }
  };
  const isDisable = (studioName: string) => {
    return getRoleUserWithStudio(studioName) === EnumRoleStudio.Admin || combineRoleWithSuperAdmin();
  };

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: "Image",
        dataIndex: "studioInfo",
        width: "5%",
        className: "text-center text-[14px]",
        render: (value: { icon: string }) => (
          <div className="flex items-center justify-center space-x-2">
            <ProductIconAndPlatform imageUrl={value.icon} />
          </div>
        ),
      },
      {
        title: "Studio name",
        dataIndex: "name",
        key: "name",
        width: "30%",
        className: "text-[14px]",
        ...getColumnSearchProps({
          isCustomized: true,
          logicalFilters: [
            {
              field: "name",
              operator: "containss",
            },
          ],
        }),
        render: (name: string, record: { id: string }) => (
          <div className="flex items-center space-x-2">
            <Link to={`/studios/show/${record.id}`} className="font-semibold text-color-golden-purple">
              {name}
            </Link>
          </div>
        ),
      },
      {
        title: "Website",
        dataIndex: "website",
        key: "id",
        width: "30%",
        className: "text-[14px]",
        render: (value?: string) => {
          const isHttps = Boolean(value && value.startsWith("https://"));
          const isHttp = Boolean(value && value.startsWith("http://"));
          const href = (!isHttps && !isHttp ? `//` : "") + value;
          return (
            <div>
              {value ? (
                <a
                  target="_blank"
                  href={href}
                  className="font-semibold underline text-color-primary"
                  rel="noreferrer"
                  referrerPolicy="unsafe-url"
                >
                  {value}
                </a>
              ) : (
                ""
              )}
            </div>
          );
        },
      },
      {
        title: "Studio status",
        dataIndex: "status",
        width: "20%",
        className: "text-[14px]",
        filters: [
          {
            text: "ACTIVE",
            value: true,
          },
          {
            text: "INACTIVE",
            value: false,
          },
        ],
        // filterSearch: true,
        sorter: (a: { status: string }, b: { status: string }) => a?.status?.length - b?.status?.length,
        filterIcon: (filtered: boolean) => <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />,
        render: (value, record) => {
          return (
            <Switch
              disabled={!isDisable(record.name)}
              checkedChildren="ACTIVE"
              unCheckedChildren="INACTIVE"
              defaultChecked={Boolean(value)}
              checked={value ? true : false}
              onClick={showModalWarning(record)}
            />
          );
        },
      },
      {
        title: "Action",
        width: "15%",
        className: "text-[14px]",
        render: (_, record, __) => {
          console.log({ a: isDisable(record.name) });

          return (
            <div
              css={css`
                svg {
                  margin-right: 0 !important;
                  margin-left: 8px;
                }
                button {
                  display: flex;
                  align-items: center;
                  flex-direction: row-reverse;
                }
              `}
            >
              <CanAccessEditButton
                resource="studios"
                action="edit"
                status={isDisable(record.name) && record.status}
                id={record.id.toString()}
              />
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getColumnSearchProps],
  );

  return (
    <div>
      <AlertModal
        modalProps={modalProps}
        handleClose={close}
        message={`If you disable studio ${selectedStudio?.name}, all its product & application & integration will be disable. Do you want to disable this studio on PMP?`}
        handleOk={() => handleChangeStudioStatus(selectedStudio)}
        isLoading={changeStudioLoading}
      />
      <div className="flex justify-end w-full mb-4">
        <Button>
          <a
            href="https://docs.google.com/presentation/d/1wELOrc731cckjw8cvqjqaurSX4VsJoCksGjiqX_xF6c/edit#slide=id.g1434d8e2421_0_864"
            target="_blank"
            rel="noreferrer"
          >
            View guidelines
          </a>
        </Button>
      </div>
      <List
        title="Studio overview"
        pageHeaderProps={{
          extra: me?.isOps && (
            <CreateButton type="primary" icon={false}>
              + Add new
            </CreateButton>
          ),
        }}
      >
        <Table
          {...tableProps}
          rowKey="id"
          loading={isLoading}
          columns={columns}
          dataSource={studiosFormatted}
          locale={{ emptyText: messageTableError }}
          pagination={{
            ...tableProps.pagination,
            pageSizeOptions: [10, 20, 50],
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </List>
    </div>
  );
};
