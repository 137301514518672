import React from "react";
import { Link } from "react-router-dom";
import { CreateButton, List, Table, Switch, Button, useModal } from "@pankod/refine-antd";
import { useUpdate } from "@pankod/refine-core";

// ant icons
import { FilterFilled } from "@ant-design/icons";

// components
import { ProductIconAndPlatform } from "components/ProductIcon";
import { TagStatus } from "components/Tag";

// hooks
import { useGetHighestRole } from "hooks/useGetHigherRole";

// contexts
import { useMe } from "context/MeContext";

// interface
import { IProduct, IStudio } from "interfaces";

// utils
import { sortBy } from "utils/sortBy";

// schema
import { Enum_App_Status, Enum_Product_Status } from "schema/schema";
import { EnumRoleProduct } from "enums";
import { getStatusProduct } from "utils/getStatusProduct";
import { usePermission } from "hooks/usePermission";
import useTableProps from "hooks/useTableProps";
import AlertModal from "components/Modal/AlertModal";
import { useGetMyRole } from "hooks/useGetMyRole";
import { useProductTable } from "hooks/useProduct/useProductTable";
import useLocalStorage from "hooks/useLocalStorage";
export interface IDataProduct {
  studioFiltered: string[];
  productsFiltered: IProduct[];
}

export const ProductList: React.FC = () => {
  // hooks
  const [selectedStudioId] = useLocalStorage<string>("studioId");

  const { tableProps, tableQueryResult, filters, setFilters } = useProductTable(selectedStudioId);
  const { mutateAsync: updateProduct, isLoading: isLoadingDisabled } = useUpdate();
  const { data: me, isAdmin } = useMe();
  const { combineRoleWithSuperAdmin } = usePermission();
  const { listMyRoleProduct } = useGetMyRole();
  const { highestRole } = useGetHighestRole();
  const { show, modalProps, close } = useModal();
  const [selectedProduct, setSelectedProduct] = React.useState<IProduct>();

  const isDisabledCreate = highestRole === "member" && !me?.isOps;
  const { isLoading, refetch: refetchProduct } = tableQueryResult;
  // state
  const dataProduct = React.useMemo(
    () =>
      tableProps.dataSource?.reduce(
        (product: IDataProduct, productItem) => {
          productItem.studios?.forEach((studio) => product.studioFiltered.push(studio.name));
          const statusProduct = getStatusProduct(productItem.apps);
          product.productsFiltered.push({
            ...productItem,
            id: productItem.id,
            statusProduct: statusProduct,
            studios: productItem.studios?.sort((a, b) => sortBy(a.name, b.name)),
          });
          return product;
        },
        {
          studioFiltered: [],
          productsFiltered: [],
        },
      ),
    [tableProps.dataSource],
  );
  const showModalWarning = (product: IProduct) => async () => {
    const isProductEnabled = product.status === Enum_Product_Status.Enabled;
    setSelectedProduct(product);
    if (!isProductEnabled) {
      await handleChangePMPStatus(product);
    } else {
      show();
    }
  };

  const handleChangePMPStatus = async (product?: IProduct) => {
    const isProductEnabled = product?.status === Enum_Product_Status.Enabled;
    product?.id &&
      (await updateProduct({
        resource: "products",
        id: product?.id,
        values: {
          status: isProductEnabled ? Enum_Product_Status.Disabled : Enum_Product_Status.Enabled,
        },
        errorNotification: {
          type: "error",
          description: "Error",
          message: `${isProductEnabled ? "Fail to disable PMP status" : "Fail to enable PMP status"}`,
        },
        successNotification: {
          type: "success",
          description: "Success",
          message: `${isProductEnabled ? "PMP status is successfully disabled" : "PMP status is successfully enabled"}`,
        },
      }));
    refetchProduct();
    close();
  };

  const { getColumnSearchProps } = useTableProps({
    filters,
    setFilters,
  });
  const columns: any = React.useMemo(() => {
    if (!dataProduct) return [];
    return [
      {
        title: "Image",
        dataIndex: "icon",
        width: "5%",
        className: "text-center text-[14px]",
        render: (value: { url: string }) => {
          return (
            <div className="flex items-center justify-center space-x-2">
              <ProductIconAndPlatform imageUrl={value?.url} />
            </div>
          );
        },
      },
      {
        title: "Product name",
        dataIndex: "name",
        key: "name",
        width: "20%",
        className: "text-[14px]",
        ...getColumnSearchProps({
          isCustomized: true,
          logicalFilters: [
            {
              field: "name",
              operator: "containss",
            },
          ],
        }),
        render: (_: string, record: { id: string; name: string }) => (
          <div className="flex items-center space-x-2">
            <Link to={`/products/show/${record.id}`} className="font-semibold text-color-golden-purple">
              {record.name}
            </Link>
          </div>
        ),
      },
      {
        title: "Product code",
        dataIndex: "product_code",
        key: "product_code",
        width: "20%",
        className: "text-[14px]",
        ...getColumnSearchProps({
          isCustomized: true,
          logicalFilters: [
            {
              field: "product_code",
              operator: "containss",
            },
          ],
        }),
        render: (value: string) => value.toUpperCase(),
      },
      {
        title: "Studio",
        dataIndex: "studios",
        key: "studios][name]",
        width: "25%",
        className: "text-[14px]",
        filters: [...new Set(dataProduct?.studioFiltered)].map((ele) => ({
          text: ele,
          value: ele,
        })),
        // filterSearch: true,
        filterIcon: (filtered: boolean) => <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />,
        render: (value: IStudio[]) => (
          <>
            {value?.map((ele: IStudio) => {
              return (
                <div key={ele.id} className="mt-1 inline-block">
                  <TagStatus status={ele.name} />
                </div>
              );
            })}
          </>
        ),
      },
      {
        title: "Product status",
        dataIndex: "product_status",
        key: "product_status",
        width: "15%",
        className: "text-[14px]",
        filters: [
          {
            value: Enum_App_Status.Active,
            text: Enum_App_Status.Active,
          },
          {
            value: Enum_App_Status.Development,
            text: Enum_App_Status.Development,
          },
          {
            value: Enum_App_Status.Inactive,
            text: Enum_App_Status.Inactive,
          },
        ],

        onFilter: (value: string, record: any) => {
          return record.statusProduct.includes(value);
        },
        sorter: true,
        filterIcon: (filtered: boolean) => <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />,
        render: (value: any) => {
          return <TagStatus status={value} />;
        },
      },
      {
        title: "PMP status",
        width: "15%",
        key: "status",
        className: "text-[14px]",
        filters: [
          {
            text: "ON",
            value: Enum_Product_Status.Enabled,
          },
          {
            text: "OFF",
            value: Enum_Product_Status.Disabled,
          },
        ],
        sorter: (a: { status: string }, b: { status: string }) => a?.status.localeCompare(b?.status),
        filterIcon: (filtered: boolean) => <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />,
        render: (value: IProduct, record: any) => {
          const listStudioOfProduct: IStudio[] = record.studios;
          const isActiveStudio = listStudioOfProduct?.some((studio) => studio.status);
          return (
            <Switch
              disabled={
                !isActiveStudio ||
                (!combineRoleWithSuperAdmin(false) &&
                  !((listMyRoleProduct && listMyRoleProduct[value.id] === EnumRoleProduct.Owner) || isAdmin))
              }
              checkedChildren="ON"
              unCheckedChildren="OFF"
              checked={value.status === Enum_Product_Status.Enabled ? true : false}
              onClick={showModalWarning(value)}
            />
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProduct]);

  return (
    <div>
      <AlertModal
        modalProps={modalProps}
        handleClose={close}
        message={`If you disable product ${selectedProduct?.name}, all its application & integration will be disable. Do you want to disable this product on PMP?`}
        handleOk={() => handleChangePMPStatus(selectedProduct)}
        isLoading={isLoadingDisabled}
      />
      <div className="flex justify-end w-full mb-4">
        <Button>
          <a
            href="https://docs.google.com/presentation/d/1wELOrc731cckjw8cvqjqaurSX4VsJoCksGjiqX_xF6c/edit#slide=id.g1434d8e2421_0_864"
            target="_blank"
            rel="noreferrer"
          >
            View guidelines
          </a>
        </Button>
      </div>
      <List
        title="Product overview"
        pageHeaderProps={{
          extra: (
            <CreateButton type="primary" icon={false} disabled={isDisabledCreate}>
              + Add new
            </CreateButton>
          ),
        }}
      >
        <Table
          {...tableProps}
          rowKey="id"
          columns={columns}
          loading={isLoading || tableQueryResult.isFetching}
          dataSource={dataProduct?.productsFiltered}
          rowClassName={(record) => {
            return record.disabled ? "disabled" : "";
          }}
          pagination={{
            ...tableProps.pagination,
            pageSizeOptions: [10, 20, 50],
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </List>
    </div>
  );
};
