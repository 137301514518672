const green = {
  50: "#EBFBEE",
  100: "#D3F9D8",
  200: "#B2F2BB",
  300: "#8CE99A",
  400: "#69DB7C",
  500: "#51CF66",
  600: "#40C057",
  700: "#37B24D",
  800: "#2F9E44",
  900: "#2B8A3E",
};

export default green;
