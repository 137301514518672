import config from "config";
import isArray from "lodash/isArray";
import { FileFragment } from "schema/schema";

export type ImageSize = "original" | "thumbnail" | "small" | "medium" | "large";

export const getImageInfo = (
  image: FileFragment,
  size: ImageSize = "small",
): { url: string | undefined; alt: string | undefined } => {
  const result = {
    url: `${config.BACKEND_URL}${image.attributes?.url}`,
    alt: image.attributes?.alternativeText || image.attributes?.name,
  };
  if (size !== "original" && image.attributes?.formats && image.attributes?.formats[size]) {
    result.url = `${image.attributes?.formats[size]?.url}`;
  }
  return result;
};

export const getImageInfoFromFile = (
  files: FileFragment | FileFragment[] | undefined | null,
  size: ImageSize = "small",
): { url: string | undefined; alt: string | undefined } | undefined => {
  let image: FileFragment | undefined | null;
  if (!files) return undefined;
  if (isArray(files)) {
    image = (files as FileFragment[])[0];
  } else {
    image = files as FileFragment;
  }
  if (!image) return undefined;
  return getImageInfo(image, size);
};
