import React from "react";
import { Button, Icons, Tooltip, Collapse, Input, Tag } from "@pankod/refine-antd";
import { useParams } from "react-router-dom";
import { useCustom, useApiUrl } from "@pankod/refine-core";

// assets
import { ReactComponent as IconOpen } from "assets/img/icon-open.svg";

// components
import { EditIntegration } from "../EditIntegration";
import { Chart } from "components/Chart";

// sections
import Prepare from "./RunTest/Prepare";
import Install from "./RunTest/Install";
import Status from "./RunTest/Status";

// context
import { useIntegration } from "context/IntegrationContext";
import { CopyToClipboardTooltip } from "components/Input/CopyToClipboard";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

// configs
import { Enum_Integration_Service } from "schema/schema";

import { getPlatform } from "utils/getPlatform";

const { ArrowLeftOutlined } = Icons;
const { Panel } = Collapse;

const actionOptions = ["install_click", "install_complete"];

function AppsFlyerSetting() {
  const { appId } = useParams();
  const { productInfo, isLoadingProduct } = useIntegration();
  // state
  const [deviceId, setDeviceId] = React.useState("");
  const [actionType, setActionType] = React.useState("");
  const [visible, setVisible] = React.useState<boolean>(false);
  // hooks
  const apiUrl = useApiUrl();
  let socket = React.useRef<WebSocket | null>(null);
  const appPayload = productInfo?.apps?.find((ele) => ele.id === appId);
  const appflyerPayload = productInfo?.integrationPayloads?.data.find(
    (v) => v.attributes?.integration?.data?.attributes?.service === Enum_Integration_Service.Appsflyer,
  )?.attributes?.payload;
  const devKey = appPayload?.attributes ? appflyerPayload?.apps[appPayload?.attributes?.product_code].dev_key : "";

  // parser connect url to go to detail appflyer
  let idConnectUrl = appPayload?.attributes?.bundle_id;
  if (appPayload?.attributes?.platform === "IOS") {
    const [, id] =
      appPayload?.attributes?.connect_url?.match(/appstoreconnect.apple.com\/apps\/(\d*)\/appstore\/info/) || [];
    idConnectUrl = `id${id}`;
  }
  const listProductCodeAppConnected = productInfo.integrationPayloads?.data.find(
    (v) => v.attributes?.integration?.data?.attributes?.service === Enum_Integration_Service.Appsflyer,
  )?.attributes?.payload;
  const isConnected: Boolean = listProductCodeAppConnected?.apps[appPayload?.attributes?.product_code || ""];

  // fetch data qr code
  const { data: installationLinkData, refetch: refetchQrCode } = useCustom({
    url: `${apiUrl}/products/${productInfo.id}/integrations:appsflyer/make-installation-links`,
    method: "post",
    queryOptions: {
      enabled: false,
      retry: 0,
    },
    config: {
      payload: {
        data: {
          app_id: Number(appPayload?.id),
          idfa: deviceId,
        },
      },
    },
  });

  React.useEffect(() => {
    !!deviceId && refetchQrCode();
  }, [refetchQrCode, deviceId]);

  React.useEffect(() => {
    if (!installationLinkData) return;
    const { data } = installationLinkData.data;
    socket.current = new WebSocket(data.attributes.socketUrl);
    socket.current.onopen = (event): void => {
      console.log("onopen: ", event);
    };
    socket.current.onmessage = (event: { data: string }) => {
      const dataParsed = JSON.parse(event.data);
      setActionType(dataParsed.action);
    };
  }, [installationLinkData]);

  // handle actions
  const handleChangeDevice = React.useCallback((value: string) => {
    setDeviceId(value);
  }, []);

  const handleRestartTest = React.useCallback(() => {
    setActionType("");
    setDeviceId("");
    socket.current && socket.current.close();
  }, []);

  const handleOpenTabChart = () => {
    window.open(`/products/show/${productInfo.id}/integrations/edit/appsflyer/setting/${appId}/chart`, "_blank");
  };

  return (
    <EditIntegration
      actionButtons={() => {}}
      isLoading={Boolean(isLoadingProduct)}
      hiddenDelete={true}
      title="Appsflyer Integration details"
    >
      {/* <div>
        <div className="text-[#000] font-bold text-[14px]">Select app</div>
        <Dropdown overlay={menu}>
          <Button>
            <Space>
              Button
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </div> */}
      <Collapse defaultActiveKey={["1", "2", "3"]}>
        <Panel
          className="text-[14px]"
          header={<span className="text-[#2D2D2D] font-normal">Appsflyer app info</span>}
          key="1"
        >
          <div className="text-[16px] font-bold text-[#000]">
            <span className="mr-10">{appPayload?.attributes?.name}</span>
            {appPayload && (
              <Tag color={isConnected ? "green" : "red"}>{isConnected ? "Connected" : "Disconnected"}</Tag>
            )}
          </div>
          <div className="font-normal mt-[8px]">Dev key</div>
          <div className="flex items-center w-1/2 mt-[8px]">
            <Input
              type={`${visible ? "text" : "password"}`}
              value={devKey}
              suffix={<CopyToClipboardTooltip disabled={!visible} text={devKey || ""} />}
              disabled
            />
            <div
              className="text-[14px] text-white cursor-pointer ml-4 p-2 bg-[#BFBFBF] rounded-[1px]"
              onClick={() => setVisible(!visible)}
            >
              {visible && <EyeInvisibleOutlined />}
              {!visible && <EyeOutlined />}
            </div>
          </div>
          <div className="flex mt-[12px]">
            <div className="font-normal mr-[24px]">Platform: </div>
            <div>{getPlatform(appPayload?.attributes?.platform.toUpperCase())}</div>
          </div>
          <div className="flex mt-[12px]">
            <div className="font-normal mr-[24px]">Integration details:</div>
            <a
              type="link"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://hq.appsflyer.com/unified-ltv/dashboard/${idConnectUrl}`}
              className="underline"
            >
              View project on Appsflyer
            </a>
          </div>
        </Panel>
        <Panel
          className="text-[14px] w-full"
          header={
            <div className="w-full flex justify-between text-[#2D2D2D] font-normal">
              <div className="w-1/2">Appsflyer insight</div>
              <div className="w-1/2 flex justify-end">
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenTabChart();
                  }}
                >
                  <Tooltip title="search">
                    <IconOpen />
                  </Tooltip>
                </div>
              </div>
            </div>
          }
          key="2"
        >
          <Chart />
        </Panel>
        <Panel
          className="text-[14px]"
          header={<span className="text-[#2D2D2D] font-normal">Initial setup test</span>}
          key="3"
        >
          <div className="w-full max-w-3xl mx-auto">
            {actionType !== "" && (
              <Tooltip title="Restart the test" className="mb-10">
                <Button shape="circle" icon={<ArrowLeftOutlined />} onClick={handleRestartTest} />
              </Tooltip>
            )}

            {actionType === "" && (
              <Prepare
                deviceId={deviceId}
                platform={appPayload?.attributes?.platform}
                installationLinkData={installationLinkData?.data.data.attributes}
                handleChangeDevice={handleChangeDevice}
              />
            )}

            {actionType === actionOptions[0] && <Install handleRestartTest={handleRestartTest} />}

            {actionType === actionOptions[1] && <Status />}
          </div>
        </Panel>
      </Collapse>
    </EditIntegration>
  );
}

export default AppsFlyerSetting;
