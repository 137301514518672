import React, { useEffect } from "react";
import { Select } from "antd";
import { useList } from "@pankod/refine-core";

// config
import config from "config";

// interface
import { IProductSelect } from "interfaces";

type IProps = {
  productSelected: IProductSelect | null;
  appSelected: IProductSelect | null;
  handleSelectApp: (appItem: any) => void;
  queryAppId: string | null;
};

function AppSelectField({ productSelected, appSelected, queryAppId, handleSelectApp }: IProps) {
  const [page, setPage] = React.useState(1);

  const { data: dataApps, isFetching: isFetchingApp } = useList<any>({
    resource: "apps",
    config: {
      filters: [
        {
          field: "product][id]",
          operator: "in",
          value: productSelected?.id,
        },
      ],
      pagination: { current: page, pageSize: config.INITIAL_PAGE_SIZE * 5 },
    },
    queryOptions: {
      retry: 0,
      enabled: Boolean(productSelected?.id),
    },
  });

  const optionsApps = React.useMemo(() => {
    return (
      dataApps?.data.map((app) => ({
        label: app.product_code,
        value: app.id,
      })) || []
    );
  }, [dataApps?.data]);

  // init app when has queryString on url.
  useEffect(() => {
    if (appSelected?.id || !queryAppId) return;
    const appItem = dataApps?.data.find((item) => item.id === Number(queryAppId)) || null;
    if (appItem) {
      handleSelectApp(appItem);
      return;
    }
    if (dataApps?.data && dataApps?.total > page * config.INITIAL_PAGE_SIZE) {
      setPage((prevState) => prevState + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataApps, page, queryAppId]);

  return (
    <Select
      value={appSelected?.label}
      className="w-[200px]"
      dropdownClassName="custom-select"
      placeholder="App id"
      allowClear
      loading={isFetchingApp}
      disabled={!Boolean(productSelected?.id)}
      options={optionsApps}
      onChange={(value) => {
        const appItem = dataApps?.data.find((item) => item.id === value);
        handleSelectApp(appItem);
      }}
      onPopupScroll={(event) => {
        const ele = event.currentTarget;
        const isBottom = ele.scrollHeight - ele.scrollTop === ele.clientHeight;
        if (isBottom && dataApps && dataApps?.total > page * config.INITIAL_PAGE_SIZE) {
          setPage((curr) => curr + 1);
        }
      }}
    />
  );
}

export default AppSelectField;
