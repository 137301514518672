import dayjs from "dayjs";

// interfaces
import { ITransformData, IConvertData } from "interfaces/chart";

// utils
import { sortBy } from "utils/sortBy";
import { getDateArray } from "utils/getDateArray";

// helpers
import { mappingDataTouchPoints } from "../chartHelper/commonHelper";
import {
  mappingOtherMetricByColumn,
  mappingOtherSeriesWithColumn,
  getComparesCalculationByColumnChart,
} from "../chartHelper/columnHelper";

const transformColumnData = ({ data, dimension, metric, days, type }: ITransformData): IConvertData => {
  const columData = data ? data.data : [];
  const groupBy = data["grouped-by"];
  if (columData.length === 0 || !groupBy) {
    return {
      categories: [],
      series: [],
    };
  }
  // get date array
  const subtractADay = dayjs(days[0]).subtract(1, "day").format("YYYY-MM-DD");
  let categories = getDateArray(new Date(subtractADay), new Date(days[1]), type).sort((a: string, b: string) =>
    sortBy(a, b),
  );
  let series = [];

  // metric is touchpoints
  if (metric === "touchpoints") {
    const temp = mappingDataTouchPoints(
      [...columData].sort((a, b) => sortBy(a.date, b.date)),
      categories,
    );
    series = temp.series;
  } else {
    // other metric
    const temp = mappingOtherMetricByColumn(columData, categories, dimension, metric);
    series = temp.series;
  }

  // push other series into series if data has Others.
  const otherSeries = mappingOtherSeriesWithColumn(data, categories, metric);
  if (otherSeries) {
    series.push(otherSeries);
  }

  // check if series is empty
  const isEmpty = series.every((item: any) => item?.data?.every((ele: number) => ele === 0));

  // get calculation % dfference between 2 dates
  let comparesCalculation = null;
  if (series.length > 0) {
    comparesCalculation = getComparesCalculationByColumnChart(series, categories);
  }

  // remove first day
  const categoriesSliced = categories.slice(1);
  const seriesSliced = series.map((item: any) => {
    return {
      ...item,
      data: item.data.slice(1),
    };
  });

  return {
    categories: categoriesSliced.map((key) => dayjs(key).format("MMM D")),
    series: isEmpty ? [] : seriesSliced,
    comparesCalculation,
  };
};

export default transformColumnData;
