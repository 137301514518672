import React from "react";
import { Icons, Table, Switch, Modal } from "@pankod/refine-antd";
import { BaseRecord, CreateResponse } from "@pankod/refine-core";

// components
import { EditIntegration } from "../EditIntegration";

// context
import { useNavigation } from "@pankod/refine-core";

// schema
import { EnumRoleProduct } from "enums";
import { Enum_App_Platform, Enum_App_Status, Enum_Integration_Service } from "schema/schema";
import { PLATFORM, StatusAppIntegration } from "config";
import useTableProps from "hooks/useTableProps";
import { usePermission } from "hooks/usePermission";
import { useGetMyRole } from "hooks/useGetMyRole";
import { useAppKey } from "hooks/useIntegration/useAppKey";
import { useProduct } from "context/ProductContext";
import { checkExitsIntegration } from "utils/getIntegrationConnect";
import { convertIOSStoreIdAppsflyer } from "utils/convertIdAppsflyer";
import { useMe } from "context/MeContext";
const { FilterFilled } = Icons;
const { confirm, destroyAll } = Modal;

interface DataType {
  app_id: string;
  app_name: string;
  product_code: string;
  bundle_id: string;
  connect_url: string;
  market: string;
  platform: string;
  status: string;
  idConnectUrl: string;
  connected: boolean;
}
export const AppsFlyerEdit: React.FC = () => {
  // states
  const [itemSelected, setItemSelected] = React.useState<DataType>();
  //hooks
  const { push } = useNavigation();
  const { productInfo, isLoading: isLoadingProduct, isStale, refetchProduct } = useProduct();
  const {
    mutateDeleteAppKey,
    isLoadingDelete,
    mutateCreateAppKey,
    isLoadingCreate,
    tableQueryResult,
    filters,
    setFilters,
  } = useAppKey();
  const { combineRoleWithSuperAdmin } = usePermission();
  const { userRoleProductPermission } = useGetMyRole();
  const { isMember } = useMe();
  const apps: any = tableQueryResult.data?.data;
  const listProductCodeAppConnected = productInfo?.integrationPayloads?.data.find(
    (v) => v.attributes?.integration?.data?.attributes?.service === Enum_Integration_Service.Appsflyer,
  )?.attributes?.payload;

  let dataTable: DataType[] = apps?.map((value: any) => {
    const iosStoreId = convertIOSStoreIdAppsflyer(value.connect_url);
    const connected = listProductCodeAppConnected?.apps?.[value.product_code]
      ? !Boolean(listProductCodeAppConnected?.apps?.[value.product_code][StatusAppIntegration])
      : false;
    return {
      app_id: value.id,
      app_name: value.name,
      bundle_id: value.bundle_id,
      connect_url: value.connect_url,
      market: value.market,
      platform: value.platform === "IOS" ? PLATFORM.iOS : PLATFORM.Android,
      product_code: value.product_code,
      status: value.status,
      connected: listProductCodeAppConnected?.apps?.[value.product_code]
        ? !Boolean(listProductCodeAppConnected?.apps?.[value.product_code][StatusAppIntegration])
        : false,
      idConnectUrl: value.platform === "IOS" ? `id${iosStoreId}` : value.bundle_id,
      icon: value.icon,
      hasLink: connected,
    };
  });
  //useEffect
  React.useEffect(() => {
    if (!isStale && !checkExitsIntegration(productInfo, Enum_Integration_Service.Appsflyer) && productInfo?.id) {
      push(`/products/show/${productInfo?.id}#integration`);
      return;
    }
  }, [isStale, productInfo, push]);
  //function
  const { columnsPropsAppName, columnsPropsAppCode, columnsPropsMarket, columnsPropsAppIcon } = useTableProps({
    filters,
    setFilters,
  });

  const handleConnectAppsflyer = (name: string, isConnect: boolean, appId: string, record: DataType) => () => {
    let promise: Promise<CreateResponse<BaseRecord>>;
    if (!isConnect && record.status !== Enum_App_Status.Active) {
      confirm({
        title: `You have to active application before connect application integration`,
        closable: true,
        centered: true,
        className: "no-footer",
      });
      return;
    }
    confirm({
      title: `Are you sure you want to switch ${isConnect ? "Off" : "On"} ?`,
      okText: "Yes",
      cancelText: "No",
      async onOk() {
        destroyAll();
        setItemSelected(record);
        if (isConnect) {
          // disconnect
          promise = mutateDeleteAppKey({
            resource: `products/${productInfo?.id}/integrations:appsflyer/disconnect?app_id=${Number(appId)}`,
            id: "",
            successNotification: {
              type: "success",
              description: "Success",
              message: `Product have successfully disconnect to ${name}`,
            },
            errorNotification: {
              type: "error",
              description: "Error",
              message: `Fail to disconnect from  ${name}`,
            },
          });
        } else {
          // connect
          promise = mutateCreateAppKey({
            resource: `products/${productInfo?.id}/integrations:appsflyer/app`,
            values: {
              app_id: Number(appId),
            },
            successNotification: {
              type: "success",
              description: "Success",
              message: `Product have succesfully connected to ${name}`,
            },
            errorNotification: {
              type: "error",
              description: "Error",
              message: `Fail to connect to ${name}`,
            },
          });
        }
        Promise.resolve(promise).then(
          () => {
            refetchProduct();
            setItemSelected(undefined);
          },
          () => {
            setItemSelected(undefined);
          },
        );
      },
      onCancel() {
        setItemSelected(undefined);
      },
    });
  };

  const columns: any = React.useMemo(() => {
    return [
      {
        ...columnsPropsAppIcon,
      },
      {
        title: "App name",
        key: "name",
        width: "20%",
        className: "text-[rgba(0, 0, 0, 0.85)]",
        ...columnsPropsAppName,
      },
      {
        ...columnsPropsAppCode,
      },
      {
        title: "Market",
        dataIndex: "market",
        key: "market",
        width: "10%",
        className: "text-[rgba(0, 0, 0, 0.85)]",
        ...columnsPropsMarket,
      },
      {
        title: "Platform",
        dataIndex: "platform",
        key: "platform",
        width: "25%",
        className: "text-[rgba(0, 0, 0, 0.85)]",
        filterIcon: (filtered: boolean) => <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />,
        filters: [
          {
            text: "iOS",
            value: "ios",
          },
          {
            text: "Android",
            value: "android",
          },
        ],
        onFilter: (value: string, record: { platform: string[] }) => record.platform.includes(value),
        sorter: (a: { platform: string }, b: { platform: string }) => a?.platform.localeCompare(b?.platform),
        render: (platform: string) => (
          <div className="font-normal transform-initial">
            {platform.toUpperCase() === Enum_App_Platform.Android && "Android"}
            {platform.toUpperCase() === Enum_App_Platform.Ios && "iOS"}
          </div>
        ),
      },
      {
        title: "View on Integration",
        width: "12%",
        render: (_: any, record: DataType) => {
          return (
            <>
              {record.connected ? (
                <a
                  className="text-blue-500 font-normal w-full inline-block"
                  href={`https://hq.appsflyer.com/unified-ltv/dashboard/${record.idConnectUrl}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  View details
                </a>
              ) : (
                <div className="text-gray-400">View details</div>
              )}
            </>
          );
        },
      },
      {
        title: "Connected",
        dataIndex: "id",
        key: "id",
        width: "15%",
        className: "text-[rgba(0, 0, 0, 0.85)]",
        render: (_: string, record: DataType) => {
          return (
            <Switch
              checked={record.connected}
              disabled={
                productInfo?.isDisabled ||
                (!combineRoleWithSuperAdmin(userRoleProductPermission !== EnumRoleProduct.Viewer) && isMember) ||
                (itemSelected && itemSelected.bundle_id !== record.bundle_id)
              }
              loading={
                itemSelected && itemSelected.bundle_id === record.bundle_id && (isLoadingCreate || isLoadingDelete)
              }
              className="flex-shrink-0"
              checkedChildren="On"
              unCheckedChildren="Off"
              onClick={handleConnectAppsflyer(record.app_name, record.connected, record.app_id, record)}
            />
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTable]);
  return (
    <EditIntegration
      title="Appsflyer Integration"
      isLoading={isLoadingProduct || tableQueryResult.isFetching || isStale}
      hiddenDelete={true}
      actionButtons={<></>}
    >
      <Table
        rowKey="bundle_id"
        columns={columns}
        dataSource={dataTable}
        pagination={{
          pageSizeOptions: [10, 20, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
      />
    </EditIntegration>
  );
};
