import { css } from "@emotion/react";
import { Checkbox, Col, Form, FormInstance, Row } from "@pankod/refine-antd";
import { DatePicker } from "antd";
import React from "react";

export const PerformanceMonitoring: React.FC<{
  disableDatePickerProps: boolean;
  form: FormInstance<any>;
}> = ({ disableDatePickerProps, form }) => {
  const [disableDatePicker, setDisableDatePicker] = React.useState<boolean>(disableDatePickerProps);
  const dateFormat = "YYYY/MM/DD";
  const pm_enabled = Form.useWatch("pm_enabled");
  React.useEffect(() => {
    form.validateFields(["pm_start_date"]);
  }, [form, pm_enabled]);
  return (
    <>
      <Row className="flex">
        <Col span={10} className="mb-4">
          <Form.Item
            className="w-full"
            css={css`
              .ant-form-item-control {
                max-width: 100% !important;
              }
            `}
            name="pm_enabled"
            valuePropName="checked"
          >
            <Checkbox onChange={(e) => setDisableDatePicker(!e.target.checked)}>
              <span className="font-semibold text-[14px]">Enable Performance Monitoring</span>
              <p className=" m-0 text-[13px]">Data process for analytics, dashboards for metric tracking, etc.</p>
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={14}>
          <p className="m-0 text-[14px] font-semibold">Start running data from</p>

          <Form.Item
            name="pm_start_date"
            rules={[
              {
                required: Boolean(pm_enabled),
                message: "Start running data is required!",
              },
            ]}
          >
            <DatePicker disabled={disableDatePicker} allowClear={true} format={dateFormat} className="w-[400px]" />
          </Form.Item>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col span={20}>
          <Form.Item
            className="w-full"
            css={css`
              .ant-form-item-control {
                max-width: 100% !important;
              }
            `}
            name="pm_subscription_enabled"
            valuePropName="checked"
          >
            <Checkbox>
              <span className="font-semibold text-[14px]">Enable Subcription in PM</span>
              <p className=" m-0 text-[13px]">
                Whether to include data fields relating to subscription in Performance Monitoring datasources and
                dashboards. *MUST DO THIS BEFORE:
                *https://amanotesjsc.atlassian.net/wiki/spaces/BI/pages/783777982/Subscription+Setup+Require
              </p>
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <div className="mb-4">
        <Form.Item
          className="w-full"
          css={css`
            .ant-form-item-control {
              max-width: 100% !important;
            }
          `}
          name="pm_has_eazegames_rev"
          valuePropName="checked"
        >
          <Checkbox>
            <span className="font-semibold text-[14px]">Use EazeGames for revenue</span>
          </Checkbox>
        </Form.Item>
      </div>
    </>
  );
};
