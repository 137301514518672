import { InfoCircleOutlined } from "@ant-design/icons";
import { Form, FormInstance, Input, Select, Tooltip } from "@pankod/refine-antd";

import React from "react";

interface IProps {
  listDataset: String[];
  form: FormInstance<any>;
}

export const DaremiAppInfo: React.FC<IProps> = ({ listDataset, form }: IProps) => {
  const renderLabel = (label: string) => {
    return <span className="text-[14px] font-normal">{label}</span>;
  };
  const pm_enabled = Form.useWatch("pm_enabled");
  React.useEffect(() => {
    form.validateFields(["bigquery_datasets"]);
  }, [form, pm_enabled]);
  return (
    <div className="flex justify-center flex-col lg:w-[40%] lg:min-w-[750px] pr-[2rem] mx-auto">
      <Form.Item label={renderLabel("Internal appID with platform")} name="app_id" required>
        <Input placeholder="AppID" disabled />
      </Form.Item>
      <Form.Item label={renderLabel("BundleID / AdamID")} name="bundle_id" required>
        <Input disabled placeholder="BundleID / AdamID" />
      </Form.Item>
      <Form.Item
        label={renderLabel("BigQuery datasets")}
        name="bigquery_datasets"
        rules={[{ required: Boolean(pm_enabled), message: "Bigquery datasets is required!" }]}
      >
        <Select placeholder="BigQuery datasets" mode="multiple">
          {listDataset.map((dataSet) => (
            <Select.Option key={`${dataSet}`} value={dataSet}>
              {dataSet}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={
          <>
            {renderLabel("AppsFlyer ID")}
            <Tooltip title={"This field is automatically filled after you integrate Appsflyer on PMP"}>
              <InfoCircleOutlined className="ml-2 mr-1" />
            </Tooltip>
          </>
        }
        name="appsflyer_app_id"
      >
        <Input disabled />
      </Form.Item>

      <Form.Item
        label={
          <>
            {renderLabel("IronSource ID")}
            <Tooltip title={"This field is automatically filled after you integrate IronSource on PMP"}>
              <InfoCircleOutlined className="ml-2 mr-1" />
            </Tooltip>
          </>
        }
        name="ironsource_app_id"
      >
        <Input disabled />
      </Form.Item>
    </div>
  );
};
