import {
  Button,
  Create,
  Form,
  getValueFromEvent,
  Icons,
  Input,
  Modal,
  Radio,
  Select,
  Upload,
  useForm,
} from "@pankod/refine-antd";
import { AssetInput } from "interfaces";
import { UploadOutlined } from "@ant-design/icons";
import useUpload, { UploadProps } from "hooks/useUpload";
import React from "react";
import config from "config";
import { css } from "@emotion/react";
import { CopyToClipboardControl } from "components/Input/CopyToClipboard";
import { isDuplicatedFieldFormList } from "utils/form";
import { TooltipCustom } from "components/Input/Tooltip";

const { FileAddOutlined, MinusCircleOutlined, PlusOutlined } = Icons;

export const awsMetadataKeys = [
  {
    label: "Content-Type",
    value: "ContentType",
  },
  {
    label: "Cache-Control",
    value: "CacheControl",
  },
  {
    label: "Content-Disposition",
    value: "ContentDisposition",
  },
  {
    label: "Content-Encoding",
    value: "ContentEncoding",
  },
  {
    label: "Content-Language",
    value: "ContentLanguage",
  },
  {
    label: "Expires",
    value: "Expires",
  },
];

export const ConentTypeHint: React.FC = () => {
  const contentTypeValues = [
    "binary/octet-stream",
    "image/png",
    "image/gif",
    "image/jepg",
    "image/bmp",
    "image/tiff",
    "image/svg+xml",
    "text/plain",
    "text/rtf",
    "application/msword",
    "application/zip",
    "audio/mpeg",
    "application/pdf",
    "application/x-gzip",
    "application/x-compressed",
    "application/vnd.android.package-archive",
  ];
  return (
    <div className="text-[12px]">
      <p className="italic text-[13px]">
        This is all types valid for <b>Content-Type</b> key. You can copy and paste it to the value's input
      </p>
      <ul>
        {contentTypeValues.map((item) => {
          return (
            <li key={item}>
              {item} <CopyToClipboardControl text={item} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const UploadModal: React.FC<{
  onClose: () => void;
  cb?: () => void;
  prefix?: string | null;
  bucketName: string;
  handleUpload: (data: UploadProps) => void;
  isOpen: boolean;
}> = ({ onClose, handleUpload, isOpen, prefix, bucketName }) => {
  const [isDirectory, setIsDirectory] = React.useState(false);
  const textName = isDirectory ? "folders" : "files";
  const { formProps, form } = useForm<AssetInput>();
  const { props, totalFiles, setTotalFiles } = useUpload();
  const _onClose = () => {
    onClose();
    setTotalFiles(0);
    form.resetFields();
  };
  const onFinish = async (e: any) => {
    _onClose();
    const files: any[] = e.file;
    const isError = files.some((file) => file.status === "error");
    if (isError) return;
    const url = `${config.S3_URL}${files.length > 1 ? "/uploadFiles" : "/uploadFile"}`;

    const metadata =
      Array.isArray(e.metadata) &&
      e.metadata?.reduce((curr: any, prev: any) => {
        if (!curr[prev.key]) {
          curr[prev.key] = prev.value;
        }
        return curr;
      }, {});
    handleUpload({
      fileList: files,
      bucketName,
      prefix,
      url,
      metadata: JSON.stringify(metadata),
    });
  };

  return (
    <Modal
      footer={
        <>
          <Button onClick={form.submit} icon={<UploadOutlined />}>
            Upload
          </Button>
        </>
      }
      visible={isOpen}
      centered
      onCancel={_onClose}
      css={css`
        .ant-page-header-heading {
          box-shadow: none !important;
          margin-bottom: 0;
        }
        .ant-upload.ant-upload-drag {
          border: 3px dashed #d9d9d9;
        }
        .ant-modal-body {
          padding: 10px;
        }
        .ant-card-body {
          max-height: 550px;
          overflow-y: auto;
        }
      `}
    >
      <div className="px-4">
        <Create
          title={"Upload S3"}
          pageHeaderProps={{
            backIcon: <></>,
          }}
          actionButtons={<></>}
        >
          <div className="px-4">
            <Form
              {...formProps}
              layout="vertical"
              onFinish={async (e: any) => {
                onFinish(e);
              }}
            >
              <div>
                <p className="italic text-[13px]">Maximum number of files is 200 & size is 200MB per file</p>
              </div>
              <div className="mb-2">
                <Radio.Group
                  onChange={(e) => {
                    const val = e.target.value;
                    setIsDirectory(val);
                  }}
                  value={isDirectory}
                >
                  <Radio value={false}>Upload File</Radio>
                  <Radio value={true}>Upload Folder</Radio>
                </Radio.Group>
              </div>
              <p>
                <b>Total file(s):</b> {totalFiles}
              </p>
              <Form.Item
                valuePropName="fileList"
                getValueFromEvent={getValueFromEvent}
                name="file"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Upload.Dragger
                  directory={isDirectory}
                  listType="text"
                  multiple
                  height={150}
                  showUploadList
                  maxCount={200}
                  {...props}
                >
                  <p>
                    <FileAddOutlined className="mr-2 text-[40px]" />
                  </p>
                  <p className="ant-upload-text">Drag & drop {textName} in this area</p>
                </Upload.Dragger>
              </Form.Item>
              <Form.Item
                label={
                  <p className="mb-0 text-[16px]">
                    Metadata - <i className="font-normal text-[14px]">optional</i>
                  </p>
                }
              >
                <Form.List
                  name="metadata"
                  rules={[
                    {
                      validator: (_rules, value) => {
                        if (!isDuplicatedFieldFormList("key", value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("You can add each metadata key only once. Please check it again!"),
                        );
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <div key={key} className={`flex space-x-2 mb-4`}>
                          <Form.Item
                            {...restField}
                            label={`${index === 0 ? "Key" : ""}`}
                            name={[name, "key"]}
                            rules={[{ required: true, message: "Missing key" }]}
                            className="w-1/2 mb-0"
                          >
                            <Select placeholder="Choose key" options={awsMetadataKeys}></Select>
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            label={
                              index === 0 ? (
                                <>
                                  Value
                                  <TooltipCustom title={ConentTypeHint} placement="right"></TooltipCustom>
                                </>
                              ) : (
                                ""
                              )
                            }
                            name={[name, "value"]}
                            rules={[{ required: true, message: "Missing value" }]}
                            className="w-1/2 mb-0"
                          >
                            <Input placeholder="Value" allowClear />
                          </Form.Item>
                          <MinusCircleOutlined
                            className={` ${
                              index === 0 ? "mt-[40px]" : "mt-[10px]"
                            } self-baseline text-[16px] text-red-500`}
                            onClick={() => remove(name)}
                          />
                        </div>
                      ))}
                      {errors && <p className="text-danger">{errors}</p>}

                      <Form.Item className="w-[150px] mb-2">
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          Add metadata
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </Form>
          </div>
        </Create>
      </div>
    </Modal>
  );
};

export default UploadModal;
