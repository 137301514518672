import { css } from "@emotion/react";
import {
  Button,
  Collapse,
  DateField,
  Form,
  Icons,
  Input,
  Select,
  TextField,
  Typography,
  useForm,
} from "@pankod/refine-antd";
import { useCreate, useCustom } from "@pankod/refine-core";
import { GetObjectOutput } from "aws-sdk/clients/s3";
import { CopyToClipboardTooltip } from "components/Input/CopyToClipboard";
import config from "config";
import { useMe } from "context/MeContext";
import ProductInfo from "factory/Product";
import tw from "twin.macro";
import { bytesToSize } from "utils/byteToSize";
import React from "react";
import { LoadingBlock } from "components/Loading/LoadingBlock";
import { awsMetadataKeys, ConentTypeHint } from "components/Modal/UploadModal";
import { getLastPartOfPath } from "utils/folderAndFile";
import { isDuplicatedFieldFormList } from "utils/form";
import { TooltipCustom } from "components/Input/Tooltip";
import { EnumRoleProduct } from "enums";
import { useGetMyRole } from "hooks/useGetMyRole";
const { Title, Text } = Typography;
const { Panel } = Collapse;
const { PlusOutlined, MinusCircleOutlined } = Icons;
interface ObjectDetailProps {
  productInfo?: ProductInfo;
  region?: string;
  bucketName: string;
  prefix?: string | null;
  loading?: boolean;
}

export const ObjectDetail: React.FC<ObjectDetailProps> = ({ productInfo, region, bucketName, prefix, loading }) => {
  const me = useMe();
  const type = getLastPartOfPath(prefix || "")
    .split(".")
    .pop();

  const { data, isLoading, refetch } = useCustom<GetObjectOutput>({
    url: `${config.S3_URL}/getObjectInfo`,
    method: "get",
    queryOptions: {
      enabled: Boolean(me?.data?.id) && Boolean(productInfo?.id),
      retry: 0,
    },
    config: {
      query: {
        bucketName,
        keys: [prefix],
        userId: me?.data?.id,
        productId: productInfo?.id,
      },
    },
    successNotification: false,
    errorNotification: false,
  });
  const objectInfo = data?.data;
  const s3URI = `s3://${bucketName}/${prefix}`;
  const arn = `arn:aws:s3:::${bucketName}/${prefix}`;
  const etag = objectInfo?.ETag ? JSON.parse(objectInfo?.ETag) : "";
  const objectURL = `https://${bucketName}.s3.${region}.amazonaws.com/${prefix}`;

  const extrasDataRaw = objectInfo ? Object.entries(objectInfo) : [];
  const extrasData: { key: string; value: string }[] = extrasDataRaw
    .filter((item) => awsMetadataKeys.some((a) => a.value === item[0]))
    .reduce((obj: any, item) => {
      const object: any = {};
      object["key"] = item[0];
      object["value"] = item[1];
      obj.push(object);
      return obj;
    }, []);
  const { form } = useForm();

  const { mutateAsync: updateMetadata, isLoading: isLoadingUpdate, isIdle } = useCreate();
  React.useEffect(() => {
    !isLoadingUpdate &&
      isIdle &&
      form.setFieldsValue({
        metadata: extrasData,
      });
  }, [extrasData, form, isLoadingUpdate, isIdle]);
  const { userRoleProductPermission: userRolePermision } = useGetMyRole();
  const isDisabled = userRolePermision === EnumRoleProduct.Viewer || productInfo?.isDisabled;
  return (
    <div className="relative">
      {(isLoading || loading) && <LoadingBlock />}
      <Collapse defaultActiveKey={["1", "2"]} ghost>
        <Panel
          className="text-[14px]"
          header={<span className="text-[#2D2D2D] font-normal">Object overview</span>}
          key="1"
        >
          <div
            className="flex -mx-2 w-full flex-wrap"
            css={css`
              > div {
                ${tw`w-1/2 px-2 mb-6`}
                > div {
                  ${tw`mb-6`}
                }
              }
            `}
          >
            <div>
              <div>
                <Title level={5}>AWS Region</Title>
                <Text>{region}</Text>
              </div>
              <div>
                <Title level={5}>Last modified</Title>
                <Text>
                  <DateField format="YYYY-MM-DD hh:ss" value={objectInfo?.LastModified} />
                </Text>
              </div>
              <div>
                <Title level={5}>Size</Title>
                <Text>
                  <TextField value={bytesToSize(objectInfo?.ContentLength)} />
                </Text>
              </div>
              <div>
                <Title level={5}>Type</Title>
                <Text>{type}</Text>
              </div>
              <div>
                <Title level={5}>Key</Title>
                <Text>
                  {prefix}
                  <CopyToClipboardTooltip text={prefix || ""} />
                </Text>
              </div>
            </div>
            <div>
              <div>
                <Title level={5}>S3 URI</Title>
                <Text>
                  {s3URI}
                  <CopyToClipboardTooltip text={s3URI || ""} />
                </Text>
              </div>
              <div>
                <Title level={5}>Amazon Resource Name (ARN)</Title>
                <Text>
                  {arn}
                  <CopyToClipboardTooltip text={arn || ""} />
                </Text>
              </div>
              <div>
                <Title level={5}>Entity tag (Etag)</Title>
                <Text>
                  {etag}
                  <CopyToClipboardTooltip text={etag || ""} />
                </Text>
              </div>
              <div>
                <Title level={5}>Object URL</Title>
                <Text>
                  {objectURL}
                  <CopyToClipboardTooltip text={objectURL || ""} />
                </Text>
              </div>
            </div>
          </div>
        </Panel>
        <Panel className="text-[14px]" header={<span className="text-[#2D2D2D] font-normal">Metadata</span>} key="2">
          {!isDisabled && (
            <div className="w-1/2">
              <Form
                form={form}
                layout="vertical"
                onFinish={async (e: any) => {
                  const metadata =
                    Array.isArray(e.metadata) &&
                    e.metadata?.reduce((curr: any, prev: any) => {
                      if (!curr[prev.key]) {
                        curr[prev.key] = prev.value;
                      }
                      return curr;
                    }, {});

                  const res = await updateMetadata({
                    resource: "updateMetadata",
                    values: {
                      bucketName,
                      key: prefix,
                      from: `${bucketName}/${prefix}`,
                      metadata,
                      userId: me?.data?.id,
                      productId: productInfo?.id,
                    },
                    dataProviderName: "s3Service",
                    successNotification: {
                      type: "success",
                      description: "Success",
                      message: "Successfully updated metadata",
                    },
                  });
                  if (res.data) {
                    refetch();
                  }
                }}
              >
                <Form.List
                  name="metadata"
                  rules={[
                    {
                      validator: (_rules, value) => {
                        if (!isDuplicatedFieldFormList("key", value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("You can add each metadata key only once. Please check it again!"),
                        );
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <div key={key} className={`items-center space-x-2 mb-4 flex`}>
                          <Form.Item
                            {...restField}
                            label={`${index === 0 ? "Key" : ""}`}
                            name={[name, "key"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing key",
                              },
                            ]}
                            className="w-1/2 mb-0"
                          >
                            <Select placeholder="Choose key" options={awsMetadataKeys}></Select>
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            label={
                              index === 0 ? (
                                <>
                                  Value
                                  <TooltipCustom title={ConentTypeHint} placement="right"></TooltipCustom>
                                </>
                              ) : (
                                ""
                              )
                            }
                            name={[name, "value"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing value",
                              },
                            ]}
                            className="w-1/2 mb-0"
                          >
                            <Input placeholder="Value" allowClear />
                          </Form.Item>
                          <MinusCircleOutlined
                            className={` ${
                              index === 0 ? "mt-[36px]" : "mt-[10px]"
                            } self-baseline text-[16px] text-red-500`}
                            onClick={() => remove(name)}
                          />
                        </div>
                      ))}
                      {errors && <p className="text-danger">{errors}</p>}
                      <div className="flex items-center justify-between mb-2 w-full">
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} className="w-[150px]">
                          Add metadata
                        </Button>
                        <Button loading={isLoadingUpdate} onClick={form.submit}>
                          Update
                        </Button>
                      </div>
                    </>
                  )}
                </Form.List>
              </Form>
            </div>
          )}
        </Panel>
      </Collapse>
    </div>
  );
};
