// themes
import { palette, systemColors, organicColor, nonOrganicColor, clicksColor, impressionsColor } from "themes";

// interface
import { ICurrItem, ISource } from "interfaces/chart";

// configs
import { COUNTRY } from "config";
import {
  DIMENSIONS,
  METRIC,
  METRICS_TO_FORMAT_CONFIG,
  GROUPINGS,
  KPIS,
  EVENT_KPIS,
  DASHBOARD_LABEL_MAP,
  ATTRIBUTION_ENTITY,
  SOURCE,
} from "../chartConfig";

// utils
import { numberToUsd } from "utils/numberToUsd";
import { numberWithSeparator } from "utils/numberWithSeparator";

/**
 * Used to validate if the given parm is a string.
 *
 * @param {any} possibleString
 * @returns {boolean} isAString ? true : false
 */
export function isAString(possibleString: string) {
  return typeof possibleString === "string";
}

/**
 * Used to validate if the given param is an array.
 *
 * @param {any} possibleObject
 * @returns {boolean} isAnArray ? true : false
 */
export function isAnArray(possibleArray: string) {
  return Array.isArray(possibleArray);
}

/**
 * Used to validate if the given parm is a boolean.
 *
 * @param {any} possibleBoolean
 * @returns {boolean} isABoolean ? true : false
 */
function isABoolean(possibleBoolean: string) {
  return typeof possibleBoolean === "boolean";
}

const getDashboardTypeLabel = (value: string) => DASHBOARD_LABEL_MAP[value];

const isValidValue = (value: string, groupingKey: string) =>
  value && isAString(groupingKey) && (isAString(value) || isABoolean(value));

const isValidTransparentAgencies = (transparentAgencies: string) =>
  transparentAgencies && isAnArray(transparentAgencies);

export function generateAxisFormatted(xAxis: any, metric: string) {
  let number: any = 0;
  if (xAxis === null || xAxis === undefined) {
    return number;
  }

  number = numberWithSeparator(xAxis, ",");

  if (metric === METRIC.SKANRevenue.value) {
    number = parseFloat(number).toFixed(2);
  }

  if (metric === METRIC.Cost.value) {
    number = numberToUsd.format(xAxis);
  }

  if (metric === METRIC.ConvRate.value || metric === METRIC.ROI.value || metric === METRIC.Roas.value) {
    number = Intl.NumberFormat("en-US", METRICS_TO_FORMAT_CONFIG[metric].format).format(xAxis);
  }

  if (
    metric === METRIC.GrossProfit.value ||
    metric === METRIC.AvgEcpi.value ||
    metric === METRIC.Arpu.value ||
    metric === METRIC.EventRevenue.value
  ) {
    if (xAxis === 0) {
      number = 0;
    } else {
      number = "$" + Intl.NumberFormat("en-US", METRICS_TO_FORMAT_CONFIG[metric].format).format(xAxis);
    }
  }

  return number.toString();
}

export function getItemColorOrganic(currItem: ICurrItem, dimension: string, color: string) {
  let colorOrganic = color;
  if (currItem[dimension] === "organic" || currItem[dimension] === "non-organic") {
    colorOrganic = systemColors[currItem[dimension]];
  }

  if (dimension === GROUPINGS.SOURCE) {
    colorOrganic = "organic" ? organicColor : nonOrganicColor;
  }

  if (dimension === DIMENSIONS.Source.label) {
    colorOrganic = currItem.source === "organic" ? organicColor : nonOrganicColor;
  }

  return colorOrganic;
}

export const getItemColor = (dimension: string, currIndex: number, currItem: ICurrItem, metric?: string) => {
  let color = getItemColorOrganic(currItem, dimension, palette[currIndex]);

  if (currItem.source) {
    color = systemColors[currItem.source];
    return color;
  }

  if (dimension === DIMENSIONS.EventName.value && currItem.source) {
    color = systemColors[currItem.source];
    return color;
  }

  if (dimension === DIMENSIONS.AttributedTouchType.value) {
    if (currItem[dimension] === KPIS.CLICKS || currItem[dimension] === KPIS.CLICK) return clicksColor;
  }

  if (dimension === GROUPINGS.APPID) {
    if (metric === KPIS.CLICKS || metric === KPIS.CLICK) return clicksColor;
    if (metric === KPIS.IMPRESSIONS) return impressionsColor;
  }

  return color;
};

export function getOptionLabel(value: string, groupingKey: string, transparentAgencies: any = []) {
  if (!isValidValue(value, groupingKey) || !isValidTransparentAgencies(transparentAgencies)) {
    return;
  }

  if (value === "None") return "None";
  if (value === ATTRIBUTION_ENTITY.APPS_FLYER) return "AF model";
  if (value === ATTRIBUTION_ENTITY.SKAN) return "SKAN uniques";

  switch (groupingKey) {
    case GROUPINGS.VIEW_TYPE:
      return getDashboardTypeLabel(value);

    case GROUPINGS.COUNTRY:
      return `${new Intl.DisplayNames("en", { type: "region" }).of(value)} (${value})`;

    case GROUPINGS.ATTRIBUTION_TYPE:
      return value;

    case GROUPINGS.ATTRIBUTED_TOUCH_TYPE:
      return value;

    case GROUPINGS.SOURCE:
      return value;

    case GROUPINGS.PARTNER:
      return transparentAgencies?.find((a: any) => a.toLowerCase() === value.toLowerCase())
        ? `${value} transparent`
        : value;

    default:
      if (value === SOURCE.ORGANIC) return "Organic" ?? "Non-organic";
      return value;
  }
}

export const getLabel = (dimension: string, currItem: ICurrItem) => {
  let label = currItem[dimension];
  if (currItem[dimension] === SOURCE.ORGANIC) {
    label = "Organic";
  }

  if (currItem[dimension] === SOURCE.NON_ORGANIC) {
    label = "Non-organic";
  }

  return dimension === DIMENSIONS.Country.value ? `${COUNTRY[currItem[dimension]]} (${currItem[dimension]})` : label;
};
// , type === 'spline' && yAxis: 1
export function mappingDataTouchPoints(data: ISource[], categories: string[], type?: string) {
  let initialSeries: any[] = [
    { name: "impressions", orignialName: "impressions", color: impressionsColor, data: [] },
    { name: "clicks", orignialName: "clicks", color: clicksColor, data: [] },
  ];

  if (type === "spline") {
    initialSeries = [
      { name: "impressions", orignialName: "impressions", color: impressionsColor, data: [] },
      { name: "clicks", orignialName: "clicks", color: clicksColor, data: [], yAxis: 1 },
    ];
  }
  const temp = data.reduce(
    (acc, curr) => {
      const { clicks, date, impressions } = curr;
      if (!categories.includes(date)) {
        categories.push(date);
      }
      if (impressions) {
        acc.series[0].data.push(impressions);
      }
      if (clicks) {
        acc.series[1].data.push(clicks);
      }
      return acc;
    },
    {
      series: initialSeries,
    },
  );

  return {
    series: temp.series,
    categories: [...categories].slice(1),
  };
}

export function formatterLabelChart(sum: any, metric: string) {
  let result: string | number = numberWithSeparator(sum, ",");
  if (metric === METRIC.Cost.value || metric === METRIC.SKANRevenue.value) {
    result = generateAxisFormatted(sum, metric);
  }
  if (metric === METRIC.ConvRate.value || metric === METRIC.ROI.value || metric === METRIC.Roas.value) {
    const numberParsed = generateAxisFormatted(sum, metric);
    const res = numberParsed.match(/(\d+\.?\d*)%/);
    result = `${res.input}`;
  }

  if (metric === EVENT_KPIS.EventConversion.value || metric === EVENT_KPIS.EventRatio.value) {
    result = Intl.NumberFormat("en-US", METRICS_TO_FORMAT_CONFIG[metric].format).format(sum);
  }

  if (
    metric === METRIC.GrossProfit.value ||
    metric === METRIC.AvgEcpi.value ||
    metric === METRIC.Arpu.value ||
    metric === EVENT_KPIS.EventECPA.value ||
    metric === METRIC.EventRevenue.value
  ) {
    result = "$" + Intl.NumberFormat("en-US", METRICS_TO_FORMAT_CONFIG[metric].format).format(sum);
  }

  return result;
}

export const getNameFomatter = (dimension: string, name: string) => {
  let otherName = name;
  const nameFomatter = COUNTRY[name] === "None" ? COUNTRY[name] : `${COUNTRY[name]} (${name})`;

  if (name === "attrtouch_unset") {
    otherName = "unknown";
  }

  if (name === "install") {
    otherName = "installs";
  }

  if (name === "reattr") {
    otherName = "re-attributions";
  }

  return dimension === DIMENSIONS.Country.value ? nameFomatter : otherName;
};

export function getStackingBarChart(metricSelected: string) {
  return metricSelected === KPIS.ASSISTS ? "percent" : "normal";
}
