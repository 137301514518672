import Page403 from "components/Result/403";
import Page404 from "components/Result/404";
import Page500 from "components/Result/500";
import NotAcceptInvitation from "components/Result/NotAcceptInvitation";
import NotInAStudio from "components/Result/NotInAStudio";
import { IRoute } from "interfaces";
import { Invitations } from "pages/Auth/Invitations";
import { Login } from "pages/Auth/login";
import LoginRedirect from "pages/Auth/LoginRedirect";

export const generalRoutes: IRoute[] = [
  {
    element: <Page404 />,
    path: "404",
  },
  {
    element: <Page403 />,
    path: "403",
  },
  {
    element: <Page500 />,
    path: "500",
  },
  {
    element: <NotInAStudio />,
    path: "not-in-a-studio",
  },
  {
    element: <NotAcceptInvitation />,
    path: "not-accept-invitation",
  },
  {
    element: <Login />,
    path: "login",
  },
  {
    element: <LoginRedirect />,
    path: "connect/auth0",
  },
  {
    element: <Invitations />,
    path: "invitations/:userId",
  },
];
