import React from "react";

import { IOptionMetric, IOptionDimension } from "interfaces/chart";
import { DIMENSIONS } from "utils/chart/chartConfig";
import { mapMetricByDimension } from "utils/chart/chartHelper/mapMetricByDimension";

export function useFilterChart(valueDimension: string, valueMetric: string, defaultChartType: string) {
  const [chartTypeSelected, setChartTypeSelected] = React.useState(defaultChartType);
  const [dimensionSelected, setDimensionSelected] = React.useState(valueDimension);
  const optionMetrics: IOptionMetric[] = mapMetricByDimension(dimensionSelected);
  const optionDimensions: IOptionDimension[] = Object.keys(DIMENSIONS).map((key: string) => DIMENSIONS[key]);
  const [metricSelected, setMetricSelected] = React.useState(valueMetric);

  const onChangeDimension = (value: string) => {
    const optionMetrics = mapMetricByDimension(value);
    setDimensionSelected(value);
    setMetricSelected(optionMetrics[0].value);
  };

  const onChangeMetric = (value: string) => {
    setMetricSelected(value);
  };

  return {
    optionMetrics,
    optionDimensions,
    metricSelected,
    dimensionSelected,
    onChangeDimension,
    onChangeMetric,
    chartTypeSelected,
    setChartTypeSelected,
  };
}
